import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { BoqItemAlternativeTextService } from "../../services/BoqItemAlternativeTextervice";
import { BoqItemAlternativeTextModel } from "../../models/BoqItemAlternativeTextModel";
import { Title } from "@mui/icons-material";

const BoqItemAlternativeTextForm = (...props: any) => {

  const { BoqitemId, BoqitemAlternativeTextId } = useParams();
 
  let navigate = useNavigate();
    const { Code }: any = useParams();
    const mode = Code ? 'Edit' : 'Create';
    const validate = (fieldValues = values) => {
      let temp: any = { ...errors };
      if ("BoqitemAlternativeTextName" in fieldValues)
        temp.BoqitemAlternativeTextName = fieldValues.BoqitemAlternativeTextName
          ? ""
          : "BOQ Item AlternativeText Name is required.";
      setErrors({
        ...temp,
      });
  
      if (fieldValues === values)
        return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(BoqItemAlternativeTextService.initialFieldValues, validate, BoqitemAlternativeTextId);

  const newItemAltText = () => {
    setValues(BoqItemAlternativeTextService.initialFieldValues);
  };

  function setFormValue(model: BoqItemAlternativeTextModel) {
    let newModel = {
      BoqitemAlternativeTextId: model.BoqitemAlternativeTextId,
      BoqitemId: model.BoqitemId || "",
      BoqsubTitleId:model.BoqsubTitleId || "",
      BoqitemAlternativeTextName: model.BoqitemAlternativeTextName || "",
      Active: model.Active,
    };
    return newModel;
  }
    useEffect(() => {
      if (BoqitemAlternativeTextId) {
        getItemAltText(BoqitemAlternativeTextId);
        setErrors({});
      } else newItemAltText();
    }, [BoqitemAlternativeTextId]);

    const getItemAltText = (BoqitemAlternativeTextId: any) => {
      BoqItemAlternativeTextService.GetById(BoqitemAlternativeTextId).then((response) => {
        if (response) {
          let result = response.data;
          setValues(setFormValue(result.list));
        }
      });
    };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (validate()) {
              
        values.BoqitemId = BoqitemId;

        if (BoqitemAlternativeTextId) {
          BoqItemAlternativeTextService.put(values).then((response: any) => {
            let result = response.data;
            if (response) {
              globalService.success(result.message);
              navigate("/boqItemAlternativeTexts/" + BoqitemId);
            } else {
              globalService.error(result.message);
            }
          });
        } else {
          
          BoqItemAlternativeTextService.post(values).then((response: any) => {
            if (response) {
              let result = response.data;
  
              if (result.isSuccess) {
                globalService.success(result.message);
               
                navigate("/boqItemAlternativeTexts/" + BoqitemId);
              } else {
                globalService.error(result.message);
              }
            }
          });
        }
      }
    };
  
  
  return (
    <>
    <Typography>  BOQ Item Alternative Text</Typography>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="BOQ Item Alternative Text Name"
                    name="BoqitemAlternativeTextName"
                    value={values.BoqitemAlternativeTextName}
                    onChange={handleInputChange}
                    error={errors.BoqitemAlternativeTextName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}
                 className={mode !== "Edit" ? "hidden" : ""}
                 >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/boqItemAlternativeTexts/" + BoqitemId)}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
    
    </>
  )
}

export default BoqItemAlternativeTextForm