import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import { useNavigate, useParams } from "react-router-dom";
import { mPaymentTermsService } from "../../services/MPaymentTermsService";
import useForm from "../../utility/hooks/UseForm";
import { globalService } from "../../services/GlobalService";
import { PaymentTermsModel } from "../../models/MPaymentTermsModel";
import { useScatterSeries } from "@mui/x-charts/hooks/useSeries";
import { SelectListModel } from "../../models/ApiResponse";

const MPaymentTermsForm = () => {
  const { PaymentTermId } = useParams();

  const [PaymentTermTypeList, setPaymentTermTypeList] = useState<
    SelectListModel[]
  >([]);

  let navigate = useNavigate();

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    
    if ("PaymentTerm" in fieldValues)
      temp.PaymentTerm = fieldValues.PaymentTerm
        ? ""
        : "Term's And Conditions is required";

    if ("PaymentTermTypeId" in fieldValues)
      temp.PaymentTermTypeId = fieldValues.PaymentTermTypeId
        ? ""
        : "Please Select Payment Term Type";

    setErrors({ ...temp });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mPaymentTermsService.initialFieldValues, validate, PaymentTermId);

  const newPaymmentTerms = () => {
    setValues(mPaymentTermsService.initialFieldValues);
  };

  function setFormValue(model: PaymentTermsModel) {
    
    let newModel = {
      PaymentTermId: model.PaymentTermId,
      PaymentTerm: model.PaymentTerm || "",
      PaymentTermTypeId: model.PaymentTermTypeId.toString() || "",
      PaymentTermType: model.PaymentTermType || "",
      Active: model.Active,
    };
    return newModel;
  }

  useEffect(() => {
    if (PaymentTermTypeList.length === 0) getPaymentTermTypesList();
    getPaymentTermTypesList();
    if (PaymentTermId) {
      getPaymentTermsType(PaymentTermId);
      setErrors({});
    } else newPaymmentTerms();
  }, [PaymentTermId]);

  const getPaymentTermsType = (PaymentTermId: any) => {
    mPaymentTermsService.getById(PaymentTermId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.row));
      }
    });
  };

  const getPaymentTermTypesList = () => {
    mPaymentTermsService.getPaymentTermsTypeSelectList().then((response) => {
      setPaymentTermTypeList(response.data);
    });
  };

  const hadleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (PaymentTermId) {
        mPaymentTermsService.put(values).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/mPaymentTerms");
          } else {
            globalService.success(result.message);
          }
        });
      } else {
        mPaymentTermsService.post(values).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/mPaymentTerms");
          } else {
            globalService.success(result.message);
          }
        });
      }
    }
  };

  const mode = PaymentTermId ? "Edit" : "Create";
  return (
    <>
      <Typography variant="h5" align="center">
      Term's And Conditions
      </Typography>

      <form autoComplete="off" noValidate onSubmit={hadleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Select
                    required
                    // showEmptyItem={false}
                    name="PaymentTermTypeId"
                    label="Payment Term Type"
                    options={PaymentTermTypeList}
                    value={
                      PaymentTermTypeList.length > 0
                        ? values.PaymentTermTypeId
                        : ""
                    }
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.PaymentTermTypeId}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Controls.Input
                    label="Term's And Conditions"
                    name="PaymentTerm"
                    multiline
                    rows={4}
                    value={values.PaymentTerm}
                    onChange={handleInputChange}
                    error={errors.PaymentTerm}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>

              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/mPaymentTerms")}
              >
                Back
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default MPaymentTermsForm;
