import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  Divider,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  CardActions,
  DialogActions,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import MessageIcon from "@mui/icons-material/Message";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ExpenseHdrsService } from "../../services/ExpenseHdrsService";
import dayjs from "dayjs";
import Controls from "../../utility/Controls.tsx/Controls";
import {
  AuthContext,
  initialFieldValues,
} from "../../utility/context/AuthContext";
import useForm from "../../utility/hooks/UseForm";
import { globalService } from "../../services/GlobalService";
import { ExpenseHdrsModel } from "../../models/ExpenseHdrsModel";
import ExpenseQueriesList from "./ExpenseQueriesList";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { expenseDtlFileService } from "../../services/ExpenseDtlFileService";
import { config } from "../../utility/Config";
import { ExpenseQueriesModel } from "../../models/ExpenseQueriesModel";
import { Guid } from "guid-typescript";
import { PoApprovalService } from "../../services/PoApprovalService";
import { BoqposService } from "../../services/BoqPosService";
import { mMailerService } from "../../services/MMailerService";
import { MMailerModel } from "../../models/MMailerModel";
import { BoqDocFileService } from "../../services/BoqdocFilesService";
import DownloadIcon from "@mui/icons-material/Download";
import fileDownload from "js-file-download";
import { reportService } from "../../services/ReportService";
import { SelectListModel } from "../../models/ApiResponse";
import { MProcurementTypesService } from "../../services/MProcurementTypesService";

const PoApprovalList: React.FC = (...props: any) => {
  const [viewDocsFiles, setViewDocsFiles] = useState([]);
  const { procurementTypeId } = useParams();
  const [procurementTypes, setProcurementTypes] = useState<SelectListModel[]>(
    []
  );
  const [procurementsForApproval, setProcurementsForApproval] = useState([]);
  const [viewPaymentTermsOpen, setViewPaymentTermsOpen] = React.useState(false);
  const [procurementsForApprovalHistorys, setProcurementsForApprovalHistory] =
    useState([]);
  const [expenseManager, setExpenseManager] = useState([]);
  const [viewPaymentTerms, setViewPaymentTerms] = useState([]);
  const [openRejectD, setopenRejectD] = useState(false);
  const { auth } = React.useContext(AuthContext);
  const [viewDocsOpen, setViewDocsOpen] = React.useState(false);
  const [openExpenseManagerD, setopenExpenseManagerD] = useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const { ExpenseHdrId } = useParams();
  const [value, setValue] = React.useState("1");
  const [expenseHdrId, setExpenseHdrId] = useState<any>(null);
  const [expenseQueryOpen, setExpenseQueryOpen] = React.useState(false);
  const [expenseDtlDocOpen, setExpenseDtlDocOpen] = React.useState(false);
  const [expenseDtlFiles, setExpenseDtlFiles] = useState([]);
  const navigate = useNavigate();
  const UserId = auth.Id;

  const openExpenseDtlDocDialog = (expenseHdrId: any) => {
    setExpenseDtlDocOpen(true);
  };

  const closeExpenseDtlDocDialog = () => {
    setExpenseDtlDocOpen(false);
  };

  const openExpenseQueryDialog = (expenseHdrId: any) => {
    setExpenseHdrId(expenseHdrId);
    setExpenseQueryOpen(true);
  };
  const closeExpenseQueryDialog = () => {
    setExpenseQueryOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const handleRejectopen = (BOQPOB2BID: any) => {
    values.Boqpob2bid = BOQPOB2BID;
    setopenRejectD(true);
  };

  const handleRejectclosed = () => {
    setopenRejectD(false);
  };

  const handleExpenseManageopen = (row: any) => {
    values.ExpenseHdrId = row;
    setopenExpenseManagerD(true);
  };
  const handleExpenseManageclose = () => {
    setopenExpenseManagerD(false);
  };
  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    if ("Status" in fieldValues)
      temp.Status = fieldValues.Status ? "" : "Please Select Status";
    if ("UserId" in fieldValues)
      temp.UserId = fieldValues.UserId ? "" : "Please Select The User Manager";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    if (procurementTypes.length === 0) getProcurementType();
    if (UserId) getProcurementsForApprovalHistory();
  }, []);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      BoqposService.initialFieldValuesforinsertDataFBoqpob2bsmodel,
      validate,
      props.setCurrentId
    );

  const getProcurementsForApprovalHistory = () => {
    values.UserID = UserId;
    values.Boqpob2bstatusId = "1";
    PoApprovalService.getProcurementsForApprovalHistory(values).then(
      (response) => {
        let result = response.data;
        setProcurementsForApproval(result.list);
      }
    );
  };

  const getProcurementType = () => {
    MProcurementTypesService.getAllSelectList().then((response: any) => {
      setProcurementTypes(response.data);
      setValues((prevValues: any) => ({
        ...prevValues,
        ProcurementTypeId: procurementTypeId, //response.data[0].Value,
      }));
    });
  };

  const SendingForApproval = (BOQPOB2BID: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const updatedValues = {
      ...values,
      BOQPOB2BID,
      Boqpob2bstatusId: 2,
    };

    values.Boqpob2bid = BOQPOB2BID;

    BoqposService.updateBoqPoStatusByApprover(updatedValues).then(
      (response: any) => {
        let result = response.data;
        if (response) {
          globalService.success(result.message);
          getProcurementsForApprovalHistory();
          navigate("/poApproval");
        } else {
          globalService.error(result.message);
        }
      }
    );
  };

  const SendingForRejection = (boqpob2bid: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    const updatedValues = {
      ...values,
      BOQPOB2BID: boqpob2bid,
      Boqpob2bstatusId: 3,
      RejectedRemarks: values.RejectedRemarks,
    };

    BoqposService.rejectBoqPoStatus(updatedValues).then((response: any) => {
      let result = response.data;
      if (response) {
        handleRejectclosed();
        globalService.success(result.message);
        getProcurementsForApprovalHistory();
        navigate("/poApproval");
      } else {
        globalService.error(result.message);
      }
    });
  };
  const openviewPaymentTermsDialog = (BOQPOB2BID: any) => {
    setViewPaymentTermsOpen(true);
  };

  const closeViewPaymentTermsDialog = () => {
    setViewPaymentTermsOpen(false);
  };
  const openviewPoDocsDialog = (ProjectDirectoryId: any) => {
    setViewDocsOpen(true);
    getByProjectDirectoryId(ProjectDirectoryId);
  };
  const closeViewDocsDialog = () => {
    setViewDocsOpen(false);
  };

  const getByProjectDirectoryId = (ProjectDirectoryId: any) => {
    values.ProjectDirectoryID = ProjectDirectoryId;
    BoqDocFileService.getByProjectDirectoryId(ProjectDirectoryId).then(
      (response) => {
        let result = response.data;
        setViewDocsFiles(result.list);
      }
    );
  };

  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 3);
    return oneMonthsAgo;
  }
  const handleConfirmationReject = (boqpob2bid: any) => {
    if (!values.RejectedRemarks) {
      return globalService.error("Rejection Remark is required.");
    }
    values.Boqpob2bid = boqpob2bid;
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to send for Reject?",
      subTitle: "Once send, changes won't be allowed",
      onConfirm: () => {
        SendingForRejection(boqpob2bid);
      },
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    values.UserID = UserId;
    values.Boqpob2bstatusId = "";
    PoApprovalService.getProcurementsForApprovalHistory(values).then(
      (response) => {
        let result = response.data;
        setProcurementsForApprovalHistory(result.list);
      }
    );
  };

  const sendMail = (row: any) => {
    values.VendorIds = row.VendorId;
    values.BoqPob2bId = row.BOQPOB2BID;
    mMailerService.sendPOMailToVendor(values).then((response: any) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          globalService.success("Mail Sent Successfully");
          //navigate("/mZones")
        } else {
          globalService.error(result.message);
        }
      }
    });
  };

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(
      `${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`,
      "_blank"
    );
  };

  const downloadReport = (BOQPOB2BID: any) => {
    var poB2bId = BOQPOB2BID;
    reportService.downloadBoqPOPdf(poB2bId).then((response) => {
      let result = response.data;
      let fileName = "BOQPOReport.pdf";
      fileDownload(result, fileName);
    });
  };

  const itemcoloumnsapprovalHistorys: GridColDef[] = [
    { field: "Project", headerName: "Project", width: 130 },
    { field: "BOQPOB2BNo", headerName: "PO No", width: 130 },
    {
      field: "BOQPOB2BDate",
      headerName: "PO Date",
      width: 100,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },

    { field: "VendorName", headerName: "Vendor Name", width: 130 },
    {
      field: "ExpectedDateOfDelivery",
      headerName: "Expected Date Of Delivery",
      width: 100,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
    
    
      field: "POGrossTotal",
      headerName: "Gross Amount",
      width: 140,
      headerAlign: "right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
      cellClassName: "cell-right",

    },
    {
      field: "ApprovedRejectedBy",
      headerName: "Approved Rejected By User",
      width: 130,
    },
    {
      field: "ApprovedRejectedOn",
      headerName: "Approved Rejected On",
      width: 130,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "BOQPOB2BStatus",
      headerName: "PO Status",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              {params.value}
              {params.row.BOQPOB2BStatus === "Rejected" && (
                <Tooltip
                  title={params.row.RejectedRemarks ?? ""}
                >
                  <IconButton
                    size="small"
                    color="error"
                    aria-label="add an alarm"
                  >
                    <MessageIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </>
        );
      },
    },
    {
      field: "ViewDocs",
      headerName: "Docs",
      width: 80,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => {
                openviewPoDocsDialog(params.row.ProjectDirectoryID);
              }}
            >
              View ({params.row.TotDocs})
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "BOQRFQID",
      headerName: "Terms & Condition",
      width: 80,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => {
                openviewPaymentTermsDialog(params.row.BOQPOB2BID);
              }}
            >
              View ({params.row.TotTerms})
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "Status",
      headerName: "Action",
      width: 100,
      //flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            {/* <IconButton
                size="small"
                color="primary"
                aria-label="edit expense"
                onClick={
                  () => {
                    openExpenseDtlDocDialog(params.row.ExpenseHdrID);
                  }
                }
              >
                <AttachFileIcon fontSize="inherit" />
              </IconButton> */}
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqPODtls/" + params.row.BOQPOB2BID, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Details ({params.row.TotDetails})
            </Button>
          </Stack>
        );
      },
    },
    //{ field: "Level1ApprovalStatusDate", headerName: "Level1 Approval Status Date", flex: 1, },

    {
      field: "SendMail",
      headerName: "Send Mail",
      width: 100,
      // flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => sendMail(params.row)}
            >
              Send Mail
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "Preview",
      headerName: "Preview",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            size="large"
            color="primary"
            aria-label="add an alarm"
            className="btnGrid"
            variant="contained"
            onClick={(e) => downloadReport(params.row.BOQPOB2BID)}
          >
            <DownloadIcon fontSize="inherit" /> PO
          </Button>
        );
      },
    },
  ];
  const BoqPoB2BPaymentTermsColumns: GridColDef[] = [
    {
      field: "PaymentTerm",
      headerName: "Term & Conditions",
      flex: 1,
      valueGetter: (params) => params.row.MPaymentTerms?.PaymentTerm,
    },
  ];

  const procurementsForApprovalColumns: GridColDef[] = [
    {
      field: "Status",
      headerName: "Action",
      width: 220,
      //flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="row">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqPODtls/" + params.row.BOQPOB2BID, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Details ({params.row.TotDetails})
            </Button>

            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure, want to Approve",
                  subTitle: "Once send, changes won't be allowed",
                  onConfirm: () => {
                    SendingForApproval(params.row.BOQPOB2BID);
                  },
                });
              }}
            >
              Approve
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => {
                handleRejectopen(params.row.BOQPOB2BID);
              }}
            >
              Reject
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "Project",
      headerName: "Project",
      // width: 130,
      flex: 1,
    },
    {
      field: "BOQPOB2BNo",
      headerName: "PO No",
      //width: 130,
      flex: 1,
    },
    {
      field: "VendorName",
      headerName: "Vendor Name",
      // width: 130,
      flex: 1,
    },
    {
      field: "BOQPOB2BDate",
      headerName: "PO Date",
      width: 130,

      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "POGrossTotal",
      headerName: "Gross Amount",
      width: 130,

      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "ExpectedDateOfDelivery",
      headerName: "Expected Date Of Delivery",
      width: 120,

      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },

    {
      field: "BOQPOB2BStatus",
      headerName: "Status",
      // width: 120,
      flex: 1,
    },
    {
      field: "Preview",
      headerName: "Preview",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            size="large"
            color="primary"
            aria-label="add an alarm"
            className="btnGrid"
            variant="contained"
            onClick={(e) => downloadReport(params.row.BOQPOB2BID)}
          >
            <DownloadIcon fontSize="inherit" /> PO
          </Button>
        );
      },
    },
  ];

  const expenseDtlFileColumns: GridColDef[] = [
    {
      field: "ExpenseHeadName",
      headerName: "Expense Head Name",
      flex: 1,
      valueGetter: (params) =>
        params.row.ExpenseDtl?.MExpenseHeads?.ExpenseHeadName,
    },
    {
      field: "FileName",
      headerName: "File Name",
      width: 500,
      renderCell: (params) => (
        <>
          <Link
            // sx={{ cursor: "pointer" }}
            onClick={(event) => handleFileClick(event, params.row)}
            to={""}
          >
            {params.row.FileName}
          </Link>
        </>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
  ];
  const boqDocumentviewColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          //sx={{ cursor: "pointer" }}
          onClick={(event) => handleFileClick(event, params.row)}
          to={""}
        >
          {params.row.FileName}
        </Link>
      ),
    },
    {
      field: "UserName",
      headerName: "Created By",
      width: 200,
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
  ];

  return (
    <>
      <Typography variant="h5" align="center">
        PO Approval / Rejection
      </Typography>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={2}>
                <Controls.Select
                  name="ProcurementTypeId"
                  label="Procurement Type"
                  required
                  disabled
                  showEmptyItem={true}
                  options={procurementTypes}
                  value={
                    procurementTypes.length > 0 ? values.ProcurementTypeId : ""
                  }
                  onChange={(e: any) => {
                    handleInputChange(e);
                  }}
                  error={errors.ProcurementTypeID}
                />
              </Grid>
              <Grid item xs={6} sm={10}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="To be Approved" value="1" />
                  <Tab label="History" value="" />
                </TabList>
              </Grid>
            </Grid>
          </Box>

          <TabPanel value="1">
            <Card>
              <CardContent>
                <div style={{ width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.BOQPOB2BID}
                    rows={procurementsForApproval}
                    columns={procurementsForApprovalColumns}
                    columnHeaderHeight={30}
                    autoHeight={true}
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          ExpenseHdrID: false,
                        },
                      },

                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,

                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel value="">
            <Card>
              <CardContent>
                <React.Fragment>
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={2}>
                      <Controls.ReactDatePicker
                        label="From Date"
                        // min={values.FromDt}
                        value={
                          (values.FromDt = values.FromDt || getDefaultDate())
                        }
                        max={new Date()}
                        //value={values.FromDt}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value:
                                globalService.getISODateOnlyForDatePicker(date),
                              name: "FromDt",
                            },
                          });
                        }}
                        error={errors.FromDt}
                      />
                    </Grid>

                    <Grid item xs={5} sm={2}>
                      <Controls.ReactDatePicker
                        label="To Date"
                        min={values.FromDt}
                        max={new Date()}
                        value={(values.ToDt = values.ToDt || new Date())}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value:
                                globalService.getISODateOnlyForDatePicker(date),
                              name: "ToDt",
                            },
                          });
                        }}
                        error={errors.ToDt}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <Button type="submit" variant="contained">
                        Go
                      </Button>
                    </Grid>
                  </Grid>

                  <CardContent>
                    <div style={{ width: "100%" }}>
                      <DataGrid
                        getRowId={(row) => row.BOQPOB2BID}
                        rows={procurementsForApprovalHistorys}
                        columns={itemcoloumnsapprovalHistorys}
                        columnHeaderHeight={30}
                        autoHeight={true}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              ExpenseHdrID: false,
                            },
                          },

                          pagination: { paginationModel: { pageSize: 50 } },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,

                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    </div>
                  </CardContent>
                </React.Fragment>
              </CardContent>
            </Card>
          </TabPanel>
        </TabContext>
      </form>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          onClose={handleRejectclosed}
          aria-labelledby="customized-dialog-title"
          open={openRejectD}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, textAlign: "center" }}
            id="customized-dialog-title"
          >
            Reject Remark / Query
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleRejectclosed}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme: any) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <form
              autoComplete="off"
              noValidate
              onClick={() => handleConfirmationReject(values.Boqpob2bid)}
            >
              <Card>
                <CardContent>
                  <React.Fragment>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Controls.Input
                          multiline
                          rows={4}
                          label="Remark / Query"
                          name="RejectedRemarks"
                          value={values.RejectedRemarks}
                          onChange={handleInputChange}
                          error={errors.RejectedRemarks}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                </CardContent>
                <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      color="error"
                      variant="contained"
                      // onClick={() =>
                      //   handleConfirmationReject(values.Boqpob2bid)
                      // }
                    >
                      Reject
                    </Button>
                  </Stack>
                </CardActions>
              </Card>
            </form>
          </DialogContent>
        </Dialog>
      </>
      <>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          onClose={handleExpenseManageclose}
          aria-labelledby="customized-dialog-title"
          open={openExpenseManagerD}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, textAlign: "center" }}
            id="customized-dialog-title"
          >
            Send to Expense Manager
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleExpenseManageclose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme: any) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <form autoComplete="off" noValidate onSubmit={SendingForRejection}>
              <Card>
                {/* <CardContent>
                    <React.Fragment>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Controls.Select
                            label="Manager Name"
                            name="UserId"
                            required
                            options={expenseManager}
                            value={expenseManager.length > 0 ? values.UserId : ""}
                            onChange={handleInputChange}
                            error={errors.UserId}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  </CardContent> */}
                <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      //className="btnGrid"
                      variant="contained"
                      // onClick={() =>
                      //   handleConfirmationExpenseManager(values.UserId)
                      // }
                    >
                      submit
                    </Button>
                    {/* <Button type="submit" variant="contained" onClick={() => handleConfirmation(params.row)}>
            Send for Approval
          </Button> */}
                  </Stack>
                </CardActions>
              </Card>
            </form>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={expenseQueryOpen}
          onClose={closeExpenseQueryDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Expense Queries</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeExpenseQueryDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400 }}>
            <>
              <ExpenseQueriesList
                ExpenseHdrId={expenseHdrId}
                onCloseExpenseQueryDialog={closeExpenseQueryDialog}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeExpenseQueryDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={expenseDtlDocOpen}
          onClose={closeExpenseDtlDocDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Expense Detail Documents
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeExpenseDtlDocDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
            <>
              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.ExpenseDtlFileId}
                  rows={expenseDtlFiles}
                  columns={expenseDtlFileColumns}
                  columnHeaderHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                />
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeExpenseDtlDocDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={viewPaymentTermsOpen}
          onClose={closeViewPaymentTermsDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            View Term's And Conditions
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeViewPaymentTermsDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
            <>
              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.BoqrfqpaymentTermId}
                  rows={viewPaymentTerms}
                  columns={BoqPoB2BPaymentTermsColumns}
                  columnHeaderHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                />
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeViewPaymentTermsDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={viewDocsOpen}
          onClose={closeViewDocsDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">View Documents</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeViewDocsDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
            <>
              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.BoqdocFileId}
                  rows={viewDocsFiles}
                  columns={boqDocumentviewColumns}
                  columnHeaderHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                />
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeViewDocsDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default PoApprovalList;
