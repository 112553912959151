import React, { useEffect } from "react";
import { Grid, TextField, CardActions, Card, CardContent, Button, Typography, Stack } from "@mui/material";
import Title from '../helper/Title'
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Controls from "../../utility/Controls.tsx/Controls";
import { BoqPackageModel } from "../../models/BoqPackageModel";
import useForm from "../../utility/hooks/UseForm";
import { BoqsubTitleAttributeService } from "../../services/BoqsubTitleAttributeService";
import { BoqsubTitleAttributeModel } from "../../models/BoqsubTitleAttributeModel";

const BoqSubTitleAttributesForm = (...props: any) =>{

    const { BoqsubTitleId,AttributeType,AttributeTypeName,BoqsubTitleAttributeId } = useParams();
    
    let navigate = useNavigate();
    const { Code }: any = useParams();
    const mode = Code ? 'Edit' : 'Create';
    const validate = (fieldValues = values) => {
      let temp: any = { ...errors };
      if ("BoqsubTitleAttributeName" in fieldValues)
        temp.BoqsubTitleAttributeName = fieldValues.BoqsubTitleAttributeName ? "" : 
      "BOQ SubTitle Attribute Name is required.";
      setErrors({
        ...temp,
      });
  
  
  
      if (fieldValues === values)
        return Object.values(temp).every((x) => x === "");
    };
  
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
      useForm(BoqsubTitleAttributeService.initialFieldValues, validate, props.setCurrentId);
  
    const newUser = () => {
      setValues(BoqsubTitleAttributeService.initialFieldValues);
    };
    //This is used since in get the null property is not returned
    function setFormValue(model: BoqsubTitleAttributeModel) {
      let newModel = {
        BoqsubTitleAttributeId: model.BoqsubTitleAttributeId,
        BoqsubTitleAttributeName: model.BoqsubTitleAttributeName,
        AttributeType: model.AttributeType,
        AttributeTypeName : model.AttributeTypeName,
        Active: model.Active,
        BoqtitleId:model.BoqtitleId,
        BoqsubTitleId:model.BoqsubTitleId
      }
      return newModel;
    }
  
    useEffect(() => {
      if (BoqsubTitleAttributeId) {
        getBoqsubTitleAttributeName(BoqsubTitleAttributeId);
        setErrors({});
      } else newUser();
    }, [BoqsubTitleAttributeId]);
  
    const getBoqsubTitleAttributeName = (BoqsubTitleAttributeId: any) => {
      BoqsubTitleAttributeService.GetById(BoqsubTitleAttributeId).then((response) => {
        if (response) {
          let result = response.data;
          setValues(setFormValue(result.data));
        }
      })
    };
  
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if (validate()) {
        if (BoqsubTitleAttributeId) {
          
          BoqsubTitleAttributeService.put(values).then((response: any) => {
            let result = response.data;
            if (response) {
              globalService.success(result.message);
              resetForm();
              navigate(-1);
            } else {
              globalService.error(result.message);
            }
          });
        } else {
      
          values.AttributeType = AttributeType;
          values.AttributeTypeName = AttributeTypeName;
          values.BoqsubTitleId = BoqsubTitleId;
          BoqsubTitleAttributeService.post(values).then((response: any) => {
            if (response) {
              let result = response.data;
              if (result.isSuccess) {
                globalService.success(result.message);
                resetForm();
                navigate(-1);
              } else {
                globalService.error(result.message);
              }
            }
          });
        }
      }
    };
  
    return (
      <>
        <Typography variant="h5"  align="center">
        BOQ Sub Title Attributes Form
        </Typography>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <Card>
            <CardContent>
              <React.Fragment>
                <Grid container spacing={3}>
               
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      label="Boq SubTitle Attribute Name"
                      name="BoqsubTitleAttributeName"
                      value={values.BoqsubTitleAttributeName}
                      onChange={handleInputChange}
                      error={errors.BoqsubTitleAttributeName}
                    />
                  </Grid>
              
                  <Grid item xs={12} sm={4}
                   className={mode !== "Edit" ? "hidden" : ""}
                   >
                    <Controls.Checkbox
                      name="Active"
                      label="Active"
                      value={values.Active}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack spacing={2} direction="row">
                <Button
                 type="submit" variant="contained">
                  Submit
                  </Button>
                <Button  
                variant="outlined"
                 startIcon={<ArrowBackIcon />} 
                 onClick={() => navigate(-1)}>
                  Back To List </Button>
              </Stack>
  
            </CardActions>
          </Card>
        </form>
      </>
    );
  };

export default BoqSubTitleAttributesForm