import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../utility/context/AuthContext";
import { MultiSelectListModel } from "../../models/ApiResponse";
import useForm from "../../utility/hooks/UseForm";
import { DashboardService } from "../../services/DashboardService";
import { userService } from "../../services/UserService";
import dayjs from "dayjs";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Typography,
} from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Controls from "../../utility/Controls.tsx/Controls";
import { globalService } from "../../services/GlobalService";
import { ROLES } from "../../utility/Config";

const BoqDashboard = (...props: any) => {
  const { VendorId } = useParams();
  let navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const { auth, setAuth } = React.useContext(AuthContext);
  const [projectWise, setProjectWise] = useState([]);
  const [packageWise, setPackagewise] = useState([]);
  const [procurementWise, setProcurementWise] = useState([]);
  const [allUsers, setAllUsers] = useState<MultiSelectListModel[]>([]);
  const { Code }: any = useParams();
  const mode = VendorId ? "Edit" : "Create";
  let userIds = auth.Id;
  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      DashboardService.initialDashBoardValues,
      validate,
      props.setCurrentId
    );

  const GetuserselectList = () => {
    userService
      .GetUserInfoSelectListByRole("Employee")
      .then((Response: any) => {
        setAllUsers(Response.data);
      });
  };
  const newUser = () => {
    setValues(DashboardService.initialDashBoardValues);
  };

  useEffect(() => {
    if (allUsers.length === 0) GetuserselectList();
    handleSubmit(auth.Id);
  }, []);

  const handleSubmit = (userid: any) => {
    values.UserID = userid;
    if (validate()) {
      getBOQDashboardPackageWise();
      getProjectWise();
      getProcurementWise();
    }
  };

  const getBOQDashboardPackageWise = () => {
    //   values.UserID = auth.Id;
    
    DashboardService.getBOQDashboardPackageWise(values).then((response) => {
      
      let result = response.data;
      setPackagewise(result.list);
    });
  };
  const getProjectWise = () => {
    
    DashboardService.getBOQDashboardProjectWise(values).then((response) => {
      let result = response.data;
      setProjectWise(result.list);
    });
  };
  const getProcurementWise = () => {
    DashboardService.getBOQDashboardProcurementWise(values).then((response) => {
      let result = response.data.list;
      setProcurementWise(result);
      // let projectValuesPackages = result.find();
    });
  };

  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 6);
    return oneMonthsAgo;
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue == "1") {
      getProjectWise();
    }
    else if (newValue == "2") {
      getBOQDashboardPackageWise();
    }
    else if (newValue == "3") {
      getProcurementWise();
    }
  };

  const PackageWiseChart = (prop: any) => {
    if (prop && prop.row) {
      const row = prop.row;
      const xAxisData = row.BOQPackages.map((x: any) => x.BOQPackageName);
      const seriesData = row.BOQPackages.map((x: any) => x.BOQAmount);
      return (
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc" }}>
          <BarChart
            key={"chart_" + prop.index}
            xAxis={[{
              scaleType: "band",
              data: xAxisData,
              colorMap: {
                type: 'ordinal',
                // values: ['Completed', 'In Progress', 'To do'],
                colors: ['grey']
              },
            }]}
            series={[
              {
                data: seriesData,
                label: row.Project,
                color: 'grey'
              },
            ]}
            yAxis={[{ tickSize: 10, valueFormatter: globalService.yAxisTickFormatter }]} // Apply tickSize and valueFormatter
            sx={{ width: '100%' }} // Set width using sx prop
            height={300}
          />
        </Grid>
      );
    }
    else return (
      <Grid item xs={12} sm={5} sx={{ border: "1px solid #ccc" }}>
        No Data Found
      </Grid>
    );
  }

  const ProjectWiseChart = () => {
    if (projectWise) {
      const xAxisData = projectWise.map((x: any) => x.Project);
      const seriesData = projectWise.map((x: any) => x.BOQAmount);
      return (
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} sm={12} sx={{ border: "1px solid #ccc" }}>
            <BarChart
              xAxis={[{
                scaleType: "band", data: xAxisData,
                colorMap: {
                  type: 'ordinal',
                  // values: ['Completed', 'In Progress', 'To do'],
                  colors: ['grey']
                },
              }]}
              series={[
                {
                  data: seriesData,
                  label: "Project wise data",
                  color: 'grey'
                },
              ]}
              yAxis={[{ tickSize: 10, valueFormatter: globalService.yAxisTickFormatter }]} // Apply tickSize and valueFormatter
              sx={{ width: '100%' }} // Set width using sx prop
              height={300}
            />
          </Grid>
        </Grid>
      );
    }
    else return (
      <Grid item xs={12} sm={5} sx={{ border: "1px solid #ccc" }}>
        No Data Found
      </Grid>
    )
  }

  const ProcurementWiseChart = (prop: any) => {
    if (prop && prop.row) {
      const row = prop.row;
      const xAxisData = row.ProcTypes.map((x: any) => x.ProcurementTypeName);
      const seriesData = row.ProcTypes.map((x: any) => x.BOQAmount);
      return (
        <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc" }}>
          <BarChart
            key={"chartProc_" + prop.index}
            xAxis={[{
              scaleType: "band",
              data: xAxisData,
              colorMap: {
                type: 'ordinal',
                // values: ['Completed', 'In Progress', 'To do'],
                colors: ['grey']
              },
            }]}
            series={[
              {
                data: seriesData,
                label: row.Project,
                color: 'grey'
              },
            ]}
            yAxis={[{ tickSize: 10, valueFormatter: globalService.yAxisTickFormatter }]} // Apply tickSize and valueFormatter
            sx={{ width: '100%' }} // Set width using sx prop
            height={300}
          />
        </Grid>
      );
    }
    else return (
      <Grid item xs={12} sm={5} sx={{ border: "1px solid #ccc" }}>
        No Data Found
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" align="center">
        BOQ Dashboard
      </Typography>
      <TabContext value={value}>
        <form autoComplete="off" noValidate>
          <Card>
            <CardContent>
              <React.Fragment>
                <CardContent>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={4} sm={2} >
                      <Controls.ReactDatePicker
                        label="From Date"
                        dateFormat="MMM-yyyy"
                        showMonthYearPicker
                        // min={values.FromDt}
                        max={new Date()}
                        value={
                          (values.FromDt =
                            values.FromDt ||
                            globalService.getISODateOnlyForDatePicker(
                              getDefaultDate()
                            ))
                        }
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value:
                                globalService.getISODateOnlyForDatePicker(date),
                              name: "FromDt",
                            },
                          });
                        }}
                        error={errors.FromDt}
                      />
                    </Grid>

                    <Grid item xs={4} sm={2}>
                      <Controls.ReactDatePicker
                        label="To Date"
                        dateFormat="MMM-yyyy"
                        showMonthYearPicker
                        min={values.FromDt}
                        max={new Date()}
                        value={
                          (values.ToDt =
                            values.ToDt ||
                            globalService.getISODateOnlyForDatePicker(
                              new Date()
                            ))
                        }
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value:
                                globalService.getISODateOnlyForDatePicker(date),
                              name: "ToDt",
                            },
                          });
                        }}
                        error={errors.ToDt}
                      />
                    </Grid> */}
                    <Grid item xs={8} sm={5}>
                      <TabList onChange={handleTabChange} aria-label="lab API tabs example"                      >
                        <Tab label="Project Wise" value="1" />
                        <Tab label="Package Wise" value="2" />
                        <Tab label="Procurement Wise" value="3" />
                      </TabList>
                    </Grid>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TabContext value={value}>
                      <TabPanel value="1">
                        <Grid container spacing={2} marginTop={2} key="chartPackageWise">
                          {
                            packageWise.map((row: any, index: number) =>
                              <PackageWiseChart key={index} index={index} row={row} />
                            )
                          }
                        </Grid>
                      </TabPanel>

                      <TabPanel value="2">
                        <ProjectWiseChart />
                      </TabPanel>

                      <TabPanel value="3">
                        <Grid container spacing={2} marginTop={2} key="chartProcTypeWise">
                          {
                            procurementWise.map((row: any, index: number) =>
                              <ProcurementWiseChart key={index} index={index} row={row} />
                            )
                          }
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </CardContent>
              </React.Fragment>
            </CardContent>
          </Card>
        </form>
      </TabContext>
    </>
  );
};

export default BoqDashboard;
