import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
  IconButton,
  Link,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PostAddIcon from '@mui/icons-material/PostAdd';
import Title from "../helper/Title";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { MVendorService } from "../../services/MVendorService";
import { MVendorModel } from "../../models/MVendorModel";
import {
  MultiSelectListModel,
  SelectListModel,
} from "../../models/ApiResponse";
import { userService } from "../../services/UserService";
import dayjs from "dayjs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ConfirmDialog from "../helper/ConfirmDialog";
import { vendorFileService } from "../../services/MVendorFilesService";

const MVendorForm = (...props: any) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  const { VendorId } = useParams();
  const [filesUpload, setFilesUpload] = useState<File[]>([]);
  const [states, setState] = useState<MultiSelectListModel[]>([]);
  const [userList, setUserList] = useState<MultiSelectListModel[]>([]);
  let navigate = useNavigate();
  const profileFileInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [vendorFiles, setVendorFiles] = useState([]);
  const { Code }: any = useParams();
  const mode = VendorId ? "Edit" : "Create";
  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    if ("VendorName" in fieldValues)
      temp.VendorName = fieldValues.VendorName
        ? ""
        : "Vendor Name is required.";
    if ("Email" in fieldValues) {
      temp.Email = fieldValues.Email
        ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(fieldValues.Email)
          ? ""
          : "Email is not valid."
        : "Email is required";
    }
    if ("Spocemail" in fieldValues) {
      temp.Spocemail = fieldValues.Spocemail
        ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          fieldValues.Spocemail
        )
          ? ""
          : "Spoc email is not valid."
        : "";
    }
    if ("Spocemail1" in fieldValues) {
      temp.Spocemail1 = fieldValues.Spocemail1
        ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          fieldValues.Spocemail1
        )
          ? ""
          : "Spoc email1 is not valid."
        : "";
    }
    if ("Spocemail2" in fieldValues) {
      temp.Spocemail2 = fieldValues.Spocemail2
        ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
          fieldValues.Spocemail2
        )
          ? ""
          : "Spoc email2 is not valid."
        : "";
    }

    if ("Address1" in fieldValues)
      temp.Address1 = fieldValues.Address1 ? "" : "Address is required.";
    if ("StateId" in fieldValues)
      temp.StateId = fieldValues.StateId ? "" : "State is required.";

    if (mode === "Create") {
      // commenting this. will open later when ssl certificate is ready
      if ("Password" in fieldValues)
        temp.Password = fieldValues.Password ? "" : "Password is required.";

      if ("ConfirmPassword" in fieldValues)
        temp.ConfirmPassword = fieldValues.ConfirmPassword
          ? ""
          : "Confirm Password is required.";

      if ("Password" in fieldValues || "ConfirmPassword" in fieldValues) {
        if (fieldValues.ConfirmPassword)
          temp.Password =
            values.Password !== fieldValues.ConfirmPassword
              ? "Password and ConfirmPassword doesn't match."
              : "";
        else
          temp.Password =
            values.ConfirmPassword !== fieldValues.Password
              ? "Password and ConfirmPassword doesn't match."
              : "";
      }

      if ("Gstin" in fieldValues) {

        if (fieldValues.Gstin) {
          const gstNumber = fieldValues.Gstin.trim(); // Trim whitespace
          // GST number must be exactly 15 characters long and alphanumeric
          if ((gstNumber.length > 0 && gstNumber.length !== 15) || !/^[0-9A-Za-z]+$/.test(gstNumber)) {
            temp.Gstin = "Invalid GST Number";
          }
          else
            temp.Gstin = "";
        }
      }



      if ("Pan" in fieldValues) {
        if (fieldValues.Pan) {
          const panNumber = fieldValues.Pan.trim().toUpperCase();
          const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
          if ((panNumber.length > 0 && panNumber.length !== 10) || !panRegex.test(panNumber)) {
            temp.Pan = "Invalid PAN Number";
          }
          else
            temp.Pan = "";

          setValues((prevValues: any) => ({
            ...prevValues,
            Pan: panNumber
          }));
        }
      }
    }
    setErrors({
      ...temp,
    });
    
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  // const validatePAN = (panNumber: string) => {
  //   //const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  //   const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  //   return panRegex.test(panNumber);
  // };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(MVendorService.initialFieldValues, validate, props.setCurrentId);

  const newUser = () => {
    setValues(MVendorService.initialFieldValues);
  };
  //This is used since in get the null property is not returned
  function setFormValue(model: MVendorModel) {
    let newModel = {
      VendorId: model.VendorId,
      VendorName: model.VendorName,
      VendorCode: model.VendorCode || "",
      Address1: model.Address1,
      Address2: model.Address2,
      Address3: model.Address3,
      City: model.City,
      StateId: model.StateId,
      Pan: model.Pan,
      Spocdesignation: model.Spocdesignation,
      Gstin: model.Gstin,
      LandLine: model.LandLine,
      Website: model.Website,
      Spocname: model.Spocname,
      Spocemail: model.Spocemail,
      Spocmobile: model.Spocmobile,
      Email: model.Email,
      Active: model.Active,
      Password: model.Password,
      UserId: model.UserId || "",
      Pincode: model.Pincode,
      BankName: model.BankName,
      Ifsccode: model.Ifsccode,
      AccountNo: model.AccountNo,
      VendorNameAsPerTally: model.VendorNameAsPerTally,
      Spocname1: model.Spocname1,
      Spocemail1: model.Spocemail1,
      Spocmobile1: model.Spocmobile1,
      Spocdesignation1: model.Spocdesignation1,
      Spocname2: model.Spocname2,
      Spocemail2: model.Spocemail2,
      Spocmobile2: model.Spocmobile2,
      Spocdesignation2: model.Spocdesignation2,
      FilePath: model.FilePath,

    };
    return newModel;
  }

  useEffect(() => {
    if (states.length === 0) getState();
    if (userList.length === 0) GetUserInfoSelectListByRole();
    if (VendorId) {
      getVendorFiles(VendorId);
      getVendorName(VendorId);
      setErrors({});
    } else newUser();
  }, [VendorId]);

  const getState = () => {
    userService.getStateSelectList().then((response) => {
      setState(response.data);
    });
  };

  const GetUserInfoSelectListByRole = () => {
    userService.GetUserInfoSelectListByRole("Vendor").then((response) => {
      setUserList(response.data);
    });
  };

  const getVendorName = (VendorId: any) => {
    MVendorService.getById(VendorId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    });
  };
  const handleButtonClick = () => {
    if (profileFileInputRef.current) {
      profileFileInputRef.current.click(); // Trigger click event on file input
    }
  };


  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files;
    if (!fileInput) {
      return;
    }

    const selectedFiles = Array.from(fileInput) as File[];
    const validFiles = selectedFiles.filter(
      (file) => file.size <= 5 * 1024 * 1024
    );

    if (validFiles.length < selectedFiles.length) {
      globalService.error("File size must be 5MB or less");
    }

    setFilesUpload((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setFilesUpload((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(`${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`, "_blank");
  };


  const getVendorFiles = (VendorId: any) => {
    
    vendorFileService.getAllFileByVendors(VendorId).then((response) => {
      setVendorFiles(response.data.list);
    })
  }

  const removeVendorFile = (VendorFileId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    vendorFileService.remove(VendorFileId).then((response) => {
      if (response) {
        getVendorFiles(VendorId);
      }
    });
  };


  const handleSubmit = (e: React.FormEvent) => {

    e.preventDefault();

    if (validate()) {
      if (VendorId) {
        MVendorService.put(values, filesUpload).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/vendors");
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        MVendorService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              resetForm();
              navigate("/vendors");
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };


  const vendorFileColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={(event) => handleFileClick(event, params.row)}

        >
          {params.row.FileName}
        </Link>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this VendorFile ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeVendorFile(params.row.VendorFileId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Typography variant="h5" align="center">
        Vendor
      </Typography>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} alignItems="center">
                  <Typography style={{ color: 'red', fontSize: '0.75rem' }}> File Size Should be 100Kb
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Vendor Company Name"
                    required
                    name="VendorName"
                    value={values.VendorName}
                    onChange={handleInputChange}
                    error={errors.VendorName}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Vendor Name As Per Tally"
                    name="VendorNameAsPerTally"
                    value={values.VendorNameAsPerTally}
                    onChange={handleInputChange}
                    error={errors.VendorNameAsPerTally}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Vendor Company Code"
                    name="VendorCode"
                    
                    value={values.VendorCode}
                    onChange={handleInputChange}
                    error={errors.VendorCode}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Owner Email"
                    name="Email"
                    required
                    value={values.Email}
                    onChange={handleInputChange}
                    error={errors.Email}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  {/* <Controls.Select
                    required
                    name="UserId"
                    label="User"
                    options={userList}
                    value={userList.length > 0 ? values.UserId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.Id}
                  /> */}
                  <Controls.MuiAutocomplete
                    options={userList}
                    label="Vendor Login"
                    error={errors.UserId}
                    onChange={(event: MultiSelectListModel) => {
                      handleInputChange({
                        target: {
                          name: "UserId",
                          value: event ? event.value : "",
                        },
                      });
                    }}
                    value={
                      userList.find(
                        (item: any) => item.value === values.UserId
                      ) || null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Address"
                    name="Address1"
                    required
                    value={values.Address1}
                    onChange={handleInputChange}
                    error={errors.Address1}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Street"
                    name="Address2"
                    value={values.Address2}
                    onChange={handleInputChange}
                    error={errors.Address2}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Locality"
                    name="Address3"
                    value={values.Address3}
                    onChange={handleInputChange}
                    error={errors.Address3}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="City"
                    name="City"
                    value={values.City}
                    onChange={handleInputChange}
                    error={errors.City}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.MuiAutocomplete
                    options={states}
                    label="State"
                    required
                    error={errors.StateId}
                    onChange={(event: MultiSelectListModel) => {
                      handleInputChange({
                        target: {
                          name: "StateId",
                          value: event ? event.value : "",
                        },
                      });
                    }}
                    value={
                      states.find(
                        (item: any) => item.value === values.StateId
                      ) || null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    type="number"
                    label="Pincode"
                    name="Pincode"
                    value={values.Pincode}
                    onChange={handleInputChange}
                    error={errors.Pincode}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="LandLine"
                    name="LandLine"
                    value={values.LandLine}
                    onChange={handleInputChange}
                    error={errors.LandLine}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Website"
                    name="Website"
                    value={values.Website}
                    onChange={handleInputChange}
                    error={errors.Website}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="PAN Number"
                    name="Pan"
                    value={values.Pan}
                    onChange={handleInputChange}
                    error={errors.Pan}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="GSTIN"
                    name="Gstin"
                    value={values.Gstin}
                    onChange={handleInputChange}
                    error={errors.Gstin}
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
        </Card>

        <Card>
          <Typography variant="h5" align="center">
            Contact Person
          </Typography>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Name1"
                    name="Spocname"
                    value={values.Spocname}
                    onChange={handleInputChange}
                    error={errors.Spocname}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Designation1"
                    name="Spocdesignation"
                    value={values.Spocdesignation}
                    onChange={handleInputChange}
                    error={errors.Spocdesignation}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Email1"
                    name="Spocemail"
                    value={values.Spocemail}
                    onChange={handleInputChange}
                    error={errors.Spocemail}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Mobile1"
                    name="Spocmobile"
                    value={values.Spocmobile}
                    onChange={handleInputChange}
                    error={errors.Spocmobile}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Name2"
                    name="Spocname1"
                    value={values.Spocname1}
                    onChange={handleInputChange}
                    error={errors.Spocname1}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Designation2"
                    name="Spocdesignation1"
                    value={values.Spocdesignation1}
                    onChange={handleInputChange}
                    error={errors.Spocdesignation1}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Email2"
                    name="Spocemail1"
                    value={values.Spocemail1}
                    onChange={handleInputChange}
                    error={errors.Spocemail1}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Mobile2"
                    name="Spocmobile1"
                    value={values.Spocmobile1}
                    onChange={handleInputChange}
                    error={errors.Spocmobile1}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Name3"
                    name="Spocname2"
                    value={values.Spocname2}
                    onChange={handleInputChange}
                    error={errors.Spocname2}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Designation3"
                    name="Spocdesignation2"
                    value={values.Spocdesignation2}
                    onChange={handleInputChange}
                    error={errors.Spocdesignation2}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Email3"
                    name="Spocemail2"
                    value={values.Spocemail2}
                    onChange={handleInputChange}
                    error={errors.Spocemail2}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Spoc Mobile3"
                    name="Spocmobile2"
                    value={values.Spocmobile2}
                    onChange={handleInputChange}
                    error={errors.Spocmobile2}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={3}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
        </Card>
        <Card>
          <Typography variant="h5" align="center">
            Bank Details
          </Typography>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Bank Name"
                    name="BankName"
                    value={values.BankName}
                    onChange={handleInputChange}
                    error={errors.BankName}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="IFSC Code"
                    name="Ifsccode"
                    value={values.Ifsccode}
                    onChange={handleInputChange}
                    error={errors.Ifsccode}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Account No"
                    name="AccountNo"
                    value={values.AccountNo}
                    onChange={handleInputChange}
                    error={errors.AccountNo}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
        </Card>

        {VendorId &&
        <Card>
          <Typography variant="h5" align="center">
            Files Upload
          </Typography>
          <CardContent>
            <React.Fragment>
          <Grid item xs={12} sm={4}>
            <Typography style={{ color: "red", fontSize: "0.75rem" }}>
              {" "}
              File Size Should be 5MB
            </Typography>
            <Stack spacing={1} direction="row">
              <Grid item xs={12}>
                <input
                  type="file"
                  name="file"
                  accept="*/*"
                  multiple
                  onChange={onFileChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  id="file-input"
                />
                <Button
                  startIcon={<PostAddIcon />}
                  variant="contained"
                  //color="success"
                  sx={{ backgroundColor: "success.main", opacity: 0.8 }}
                  onClick={() => fileInputRef.current?.click()}
                >
                  Browse File
                </Button>
              </Grid>
              {filesUpload.length > 0 && (
                <Grid item xs={12}>
                  <Stack direction="column" spacing={2}>
                    {filesUpload.map((file, index) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        key={index}
                        alignItems="center"
                      >
                        <Typography>{file.name}</Typography>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          color="error"
                          onClick={() => removeFile(index)}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Stack>
                    ))}
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setFilesUpload([])}
                    >
                      Remove All
                    </Button>
                  </Stack>
                </Grid>
              )}
            </Stack>
          </Grid>
         </React.Fragment>
          </CardContent>
        </Card>
        }
        <Card className={mode === "Edit" ? "hidden" : ""}>
        {/* <Card className="hidden"> */}
          <Typography variant="h5" align="center">
            User Form
          </Typography>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    required
                    name="Password"
                    label="Password"
                    type="password"
                    value={values.Password}
                    onChange={handleInputChange}
                    error={errors.Password}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    required
                    name="ConfirmPassword"
                    label="Confirm Password"
                    type="password"
                    value={values.ConfirmPassword}
                    onChange={handleInputChange}
                    error={errors.ConfirmPassword}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
        </Card>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button type="submit" variant="contained">
              Submit
            </Button>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              href="/vendors"
            >
              Back{" "}
            </Button>
          </Stack>
        </CardActions>
        {VendorId &&
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "20px",
            }}
          >
            <div style={{ width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.VendorFileId}
                rows={vendorFiles}
                columns={vendorFileColumns}
                columnHeaderHeight={30}
                autoHeight={true}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
              />
            </div>
          </div>
        </Card>
        }
      </form>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default MVendorForm;
