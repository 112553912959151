
import { BatchHeadersmodel, DataForTallyBatchmodel, GetForTallyExpensesSummarymodel } from "../models/TallyBachesModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: GetForTallyExpensesSummarymodel ={
  
    FromDt:null,
    ToDt:null,
    Info: '',
}

const initialFieldValuesforBatchHeaders : BatchHeadersmodel ={
    Info: '',
}
const initialFieldValuesforDataForTallyBatchDownload : DataForTallyBatchmodel ={
  TallyImportBatchHeaderID: '',
  BatchNo: '',
}


const url = "/TallyImportBatchHeaders";


function getForTallyExpensesSummary(payload: GetForTallyExpensesSummarymodel){
    return customAxios.post(`${url}/GetForTallyExpensesSummary`,payload,axiosRetryConfig)
}


function getForTallyExpensesList( TallyImportBatchHeaderID:string) {
return customAxios.get(`${url}/GetForTallyExpensesList/${TallyImportBatchHeaderID}`, axiosRetryConfig); 
}

  function createBatchForTally( CreatedByID:string) {
  return customAxios.get(`${url}/CreateBatchForTally/${CreatedByID}`, axiosRetryConfig);
}

  function getDataForTallyBatchDownload(payload: GetForTallyExpensesSummarymodel) {
    return customAxios.post(`${url}/GetDataForTallyBatchDownload`, payload,{ responseType: 'blob' || 'json' });
  }

  function tallyImportStatusUpdate(TallyImportBatchHeaderID:string) {
    return customAxios.put(`${url}/TallyImportStatusUpdate/${TallyImportBatchHeaderID}`, axiosRetryConfig); 
}
export const tallyImportBatchHeadersService ={
    initialFieldValues,
    initialFieldValuesforBatchHeaders,
    getForTallyExpensesSummary,
    getForTallyExpensesList,
    createBatchForTally,
    getDataForTallyBatchDownload,
    tallyImportStatusUpdate,
    initialFieldValuesforDataForTallyBatchDownload
}