import { Stack, IconButton,Button, Card, CardContent, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/Delete";
  import DoneIcon from "@mui/icons-material/Done";
  import CloseIcon from "@mui/icons-material/Close";    
import { mPaymentTermsService } from '../../services/MPaymentTermsService';
import ConfirmDialog from '../helper/ConfirmDialog';

const MPaymentTermsList: React.FC = () => {

    const [paymentTerms, setPaymentTerms] = useState([]);

    const navigate = useNavigate();

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { },
      });

      useEffect(() => {
        getPaymentTerms();
    }, []);

const getPaymentTerms = () => {
mPaymentTermsService.getAll().then((reponse) => {
    let result = reponse.data;
    setPaymentTerms(result.list);
})
}

const paymentTermsColumns: GridColDef [] = [

  { field: "PaymentTermType", headerName: "Payment Term Type",  flex: 1,  align: "left",  },

    { field: "PaymentTerm", headerName: "Term's And Conditions",  flex: 5,  align: "left",  },
    {
      field: "Active",
      headerName: "Active",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>

            {/* {params.row.Active && "Yes"}
                {!params.row.Active && "No"} */}
            {params.row.Active && <DoneIcon color="success" />}
            {!params.row.Active && <CloseIcon color="error" />}
          </Stack>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editPaymentTerm/" + params.row.PaymentTermId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small"
              aria-label="delete" color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure to delete this Term And Conditions ?',
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removePaymentTerm(params.row.PaymentTermId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>

          </Stack>
        );
      },
    },
];

const removePaymentTerm = (PaymentTermId: any) => {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
    });

    mPaymentTermsService.remove(PaymentTermId).then((response) => {
    if(response) {
        getPaymentTerms();
    }
    });
};

  return (
   <>
   <Typography variant="h5" align="center">
     Terms And Conditions
   </Typography>

   <Card>
    <CardContent>
        <Button
         variant="contained"
         startIcon={<AddCircleOutlineIcon />}
         onClick={() => navigate("/addpaymentTerm")}
        >
          Add Record
        </Button>
        <div style={{ width: "100%" }}>
                <DataGrid
                getRowId={(row) => row.PaymentTermId}
                rows={paymentTerms}
                columns={paymentTermsColumns}
                columnHeaderHeight={30}
                autoHeight={true}
                // checkboxSelection
                // disableRowSelectionOnClick
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
  
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns Id, the other columns will remain visible
                      PaymentTermId: false,
                    },
                  },
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                 />

            </div>
    </CardContent>
   </Card>

   <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
   </>
  )
}

export default MPaymentTermsList
