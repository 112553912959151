

import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";

export const Footer: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        width: "100%",
        //position: "fixed",
        height: "auto",
        //bottom:0,
        backgroundColor: "var(--primary-color)",
        paddingTop: "0.5rem",
        paddingBottom: "0.6rem",
        textAlign: "center",
      }}
    >
      {/* <Container maxWidth="lg"> */}
      <>
        {/* <Grid container direction="column" alignItems="Left"> */}
        <Grid container direction="row">
          <Grid item xs={12} sm={7}>
            <Typography color="#e6e6fa" variant="subtitle1">
              2024 © All Rights Reserved | Designed and Developed by
              <Link
                color="#3498db"
                variant="subtitle1"
                underline="none"
                href="http://www.sentientsystems.net/"
                target="_blank"
                style={{ textAlign: 'left' }}

              >
                {" "}
                Sentient Systems Pvt Ltd.
              </Link>

            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={5}>
            <Typography color="##e6e6fa" variant="subtitle1">
              <Link
                color="#e6e6fa"
                variant="subtitle1"
                underline="none"
                href="/privacyPolicy"
                target="_blank"
              >
                {" "}
                Privacy Policy |
              </Link>
              <Link
                color="#e6e6fa"
                variant="subtitle1"
                underline="none"
                href="/refundPolicy"
                target="_blank"
              >
                {" "}
                Refund Policy |
              </Link>
              <Link
                color="#00ff00"
                variant="subtitle1"
                underline="none"
                href="/termsandConditions"
                target="_blank"
                textAlign={"right"}
              >
                {" "}
                Terms and Conditions.
              </Link>
            </Typography>
          </Grid> */}
        </Grid>
        {/* </Container> */}
      </>
    </Box>
  );
};

export default Footer;