import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { PoApprovalModel } from "../models/PoApprovelModel";

const initialFieldValues: PoApprovalModel = {
    UserID:'',
    Boqpob2bstatusId:'',
    FromDt:null,
    ToDt:null,
}

const url = 'PoApproval';

function getProcurementsForApprovalHistory(Payload : PoApprovalModel) {
    return customAxios.post(`${url}/GetProcurementsForApprovalHistory`,Payload, axiosRetryConfig);
}


// function getExpensesForApprovalHistory(payload:ExpenseApprovalModel ) {
//     return customAxios.post(`${url}/GetExpensesForApprovalHistory`, payload,axiosRetryConfig);
// }



export const PoApprovalService = {
    initialFieldValues,
    getProcurementsForApprovalHistory,
}