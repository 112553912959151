import React, { useEffect, useRef, useState } from "react";
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonIcon from '@mui/icons-material/Person';
import { globalService } from '../../services/GlobalService';
import { useNavigate, useParams } from 'react-router-dom';
import { mEmployeesService } from '../../services/MEmployeeService';
import { EmployeeModel } from '../../models/MEmployeeModel';
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
  IconButton,
  Avatar,
  Box,
  Autocomplete,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardHeader,
  Link,
} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { Folderpath, ROLES, config } from "../../utility/Config";
import DeleteIcon from "@mui/icons-material/Delete";
import { MultiSelectListModel, SelectListModel } from "../../models/ApiResponse";
import dayjs from "dayjs";
import { userService } from "../../services/UserService";
import { mCompaniesService } from "../../services/MCompaniesService";
import { employeeFileService } from "../../services/MEmployeeFilesService";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ConfirmDialog from "../helper/ConfirmDialog";
import { UploadFile } from "@mui/icons-material";

const MEmployeeForm = (...props: any) => {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  globalService.pageTitle = "profileImage";
  const { EmployeeId }: any = useParams();
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
  const profileFileInputRef = useRef<HTMLInputElement>(null);
  const [empfilesUpload, setEmpFilesUpload] = useState<File[]>([]);
  const empfileInputRef = useRef<HTMLInputElement>(null);

  const [depatmentList, setDepatmentList] = useState<SelectListModel[]>([]);
  const [designationList, setDesignationList] = useState<SelectListModel[]>([]);
  const [zoneList, setZoneList] = useState<SelectListModel[]>([]);
  const [stateList, setStateList] = useState<MultiSelectListModel[]>([]);
  const [userList, setUserList] = useState<MultiSelectListModel[]>([]);
  const [reportingToList, setReportingToList] = useState<SelectListModel[]>([]);
  const [companiesList, setCompaniesList] = useState<SelectListModel[]>([]);
  let navigate = useNavigate();
  const mode = EmployeeId ? "Edit" : "Create";
  const [roles, setRoles] = useState<MultiSelectListModel[]>([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [employeeFiles, setEmployeeFiles] = useState([]);




  const validate = (fieldValues = values) => {

    let temp: any = { ...errors };
    if ("EmpName" in fieldValues) {
      temp.EmpName = fieldValues.EmpName ? "" : "Employee Name  is required.";
      if (mode === "Create") {
        setValues((previousValue: any) => ({
          ...previousValue,
          User: {
            ...previousValue.User,
            FirstName: fieldValues.EmpName,
            FullName: fieldValues.EmpName
          }
        }));
      }
    }
    if ("EmpCode" in fieldValues)
      temp.EmpCode = fieldValues.EmpCode ? "" : "Employee Code  is required.";
    if ("Email" in fieldValues) {
      let isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(fieldValues.Email);
      temp.Email = fieldValues.Email
        ? (isValid
          ? ""
          : "Email is not valid.")
        : "Email is required.";

      if (mode === "Create" && isValid) {
        setValues((previousValue: any) => ({
          ...previousValue,
          User: {
            ...previousValue.User,
            Email: fieldValues.Email,
            UserName: fieldValues.Email
          }
        }));
      }
    }

    if ("Mobile" in fieldValues) {
      temp.Mobile = fieldValues.Mobile ? "" : "Mobile is required.";
      setValues((previousValue: any) => ({
        ...previousValue,
        User: {
          ...previousValue.User,
          PhoneNumber: fieldValues.Mobile
        }
      }));
    }
    if ("Dob" in fieldValues)
      temp.Dob = fieldValues.Dob ? "" : "Date Of Birth is required.";
    if ("Doj" in fieldValues)
      temp.Doj = fieldValues.Doj ? "" : "Date Of Joining  is required.";
    if ("DepartmentId" in fieldValues)
      temp.DepartmentId = fieldValues.DepartmentId ? "" : "Please Select Department";
    if ("DesignationId" in fieldValues)
      temp.DesignationId = fieldValues.DesignationId ? "" : "Please Select Designation";
    if ("StateId" in fieldValues)
      temp.StateId = fieldValues.StateId ? "" : "State  is required.";

    if ("Address1" in fieldValues)
      temp.Address1 = fieldValues.Address1 ? "" : "Address is required.";

    if ("CompanyId" in fieldValues)
      temp.CompanyId = fieldValues.CompanyId ? "" : "Company is required.";

    //uncomment this when ssl certificate is ready
    if ("SelectedRoleNames" in fieldValues) {
      temp.SelectedRoleNames = fieldValues.SelectedRoleNames ? "" : "Please select role.";
      setValues((previousValue: any) => ({
        ...previousValue,
        User: {
          ...previousValue.User,
          SelectedRoleNames: fieldValues.SelectedRoleNames
        }
      }));
    }

    //if edit and nothing is selected in dropdown then password and confirmPassword will be open
    if (mode === 'Create' || (mode === 'Edit' && !values.UserId)) {
      if ('Password' in fieldValues)
        temp.User = {
          ...temp.User,
          Password: fieldValues.Password ? '' : 'Password is required.',
        };

      if ("ConfirmPassword" in fieldValues)
        temp.User = {
          ...temp.User,
          ConfirmPassword: fieldValues.ConfirmPassword ? "" : "Confirm Password is required."
        };

      if (("Password" in fieldValues) || ("ConfirmPassword" in fieldValues)) {
        if (fieldValues.ConfirmPassword)
          temp.User = {
            ...temp.User,
            Password: (values.Password !== fieldValues.ConfirmPassword) ? "Password and ConfirmPassword doesn't match." : ""
          }
        else
          temp.User = {
            ...temp.User,
            Password: (values.ConfirmPassword !== fieldValues.Password) ? "Password and ConfirmPassword doesn't match." : ""
          }
      }
    }
    else {
    //to eliminate error
    temp.User = {
      ...temp.User,
      Password: '',
      ConfirmPassword: ''
    };
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      let tempUserValid = true;
      //if temp.User is not changed thn this object is not found. so set it to true at initial
      //uncomment this when ssl certificate is ready
      if (temp.User) {
        tempUserValid = Object.values(temp.User).every((x) => x === '');
      }
      return (
        Object.values(temp).filter((x) => typeof x !== 'object').every((x) => x === '') && //ignore temp.User and validate
        tempUserValid //here validate only temp.User
      );
    }

  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mEmployeesService.initialFieldValues, validate, props.setCurrentId);

  const newEmployee = () => {
    setValues(mEmployeesService.initialFieldValues);
  };

  const EighteenYearsAgoDate = dayjs().subtract(18, 'years').toDate();

  // Function to convert the string into an array of options
  const getSelectedRolesArray = (selectedRolesString: string) => {
    if (selectedRolesString) {
      const selectedValues = selectedRolesString.split(',');
      var filteredRoles = roles.filter(option => selectedValues.includes(option.value));
      setSelectedRoles(filteredRoles);
    }
    else
      setSelectedRoles([]);
  };

  function setFormValue(model: EmployeeModel) {

    let newModel = {
      EmployeeId: model.EmployeeId || "",
      EmpName: model.EmpName || "",
      EmpCode: model.EmpCode || "",
      Email: model.Email || "",
      Mobile: model.Mobile || "",
      Address1: model.Address1 || "",
      Address2: model.Address2 || "",
      Address3: model.Address3 || "",
      Active: model.Active || false,
      Qualification: model.Qualification || "",
      MonthlySalary: model.MonthlySalary || 0,
      ProfileImageName: model.ProfileImageName || null,
      Dob: model.Dob || null,
      Doj: model.Doj || null,
      Dol: model.Dol || null,
      DepartmentId: model.DepartmentId || "",
      DesignationId: model.DesignationId || "",
      ZoneId: model.ZoneId || "",
      StateId: model.StateId || "",
      City: model.City || "",
      IsSecondLevelApproverYn: model.IsSecondLevelApproverYn || false,
      ReportingToId: model.ReportingToId || "",
      Pincode: model.Pincode || "",
      UserId: model.UserId || null,
      SelectedRoleNames: model.User?.SelectedRoleNames || '',
      BankName: model.BankName || '',
      Ifsccode: model.Ifsccode || '',
      AccountType: model.AccountType || '',
      AccountNo: model.AccountNo || '',
      EmployeeNameAsPerTally: model.EmployeeNameAsPerTally || '',
      CompanyId: model.CompanyId || '',
      FilePath: model.FilePath || '',
      //Password: mode === 'Edit' ? '' : model.Password || "",
      User: model.User ? {
        Id: model.UserId || '',
        FirstName: model.User?.FirstName || model.EmpName,
        LastName: model.User?.LastName || '.',
        FullName: model.User?.FullName || model.EmpName,
        Email: model.User?.Email || model.Email,
        UserName: model.User?.UserName || '',
        SelectedRoleNames: model.User?.SelectedRoleNames || '',
        PhoneNumber: model.User?.PhoneNumber || '',
        Password: mode === 'Edit' ? '' : model.User?.Password || "",
        ConfirmPassword: mode === 'Edit' ? '' : model.User?.ConfirmPassword || ""
      } :
        {
          Id: model.UserId || '',
          FirstName: '' || model.EmpName,
          LastName: '.',
          FullName: model.EmpName || '',
          Email: model.Email || '',
          UserName: model.Email || '',
          SelectedRoleNames: '',
          PhoneNumber: model.Mobile || '',
          Password: "",
          ConfirmPassword: ""
        }

    };

    if (newModel.User?.SelectedRoleNames) {
      getSelectedRolesArray(newModel.User?.SelectedRoleNames);
    }
    else {
      getSelectedRolesArray('');
    }
    return newModel;
  }

  useEffect(() => {

    if (depatmentList.length === 0) getDepatmentList();
    if (designationList.length === 0) getDesignationList();
    if (zoneList.length === 0) getZoneList();
    if (stateList.length === 0) getStateList();
    if (userList.length === 0) getUserList();
    if (roles.length === 0) getRoles();
    if (reportingToList.length === 0) getReportingToList();
    if (companiesList.length === 0) getCompaniesList();

    if (EmployeeId) {
      getEmployeeFiles(EmployeeId);
      getEmployee(EmployeeId);
      setErrors({});
    } else newEmployee();
  }, [EmployeeId, roles]);


  const getDepatmentList = () => {
    mEmployeesService.getDepartmentSelectList().then((response) => {
      setDepatmentList(response.data);
    });
  }

  const getDesignationList = () => {
    mEmployeesService.getDesinationSelectList().then((response) => {
      setDesignationList(response.data);
    });
  }
  const getZoneList = () => {
    mEmployeesService.getZoneSelectList().then((response) => {
      setZoneList(response.data);
    });
  }

  const getStateList = () => {
    userService.getStateSelectList().then((response) => {
      setStateList(response.data);
    });
  }


  const getUserList = () => {
    userService.GetUserInfoSelectListByRole(null).then((response: any) => {
      setUserList(response.data);
    });
  }

  const getRoles = () => {
    userService.GetAllRoles().then((response: any) => {
      setRoles(response.data);
    });
  }

  const handleButtonClick = () => {
    if (profileFileInputRef.current) {
      profileFileInputRef.current.click(); // Trigger click event on file input
    }
  };

  const resetProfileImageFile = () => {
    if (profileFileInputRef.current) {
      profileFileInputRef.current.value = ''; // Reset file input value
    }
    setProfileImageFile(null);
  };

  const onProfileImageChange = (fileInput: any) => {

    const selectFile = fileInput.target.files[0];
    if (selectFile && selectFile.size <= 102400) {
      setProfileImageFile(selectFile);
    } else {
      globalService.error("File size must be 100kb or less");
      setProfileImageFile(null);
    }
  };

  const getReportingToList = () => {
    values.EmployeeId = EmployeeId
    mEmployeesService.getReportingToSelectList(values.EmployeeId).then((response) => {
      setReportingToList(response.data);
    });
  }

  const getCompaniesList = () => {
    mCompaniesService.getCompaniesSelectList().then((response) => {
      setCompaniesList(response.data);
    });
  }

  const getEmployee = (EmployeeId: any) => {
    mEmployeesService.getById(EmployeeId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.row));
        // if (result.row.UserId) {
        //getUserById(result.row.UserId);
        // }
      }
    });
  };

  const getUserById = (userId: any) => {

    if (userId) {
      userService.getById(userId).then((response) => {
        if (response) {
          let result = response.data;
          let user = result.row;
          if (user) {
            setValues((previousValue: any) => ({
              ...previousValue,
              UserId: user.Id,
              User: {
                Id: user.Id,
                UserName: user.UserName,
                FirstName: user.FirstName,
                FullName: user.FullName,
                Email: user.Email,
                PhoneNumber: user.PhoneNumber,
                SelectedRoleNames: user.SelectedRoleNames
              },
              SelectedRoleNames: user.SelectedRoleNames
            }));

            if (user.SelectedRoleNames) {
              getSelectedRolesArray(user.SelectedRoleNames);
            }
          }
        }
      });
    }
    else {
      setValues((previousValue: any) => ({
        ...previousValue,
        UserId: "",
        User: {
          Id: "",
          UserName: values.Email,
          FirstName: values.EmpName,
          FullName: values.EmpName,
          Email: values.Email,
          PhoneNumber: values.Mobile,
          SelectedRoleNames: ''
        },
        SelectedRoleNames: ''
      }));
      getSelectedRolesArray('');
    }
  };

  // const clearProfileImageFile = () => {
  //   setProfileImageFile(null);
  // }

  const accounTypeList = [
    { Value: "S", Text: "Saving" },
    { Value: "C", Text: "Current" },
  ];

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files;
    if (!fileInput) {
      return;
    }

    const selectedFiles = Array.from(fileInput) as File[];
    const validFiles = selectedFiles.filter(
      (file) => file.size <= 5 * 1024 * 1024
    );

    if (validFiles.length < selectedFiles.length) {
      globalService.error("File size must be 5MB or less");
    }

    setEmpFilesUpload((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setEmpFilesUpload((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(`${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`, "_blank");
  };


  const getEmployeeFiles = (EmployeeId: any) => {

    employeeFileService.getAllFileByEmployees(EmployeeId).then((response) => {
      setEmployeeFiles(response.data.list);
    })
  }

  const removeEmployeeFile = (EmployeeFileId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    employeeFileService.remove(EmployeeFileId).then((response) => {
      if (response) {
        getEmployeeFiles(EmployeeId);
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {

    e.preventDefault();
    values.EmployeeId = EmployeeId;

    if (validate()) {
      //uncomment this when ssl certificate is ready
      // values.User.SelectedRoleNames = values.SelectedRoleNames;
      // if (!values.User.SelectedRoleNames) {
      //   return globalService.error('Please select role.');
      // }

      if (EmployeeId) {
        mEmployeesService.put(values, profileImageFile, empfilesUpload).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/mEmployees")
          } else {
            globalService.error(result.message);
          }
        });

      } else {
        mEmployeesService.post(values, profileImageFile).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              navigate("/mEmployees")
            } else {
              globalService.error(result.message);
            }
          }
        })
        //     }
      }
    }
  };

  const employeeFileColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={(event) => handleFileClick(event, params.row)}

        >
          {params.row.FileName}
        </Link>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this Employee File ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeEmployeeFile(params.row.EmployeeFileId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Typography variant="h5" align="center">
        {mode} Employee
      </Typography>

      <form autoComplete="off" noValidate
        onSubmit={handleSubmit}>
        <Card>
          <CardHeader title="Employee Info" />
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} alignItems="center">
                  {/* Avatar container */}
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={12} alignItems="center">
                      {mode !== "Create" && (
                        <Box display="flex" flexDirection="column" >
                          {values.ProfileImageName ? <Avatar
                            alt="Employee Image"
                            src={`${process.env.REACT_APP_BASE_URL}/${Folderpath.Employee}/${values.EmployeeId}/${values.ProfileImageName}`}
                            sx={{ width: 150, height: 150, marginTop: 2 }}
                          />
                            :
                            <Avatar src="" alt="Employee Image" sx={{ width: 150, height: 150, marginTop: 2 }}>
                              <PersonIcon />
                            </Avatar>
                          }
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} alignItems="center">
                      <Typography style={{ color: 'red', fontSize: '0.75rem' }}> File Size Should be 100Kb
                      </Typography>
                      <Stack spacing={1} direction="row" alignItems="center" >
                        <input id="profileImageSelectedFile"
                          type="file"
                          style={{ display: 'none' }}
                          ref={profileFileInputRef}
                          accept="image/*"
                          onChange={(event: any) => {
                            onProfileImageChange(event);
                          }}
                        />
                        <Button startIcon={<PostAddIcon />}
                          variant="contained" color='success' onClick={handleButtonClick} >Browse Profile Image...</Button>
                        {profileImageFile && (
                          <Stack direction="row" spacing={0} alignItems="center">
                            <p>{profileImageFile.name}</p>
                            {/* Display other file details if needed */}
                            <IconButton
                              size="small"
                              aria-label="delete"
                              color="error"
                              onClick={resetProfileImageFile}
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Stack>
                        )}

                        {/* <Button variant="contained" component="label" >
                      Upload image
                      <input type="file" accept="image/*" onChange={(event: any) => { onProfileImageChange(event) }} hidden
                        ref={profileFileInputRef}
                      />
                    </Button>
                    <p>{profileImageFile?.name}</p> */}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}
                      className={mode !== "Edit" ? "hidden" : ""}>
                      <Controls.Checkbox
                        label="Active"
                        name="Active"
                        value={values.Active}
                        onChange={handleInputChange}
                        error={errors.Active}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        required
                        label="Employee Name"
                        name="EmpName"
                        value={values.EmpName}
                        onChange={handleInputChange}
                        error={errors.EmpName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        label="Employee Name As Per Tally"
                        name="EmployeeNameAsPerTally"
                        value={values.EmployeeNameAsPerTally}
                        onChange={handleInputChange}
                        error={errors.EmployeeNameAsPerTally}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        label="Employee Code"
                        name="EmpCode"
                        value={values.EmpCode}
                        onChange={handleInputChange}
                        error={errors.EmpCode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.MuiAutocomplete
                        options={reportingToList}
                        label="Reporting To"
                        error={errors.ReportingToId}
                        onChange={(event: MultiSelectListModel) => {
                          handleInputChange({
                            target: {
                              name: "ReportingToId",
                              value: event ? event.value : "",
                            }
                          });
                        }}
                        value={reportingToList.find((item: any) => item.value === values.ReportingToId) || null}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.MuiAutocomplete
                        options={companiesList}
                        label="Company"
                        required
                        error={errors.CompanyId}
                        onChange={(event: MultiSelectListModel) => {
                          handleInputChange({
                            target: {
                              name: "CompanyId",
                              value: event ? event.value : "",
                            }
                          });
                        }}
                        value={companiesList.find((item: any) => item.value === values.CompanyId) || null}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                      <Controls.MuiAutocomplete
                        required
                        name="DesignationId"
                        label="Designation"
                        options={designationList}
                      //  value={designationList.length > 0 ? values.DesignationId : ""}
                        value={designationList.find((item: any) => item.value === values.DesignationId) || null}
                        // onChange={(e: any) => {
                        //   handleInputChange(e);
                        // }}
                        onChange={(event: MultiSelectListModel) => {
                          handleInputChange({
                            target: {
                              name: "DesignationId",
                              value: event ? event.value : "",
                            }
                          });
                        }}
                        error={errors.DesignationId}
                      />
                    </Grid> */}
                        <Grid item xs={12} sm={6}>
                      <Controls.MuiAutocomplete
                        options={designationList}
                        label="Designation"
                        error={errors.DesignationId}
                        onChange={(event: MultiSelectListModel) => {
                          handleInputChange({
                            target: {
                              name: "DesignationId",
                              value: event ? event.value : "",
                            }
                          });
                        }}
                        value={designationList.find((item: any) => item.value === values.DesignationId) || null}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controls.MuiAutocomplete
                        required
                        name="DepartmentId"
                        label="Department"
                        options={depatmentList}
                        value={depatmentList.find((item: any) => item.value === values.DepartmentId) || null}
                        onChange={(event: MultiSelectListModel) => {
                          handleInputChange({
                            target: {
                              name: "DepartmentId",
                              value: event ? event.value : "",
                            }
                          });
                        }}
                        error={errors.DepartmentId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        // type="number"
                        label="Email"
                        name="Email"
                        value={values.Email}
                        onChange={handleInputChange}
                        error={errors.Email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        required
                        // type="number"
                        label="Mobile"
                        name="Mobile"
                        value={values.Mobile}
                        onChange={handleInputChange}
                        error={errors.Mobile}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controls.ReactDatePicker
                        label="Date Of Birth"
                        maxDate={EighteenYearsAgoDate}
                        value={values.Dob}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnlyForDatePicker(date),
                              name: 'Dob',
                            },
                          });
                        }}
                        error={errors.Dob}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controls.ReactDatePicker
                        label="Date Of Joining"
                        //  min={values.FromDt}
                        max={new Date()}
                        value={values.Doj}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnly(date),
                              name: 'Doj',
                            },
                          });
                        }}
                        error={errors.Doj}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.ReactDatePicker
                        label="Date Of Leaving"
                        min={values.Dol}
                        max={new Date()}
                        value={values.Dol}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnlyForDatePicker(date),
                              name: 'Dol',
                            },
                          });
                        }}
                        error={errors.Dol}
                      />
                    </Grid>
                    {/* {mode === "Edit" && (
                      <Grid item xs={12} sm={6}>
                        <Controls.Input
                          // type="number"
                          label="Monthly Salary"
                          name="MonthlySalary"
                          value={values.MonthlySalary}
                          onChange={handleInputChange}
                          error={errors.MonthlySalary}
                        />
                      </Grid>
                    )} */}
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        // type="number"
                        label="Qualification"
                        name="Qualification"
                        value={values.Qualification}
                        onChange={handleInputChange}
                        error={errors.Qualification}
                      />
                    </Grid>
                  </Grid>
                </Grid>







                {/* <Grid item xs={12} sm={4}>
                  <Controls.Checkbox
                    label="Is Expense Manager Y/N ?"
                    name="IsSecondLevelApproverYn"
                    value={values.IsSecondLevelApproverYn}
                    onChange={handleInputChange}
                    error={errors.IsSecondLevelApproverYn}
                  />
                </Grid> */}


              </Grid>
            </React.Fragment>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="Address" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Controls.Input
                  required
                  // type="number"
                  label="Address"
                  name="Address1"
                  value={values.Address1}
                  onChange={handleInputChange}
                  error={errors.Address1}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controls.Input
                  label="Apartment"
                  name="Address2"
                  value={values.Address2}
                  onChange={handleInputChange}
                  error={errors.Address2}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controls.Input
                  label="Locality"
                  name="Address3"
                  value={values.Address3}
                  onChange={handleInputChange}
                  error={errors.Address3}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controls.Input

                  // type="number"
                  label="City"
                  name="City"
                  value={values.City}
                  onChange={handleInputChange}
                  error={errors.City}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                  <Controls.Select
                    required
                    name="StateId"
                    label="State"
                    options={stateList}
                    value={stateList.length > 0 ? values.StateId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.StateId}
                  />
                </Grid> */}
              <Grid item xs={12} sm={4}>
                <Controls.MuiAutocomplete
                  options={stateList}
                  label="State"
                  error={errors.StateId}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "StateId",
                        value: event ? event.value : "",
                      }
                    });
                  }}
                  value={stateList.find((item: any) => item.value === values.StateId) || null}
                />

              </Grid>
              <Grid item xs={12} sm={4}>
                <Controls.Input
                  type="number"
                  label="Pincode"
                  name="Pincode"
                  value={values.Pincode}
                  onChange={handleInputChange}
                  error={errors.Pincode}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controls.Select
                  required
                  name="ZoneId"
                  label="Zone"
                  options={zoneList}
                  value={zoneList.length > 0 ? values.ZoneId : ""}
                  onChange={(e: any) => {
                    handleInputChange(e);
                  }}
                  error={errors.ZoneId}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="User Form" />
          <CardContent sx={{ minHeight: 200 }}>
            <React.Fragment>
              <Grid container spacing={3}>
                {mode === "Edit" && (
                  <Grid item xs={12} sm={4}>
                    <Controls.MuiAutocomplete
                      options={userList}
                      label="Employee Login"
                      error={errors.UserId}
                      onChange={(event: MultiSelectListModel) => {
                        handleInputChange({
                          target: {
                            name: "UserId",
                            value: event ? event.value : "",
                          }
                        });
                        { event ? getUserById(event.value) : getUserById('') };
                      }}
                      value={userList.find((item: any) => item.value === values.UserId) || null}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={4}>
                  <Controls.ReactMultiSelect
                    // dataId="User"
                    // dataNested=""
                    options={roles}
                    value={selectedRoles}
                    onChange={(e: any[]) => {
                      setSelectedRoles(e);
                      handleInputChange({ target: { value: e.map((x: any) => x.value).join(','), name: 'SelectedRoleNames' } });
                    }}
                    label="Select Roles"
                    labelledBy="Select Roles"
                    error={errors.SelectedRoleNames}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className="label">Full Name</Typography>
                  <Typography variant="body2">{values?.User?.FullName}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    required
                    dataId="User"
                    dataNested=""
                    readOnly={values.UserId ? true : false}
                    name="UserName"
                    label="UserName"
                    type="text"
                    value={values.User?.UserName}
                    onChange={handleInputChange}
                    error={errors.User?.UserName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className="label">Email</Typography>
                  <Typography variant="body2">{values?.User?.Email}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className="label">Mobile</Typography>
                  <Typography variant="body2">{values?.User?.PhoneNumber}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}></Grid>
                {!values.UserId && <>
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      required
                      dataId="User"
                      dataNested=""
                      name="Password"
                      label="Password"
                      type="password"
                      value={values.User?.Password}
                      onChange={handleInputChange}
                      error={errors.User?.Password}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      required
                      dataId="User"
                      dataNested=""
                      name="ConfirmPassword"
                      label="Confirm Password"
                      type="password"
                      value={values.User?.ConfirmPassword}
                      onChange={handleInputChange}
                      error={errors.User?.ConfirmPassword}
                    />
                  </Grid>
                </>}

              </Grid>
            </React.Fragment>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="Bank Details" />
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Bank Name"
                    name="BankName"
                    value={values.BankName}
                    onChange={handleInputChange}
                    error={errors.BankName}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="IFSC Code"
                    name="Ifsccode"
                    value={values.Ifsccode}
                    onChange={handleInputChange}
                    error={errors.Ifsccode}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="AccountType"
                    label="Account Type"
                    value={accounTypeList.length > 0 ? values.AccountType : ""}
                    onChange={handleInputChange}
                    options={accounTypeList}
                    error={errors.AccountType}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Account No"
                    name="AccountNo"
                    value={values.AccountNo}
                    onChange={handleInputChange}
                    error={errors.AccountNo}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
        </Card>
        {EmployeeId &&
          <Card>
            <Typography variant="h5" align="center">
              Files Upload
            </Typography>
            <CardContent>
              <React.Fragment>
                <Grid item xs={12} sm={4}>
                  <Typography style={{ color: "red", fontSize: "0.75rem" }}>
                    {" "}
                    File Size Should be 5MB
                  </Typography>
                  <Stack spacing={1} direction="row">
                    <Grid item xs={12}>
                      <input
                        type="file"
                        name="file"
                        accept="*/*"
                        multiple
                        onChange={onFileChange}
                        ref={empfileInputRef}
                        style={{ display: "none" }}
                        id="file-input"
                      />
                      <Button
                        startIcon={<PostAddIcon />}
                        variant="contained"
                        //color="success"
                        sx={{ backgroundColor: "success.main", opacity: 0.8 }}
                        onClick={() => empfileInputRef.current?.click()}
                      >
                        Browse File
                      </Button>
                    </Grid>
                    {empfilesUpload.length > 0 && (
                      <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                          {empfilesUpload.map((file, index) => (
                            <Stack
                              direction="row"
                              spacing={1}
                              key={index}
                              alignItems="center"
                            >
                              <Typography>{file.name}</Typography>
                              <IconButton
                                size="small"
                                aria-label="delete"
                                color="error"
                                onClick={() => removeFile(index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Stack>
                          ))}
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setEmpFilesUpload([])}
                          >
                            Remove All
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
              </React.Fragment>
            </CardContent>
          </Card>
        }
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button type="submit" variant="contained">
              Submit
            </Button>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              href="/mEmployees"
            >
              Back{" "}
            </Button>
          </Stack>

        </CardActions>
        {EmployeeId &&
          <Card>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.EmployeeFileId}
                  rows={employeeFiles}
                  columns={employeeFileColumns}
                  columnHeaderHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                />
              </div>
            </div>
          </Card>
        }
      </form >
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  )
}

export default MEmployeeForm
