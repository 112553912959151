import { Guid } from "guid-typescript";
import { ExpenseApprovalModel, ExpenseForAccountModel } from "../models/ExpenseForAccountModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: ExpenseForAccountModel = {

    ExpenseHdrId: Guid.EMPTY,
    ExpenseVoucherNo: "",
    ExpenseVoucherDate: null,
    ExpenseDescription: "",
    CreatedOn: null,
  
}

const initialFieldExpenseApprovalValues: ExpenseApprovalModel = {
    UserID: "",
    FromDt: null,
    ToDt: null,
}


const url = 'ExpenseApproval';

function getExpensesForApproval(userID: string) {
    return customAxios.get(`${url}/GetExpensesForApproval/${userID}`, axiosRetryConfig
    );
}

function getExpensesForAccount() {
    return customAxios.get(`${url}/GetExpensesForAccount`, axiosRetryConfig
    );
}
function getExpensesForApprovalHistory(payload:ExpenseApprovalModel ) {
    return customAxios.post(`${url}/GetExpensesForApprovalHistory`, payload,axiosRetryConfig
    );
}



export const ExpenseApprovalService = {

    getExpensesForApproval,
    getExpensesForAccount,
    getExpensesForApprovalHistory
   
}