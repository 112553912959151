import { MUomsModel, PageUomInfoRequestModel, PageUomInfoResponseModel } from "../models/MUomsModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { Guid } from "guid-typescript";

const initialFieldValues: MUomsModel = {
  Uomid: Guid.EMPTY,
  Uomname: "",
  Uomcode: "",
  Active: true,
};

const initialPageUomInfoResponseValues: PageUomInfoResponseModel = {
  Uomname: "",
  Uomcode: "",
}


const url = "MUoms";

function getAll() {
  return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

function post(payload: MUomsModel) {
  return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}
function put(payload: MUomsModel) {
  return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}
function remove(id: number) {
  return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(id: number) {
  return customAxios.get(`${url}/GetById/${id}`, axiosRetryConfig);
}

function getMUmosSelectList(){
  return customAxios.get(`${url}/GetMUmosSelectList`, axiosRetryConfig)
}

function getPageUomInfo(payload: PageUomInfoRequestModel) {
  return customAxios.post(`${url}/GetPageUomInfo`, payload, axiosRetryConfig);
}


export const mUomsService = {
  initialFieldValues,
  initialPageUomInfoResponseValues,
  getAll,
  post,
  put,
  remove,
  getById,
  getMUmosSelectList,
  getPageUomInfo
};
