import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { MCompaniesModel } from "../models/MCompaniesModel";


const initialFieldValues: MCompaniesModel = {
    CompanyId: Guid.EMPTY,
    CompanyName: '',
    CompanyNameAsPerTally: '',
    Active: true
}

const url = "Mcompanies";


function getCompaniesSelectList() {
    return customAxios.get(`${url}/GetCompaniesSelectList`, axiosRetryConfig);
} 

export const mCompaniesService = {
    initialFieldValues,
    getCompaniesSelectList

}