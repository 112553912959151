import React from "react";

import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Header from "./Header";

export default function DefaultLayout() {
  const location = useLocation();
  let showHeader = true,
    showNavBar = true;
  //if (location.pathname === "/login") showHeader = false;
  if (location.pathname === "/login") {
    showHeader = false;
  }
  if (location.pathname === "/login" || location.pathname === "/")
    showNavBar = false;

  return (
    <>
      {showHeader && <Header />}
      {showNavBar && <Navbar />}
      <main className={location.pathname === "/" ? "" : "main-container"}>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
