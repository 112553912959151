import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDialog from "../helper/ConfirmDialog";
import { BoqposService } from "../../services/BoqPosService";
import { insertDataFBoqpob2bsmodel } from "../../models/BoqposModel";
import useForm from "../../utility/hooks/UseForm";
import dayjs from "dayjs";
import SaveIcon from "@mui/icons-material/Save";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const BOQPODtlList = () => {
  const { BOQPOB2BID } = useParams();
  const [viewPaymentTermsOpen, setViewPaymentTermsOpen] = React.useState(false);
  //  const [boqPoDtls, setBoqPoDtls] = useState([]);
  const [editBoqPoDtls, setEditBoqPoDtls] = useState<any[]>([]);
  const [actualBoqPoDtls, setActualBoqPoDtls] = useState<any[]>([]);

  const [Boqpob2bId, setBoqpob2bId] = useState<any>(
    BoqposService.initialFieldValuesforinsertDataFBoqpob2bsmodel
  );

  const navigate = useNavigate();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });



  useEffect(() => {
    getBoqpob2bid(BOQPOB2BID);
    getBoqPoDtls(BOQPOB2BID);
  }, []);

  const getBoqpob2bid = (Boqpob2bid: any) => {
    BoqposService.getByIdBoqpob2bid(Boqpob2bid).then((response) => {
      if (response) {
        let result = response.data;
        setBoqpob2bId(result.data);

        // setEditBoqPoDtls(result);
        // setActualBoqPoDtls(result);
      }
    });
  };
  const openviewPaymentTermsDialog = (BOQPOB2BID: any) => {
    setViewPaymentTermsOpen(true)
  };
  const handleProcessQuotationRowUpdate = (newRow: any, oldRow: any) => {
    const updatedField = Object.keys(newRow).find(
      (key) => newRow[key] !== oldRow[key]
    );

    let actualData = actualBoqPoDtls.filter(
      (x) => x.BOQUploadId === newRow.BOQUploadId
    )[0];

    //let isError = false;
    if (newRow.InstallationRate < 0) {
      globalService.error("InstallationRate cannot be < 0");
      newRow.InstallationRate = actualData.InstallationRate;
    }
    if (!newRow.HSNCode) {
      globalService.error("HSNCode is required");
      newRow.HSNCode = actualData.HSNCode; 
    }
    if (newRow.SupplyRate < 0) {
      globalService.error("SupplyRate cannot be < 0");
      newRow.SupplyRate = actualData.SupplyRate;
    }
  
    if (
      (newRow.InstallationRate &&
        actualData.InstallationRate !== newRow.InstallationRate) ||
      (newRow.SupplyRate && actualData.SupplyRate !== newRow.SupplyRate) ||
      (newRow.Cgstperc && actualData.Cgstperc !== newRow.Cgstperc) ||
      (newRow.Cgstamt && actualData.Cgstamt !== newRow.Cgstamt) ||
      (newRow.Sgstperc && actualData.Sgstperc !== newRow.Sgstperc) ||
      (newRow.Sgstamt && actualData.Sgstamt !== newRow.Sgstamt) ||
      (newRow.Igstperc && actualData.Igstperc !== newRow.Igstperc) ||
      (newRow.Igstamt && actualData.Igstamt !== newRow.Igstamt) ||
      (newRow.Remarks && actualData.Remarks !== newRow.Remarks) ||
      (newRow.Qty && actualData.Qty !== newRow.Qty) ||
      (newRow.HSNCode && actualData.HSNCode !== newRow.HSNCode) ||
      (newRow.Rate && actualData.Rate !== newRow.Rate) ||
      (newRow.Amount && actualData.Amount !== newRow.Amount)
    ) {
      newRow.IsEdited = true;
    } else {
      newRow.IsEdited = false;
    }
    
    setEditBoqPoDtls((prevRows: any) =>
      prevRows.map((row: any) =>
        row.BOQUploadId === newRow.BOQUploadId ? newRow : row,
      )
    );
    return newRow;
  };

  const getBoqPoDtls = (BOQPOB2BID: any) => {
    //  var BOQPOB2BID = values.BOQPOB2BID
    BoqposService.getBOQPOItemDtls(BOQPOB2BID).then((response) => {
      let result = response.data;
      setEditBoqPoDtls(result.list);

      setActualBoqPoDtls(result.list);
    });
  };

  const getQuotationRowClassName = (params: any) => {
    let isRowEdited = editBoqPoDtls.some(
      (x) => x.BOQUploadId === params.id && x.IsEdited == true
    );
    return isRowEdited ? "editedRow" : "";
  };

  const handleProcessQuotationRowUpdateError = (error: any) => {
    console.error(error);
  };

  const UpdateBoqPoDtls = () => {
    
    var modifiedRows = editBoqPoDtls.filter((x) => x.IsEdited);
    if (modifiedRows.length === 0) {
      globalService.error("No records to modify.");
      return;
    }
  
    // Prepare data to send for editing
    const filteredArray = modifiedRows.map((row) => {
      // Calculate Rate based on SupplyRate and InstallationRate
      const supplyRate = row.SupplyRate || 0;
      const installationRate = row.InstallationRate || 0;
      const rate = supplyRate + installationRate;
      // Calculate Amount based on Qty and Rate
      const qty = row.Qty || 0;
      const amount = qty * rate;
      const cgstperc = row.Cgstperc || 0;
      const sgstperc = row.Sgstperc || 0;
      const igstperc = row.Igstperc || 0;
      const cgstamt = amount *cgstperc / 100;
      const sgstamt = amount * sgstperc / 100;
      const igstamt =amount * igstperc / 100;
      return {
        BOQUploadId: row.BOQUploadId,
        Qty: row.Qty,
        SupplyRate: row.SupplyRate,
        InstallationRate: row.InstallationRate,
        Rate: rate, // Assign calculated Rate
        Remarks: row.Remarks,
        HSNCode: row.HSNCode,
        Amount: amount, // Assign calculated Amount
        Cgstamt:cgstamt,
        Sgstamt:sgstamt,
        Igstamt: igstamt,
        Cgstperc: cgstperc,
        Sgstperc: sgstperc,
        Igstperc: igstperc

      };
    });
  
    // Call the API to edit BoqPoDtls
    BoqposService.editBoqPoDtls(filteredArray).then((response) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          globalService.success(result.message);
          getBoqPoDtls(BOQPOB2BID); // Refresh data after successful edit
        } else {
          globalService.error(result.message);
        }
      }
    });
  };

  const boqPoDtlscolumns: GridColDef[] = [
    {
      field: "BOQDescription",
      headerName: "BOQ Description",
      width: 220,
      cellClassName:"cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    { field: "Unit", headerName: "Unit", width: 50 },
    {
      field: "HSNCode",
      headerName: "HSNCode",
      width: 100,
      // type: "number",
      editable: true,
      cellClassName: "editableColumn",
    },
    {
      field: "SupplyRate",
      headerName: "Supply Rate",
      width: 90,
      headerAlign: "right", valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    
      type: "number",
      editable: true,
      cellClassName: "editableColumn",
    },
    {
      field: "InstallationRate",
      headerName: "Installn Rate",
      width: 90,
      type: "number",
      editable: true,
      cellClassName: "editableColumn",valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    
    },
    {
      field: "Rate",
      headerName: "Rate",
      width: 90,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueGetter: (params) => {
        const supplyRate = params.row.SupplyRate || 0;
        const installationRate = params.row.InstallationRate || 0;
        params.row.Rate = supplyRate + installationRate;
        return supplyRate + installationRate;
      },
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    
    {
      field: "Qty",
      headerName: "Qty",
      width: 70,
      headerAlign: "right",
    
      type: "number",
      editable: true,
      cellClassName: "editableColumn",
    },
    {
      field: "Amount",
      headerName: "Amount",
      width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueGetter: (params) => {
        var qty = params.row.Qty || 0;
        var rate = params.row.Rate || 0;
        return qty * rate;
      },
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "Cgstperc",
      headerName: "CGST %",
      width: 60,
      headerAlign: "right",
      type: "number",
      editable: true,
      cellClassName: "editableColumn",
      valueFormatter: (params) => {
        return globalService.formatToPercentage(params.value);
     },
    },
    { field: "Cgstamt", headerName: "CGST Amt", width: 80 ,
      valueGetter:(params)=>{
        var amount = params.row.Amount || 0;
        var cgstperc = params.row.Cgstperc || 0;
        
        return amount * cgstperc / 100;
      },
      
valueFormatter: (params) => {
  return globalService.formatToIndianCurrency(params.value);
},
    },
    {
      field: "Sgstperc",
      headerName: "SGST %",
      width: 60,
      headerAlign: "right",
    
      type: "number",
      editable: true,
      cellClassName: "editableColumn",
      valueFormatter: (params) => {
        return globalService.formatToPercentage(params.value);
     },
    },
    { field: "Sgstamt", headerName: "SGST Amt", width: 80 ,  valueGetter:(params)=>{
      var amount = params.row.Amount || 0;
      var sgstperc = params.row.Sgstperc || 0;
      return amount * sgstperc / 100;
    },
    valueFormatter: (params) => {
            return globalService.formatToIndianCurrency(params.value);
          },},
    {
      field: "Igstperc",
      headerName: "IGST %",
      width: 60,
      headerAlign: "right",
    
      type: "number",
      editable: true,
      cellClassName: "editableColumn",
      valueFormatter: (params) => {
        return globalService.formatToPercentage(params.value);
     },
    },
    { field: "Igstamt", headerName: "IGST Amt", width: 80  ,  valueGetter:(params)=>{
      var amount = params.row.Amount || 0;
      var igstperc = params.row.Igstperc || 0;
      return amount * igstperc / 100;
    },
    valueFormatter: (params) => {
            return globalService.formatToIndianCurrency(params.value);
          },},
    { field: "VendorMake", headerName: "Vendor Make", width: 100 },
    {
      field: "Remarks",
      headerName: "Remarks",
      width: 150,
      // type: "number",
      editable: true,
      cellClassName: "editableColumn",
    },
    { field: "Location", headerName: "Location", width: 100 },
    { field: "BOQSize", headerName: "BOQ Size", width: 100 },
    { field: "BOQMake", headerName: "BOQ Make", width: 100 },
    { field: "ClientSrNo", headerName: "Client SrNo", width: 80 },
    { field: "ANJSrNo", headerName: "ANJ SrNo", width: 80 },
  ];

  return (
    <>
      <Typography variant="h5" align="center">
        BOQ PO Item Details
      </Typography>

      <Card>
        <CardContent>
          <React.Fragment>
            <Grid container spacing={3}>
              <Grid item xs={8} sm={3}>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                  <Typography className="label">Project </Typography>
                  <Typography variant="body2">
                    {Boqpob2bId.ProjectDirectory?.ProjectShortName}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8} sm={3}>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                  <Typography className="label"> PO No. </Typography>
                  <Typography variant="body2">
                    {Boqpob2bId.Boqpob2bno}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8} sm={3}>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                  <Typography className="label"> PO Date </Typography>
                  <Typography variant="body2">
                    {dayjs(Boqpob2bId.Boqpob2bdate).format("DD-MMM-YYYY")}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8} sm={3}>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                  <Typography className="label">PO Value </Typography>
                  <Typography variant="body2">
                  {globalService.formatToIndianCurrency(Boqpob2bId.PoGrossTotal)}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8} sm={3}>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                  <Typography className="label"> Expected DateOfDelivery </Typography>
                  <Typography variant="body2">
                    {dayjs(Boqpob2bId.ExpectedDateOfDelivery).format("DD-MMM-YYYY")}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8} sm={3}>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                  <Typography className="label">Vendor Name </Typography>
                  <Typography variant="body2">
                    {Boqpob2bId.Vendor?.VendorName}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8} sm={3}>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                  <Typography className="label">PO Status </Typography>
                  <Typography variant="body2">
                    {Boqpob2bId.Boqpob2bstatus} {Boqpob2bId?.RejectedRemarks}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8} sm={3}>
                <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                  <Typography className="label">CreatedOn </Typography>
                  <Typography variant="body2">
                    {dayjs(Boqpob2bId.CreatedOn).format("DD-MM-YYYY")}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BOQUploadId}
              rows={editBoqPoDtls}
              columns={boqPoDtlscolumns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={false}
              getRowClassName={getQuotationRowClassName}
              processRowUpdate={handleProcessQuotationRowUpdate}
              onProcessRowUpdateError={handleProcessQuotationRowUpdateError}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    BOQPOB2BID: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />

            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </div>
        </CardContent>

        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
          {(Boqpob2bId.Boqpob2bstatus) === 'Pending' && (
            <Button
              sx={{ marginTop: 2 }}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => UpdateBoqPoDtls()}
            >
              Submit Details
            </Button>
          )}
           <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              // href="/editpos"
              onClick={() => navigate(-1)}
            >
              Back{" "}
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};

export default BOQPODtlList;
