import Checkbox from "./Checkbox";
import ReactDatePicker from "./DatePicker";
import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Tab from "./Tab";
import TextareaCellEditor from "./TextareaCellEditor";
import ReactMultiSelect from "./ReactMultiSelect"
import MuiAutocomplete from "./MuiAutocomplete";

const Controls = {
    Input,
    RadioGroup,
    Select,
    ReactMultiSelect,
    Checkbox,
    ReactDatePicker,
    Tab,
    TextareaCellEditor,
    MuiAutocomplete,
    
}

export default Controls;