import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { boqitemService } from "../../services/BoqItemService";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import ConfirmDialog from "../helper/ConfirmDialog";
import { FC } from "react";
import { boqPackageService } from "../../services/BoqPackageService";
import useForm from "../../utility/hooks/UseForm";
import { config, AttributeTypes } from "../../utility/Config";
import { SelectListModel } from "../../models/ApiResponse";
import Controls from "../../utility/Controls.tsx/Controls";
import { globalService } from "../../services/GlobalService";

const BoqItemList = (...props: any) => {
  const [boqItem, setBoqItem] = useState([]);
  const [packagesList, setPackagesList] = useState<SelectListModel[]>([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (packagesList.length === 0) {
      getPackageSelectLists();
    } else {
      getBoqItem();
    }
  }, []);
  

  const validate = (fieldValues:any = values) => {
    let temp: any = { ...errors };
    if ("BoqpackageId" in fieldValues)
      temp.BoqpackageId = fieldValues.BoqpackageId 
        ? ""
        : "Please Select BOQ Package";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqitemService.initialFieldValues, validate, props.setCurrentId);

    
  const getPackageSelectLists = () => {
    boqPackageService.getPackageSelectList().then((response) => {
      setPackagesList(response.data);
      //setValues(response.data);
    });
  };

    
  const getBoqItem = () => {
    boqitemService
      .getAllMBoqitemsByPackageID(values.BoqpackageId)
      .then((response) => {
        let result = response.data;
        setBoqItem(result.list);
      });
  };
  


  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate()) {
      if (values.BoqpackageId) {
        boqitemService.getAllMBoqitemsByPackageID(values.BoqpackageId).then((response) => {
          let result = response.data;
          if (response) {
            setBoqItem(result.list);
          } else {
            globalService.error(result.message);
          }
        });
      }else{
        globalService.error("Please Select BOQ Package");
      }
    }
  };


  const itemcoloumns: GridColDef[] = [
    {
      field: "BoqtitleName",
      headerName: "Title Name",
      flex: 1,
      valueGetter: (params) => params.row.BoqsubTitle?.Boqtitle?.BoqtitleName,
    },
    {
      field: "BoqsubTitle",
      headerName: "SubTitle Name",
      flex: 1,
      valueGetter: (params) => params.row.BoqsubTitle?.BoqsubTitleName,
    },
    { field: "BoqitemName", headerName: "Item Name", flex: 1 },
    {
      field: "AlternativeText",
      headerName: "Alternative Text",
      // width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqItemAlternativeTexts/" 
                + params.row.BoqitemId, 
               {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Alternatives
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "Attributes",
      headerName: "Attributes",
      flex: 1,
      // width: 400,
      // type: "number",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <Button
              className="btnGrid"
              variant="contained"
              onClick = {() =>
                navigate("/boqItemAttribute/" + params.row.BoqitemId  +
               "/" + AttributeTypes.Size ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Size
            </Button>
          
            <Button
              className="btnGrid"
              variant="contained"
              onClick = {() =>
                navigate("/boqItemAttribute/" + params.row.BoqitemId  +
               "/" + AttributeTypes.Make ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Make
            </Button>
            {/* <Button
              className="btnGrid"
              variant="contained"
              onClick = {() =>
                navigate("/boqItemAttribute/" + params.row.BoqitemId  +
               "/" + AttributeTypes.Color ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Color
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick = {() =>
                navigate("/boqItemAttribute/" + params.row.BoqitemId  +
               "/" + AttributeTypes.Material ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Material
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick = {() =>
                navigate("/boqItemAttribute/" + params.row.BoqitemId  +
               "/" + AttributeTypes.Activity ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Activity
            </Button> */}
          </Stack>
        );
      },
    },
    // {
    //   field: "Active",
    //   headerName: "Active",
    //   width: 130,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Stack>
    //         {/* {params.row.Active && "Yes"}
    //         {!params.row.Active && "No"} */}
    //         {params.row.Active && <DoneIcon color="secondary" />}
    //         {!params.row.Active && <CloseIcon />}
    //       </Stack>
    //     );
    //   },
    // },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      width: 100,
      // flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editItem/" +params.row.BoqsubTitle.Boqtitle.BoqpackageId+ "/" + params.row.BoqitemId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: `Are you sure to delete "${params.row?.BoqitemName}" Item ?`,
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeBoqItem(params.row.BoqitemId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const removeBoqItem = (BoqitemId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    boqitemService.remove(BoqitemId).then((response) => {
      let result = response.data;
      if (response) {
          globalService.success(result.message);
          getBoqItem();
      }
    });
  };
  const packageValidate = () => {
    var boqPackId = values.BoqpackageId
    if(boqPackId){
      navigate("/addItem/" + boqPackId)
    }else{
      globalService.error("Please Select Package Name.")
    //  alert("Please select package name.")
    }

  };

  return (
    <>
      <Typography variant="h5" align="center">
        BOQ Items
      </Typography>
      <Card>
        <CardContent>
        <form noValidate onSubmit={handleSubmit}>
            <React.Fragment>
              <Grid container spacing={3} marginBottom={1}>
                <Grid item xs={12} sm={4}>
                  <Controls.Select
                    name="BoqpackageId"
                    label="BOQ Packages"
                    required
                    // showEmptyItem={true}
                    options={packagesList}
                    value={packagesList.length > 0 ? values.BoqpackageId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.BoqpackageId}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button type="submit" variant="contained">
                    Go
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          </form>

          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => packageValidate()}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BoqitemId}
              columns={itemcoloumns}
              rows={boqItem}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    boqTitleId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default BoqItemList;
