
import { Guid } from "guid-typescript";
import { EditDataForBOQPODTOmodel, EditPosChangeStatusModel, EditPosmodel, RejectBoqPoStatus, UpdateBoqPoStatusByApprover, insertDataFBoqpob2bsmodel, insertDataForBOQPOmodel } from "../models/BoqposModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: EditPosmodel = {
    UserID: '',
    ProjectDirectoryId: '',
    VendorId: '',
    FromDt: null,
    ToDt: null,
}
const initialFieldValuesforEditPosChangeStatus: EditPosChangeStatusModel = {
    Boqpob2bid: '',
    Boqpob2bstatusId: '',
    UpdatedByID: '',
    UpdatedOn: null,
}

const initialFieldValuesforinsertDataForBOQPOmodel: insertDataForBOQPOmodel = {
    ProjectDirectoryID: '',
    BOQUploadIDs: '',
    CreatedByID: '',
    ShipToSiteID: "",
    BillToOurOfficesID: ""
}


const initialFieldValuesforinsertDataFBoqpob2bsmodel: insertDataFBoqpob2bsmodel = {
    Boqpob2bid: Guid.EMPTY,
    Boqrfqid: "",
    ProjectDirectoryId: "",
    ApprovedRejectedByUserId: "",
    Boqpob2bno: "",
    BillToOurOfficesId: "",
    ShipToSiteId: "",
    SerialNo: "",
    Boqpob2bdate: null,
    VendorId: "",
    Remarks: "",
    Boqpob2bstatusId: 0,
    RejectedRemarks: "",
    PaymentTerms: "",
    ExpectedDateOfDelivery: null,
    Boqpob2bstatus: "",
    PoGrossTotal: 0,
    ProcurementTypeId: "",
    ApprovedRejectedOn: null,
    BoqpackageId: ""
}

const initialUpdateBoqPoStatusByApproverFieldValues: UpdateBoqPoStatusByApprover = {
    Boqpob2bid: "",
    Boqpob2bstatusId: "",
    UpdatedById: "",
    UpdatedOn: null,
}

const initialRejectBoqPoStatusFieldValues: RejectBoqPoStatus = {
    Boqpob2bid: "",
    Boqpob2bstatusId: "",
    RejectedRemarks: "",
    UpdatedById: "",
    UpdatedOn: null,
}
const initialvalueforEditDataForBOQPODTOmodel: EditDataForBOQPODTOmodel = {
    BOQPOB2BID: "",
    PaymentTermIDs: [],
    BOQDocFileIDs: [],
    UpdatedByID: ""
}
const url = "/boqpob2bs";

function GetB2BPOs(payload: EditPosmodel) {
    return customAxios.post(`${url}/GetB2BPOs`, payload, axiosRetryConfig)
}
function editDataForBOQPOs(payload: EditDataForBOQPODTOmodel) {

    return customAxios.post(`${url}/EditDataForBOQPOs`, payload, axiosRetryConfig)
}

function editPosforStatus(payload: EditPosChangeStatusModel) {
    return customAxios.put(`${url}/EditPosforStatus`, payload, axiosRetryConfig);
}

function insertDataForBOQPO(payload: insertDataForBOQPOmodel) {
    return customAxios.post(`${url}/InsertDataForBOQPO`, payload, axiosRetryConfig)
}

function getBOQPOItemDtls(BOQPOB2BID: string) {

    return customAxios.post(`${url}/GetBOQPOItemDtls/${BOQPOB2BID}`, axiosRetryConfig)
}
function put(payload: insertDataFBoqpob2bsmodel) {
    return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig)
}

function getByIdBoqpob2bid(Boqpob2bid: string) {

    return customAxios.get(`${url}/GetById/${Boqpob2bid}`, axiosRetryConfig);
}


function editBoqPoDtls(payloads: any[]) {
    //BOQUploadId, BOQMake, BOQSize
    return customAxios.put(`${url}/EditBoqPoDtls`, payloads, axiosRetryConfig);
}

function updateBoqPoStatusByApprover(payloads: UpdateBoqPoStatusByApprover) {
    return customAxios.put(`${url}/UpdateBoqPoStatusByApprover`, payloads, axiosRetryConfig);
}
function rejectBoqPoStatus(payloads: RejectBoqPoStatus) {
    return customAxios.put(`${url}/RejectBoqPoStatus`, payloads, axiosRetryConfig);
}



export const BoqposService = {
    initialFieldValues,
    editDataForBOQPOs,
    initialFieldValuesforinsertDataForBOQPOmodel,
    initialFieldValuesforinsertDataFBoqpob2bsmodel,
    initialFieldValuesforEditPosChangeStatus,
    initialUpdateBoqPoStatusByApproverFieldValues,
    initialRejectBoqPoStatusFieldValues,
    GetB2BPOs,
    initialvalueforEditDataForBOQPODTOmodel,
    editPosforStatus,
    insertDataForBOQPO,
    getBOQPOItemDtls,
    put,
    getByIdBoqpob2bid,
    editBoqPoDtls,
    updateBoqPoStatusByApprover,
    rejectBoqPoStatus,
}