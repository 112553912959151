import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { boqItemAttributeService } from '../../services/BoqItemAttributeService';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddCircleOutline } from "@mui/icons-material";
import ConfirmDialog from "../helper/ConfirmDialog";
import { Button, Card, CardActions, CardContent, IconButton, Stack, Typography } from "@mui/material";
import { boqitemService } from '../../services/BoqItemService';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PageTitleInfoRequestModel, PageTitleInfoResponseModel } from '../../models/BoqItemModel';


const BoqItemAttributeList: React.FC = () => {

  const [boqItemAttribute, setItemAttribute] = useState([]);
  const prevPgState = useLocation();
  const [titleInfo, setTitleInfo] = useState<PageTitleInfoResponseModel>(boqitemService.initialPageTitleInfoResponseValues);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  const { AttributeType, BoqitemId }: any = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    getItemAttribute();
    getPageTitle();
    //   getBoqitemName(BoqitemId);
  }, []);

  const getItemAttribute = () => {

    boqItemAttributeService.GetAllMBoqitemAttributesByAttType(BoqitemId, AttributeType).then((response) => {
      let result = response.data;
      
      setItemAttribute(result.list);
      // values.AttributeTypeName = result.list[0].AttributeTypeName
    });
  };

  const getPageTitle = () => {
    let model: PageTitleInfoRequestModel = {
      BoqItemId: BoqitemId,
      AttributeType: AttributeType,
      BoqSubTitleId: '',
      BoqTitleId: '',
      BoqPackageId: '',
    };
    boqitemService
      .getPageTitleInfo(model)
      .then((response) => {
        setTitleInfo(response.data.row);
      });
  };

  const itemattcolumn: GridColDef[] = [
    { field: "BoqitemAttributeName", headerName: "Item Attribute Name", flex: 1, },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      width: 100,
      // flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editItemAttribute/" + BoqitemId + "/" + AttributeType + "/" + params.row.BoqitemAttributeId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this Item Attribute ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeBoqItemAttribute(params.row.BoqitemAttributeId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ]

  const removeBoqItemAttribute = (BoqitemAttributeId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    boqItemAttributeService.remove(BoqitemAttributeId).then((response) => {
      let result = response.data;
      if (response) {
        //   globalService.success(result.message);
        getItemAttribute();
      }
    });
  };


  return (
    <>
      {/*    
<Typography variant="h5" align="center">
        Attribute Type : {AttributeTypeName}
      </Typography> */}
      <Stack direction="row" spacing={0} justifyContent="space-between">
        <Typography variant="h5" align="center">
          BOQ Item Attribute
        </Typography>
        {(titleInfo ) &&
          <Typography variant="body1"> <b>BOQ Package :</b> {titleInfo.BoqPackageName}
            <br />
            <b>BOQ Title Name :</b> {titleInfo.BoqTitleName}
            <br />
            <b> BOQ SubTitle Name: </b>{titleInfo.BoqSubTitleName}
            <br />
            <b> BOQ Item Name: </b>{titleInfo.BoqItemName}
            <br />
            <b> BOQ Attribute: </b>{titleInfo.AttributeTypeName}
          </Typography>
        }
      </Stack>
      <Card>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => navigate("/addItemAttribute/" + BoqitemId + "/" + AttributeType)}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BoqitemAttributeId}
              columns={itemattcolumn}
              rows={boqItemAttribute}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    boqTitleId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>

        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back To List
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  )
}

export default BoqItemAttributeList
