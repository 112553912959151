import { MUomalternativeTextsModel } from "../models/MUomalternativeTextsModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { Guid } from "guid-typescript";

const initialFieldValues: MUomalternativeTextsModel = {
  UomalternativeTextId: Guid.EMPTY,
  Uomid: "",
  UomalternativeTextName: "",
  Active: true,
};

const url = "MUomalternativeText";

function getAll(UomId:number) {
  return customAxios.get(`${url}/GetAll/${UomId}`, axiosRetryConfig);
}

function post(payload: MUomalternativeTextsModel) {
  return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}
function put(payload: MUomalternativeTextsModel) {
  return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}
function remove(id: number) {
  return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(id: number) {
  return customAxios.get(`${url}/GetById/${id}`, axiosRetryConfig);
}

export const mUomalternativeTextService = {
  initialFieldValues,
  getAll,
  post,
  put,
  remove,
  getById,
};
