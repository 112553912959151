import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { mBoqshortFormsService } from "../../services/MBoqshortFormsService";
import { mItemsService } from "../../services/MItemsService";
import { MItemModel } from "../../models/MItemModel";
import { mUomsService } from "../../services/MUomsService";
import { SelectListModel } from "../../models/ApiResponse";
// import { ItemTypes } from "../../utility/Config";

const MItemForm = (...props: any) => {
  const { ItemId } = useParams();
  let navigate = useNavigate();
  const mode = ItemId ? "Edit" : "Create";

  const [uoms, setUoms] = useState<SelectListModel[]>([]);
  const ItemTypes = [
    { Value: "F", Text: "F" },
    { Value: "I", Text: "I" },
    { Value: "R", Text: "R" },
  ];
  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    if ("ItemName" in fieldValues)
      temp.ItemName = fieldValues.ItemName ? "" : "Item Name is required";
    if ("Hsncode" in fieldValues)
      temp.Hsncode = fieldValues.Hsncode ? "" : "Hsn code is required";
    if("Qty" in fieldValues)
      temp.Qty = fieldValues.Qty ? "" : "Qty is required";
    if("ItemType" in fieldValues)
      temp.ItemType = fieldValues.ItemType ? "" : "ItemType is required";
    if("Uomid" in fieldValues)
      temp.Uomid = fieldValues.Uomid ? "" : "Uom is required";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mItemsService.initialFieldValues, validate, ItemId);

  const newItems = () => {
    setValues(mItemsService.initialFieldValues);
  };

  function setFormValue(model: MItemModel) {
    let newModel = {
      ItemId: model.ItemId,
      ItemName: model.ItemName || "",
      Uomid: model.Uomid,
      Hsncode: model.Hsncode || "",
      ItemType: model.ItemType,
      Qty: model.Qty,
      TaxPerc: model.TaxPerc,
      Active: model.Active,
    };
    return newModel;
  }

  useEffect(() => {
    if (ItemId) {
      getItem(ItemId);
      setErrors({});
    }
    if (uoms.length === 0) {
      getMUmosSelectList();
    } else newItems();
  }, [ItemId]);

  const getItem = (ItemId: any) => {
    mItemsService.getById(ItemId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    });
  };

  const getMUmosSelectList = () => {
    mUomsService.getMUmosSelectList().then((response) => {
      let result = response.data;
      setUoms(result);
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    
    e.preventDefault();
    if(validate()) {
    if (ItemId) {
    
      mItemsService.put(values).then((response: any) => {
        let result = response.data;
        if (response) {
          globalService.success(result.message);
          navigate("/mItems");
        } else {
          globalService.error(result.message);
        }
      });
    } else {
      
      mItemsService.post(values).then((response: any) => {
        if (response) {
          let result = response.data;
          if (result.isSuccess) {
            globalService.success(result.message);
            navigate("/mItems");
          } else {
            globalService.error(result.message);
          }
        }
      });
    }
    }
  };

  return (
    <>
      <Typography>Item Form</Typography>

      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Item Name"
                    name="ItemName"
                    required
                    value={values.ItemName}
                    onChange={handleInputChange}
                    error={errors.ItemName}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="HSN Code"
                    name="Hsncode"
                    required
                    value={values.Hsncode}
                    onChange={handleInputChange}
                    error={errors.Hsncode}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Qty"
                    name="Qty"
                    required
                    value={values.Qty}
                    onChange={handleInputChange}
                    error={errors.Qty}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Tax Perc"
                    name="TaxPerc"
                    value={values.TaxPerc}
                    onChange={handleInputChange}
                    error={errors.TaxPerc}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="Uomid"
                    label="UOM Name"
                    showEmptyItem={false}
                    options={uoms}
                    value={uoms.length > 0 ? values.Uomid : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.Uomid}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="ItemType"
                    label="Item Type"
                    showEmptyItem={false}
                    options={ItemTypes}
                    value={values.ItemType}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.ItemType}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default MItemForm;
