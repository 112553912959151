import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { AuthProvider } from './utility/context/AuthContext';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      //main: '#0492c2'
      main: '#9ba1a3' //#0084ef
    },
    secondary: {
      main: '#E33E7F'
    },
    error: {
      main: '#cb0e00'
    },
    info: {
      main: '#5211ea'
    }
  },
  typography: {
    // subtitle1: {
    //   fontSize: 12,
    // },
    // body1: {
    //   fontWeight: 500,
    // },
    // button: {
    //   fontStyle: 'italic',
    // },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: "center",
          border: "1px solid rgba(224, 224, 224, 1)",
          fontSize: '0.8rem',
        }
      }
    },
    // MuiTablePagination: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '0.8rem',
    //     }
    //   }
    // },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          fontSize: '0.8rem',
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          //width: "100%",
          // margin: 0
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          // fontSize: '0.8rem',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.8rem',
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.8rem',
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'transparent', // Hide the default indicator
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#0492c2",
          cursor: "pointer"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          padding: '7px',
          '&.Mui-selected': {
            color: '#0084ef',
            borderBottom: '2px solid #0084ef',
          },
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          // color: 'white' //this is affecting outlined button also
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          '&.Mui-focused': {
            fontSize: '1rem', // Ensure the font size is the same when focused
          },
          '&.MuiInputLabel-shrink': {
            fontSize: '1rem', // Ensure the font size is the same when shrinked
          },
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            fontSize: '0.8rem',
          },
        },
      },
    },
  }
});
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Container maxWidth="xl"> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </Container> */}
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

