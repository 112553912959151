import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
  Divider,
  Link,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { expenseDtlsService } from "../../services/ExpenseDtlsService";
import { ExpenseDtlsModel } from "../../models/ExpenseDtlsModel";
import { SelectListModel } from "../../models/ApiResponse";
import { config } from "../../utility/Config";
import { expenseHeadService } from "../../services/MExpenseHeadService";
import { ExpenseHdrsService } from "../../services/ExpenseHdrsService";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import { ROLES } from "../../utility/Config";
import { AuthContext } from "../../utility/context/AuthContext";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ConfirmDialog from "../helper/ConfirmDialog";
import { expenseDtlFileService } from "../../services/ExpenseDtlFileService";

const ExpenseDtlsForm = (props: any) => {
  const { ExpenseDtlId, ExpenseHdrId } = props;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  const { auth } = React.useContext(AuthContext);
  const mode = ExpenseDtlId ? "Edit" : "Create";
  const isAccountRole = globalService.roleMatch([ROLES.Accounts], auth);
  const [filesUpload, setFilesUpload] = useState<File[]>([]);
  const [actualInvoiceAmount, setActualInvoiceAmount] = useState<number>(0);
  const [expenseHeadList, setExpenseHeadList] = useState<SelectListModel[]>([]);
  const [expenseDtlFiles, setExpenseDtlFiles] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("ExpenseHeadId" in fieldValues)
      temp.ExpenseHeadId = fieldValues.ExpenseHeadId
        ? ""
        : "Please Select Expense Head";
    if ("Remark" in fieldValues)
      temp.Remark = fieldValues.Remark ? "" : "Please Select Remark";
    if ("BasicAmount" in fieldValues)
      temp.BasicAmount = fieldValues.BasicAmount
        ? (temp.BasicAmount = /^[0-9\b]+$/.test(
          fieldValues.BasicAmount.toString()
        )
          ? ""
          : "Basic Amount is not valid.")
        : "Basic Amount is required";

    // if ("Gsttotal" in fieldValues)
    //   temp.Gsttotal = fieldValues.Gsttotal
    //     ? (temp.Gsttotal = /^[0-9\b]+$/.test(fieldValues.Gsttotal.toString())
    //       ? ""
    //       : "GST Total is not valid.")
    //     : "GST Total is required";

    if ("Gsttotal" in fieldValues) {
      const gstTotalValue = fieldValues.Gsttotal;
      if (
        gstTotalValue !== undefined &&
        gstTotalValue !== null &&
        gstTotalValue !== ""
      ) {
        if (/^[0-9]+$/.test(gstTotalValue.toString())) {
          temp.Gsttotal = ""; // Valid GST total
        } else {
          temp.Gsttotal = "GST Total is not valid."; // Invalid GST total
        }
      } else {
        temp.Gsttotal = "GST Total is required"; // GST total is required
      }
    }
    
    if ("InvoiceAmt" in fieldValues) {
      temp.InvoiceAmt = fieldValues.InvoiceAmt
        ? (isAccountRole && mode === 'Edit') ? (actualInvoiceAmount !== fieldValues.InvoiceAmt) ? "Invoice Amount should be " + actualInvoiceAmount : "" : ""
        : "Invoice Amount is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(expenseDtlsService.initialFieldValues, validate, ExpenseDtlId);

  const newExpenseDtl = () => {
    setValues(expenseDtlsService.initialFieldValues);
  };

  useEffect(() => {
    //getExpenseHdrId(ExpenseHdrId);
    if (ExpenseDtlId) {
      getExpenseDtl(ExpenseDtlId);
      getExpenseDtlFiles(ExpenseDtlId);
      setErrors({});
    } else newExpenseDtl();
    if (expenseHeadList.length === 0) getExpenseHeadList();
    // if (clientList.length === 0) getClients();
  }, [ExpenseDtlId, ExpenseHdrId]);

  const getExpenseHeadList = () => {
    expenseHeadService.getExpenseHeadSelectList().then((response) => {
      setExpenseHeadList(response.data);
    });
  };

  function setFormValue(model: ExpenseDtlsModel) {
    let newModel = {
      ExpenseDtlId: model.ExpenseDtlId,
      ExpenseHdrId: model.ExpenseHdrId,
      ExpenseHeadId: model.ExpenseHeadId,
      ProjectDirectoryId: model.ProjectDirectoryId,
      BasicAmount: model.BasicAmount,
      Cgst: model.Cgst,
      Sgst: model.Sgst,
      Igst: model.Igst,
      Gsttotal: model.Gsttotal,
      InvoiceAmt: model.InvoiceAmt,
      Active: model.Active,
      Remark: model.Remark,
      FilePath: model.FilePath,
      ClientId:
        model.ClientId || model.ProjectDirectory
          ? model.ProjectDirectory.ClientId
          : "",
      SiteId:
        model.SiteId || model.ProjectDirectory
          ? model.ProjectDirectory.SiteId
          : "",
      ProjectShortName:
        model.ProjectShortName || model.ProjectDirectory
          ? model.ProjectDirectory.ProjectShortName
          : "",
    };
    return newModel;
  }

  const getExpenseDtl = (ExpenseDtlId: any) => {
    expenseDtlsService.getById(ExpenseDtlId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.row));
        setActualInvoiceAmount(result.row.InvoiceAmt);
      }
    });
  };

  // const onFileChange = (fileInput: any) => {
  //   var selectedFiles = fileInput.target.files;
  //   var validFiles = [];

  //   for (let i = 0; i < selectedFiles.length; i++) {
  //     const file = selectedFiles[i];
  //     if (file && file.size <= 102400) {
  //       validFiles.push(file);
  //     } else {
  //       globalService.error("File size must be 100kb or less");
  //     }
  //   }

  //   if (validFiles.length > 0) {
  //     setFilesUpload(validFiles);
  //     //setExpenseFile(validFiles);
  //   }
  // };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files;
    if (!fileInput) {
      return;
    }

    const selectedFiles = Array.from(fileInput) as File[];
    const validFiles = selectedFiles.filter(
      (file) => file.size <= 5 * 1024 * 1024
    );

    if (validFiles.length < selectedFiles.length) {
      globalService.error("File size must be 5MB or less");
    }

    setFilesUpload((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setFilesUpload((prevFiles) => prevFiles.filter((_, i) => i !== index));

    // const updatedFiles = filesUpload.filter((_, i) => i !== index);
    // setFilesUpload(updatedFiles);
  };

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    
    window.open(`${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`, "_blank");
    // window.open(
    //   `${process.env.REACT_APP_BASE_URL}/${FilePath}/${values.ExpenseDtlId}/${values.FileName}`,
    //   "_blank"
    // );
  };

  const ScrollableContent = ({ value }: any) => (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>{value}</div>
  );

  const getExpenseDtlFiles = (ExpenseDtlId: any) => {
    values.ExpenseDtlId = ExpenseDtlId;
    expenseDtlFileService
      .getAllFileByExpenseDtl(values.ExpenseDtlId)
      .then((response) => {
        let result = response.data;
        setExpenseDtlFiles(result.list);
        values.FileName = result.list.FileName;
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      values.ExpenseHdrId = ExpenseHdrId;
      if (!globalService.roleMatch([ROLES.Accounts], auth)) {
        values.Igst = 0;
        values.Cgst = 0;
        values.Sgst = 0;
      }
      if (ExpenseDtlId) {
        expenseDtlsService.put(values, filesUpload).then((response) => {
          
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            props.onCloseExpenseDtlDialog();
            props.refreshExpenseDetails();
            //navigate("/expenseDtls/" + ExpenseHdrId);
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        expenseDtlsService.post(values, filesUpload).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              props.onCloseExpenseDtlDialog();
              props.refreshExpenseDetails();
              //navigate("/expenseDtls/" + ExpenseHdrId);
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  // const getExpenseHdrId = (ExpenseHdrId: any) => {
  //   ExpenseHdrsService.getById(ExpenseHdrId).then((response) => {
  //     if (response) {
  //       let result = response.data;
  //       setExpenseHdr(result.row);
  //       // getClients();
  //     }
  //   });
  // };

  const expenseDtlFileColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={(event) => handleFileClick(event, params.row)}
        >
          {params.row.FileName}
        </Link>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this ExpenseFile ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeExpenseDtlFile(params.row.ExpenseDtlFileId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const removeExpenseDtlFile = (ExpenseDtlFileId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    expenseDtlFileService.remove(ExpenseDtlFileId).then((response) => {
      if (response) {
        getExpenseDtlFiles(ExpenseDtlId);
      }
    });
  };

  return (
    <>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              {/* <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <Typography className="label">Voucher No</Typography>
                  <Typography variant="body2">
                    {expenseHdr?.ExpenseVoucherNo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography className="label">Voucher Dt</Typography>
                  <Typography variant="body2">
                    {expenseHdr
                      ? dayjs(expenseHdr.ExpenseVoucherDate).format(
                        "DD-MMM-YYYY"
                      )
                      : ""}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography className="label">Expense Description</Typography>
                  <ScrollableContent value={expenseHdr?.ExpenseDescription} />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography className="label">Status</Typography>
                  <Typography variant="body2">
                    {expenseHdr?.ExpenseHdrStatus}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 2, borderColor: "rgba(0, 0, 0, 0.5)" }} /> */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="size-small-outlined"
                    size="small"
                    options={expenseHeadList}
                    getOptionLabel={(option) => option.Text}
                    value={
                      expenseHeadList.find(
                        (item) => item.Value === values.ExpenseHeadId
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleInputChange({
                        target: {
                          name: "ExpenseHeadId",
                          value: newValue ? newValue.Value : "",
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <Controls.Input
                        {...params}
                        label="Expense Head"
                        error={!!errors.ExpenseHeadId}
                        helperText={errors.ExpenseHeadId}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Controls.Input
                    multiline
                    rows={2}
                    label="Remark"
                    name="Remark"
                    value={values.Remark}
                    onChange={handleInputChange}
                    error={errors.Remark}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    required
                    type="number"
                    label="Basic Amount"
                    name="BasicAmount"
                    value={values.BasicAmount}
                    onChange={handleInputChange}
                    error={errors.BasicAmount}
                  />
                </Grid>
                {globalService.roleMatch([ROLES.Accounts], auth) && (
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      type="number"
                      label="CGST "
                      name="Cgst"
                      value={values.Cgst}
                      onChange={handleInputChange}
                      error={errors.Cgst}
                    />
                  </Grid>
                )}
                {globalService.roleMatch([ROLES.Accounts], auth) && (
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      type="number"
                      label="SGST"
                      name="Sgst"
                      value={values.Sgst}
                      onChange={handleInputChange}
                      error={errors.Sgst}
                    />
                  </Grid>
                )}
                {globalService.roleMatch([ROLES.Accounts], auth) && (
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      type="number"
                      label="IGST"
                      name="Igst"
                      value={values.Igst}
                      onChange={handleInputChange}
                      error={errors.Igst}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    required
                    type="number"
                    label="GST Amount"
                    name="Gsttotal"
                    disabled={isAccountRole}
                    value={
                      isAccountRole
                        ? (values.Gsttotal =
                          parseFloat(values.Igst) +
                          parseFloat(values.Sgst) +
                          parseFloat(values.Cgst))
                        : values.Gsttotal
                    }
                    onChange={handleInputChange}
                    error={errors.Gsttotal}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    required
                    type="number"
                    disabled
                    label="Invoice Amount"
                    name="InvoiceAmt"
                    value={
                      (values.InvoiceAmt =
                        parseFloat(values.Gsttotal) +
                        parseFloat(values.BasicAmount))
                    }
                    onChange={handleInputChange}
                    //error={errors.InvoiceAmt}
                    error={(isAccountRole && mode === 'Edit' && actualInvoiceAmount !== values.InvoiceAmt) ? "Invoice Amount should be " + actualInvoiceAmount : ""}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography style={{ color: "red", fontSize: "0.75rem" }}>
                    {" "}
                    File Size Should be 5MB
                  </Typography>
                  <Stack spacing={1} direction="row">
                    <Grid item xs={12}>
                      <input
                        type="file"
                        name="file"
                        accept="*/*"
                        multiple
                        onChange={onFileChange}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        id="file-input"
                      />
                      <Button
                        startIcon={<PostAddIcon />}
                        variant="contained"
                        //color="success"
                        sx={{ backgroundColor: "success.main", opacity: 0.8 }}
                        onClick={() => fileInputRef.current?.click()}
                      >
                        Browse File
                      </Button>
                    </Grid>
                    {filesUpload.length > 0 && (
                      <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                          {filesUpload.map((file, index) => (
                            <Stack
                              direction="row"
                              spacing={1}
                              key={index}
                              alignItems="center"
                            >
                              <Typography>{file.name}</Typography>
                              <IconButton
                                size="small"
                                aria-label="delete"
                                color="error"
                                onClick={() => removeFile(index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Stack>
                          ))}
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setFilesUpload([])}
                          >
                            Remove All
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
                
                <Grid item xs={15}>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.ExpenseDtlFileId}
                      rows={expenseDtlFiles}
                      columns={expenseDtlFileColumns}
                      columnHeaderHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                    />
                  </div>
                </div>
              </Grid>
            </React.Fragment>
          </CardContent>

        </Card>
      </form>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default ExpenseDtlsForm;
