import { ControlPoint } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Controls from "../../utility/Controls.tsx/Controls";
import { useNavigate, useParams } from "react-router-dom";
import { appSettingService } from "../../services/AppSettingService";
import useForm from "../../utility/hooks/UseForm";
import { AppSettingModel } from "../../models/AppSettingModel";
import { globalService } from "../../services/GlobalService";
import { MultiSelectListModel } from "../../models/ApiResponse";
import { userService } from "../../services/UserService";

const AppSettingForm = () => {
  const { AppSettingId } = useParams();
  const [states, setState] = useState<MultiSelectListModel[]>([]);

  let navigate = useNavigate();

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("LapseDays" in fieldValues){
      if (!fieldValues.LapseDays || fieldValues.LapseDays <= 0) {
        temp.LapseDays = "Fill Lapse Days ";
    }

    if ("CoGstinno" in fieldValues) {
      const gstNumber = fieldValues.CoGstinno.trim(); // Trim whitespace
      // GST number must be exactly 15 characters long and alphanumeric
      if (gstNumber.length !== 15 || !/^[0-9A-Za-z]+$/.test(gstNumber)) {
        temp.CoGstinno = "Invalid GST Number";
      }
  }

  if ("CoPanno" in fieldValues) {
    
    const panNumber = fieldValues.CoPanno.trim();
  
    if (panNumber.length !== 10 || !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(panNumber)) {
      temp.CoPanno = "Invalid PAN Number";
    }
  }
  if ("Address1" in fieldValues)
    temp.Address1 = fieldValues.Address1 ? "" : "Address is required.";
  if ("CoStateId" in fieldValues)
    temp.CoStateId = fieldValues.CoStateId ? "" : "State is required.";
}



    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(appSettingService.initialFieldValues, validate, AppSettingId);

  const newAppSetting = () => {
    setValues(appSettingService.initialFieldValues);
  };

  function setFormValue(model: AppSettingModel) {
    let newModel = {
      AppSettingId: model.AppSettingId,
      LapseDays: model.LapseDays ,
      CoGstinno: model.CoGstinno || "",
      CoPanno: model.CoPanno || "",
      PocreditDays: model.PocreditDays || "",
      PotermsConditions: model.PotermsConditions || "",
      CoName: model.CoName || "",
      CoUrl: model.CoUrl || "",
      Address1: model.Address1 || "",
      Address2: model.Address2 || "",
      Address3: model.Address3 || "",
      City: model.City || "",
      CoPinCode: model.CoPinCode || "",
      CoStateId: model.CoStateId || "",
      CoTel: model.CoTel || "",
      UpdatedById: model.UpdatedById || "",
      UpdatedOn: model.UpdatedOn ,
    };
    return newModel;
  }

  useEffect(() => {
    if (states.length === 0) getState();
   // if () {
      getAppSetting();
      setErrors({});
   // }
  }, []);

  const getAppSetting = () => {
    appSettingService.getById().then((response) => {
      if (response) {
        
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    });
  };

  const getState = () => {
    userService.getStateSelectList().then((response) => {
      setState(response.data);
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      //if (AppSettingId) 
        //{
        
        appSettingService.put(values).then((response: any) => {
          
          let result = response.data;
          if (response) {
            globalService.success(result.message);
          } else {
            globalService.error(result.message);
          }
        });
      }
    //}
  };

  return (
    <>
      <Typography variant="h5" align="center">
        App Settings
      </Typography>

      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Lapse Days"
                    name="LapseDays"
                    value={values.LapseDays}
                    onChange={handleInputChange}
                    error={errors.LapseDays}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                  <Typography variant="body1"  align="center">Company Details</Typography>
                  </Grid>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Company Name"
                    name="CoName"
                    value={values.CoName}
                    onChange={handleInputChange}
                    error={errors.CoName}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Address"
                    name="Address1"
                    required
                    value={values.Address1}
                    onChange={handleInputChange}
                    error={errors.Address1}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Street"
                    name="Address2"
                    value={values.Address2}
                    onChange={handleInputChange}
                    error={errors.Address2}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Locality"
                    name="Address3"
                    value={values.Address3}
                    onChange={handleInputChange}
                    error={errors.Address3}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="City"
                    name="City"
                    value={values.City}
                    onChange={handleInputChange}
                    error={errors.City}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.MuiAutocomplete
                    options={states}
                    label="State"
                    required
                    error={errors.CoStateId}
                    onChange={(event: MultiSelectListModel) => {
                      handleInputChange({
                        target: {
                          name: "CoStateId",
                          value: event ? event.value : "",
                        },
                      });
                    }}
                    value={
                      states.find(
                        (item: any) => item.value === values.CoStateId
                      ) || null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    type="number"
                    label="Pincode"
                    name="CoPinCode"
                    value={values.CoPinCode}
                    onChange={handleInputChange}
                    error={errors.CoPinCode}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Company Tel"
                    name="CoTel"
                    value={values.CoTel}
                    onChange={handleInputChange}
                    error={errors.CoTel}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Company Url"
                    name="CoUrl"
                    value={values.CoUrl}
                    onChange={handleInputChange}
                    error={errors.CoUrl}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Company GST No"
                    name="CoGstinno"
                    value={values.CoGstinno}
                    onChange={handleInputChange}
                    error={errors.CoGstinno}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Company PAN No"
                    name="CoPanno"
                    value={values.CoPanno}
                    onChange={handleInputChange}
                    error={errors.CoPanno}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                  <Typography variant="body1"  align="center">PO Details</Typography>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                  <Controls.Input
                    label="PO credit Days"
                    name="PocreditDays"
                    value={values.PocreditDays}
                    onChange={handleInputChange}
                    error={errors.PocreditDays}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={10}>
                  <Controls.Input
                    label="PO Terms Conditions"
                    name="PotermsConditions"
                    multiline
                    rows={6}
                    value={values.PotermsConditions}
                    onChange={handleInputChange}
                    error={errors.PotermsConditions}
                  />
                </Grid> */}

              </Grid>
            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              {/* <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/mDepartments" )}
              >
                Back{" "}
              </Button> */}
            </Stack>
          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default AppSettingForm;
