import { BoqPackageModel } from "../models/BoqPackageModel";
import { MVendorModel } from "../models/MVendorModel";
import { config } from "../utility/Config";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { Guid } from "guid-typescript";

const initialFieldValues: MVendorModel = {
  VendorId: Guid.EMPTY,
  VendorName: "",
  VendorCode: "",
  Address1: "",
  Address2: "",
  Address3: "",
  City: "",
  StateId: "",
  Pan: "",
  Spocdesignation: "",
  Gstin: "",
  LandLine: "",
  Website: "",
  Spocname: "",
  Spocemail: "",
  State: null,
  Spocmobile: "",
  Email: "",
  Active: false,
  UserId: "",
  Password: "",
  Pincode: "",
  BankName: "",
  Ifsccode: "",
  AccountNo: "",
  VendorNameAsPerTally: "",
  Spocname1: "",
  Spocemail1: "",
  Spocmobile1: "",
  Spocdesignation1: "",
  Spocname2: "",
  Spocemail2: "",
  Spocmobile2: "",
  Spocdesignation2: "",
  FilePath:""
};

const url = "MVendor";

function getAll() {
  return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

function post(payload: MVendorModel) {
  return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}
function put(payload: MVendorModel , files : File[]) {
  const formData = new FormData(); 
    if (files != null && files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        formData.append("VendorFile", files[i]);
      }
    }
    formData.append("data", JSON.stringify(payload));
    return customAxios.put(`${url}/Edit`, formData, config.formDataConfig);
}
function remove(id: number) {
  return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(VendorId: number) {
  return customAxios.get(`${url}/GetById/${VendorId}`, axiosRetryConfig);
}

function getVendorSelectList() {
  return customAxios.get(`${url}/GetVendorSelectList`, axiosRetryConfig);
}


function getMVendorSelectListByUserId( Id:string) {
  return customAxios.get(`${url}/GetMVendorSelectListByUserId/${Id}`, axiosRetryConfig);
}

export const MVendorService = {
  initialFieldValues,
  getAll,
  post,
  put,
  remove,
  getById,
  getVendorSelectList,
  getMVendorSelectListByUserId
};
