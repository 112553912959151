import { Guid } from "guid-typescript";
import { BoqsubTitleAlternativeTextModel } from "../models/BoqSubTitleAlternativeTextModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqsubTitleAlternativeTextModel = {
    BoqsubTitleAlternativeTextId: Guid.EMPTY,
    BoqtitleId:"",
    BoqsubTitleId:"",
    BoqsubTitleAlternativeTextName:"",
    Active: false,
}

const url = "MBoqsubTitleAlternativeTexts";

function post(payload:BoqsubTitleAlternativeTextModel ){
    return customAxios.post(`${url}/Add`,payload,axiosRetryConfig);
}
function put(payload: BoqsubTitleAlternativeTextModel){
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}
function remove(id: number){
    return customAxios.delete(`${url}/${id}`,axiosRetryConfig);
}

function GetById(BoqsubTitleAlternativeTextId: number){
    return customAxios.get(`${url}/GetById/${BoqsubTitleAlternativeTextId}`,axiosRetryConfig);
}


function GetByBoqsubTitleId(BoqsubTitleId: number){
    return customAxios.get(`${url}/GetByBoqsubTitleId/${BoqsubTitleId}`,axiosRetryConfig);
}

function GetByBoqtitleId(BoqtitleId: number){
    return customAxios.get(`${url}/GetByBoqtitleId/${BoqtitleId}`,axiosRetryConfig);
}


export const BoqsubTitleAlternativeTextService = {
    initialFieldValues,
    post,
    put,
    remove,
    GetById,
    GetByBoqtitleId,
    GetByBoqsubTitleId
};