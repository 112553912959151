import React from "react";
import { ChangePasswordDTO, LoginModel, UserModel } from "../models/UserModel";
//import { globalService } from "./GlobalService";
//import http, { HttpResponse } from "./AxiosHttpCommon";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { AuthContext } from "../utility/context/AuthContext";
import { Guid } from "guid-typescript";

const initialFieldValues: UserModel = {
  Id: Guid.EMPTY,
  UserGuid: "",
  Email: "",
  ConfirmPassword: "",
  EmailConfirmed: "",
  PasswordHash: "",
  SecurityStamp: "",
  PhoneNumber: "",
  PhoneNumberConfirmed: "",
  TwoFactorEnabled: "",
  LockoutEndDateUtc: "",
  LockoutEnabled: "",
  AccessFailedCount: "",
  UserName: "",
  FirstName: "",
  LastName: "",
  NotificationToken: "",
  JwtTokenExpiresOn: "",
  JwtToken: "",
  Active: false,
};

const initialLoginFieldValues: LoginModel = {
  UserName: "",
  PasswordHash: "",
};

const initialChangePasswordFieldValues: ChangePasswordDTO = {
  UserId: "",
  NewPassword: "",
  OldPassword: "",
  ConfirmPassword: ""
}

const url = "User";

function get(id: number) {
  return customAxios.get(`${url}/${id}`, axiosRetryConfig);
}

function getAll() {
  return customAxios.get(`${url}/GetAllUsers`, axiosRetryConfig);
}

function getByCompanyId(companyId: string) {
  return customAxios.get(`${url}/GetByCompanyId/${companyId}`, axiosRetryConfig);
}

function getById(EmployeeId: number) {
  return customAxios.get(`${url}/GetById/${EmployeeId}`, axiosRetryConfig);
}

function post(payload: UserModel) {
  return customAxios.post(`${url}/Register`, payload, axiosRetryConfig);
}

function login(payload: any) {

  return customAxios.post(`${url}/Login`, payload, axiosRetryConfig);
}

function put(payload: UserModel) {
  return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}

// function remove(userName: string) {
//   return customAxios.delete(`${url}/DeleteUser/${userName}`, axiosRetryConfig);
// }

function removeAll() {
  return customAxios.delete(`${url}/user`, axiosRetryConfig);
}

function ChangePassword(payload: ChangePasswordDTO) {
  return customAxios.post(`${url}/ChangePassword`, payload, axiosRetryConfig);
}
function remove(id: number) {
  return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getReportingUserSelectList(id: string) {
  if (id) {
    return customAxios.get(`${url}/GetReportingUserSelectList?id=${id}`, axiosRetryConfig);
  } else {
    return customAxios.get(`${url}/GetReportingUserSelectList`, axiosRetryConfig);
  }
}

function useAuth() {
  const { auth } = React.useContext(AuthContext);
  return auth;
}

function EditUser(payload: UserModel) {
  return customAxios.put(`${url}/EditUser`, payload, axiosRetryConfig);
}
// function ChangePassword(payload: ChangePasswordDTO) {
//   return customAxios.put(`${url}/ChangePassword`, payload, axiosRetryConfig);
// }
function getSelectList() {
  return customAxios.get(`${url}/GetSelectList`, axiosRetryConfig);
}

function getUserSelectList() {
  return customAxios.get(`${url}/GetUserSelectList`, axiosRetryConfig);
}

function GetAllRoles() {
  return customAxios.get(`${url}/GetAllRoles`, axiosRetryConfig);
}

function GetUserInfoSelectListByRole(userRole: string) {
  if (userRole)
    return customAxios.get(`${url}/GetUserInfoSelectListByRole?userRole=${userRole}`, axiosRetryConfig);
  else
    return customAxios.get(`${url}/GetUserInfoSelectListByRole`, axiosRetryConfig);
}

function getStateSelectList() {
  return customAxios.get(`${url}/GetMStateSelectList`, axiosRetryConfig);
}

export const userService = {
  initialFieldValues,
  initialLoginFieldValues,
  initialChangePasswordFieldValues,
  get,
  getAll,
  post,
  ChangePassword,
  put,
  remove,
  removeAll,
  login,
  useAuth,
  getReportingUserSelectList,
  getByCompanyId,
  EditUser,
  getUserSelectList,
  // ChangePassword,
  getById,
  getSelectList,
  GetAllRoles,
  GetUserInfoSelectListByRole,
  getStateSelectList
};
