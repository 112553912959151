import { Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React, { useRef, useState } from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../helper/ConfirmDialog';
import { RFQService } from '../../services/RequestForQuoteService';
import { globalService } from '../../services/GlobalService';

const QuoteInputExcelUpload = () => {

  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const [files,setFiles] =  useState<File[]>([]);
  const [quoteInputExcelUpload, setQuoteInputExcelUpload] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const onFileChange = (fileInput: any) => {
    setFiles(fileInput.target.files);
  };

  const uploadFile = () => {
    
    let payload = {
       // EmployeeId: EmployeeId,
       // vendorId: vendorId,
    };
     RFQService.uploadExcel(files, payload).then((response) => {
      if (response) {
        
        let result = response.data;
        if(result.list!=null)
        {
          setQuoteInputExcelUpload(result.list);
        }
        if (result.isSuccess) {
          globalService.success(result.message);
          // resetForm();
        } else {
          globalService.error(result.message);
        }
      }
    });
  };

  const quoteInputExcelColumn: GridColDef[] = [
    { field: "ClientSrNo", headerName: "Client Sr No", width: 130, flex: 1 },
     { field: "BOQDescription", headerName: "BOQ Description", width: 130, flex: 1 },
     { field: "Unit", headerName: "Unit", width: 130, flex: 1 },
     { field: "Qty", headerName: "Qty", width: 130, flex: 1 },
     { field: "BOQMake", headerName: "BOQ Make", width: 130, flex: 1 },
     { field: "SupplyRate", headerName: "Supply Rate", width: 130, flex: 1 },
     { field: "InstallationRate", headerName: "Installation Rate", width: 130, flex: 1 },
     { field: "VendorMake", headerName: "Vendor Make", width: 130, flex: 1 },
     { field: "VendorRemarks", headerName: "Vendor Remarks", width: 130, flex: 1 },
     { field: "BOQRFQNo", headerName: "BOQ RFQ No", width: 130, flex: 1 },
     { field: "BOQRFQDate", headerName: "BOQ RFQ Date", width: 130, flex: 1 },
  ];


  return (
    <>

<Typography variant="h5" align="center">
       Quote Input Excel Upload
      </Typography>
      <form autoComplete="off" noValidate
      //onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(event: any) => {
                onFileChange(event);
              }}
              // onChange={handleFileSelect}
            />
             <Button variant="contained" color="primary" onClick={uploadFile}>
              Upload Excel
            </Button>
            </div>

            <Typography variant="h5">Error Lists</Typography>
                <div>
            <DataGrid
              getRowId={(row) => row.BOQRFQItemID}
              rows={quoteInputExcelUpload}
              columns={quoteInputExcelColumn}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              scrollbarSize={1}
              //getRowHeight={() => 'auto'} getEstimatedRowHeight={() => 200}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    EmployeeImportId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>

            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back To List
            </Button>
          </Stack>
        </CardActions>

        <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

          </CardContent>
        </Card>

      </form>
      
    </>
  )
}

export default QuoteInputExcelUpload
