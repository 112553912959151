import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardService } from "../../services/DashboardService";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import Controls from "../../utility/Controls.tsx/Controls";
import { BarChart } from "@mui/x-charts";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import dayjs from "dayjs";
import useForm from "../../utility/hooks/UseForm";
import { AuthContext } from "../../utility/context/AuthContext";
import { MultiSelectListModel, SelectListModel } from "../../models/ApiResponse";
import { WidthFull } from "@mui/icons-material";
import { userService } from "../../services/UserService";
import { globalService } from "../../services/GlobalService";
import { ROLES } from "../../utility/Config";

const Dashboard = (...props: any) => {
  const { VendorId } = useParams();
  let navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const { auth, setAuth } = React.useContext(AuthContext);
  const [monthWiseExpenses, setMonthWiseExpenses] = useState([]);
  const [chargeWiseExpenses, setChargeWiseExpenses] = useState([]);
  const [projectWiseExpenses, setProjectWiseExpenses] = useState([]);
  const [statusWiseExpenses, setStatusWiseExpenses] = useState([]);
  const [usersList, setusersList] = useState<MultiSelectListModel[]>([]);
  const [allUsers, setAllUsers] = useState<MultiSelectListModel[]>([]);
  const [userforSubordinate, setUserforSubordinate] = useState([]);
  const { Code }: any = useParams();
  const mode = VendorId ? "Edit" : "Create";
  let userIds = auth.Id;
  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    // if ("VendorName" in fieldValues)
    //   temp.VendorName = fieldValues.VendorName
    //     ? ""
    //     : "Vendor Name is required.";
    // if ("VendorCode" in fieldValues)
    //   temp.VendorCode = fieldValues.VendorCode
    //     ? ""
    //     : "Vendor Code is required.";
    // if ("Address1" in fieldValues) temp.Address1 = fieldValues.Address1 ? "" : "Address is required.";
    // if ("StateId" in fieldValues) temp.StateId = fieldValues.StateId ? "" : "State is required.";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      DashboardService.initialDashBoardValues,
      validate,
      props.setCurrentId
    );
  // const getClients = () => {
  //   mClientService.GetAllSelectList().then((response: any) => {
  //     setClients(response.data);
  //   });
  // };

  const GetuserselectList = () => {
    userService.GetUserInfoSelectListByRole('Employee').then((Response: any) => {
      setAllUsers(Response.data);
    });
  };
  const newUser = () => {
    setValues(DashboardService.initialDashBoardValues);
  };

  useEffect(() => {
    if (allUsers.length === 0) GetuserselectList();
    handleSubmit(auth.Id);
  }, []);

  const handleSubmit = (userid: any) => {
    values.UserID = userid;
    //e.preventDefault();
    if (validate()) {

      DashboardService.getDashBoardGetMonthWiseExpenses(values).then(
        (response: any) => {
          let result = response.data;
          setMonthWiseExpenses(result.list);
          getChargeWiseExpenses();
          getProjectWiseExpenses();
          getDashBoardGetStatusWiseExpenses();
        }
      );
    }
  };

  const handleSubmitforSubordinate = () => {
    const userIds = values.UserIDs?.split(',');
    if (userIds) {
      userIds.forEach((userId: any) => {
        values.UserID = userId;

        if (validate()) {

          DashboardService.getDashBoardGetMonthWiseExpenses(values).then(

            (response: any) => {
              let result = response.data;
              setMonthWiseExpenses(result.list);
              getChargeWiseExpenses();
              getProjectWiseExpenses();
              getDashBoardGetStatusWiseExpenses();
            }
          );
        }
      });
    }
  };


  const getChargeWiseExpenses = () => {
    DashboardService.getDashBoardGetChargeWiseExpenses(values).then(
      (response) => {
        let result = response.data;
        setChargeWiseExpenses(result.list);
      }
    );
  };
  const getProjectWiseExpenses = () => {
    //   values.UserID = auth.Id;
    DashboardService.getDashBoardGetProjectWiseExpenses(values).then(
      (response) => {
        let result = response.data;
        setProjectWiseExpenses(result.list);
      }
    );
  };

  const getDashBoardGetStatusWiseExpenses = () => {
    //   values.UserID = auth.Id;
    DashboardService.getDashBoardGetStatusWiseExpenses(values).then(
      (response) => {
        let result = response.data;
        setStatusWiseExpenses(result.list);
      }
    );
  };

  const getUserList = () => {
    DashboardService.getReportingUsersUserID(auth.Id).then((response) => {
      setusersList(response.data);
      // if (response.data.length > 0) {
      //    values.UserID = response.data[0].Value;
      //  DashBoardGetMonthWiseExpenses();
    });
  };
  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 6);
    return oneMonthsAgo;
  }
  const roleMatch = (allowedRoles: string[]) => {
    var isMatch = false;
    auth?.Roles?.find((role: any) => allowedRoles?.includes(role))
      ? (isMatch = true)
      : (isMatch = false);
    return isMatch;
  };
  const datesforchargeWiseExpenses = chargeWiseExpenses.map(
    (expense) => expense.ExpenseHeadName
  );
  const amountsforchargeWiseExpenses = chargeWiseExpenses.map(
    (expense) => expense.TotInvoiceAmt
  );

  const datesformonthWiseExpenses = monthWiseExpenses.map((expense) =>
    dayjs(expense.newDt.split("T")[0]).format("MMM-YYYY")
  );
  const amountsformonthWiseExpenses = monthWiseExpenses.map(
    (expense) => expense.TotInvoiceAmt
  );

  const ProjectWiseExpenses = projectWiseExpenses.map(
    (expense) => expense.Project
  );
  const TotInvoiceAmtProjectWise = projectWiseExpenses.map(
    (expense) => expense.TotInvoiceAmt
  );

  const ExpenseHdrStatusstatusWiseExpenses = statusWiseExpenses.map(
    (expense) => expense.ExpenseHdrStatus
  );
  const TotInvoiceAmtstatusWiseExpenses = statusWiseExpenses.map(
    (expense) => expense.TotInvoiceAmt
  );
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    ;
    if (newValue == "1") {
      handleSubmit(auth.Id);
    }
    if (newValue == "2") {
      handleSubmit(values.UserID);
    } else {
    }
  };
  const CommonCharts = () => (
    <Grid container spacing={2} marginTop={2}>
      <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc" }}>
        <BarChart
          xAxis={[{ scaleType: "band", data: datesformonthWiseExpenses }]}
          series={[
            { data: amountsformonthWiseExpenses, label: "Month Wise Expenses" },
          ]}
          width={600}
          height={300}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc" }}>
        <BarChart
          xAxis={[{ scaleType: "band", data: datesforchargeWiseExpenses }]}
          series={[
            {
              data: amountsforchargeWiseExpenses,
              label: "Charge Wise Expenses",
            },
          ]}
          width={600}
          height={300}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc" }}>
        <BarChart
          xAxis={[{ scaleType: "band", data: ProjectWiseExpenses }]}
          series={[
            { data: TotInvoiceAmtProjectWise, label: "Project Wise Expenses" },
          ]}
          width={600}
          height={300}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ border: "1px solid #ccc" }}>
        <BarChart
          xAxis={[
            { scaleType: "band", data: ExpenseHdrStatusstatusWiseExpenses },
          ]}
          series={[
            {
              data: TotInvoiceAmtstatusWiseExpenses,
              label: "Status Wise Expenses",
            },
          ]}
          width={600}
          height={300}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Typography variant="h5" align="center">
        Expense Summary

      </Typography>
      <TabContext value={value}>
        <form
          autoComplete="off"
          noValidate
        //className={classes.root}
        // onSubmit={handleSubmit}
        >
          <Card>
            <CardContent>
              <React.Fragment>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={2}>
                      <Controls.ReactDatePicker
                        label="From Date"
                        dateFormat="MMM-yyyy"
                        showMonthYearPicker
                        // min={values.FromDt}
                        max={new Date()}
                        value={
                          (values.FromDt = values.FromDt || globalService.getISODateOnlyForDatePicker(getDefaultDate()))
                        }
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnlyForDatePicker(date),
                              name: "FromDt",
                            },
                          });
                        }}
                        error={errors.FromDt}
                      />
                    </Grid>

                    <Grid item xs={4} sm={2}>
                      <Controls.ReactDatePicker
                        label="To Date"
                        dateFormat="MMM-yyyy"
                        showMonthYearPicker
                        min={values.FromDt}
                        max={new Date()}
                        value={(values.ToDt = values.ToDt || globalService.getISODateOnlyForDatePicker(new Date()))}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnlyForDatePicker(date),
                              name: "ToDt",
                            },
                          });
                        }}
                        error={errors.ToDt}
                      />
                    </Grid>
                    <Grid item xs={8} sm={3}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Own" value="1" />
                        <Tab
                          label="Subordinate"
                          onClick={getUserList}
                          value="2"
                        />

                        {roleMatch([ROLES.Admin, ROLES.Expense_Manager, ROLES.Accounts]) ? (
                          <Tab label="All" value="3" />
                        ) : null}
                      </TabList>
                    </Grid>


                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TabContext value={value}>
                      <TabPanel value="1">
                        <CommonCharts />
                      </TabPanel>

                      <TabPanel value="2">
                        <Grid container spacing={2}>
                          <Grid item xs={8} sm={4}>
                            <Controls.ReactMultiSelect
                              options={usersList}
                              value={userforSubordinate}
                              onChange={(e: any[]) => {
                                setUserforSubordinate(e)
                                const selectedUserIds = e.map(user => user.value).join(',');
                                handleInputChange({ target: { value: selectedUserIds, name: 'UserIDs' } });
                              }}
                              label="Select Users"

                              error={errors.UserID}
                            />
                          </Grid>

                          <Grid item xs={2} sm={3}>
                            <Button variant="contained" onClick={() => handleSubmitforSubordinate()}>
                              Go
                            </Button>
                          </Grid>
                        </Grid>
                        {usersList.length > 0 && <CommonCharts />}
                      </TabPanel>


                      <TabPanel value="3">
                        <Grid container spacing={2}>
                          <Grid item xs={8} sm={4}>
                            <Controls.MuiAutocomplete
                              options={allUsers}
                              label="Employee Login"
                              error={errors.UserID}
                              onChange={(event: MultiSelectListModel) => {
                                handleInputChange({
                                  target: {
                                    name: "UserID",
                                    value: event ? event.value : "",
                                  }
                                });
                              }}
                              value={allUsers.find((item: any) => item.value === values.UserID) || null}
                            />
                          </Grid>
                        </Grid>
                        <CommonCharts />
                      </TabPanel>


                    </TabContext>
                  </Grid>
                </CardContent>
              </React.Fragment>
            </CardContent>
          </Card>
        </form>
      </TabContext>
    </>
  );
};
export default Dashboard;
