import {
    Grid,
    TextField,
    CardActions,
    Card,
    CardContent,
    Button,
    Typography,
    Stack,
  } from "@mui/material";
  import { useNavigate, useParams } from "react-router-dom";
  import { globalService } from "../../services/GlobalService";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import Controls from "../../utility/Controls.tsx/Controls";
  import useForm from "../../utility/hooks/UseForm";
import { ZoneApproversModel } from "../../models/ZoneApproversModel";
import { ZoneApproverService } from "../../services/ZoneApproverService";
import { useEffect, useState} from "react";
import { SelectListModel } from "../../models/ApiResponse";
import { mEmployeesService } from "../../services/MEmployeeService";
import { mZonesService } from "../../services/MZoneService";

const ZoneApproverForm = (...props: any) => {
    const {ZoneApproversId, ZoneId}:any = useParams();
    const [usersList, setUsersList] = useState<SelectListModel[]>([]);
    const [zoneName, setZoneName] = useState(null);
  let navigate = useNavigate();
  const mode = ZoneApproversId ? "Edit" : "Create";

  const validate = (fieldValues = values) => {
    let temp: any = {...errors};

    if("EmployeeId" in fieldValues)
        temp.EmployeeId = fieldValues.EmployeeId ? "" : "Please Select Department";
    setErrors({
        ...temp,
    });

    if(fieldValues === values)
        return Object.values(temp).every((x) => x === "");

}
const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
useForm(ZoneApproverService.initialFieldValues, validate, ZoneApproversId);

const newZoneApprover = () => {
  setValues(ZoneApproverService.initialFieldValues);
}

function setFormValue(model: ZoneApproversModel) {
  let newModel = {
    ZoneApproversId : model.ZoneApproversId,
    ZoneId: model.ZoneId,
    ApproverUserId: model.ApproverUserId,
  };
  return newModel;
}


useEffect(() => {
    if(ZoneApproversId) {
      getZoneApprover(ZoneApproversId);
        setErrors({});
    } else newZoneApprover();
    if (usersList.length === 0) getEmployeeList();
    if(ZoneId){
      getZone();
    }
  }, []);
  
  const getZone =() =>{
    mZonesService.getById(ZoneId).then((response) => {
        let result = response.data.list;
        setZoneName(result.ZoneName)
    })
}

  const getZoneApprover = (ZoneApproversId: any) => {
    ZoneApproverService.getById(ZoneApproversId).then((response) => {
        if(response) {
            let result = response.data;
            setValues(setFormValue(result.list));
        }
    });
  };

  const getEmployeeList = () => {
    ZoneApproverService.getUserSelectListForZoneApprove().then((response) => {
      setUsersList(response.data);
    });
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if(validate()) {
        values.ZoneId = ZoneId;
        values.ApproverUserId = values.Id;
       if(ZoneApproversId) {
        ZoneApproverService.put(values).then((response: any) => {
            let result = response.data;
            if(response) {
                globalService.success(result.message);
                navigate("/zoneApprovers/" + ZoneId)
            } else {
                globalService.error(result.message);
            }
        });
       } else {
        ZoneApproverService.post(values).then((response: any) => {
          
            if(response) {
                let result = response.data;
                if(result.isSuccess){
                    globalService.success(result.message);
                    navigate("/zoneApprovers/" + ZoneId)
                } else {
                    globalService.error(result.message);
                  }
            } 
        });
      // }
    }
  }
}

  return (
    <>
     <Typography>
     Zone Approver of <b>{zoneName}</b>
      </Typography>

      <form
        autoComplete="off" noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>

              <Grid container spacing={3}>

              <Grid item xs={12} sm={4}>
                  <Controls.Select
                    required
                    name="Id"
                    label="Users"
                    options={usersList}
                    value={usersList.length > 0 ? values.Id : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.EmployeeId}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={4}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid> */}

              </Grid>

          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/zoneApprovers/"+ ZoneId)}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>

      </form>
    </>

    
  )
}

export default ZoneApproverForm


{/* <div>ZoneApproverForm</div> */}