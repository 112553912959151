import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import { expenseHeadService } from "../../services/MExpenseHeadService";
import { globalService } from "../../services/GlobalService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";

const MExpenseHeadsList: React.FC = () => {

  const [expenseHead, setExpenseHead] = useState([]);

  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });

  useEffect(() => {
    
    getExpenseHead();
  }, []);

  const getExpenseHead = () => {
    
    expenseHeadService.getAll().then((response) => {
      let result = response.data;
      setExpenseHead(result.list);
    });
  };


  const expenseHeadColumns: GridColDef[] = [
    { field: "ExpenseHeadName", headerName: "Expense Head Name", width: 130, flex: 1,  },
    {
      field: "IsItForRent",
      headerName: "Is It For Rent",
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>

            {/* {params.row.Active && "Yes"}
                {!params.row.Active && "No"} */}
            {params.row.IsItForRent && <DoneIcon color="success" />}
            {!params.row.IsItForRent && <CloseIcon color="error" />}
          </Stack>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editExpenseHead/" + params.row.ExpenseHeadId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small"
              aria-label="delete" color="error"
              onClick={() => {
                
                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure to delete this Expense Head ?',
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeExpenseHead(params.row.ExpenseHeadId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>

          </Stack>
        );
      },
    },
  ];

  const removeExpenseHead = (ExpenseHeadId: any) => {
    
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    expenseHeadService.remove(ExpenseHeadId).then((response) => {
      let result = response.data;
      if (response) {

        // globalService.success(result.message);
        getExpenseHead();

      }
    });
  };

  return (
    <>
      <Typography variant="h5" align="center">
        Expense Head
      </Typography>

      <Card>
        <CardContent>
          {/* <React.Fragment> */}

          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addExpenseHead")}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.ExpenseHeadId}
              rows={expenseHead}
              columns={expenseHeadColumns}
              columnHeaderHeight={30}
              autoHeight={true}
              // checkboxSelection
              // disableRowSelectionOnClick
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}

              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    ExpenseHeadId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />

          </div>

          {/* </React.Fragment> */}

        </CardContent>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

    </>
  )
}

export default MExpenseHeadsList