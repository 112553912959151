
import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { MSiteModelModel } from "../models/MSiteModel";

const initialFieldValues: MSiteModelModel = {
    SiteId: Guid.EMPTY,
    SiteName: '',
    State: '',
}

const url = "/mSite";

function GetSelectListByClientId(ClientId: string) {
    return customAxios.get(`${url}/GetSelectListByClientId/${ClientId}`, axiosRetryConfig);
}

function GetAllMultiSelectList(){
    return customAxios.get(`${url}/GetAllMultiSelectList`, axiosRetryConfig);
}

function getSiteNameSelectList() {
    return customAxios.get(`${url}/GetSiteNameSelectList`, axiosRetryConfig);
}

function getById(SiteId: number) {
    return customAxios.get(`${url}/GetById/${SiteId}`, axiosRetryConfig);
}
function GetMultiSelectListByUploadedBoqs(){
    return customAxios.get(`${url}/GetMultiSelectListByUploadedBoqs`, axiosRetryConfig);
}

export const mSiteService = {
    initialFieldValues,
    GetSelectListByClientId,
    GetAllMultiSelectList,
    getSiteNameSelectList,
    getById,
    GetMultiSelectListByUploadedBoqs
}
