import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BoqTitleAttributeModel } from '../../models/BoqTitleAttributeModel';
import { boqTitleAttributeService } from '../../services/BoqTitleAttributeService';
import { globalService } from '../../services/GlobalService';
import { Button, Card, CardActions, CardContent, Grid, Stack, Typography } from '@mui/material';
import Controls from '../../utility/Controls.tsx/Controls';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useForm from '../../utility/hooks/UseForm';
import { PageTitleInfoRequestModel, PageTitleInfoResponseModel } from '../../models/BoqItemModel';
import { boqitemService } from '../../services/BoqItemService';


const BoqTitleAttributeForm = (...props: any) => {

    const { BoqtitleAttributeId, BoqtitleId, AttributeType,AttributeTypeName }: any = useParams();
    let navigate = useNavigate();
    const prevPgState = useLocation();
    const [titleInfo, setTitleInfo] = useState<PageTitleInfoResponseModel>(boqitemService.initialPageTitleInfoResponseValues);

    const mode = BoqtitleAttributeId ? 'Edit' : 'Create';

    const validate = (fieldValues: BoqTitleAttributeModel = values) => {
        let temp: any = { ...errors };
        if ("BoqtitleAttributeName" in fieldValues)
            temp.BoqtitleAttributeName = fieldValues.BoqtitleAttributeName ? "" : "BOQ Title Attribute Name is required";

        setErrors({
            ...temp
        });
        // if ("Active" in fieldValues)
        // temp.Active = fieldValues.Active ? "" : "Active is required.";
        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(boqTitleAttributeService.initialFieldValues, validate, props.setCurrentId);

    const newTitleAttribute = () => {
        setValues(boqTitleAttributeService.initialFieldValues);

    };


    function setFormValue(model: BoqTitleAttributeModel) {
        let newModel = {
            BoqtitleAttributeId: model.BoqtitleAttributeId,
            BoqtitleAttributeName: model.BoqtitleAttributeName,
            AttributeType: model.AttributeType,
            AttributeTypeName: model.AttributeTypeName,
            BoqtitleId: model.BoqtitleId,
            // Code : model.Code|| '',
            Active: model.Active,
        }
        return newModel;
    }
    useEffect(() => {
        if (BoqtitleAttributeId) {
            
            getTitleAttribute();
            getPageTitle();
            setErrors({});
        } else newTitleAttribute();
    }, [BoqtitleAttributeId]);

    const getTitleAttribute = () => {

        boqTitleAttributeService.GetById(BoqtitleAttributeId).then((response: { data: any; }) => {
            if (response) {
                let result = response.data;
                setValues(setFormValue(result.data));
            }
        })
    };
    const getPageTitle = () => {
        let model: PageTitleInfoRequestModel = {
          BoqItemId: '',
          AttributeType: AttributeType,
          BoqSubTitleId: '',
          BoqTitleId: BoqtitleId,
          BoqPackageId: '',
        };
        boqitemService
          .getPageTitleInfo(model)
          .then((response) => {
            setTitleInfo(response.data.row);
          });
      };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        values.AttributeTypeName = props.AttributeTypeName;
        if (validate()) {
            if (BoqtitleAttributeId) {

                boqTitleAttributeService.put(values).then((response: any) => {
                    if (response) {
                        let result = response.data;
                        if (result.isSuccess) {
                            globalService.success("Title Attribute edited successfully.");
                            resetForm();
                            navigate(-1);
                            // navigate("/boqTitleAttribute/" + BoqtitleId + "/" +AttributeType);
                        } else {
                            globalService.error(result.message);
                        }
                    }
                });
            } else {
                
                values.AttributeType = AttributeType;
                values.AttributeTypeName = AttributeTypeName;
                values.BoqtitleId = BoqtitleId;
                boqTitleAttributeService.post(values).then((response: any) => {
                    if (response) {
                        let result = response.data;
                        if (result.isSuccess) {
                            globalService.success("Title Attribute added successfully.");
                            resetForm();
                            navigate(-1);
                            // navigate("/boqTitleAttribute/" + BoqtitleId + "/" +AttributeType);
                        } else {
                            globalService.error(result.message);
                        }
                    }
                });
            }
        }
    };

    return (
        <div>
            {/* <Title> Sub Title</Title>  */}
            <Typography variant="h5" align="center">
                BOQ Title Attribute Form
            </Typography>
             {/* {(prevPgState && prevPgState.state && prevPgState.state.row) &&
          <Typography variant="body1"> <b>BOQ Package :</b> {prevPgState.state.row.Boqtitle?.Boqpackage.BoqpackageName} <br/>
          <b> BOQ Title Name: </b>{prevPgState.state.row.Boqtitle?.BoqtitleName} <br/>
          <b> BOQ SubTitle Name: </b>{prevPgState.state.row?.BoqsubTitleName} 
          </Typography>
        } */}
             <Stack direction="row" spacing={0} justifyContent="space-between">
        <Typography variant="h5" align="center">
        BOQ Title Attribute Form
        </Typography>
        {(titleInfo ) &&
          <Typography variant="body1"> 
            <b>BOQ Package :</b> {titleInfo.BoqPackageName}
            <br />
            <b>BOQ Title Name :</b> {titleInfo.BoqTitleName}
            {/* <br />
           <b> BOQ SubTitle Name: </b>{titleInfo.BoqSubTitleName}
            <br /> 
            <b> BOQ Attribute: </b>{titleInfo.AttributeTypeName} */}
          </Typography>
        }
      </Stack>
            <>
                <form
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit}>
                    <Card>
                        <CardContent>
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Controls.Input
                                            required
                                            label="Title Attribute Name"
                                            name="BoqtitleAttributeName"
                                            value={values.BoqtitleAttributeName}
                                            onChange={handleInputChange}
                                            error={errors.BoqtitleAttributeName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={mode !== "Edit" ? "hidden" : ""}>
                                        <Controls.Checkbox
                                            label="Active"
                                            name="Active"
                                            value={values.Active}
                                            onChange={handleInputChange}
                                            error={errors.Active}
                                        />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </CardContent>
                        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Stack spacing={2} direction="row">
                                <Button type="submit" variant="contained">Submit</Button>
                                <Button variant="outlined" startIcon={<ArrowBackIcon />}
                                    onClick={() => navigate(-1)
                                        // navigate("/boqTitleAttribute/" + BoqtitleId + "/" +AttributeType)
                                    }
                                >Back To List</Button>
                            </Stack>
                        </CardActions>
                    </Card>

                </form>
            </>

        </div>
    )
}

export default BoqTitleAttributeForm