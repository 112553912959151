import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import useForm from "../../utility/hooks/UseForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EditPosmodel } from "../../models/BoqposModel";

import Controls from "../../utility/Controls.tsx/Controls";
import React, { useEffect, useState } from "react";
import {
  MultiSelectListModel,
  SelectListModel,
} from "../../models/ApiResponse";
import MessageIcon from "@mui/icons-material/Message";
import { projectDirectoryService } from "../../services/ProjectDirectoryService";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { globalService } from "../../services/GlobalService";
import { AuthContext } from "../../utility/context/AuthContext";
import { mClientService } from "../../services/MClientService";
import { mSiteService } from "../../services/MSiteService";
import EditIcon from "@mui/icons-material/Edit";
import { ExpenseHdrsService } from "../../services/ExpenseHdrsService";
import ConfirmDialog from "../helper/ConfirmDialog";
import dayjs from "dayjs";
import { MVendorService } from "../../services/MVendorService";
import { ProjectDirectoryModel } from "../../models/ProjectDirectoryModel";
import { BoqposService } from "../../services/BoqPosService";
import CloseIcon from "@mui/icons-material/Close";
import { BoqDocFileService } from "../../services/BoqdocFilesService";
import { mPaymentTermsService } from "../../services/MPaymentTermsService";
import DownloadIcon from "@mui/icons-material/Download";
import { reportService } from "../../services/ReportService";
import fileDownload from "js-file-download";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { MProcurementTypesService } from "../../services/MProcurementTypesService";

import ArrowBackIcon from "@mui/icons-material/ArrowForward";

function BoqPosList() {
  const { auth } = React.useContext(AuthContext);

  const [valuefordocterms, setValueForDocTerms] = React.useState("1");
  const { procurementTypeId } = useParams();

  const [packages, setPackages] = useState<any[]>([]);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  const [projectDirectory, setProjectDirectory] =
    useState<ProjectDirectoryModel>(null);
  const [vendors, setVendor] = useState<SelectListModel[]>([]);
  const [boqpos, setboqpos] = useState([]);
  const [mpaymentTerms, setMPaymentTerm] = useState([]);
  const [DocsId, setDocsId] = useState([]);
  const [paymenttermsId, setPaymenttermsId] = useState([]);
  const [clients, setClients] = useState<MultiSelectListModel[]>([]);
  const [sites, setSites] = useState<SelectListModel[]>([]);
  const [viewDocsOpen, setViewDocsOpen] = React.useState(false);
  const [viewDocsFiles, setViewDocsFiles] = useState([]);
  const [viewPaymentTermsOpen, setViewPaymentTermsOpen] = React.useState(false);
  const [viewPaymentTerms, setViewPaymentTerms] = useState([]);
  const [procurementTypes, setProcurementTypes] = useState<SelectListModel[]>(
    []
  );
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  let navigate = useNavigate();

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    if (vendors.length === 0) getVendor();
    if (procurementTypes.length === 0) getProcurementType();
    if (sites.length === 0) getSites();
    // getProjectDirectory();
    
    // if (!values.SiteID) {
    //   getB2BPo();
    // }
  }, []);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(BoqposService.initialFieldValues, validate, "");

  const newUser = () => {
    setValues(BoqposService.initialFieldValues);
  };
  const getProcurementType = () => {
    MProcurementTypesService.getAllSelectList().then((response: any) => {
      setProcurementTypes(response.data);
      setValues((prevValues: any) => ({
        ...prevValues,
        ProcurementTypeId: procurementTypeId, //response.data[0].Value,
      }));
    });
  };
  const editPosforStatus = (Boqpob2bid: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    const updatedValues = {
      ...values,
      Boqpob2bid,
      Boqpob2bstatusId: 1,
      UpdatedByID: auth.Id,
    };
    values.Boqpob2bid = Boqpob2bid;
    BoqposService.editPosforStatus(updatedValues).then((response: any) => {
      let result = response.data;
      if (response) {
        globalService.success(result.message);
        navigate(-1);
      } else {
        globalService.error(result.message);
      }
    });
  };

 
  // const isDisabled = !(
  //   selectionModelforpayment.length > 0 && selectedPaymentTermIds.length > 0
  // );
  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(
      `${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`,
      "_blank"
    );
  };

  const openviewPoDocsDialog = (boqpob2bId: any) => {
    
    values.Boqpob2bId = boqpob2bId;
    setViewDocsOpen(true);
    getByBoqpob2bId(boqpob2bId);
    getMPaymentTerm(boqpob2bId);
  };

  const openviewPaymentTermsDialog = (boqpob2bId: any) => {
    setViewPaymentTermsOpen(true);
    // getPaymentTermsByBoqpob2bId(boqpob2bId);
    getMPaymentTerm(boqpob2bId);
  };

  const handleChangeforDocsTerms = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setValueForDocTerms(newValue);
  };

  const getByBoqpob2bId = (boqpob2bId: any) => {
    //values.ProjectDirectoryID = ProjectDirectoryId;
    BoqDocFileService.GetBoqdocFilesForPoEdit(boqpob2bId).then((response) => {
      let result = response.data;
      setViewDocsFiles(result.list);
    });
  };
  const getPaymentTermsByBoqpob2bId = (boqpob2bId: any) => {
    mPaymentTermsService
      .getPaymentTermsByBoqpob2bId(boqpob2bId)
      .then((response) => {
        let result = response.data;
        setViewPaymentTerms(result.list);
      });
  };

  // const handleConvertedBoqSelectionChange = (selectedIds: any) => {
  //   setSelectedPaymentTermIds(selectedIds);
  // };
  // const handleSelectionModelChange = (selectionModel: any) => {
  //   const updatedTerms = mpaymentTerms.map(term => ({

  const handleSelectionModelChangeforpayment = (
    selectionModelforpayment: any
  ) => {
    setPaymenttermsId(selectionModelforpayment);
    const updatedTerms = mpaymentTerms.map((term) => ({
      ...term,
      IsSelected: selectionModelforpayment.includes(term.PaymentTermId),
    }));
    setMPaymentTerm(updatedTerms);
  };
  const handleSelectionModelChangeforDoc = (selectionModelforDoc: any) => {
    setDocsId(selectionModelforDoc);
    
    const updatedTerms = viewDocsFiles.map((term) => ({
      ...term,
      IsSelected: selectionModelforDoc.includes(term.BoqdocFileId),
    }));
    setViewDocsFiles(updatedTerms);
  };

  const closeViewDocsDialog = () => {
    setViewDocsOpen(false);
  };

  const closeViewPaymentTermsDialog = () => {
    setViewPaymentTermsOpen(false);
  };
  const getMPaymentTerm = (boqpob2bId: any) => {
    mPaymentTermsService
      .GetMPaymentTermsForPoEdit(boqpob2bId)
      .then((response) => {
        let result = response.data;
        setMPaymentTerm(result.list);
      });
  };

  const downloadReport = (BOQPOB2BID: any) => {
    //e.preventDefault();
    //if (validate()) {
    // var model = setFormValue(values)
    var poB2bId = BOQPOB2BID;

    
    reportService.downloadBoqPOPdf(poB2bId).then((response) => {
      let result = response.data;
      let fileName = "BOQPOReport.pdf";
      fileDownload(result, fileName);
    });

    // }
  };
  const columns: GridColDef[] = [
    { field: "Project", headerName: "Project", width: 130, flex: 1 },
    { field: "BOQPOB2BNo", headerName: "PO No.", width: 125 },
    {
      field: "BOQPOB2BDate",
      headerName: "PO Date",
      width: 100,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "POGrossTotal",
      headerName: "PO Value",
      width: 130,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
   { field: "VendorName", headerName: "Vendor Name", width: 130, flex: 1 },
    {
      field: "ExpectedDateOfDelivery",
      headerName: "Expected Date Of Delivery",
      width: 100,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
   { field: "ProcurementTypeName", headerName: "Procurement Type Name", width: 130, flex: 1 },

    {
      field: "BOQPOB2BStatus",
      headerName: "PO Status",
      width: 130,
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              {params.value}
              {params.row.BOQPOB2BStatus === "Rejected" && (
                <Tooltip
                  title={params.row.RejectedRemarks ?? ""}
                >
                  <IconButton
                    size="small"
                    color="error"
                    aria-label="add an alarm"
                  >
                    <MessageIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </>
        );
      },
    },
    // { field: "Remarks", headerName: "Remarks", width: 130, flex: 1 },
    // {
    //   field: "RejectedRemarks",
    //   headerName: "Rejected Remarks",
    //   width: 130,
    //   flex: 1,
    // },
    // { field: "PaymentTerms", headerName: "Payment Terms", width: 130, flex: 1,  renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />, },
    // { field: "TotDetails", headerName: "Tot Details", width: 130, flex: 1 },
    // { field: "CreatedBy", headerName: "CreatedBy", width: 130, flex: 1 },
    // {
    //   field: "CreatedOn",
    //   headerName: "CreatedOn",
    //   width: 130,
    //   flex: 1,
    //   valueFormatter: (params) =>
    //     params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    // },
    {
      field: "TotDetails",
      headerName: "Details",
      width: 120,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqPODtls/" + params.row.BOQPOB2BID, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Details ({params.row.TotDetails})
            </Button>
          </Stack>
        );
      },
    },

    {
      field: "SendFor",
      headerName: "Send",
      width: 90,
      //   flex: 1,
      renderCell: (params) => {
        return (
          params.row.BOQPOB2BStatus === "Pending" &&
          params.row.TotDetails > 0 && (
            <Stack>
              <Button
                className="btnGrid"
                variant="contained"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure, want to send for Approval",
                    subTitle: "Once send, changes won't be allowed",
                    onConfirm: () => {
                      editPosforStatus(params.row.BOQPOB2BID);
                    },
                  });
                }}
              // onClick={(e: any) => {
              //   editPosforStatus(params.row.BOQPOB2BID);

              // }}
              >
                Send for Approval
              </Button>
            </Stack>
          )
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      //   flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <Button
              size="large"
              color="primary"
              aria-label="add an alarm"
              className="btnGrid"
              variant="contained"
              onClick={(e) => downloadReport(params.row.BOQPOB2BID)}
            >
              <DownloadIcon fontSize="inherit" /> PO
            </Button>
            {params.row.BOQPOB2BStatus === "Pending" &&
              params.row.TotDetails > 0 && (
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="add an alarm"
                  onClick={() =>
                    navigate("/editBoqpob2b/" + params.row.BOQPOB2BID)
                  }
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
          </Stack>
        );
      },
    },
    {
      field: "ViewDocs",
      headerName: "Docs and Terms & Condition",
      width: 80,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() => {
                openviewPoDocsDialog(params.row.BOQPOB2BID);
              }}
            >
              View
            </Button>
          </Stack>
        );
      },
    },
    // {
    //   field: "BOQRFQID",
    //   headerName: "Terms & Condition",
    //   width: 80,
    //   //  flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Stack spacing={2} direction="column">
    //         <Button
    //           className="btnGrid"
    //           variant="contained"
    //           onClick={() => {
    //             openviewPaymentTermsDialog(params.row.BOQPOB2BID);
    //           }}
    //         >
    //           View ({params.row.TotTerms})
    //         </Button>
    //       </Stack>
    //     );
    //   },
    // },
  ];

  const BoqPoB2BPaymentTermsColumns: GridColDef[] = [
    {
      field: "PaymentTerm",
      headerName: "Term & Conditions",
      flex: 1,
    },
  ];
  const boqDocumentviewColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          //sx={{ cursor: "pointer" }}
          onClick={(event) => handleFileClick(event, params.row)}
          to={""}
        >
          {params.row.FileName}
        </Link>
      ),
    },
    {
      field: "UserName",
      headerName: "Created By",
      width: 200,
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
  ];

  const handleSubmit = (e: React.FormEvent) => {
    
    e.preventDefault();
    if (validate()) {
      // values.UserId = auth.Id;
      // // values.ProjectDirectoryId = projectDirectory.ProjectDirectoryId || "";
      // if (projectDirectory && projectDirectory.ProjectDirectoryId) {
      //   values.ProjectDirectoryId = projectDirectory.ProjectDirectoryId || "";
      // } else {
      //   values.ProjectDirectoryId = "";
      // }
      getB2BPo();
    }
  };

  const getB2BPo = () => {
    
    setValues((prevValue: any) => ({
      ...prevValue,
      UserId: auth.Id,
      ProjectDirectoryId: projectDirectory
        ? projectDirectory.ProjectDirectoryId
        : "",
    }));
    values.ProcurementTypeID= procurementTypeId;
    
    BoqposService.GetB2BPOs(values).then((Response: any) => {
      let result = Response.data;
      
      setboqpos(result.list);
    });
  };

  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 3);
    return oneMonthsAgo;
  }
  const getClients = () => {
    mClientService.GetAllMultiSelectList().then((response: any) => {
      setClients(response.data);
    });
  };
  const goToNextTab = () => {
    const nextValue = (parseInt(valuefordocterms) + 1).toString();
    setValueForDocTerms(nextValue);
  };
  const getBoqPackages = (ProjectDirectoryID: any) => {
    projectDirectoryService
      .getSelectList(ProjectDirectoryID)
      .then((response: any) => {
        setPackages(response.data);
      });
  };
  const getProjectDirectory = (siteId: any) => {
    projectDirectoryService.GetBySiteId(siteId).then((response) => {
      let result = response.data;
      setProjectDirectory(result.row);
      if (result.row) values.ProjectDirectoryID = result.row.ProjectDirectoryId;
      else values.ProjectDirectoryID = "";
      getBoqPackages(values.ProjectDirectoryID);
    });
  };
  const getSites = () => {
    mSiteService.GetMultiSelectListByUploadedBoqs().then((response: any) => {
      setSites(response.data);
    });
  };
  // const handleSubmitSendToVendorsForQuotation = () => {
  //   setConfirmDialog({
  //     ...confirmDialog,
  //     isOpen: false,
  //   });
  const getVendor = () => {
    MVendorService.getMVendorSelectListByUserId(auth.Id).then((response) => {
      setVendor(response.data);
      if (response.data.length > 0) {
        setValues((prevValues: any) => ({
          ...prevValues,
          VendorId: response.data[0].Value,
        }));
      }
    });
  };
  const handleSubmitSendToVendorsForQuotation = (Boqpob2bid: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    // const updatedValues = {
    //   ...values,
    //   Boqpob2bid,
    //   UpdatedByID: auth.Id,
    // };
    
    values.Boqpob2bid = Boqpob2bid;
    values.BOQDocFileIDs = DocsId.toString();
    values.PaymentTermIDs = paymenttermsId.toString();
    values.UpdatedByID = auth.Id;

    BoqposService.editDataForBOQPOs(values).then((response: any) => {
      let result = response.data;
      if (response) {
        globalService.success(result.message);
        setViewDocsOpen(false);
      } else {
        globalService.error(result.message);
      }
    });
  };

  return (
    <>
      <Typography variant="h5" align="center">
        BOQ PO's
      </Typography>

      <Card>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={1.5}>
                  <Controls.ReactDatePicker
                    label="From Date"
                    // min={values.FromDt}
                    max={new Date()}
                    value={(values.FromDt = values.FromDt || getDefaultDate())}
                    onChange={(date: any) => {
                      handleInputChange({
                        target: {
                          value:
                            globalService.getISODateOnlyForDatePicker(date),
                          name: "FromDt",
                        },
                      });
                    }}
                    error={errors.FromDt}
                  />
                </Grid>

                <Grid item xs={6} sm={1.5}>
                  <Controls.ReactDatePicker
                    label="To Date"
                    min={values.FromDt}
                    max={new Date()}
                    value={(values.ToDt = values.ToDt || new Date())}
                    onChange={(date: any) => {
                      handleInputChange({
                        target: {
                          value:
                            globalService.getISODateOnlyForDatePicker(date),
                          name: "ToDt",
                        },
                      });
                    }}
                    error={errors.ToDt}
                  />
                </Grid>
                {/* <Grid item xs={6} sm={2}>
                  <Controls.MuiAutocomplete
                    options={clients}
                    label="Client"
                    error={errors.ClientID}
                    onChange={(event: MultiSelectListModel) => {
                      handleInputChange({
                        target: {
                          name: "ClientID",
                          value: event ? event.value : "",
                        }
                      });
                      if (event) {
                        getSites(event.value);
                        setValues((prevValues: any) => ({
                          ...prevValues,
                          SiteID: ""
                        }));
                        setProjectDirectory(projectDirectoryService.initialFieldValues);
                      }
                    }}
                    value={clients.find((item: any) => item.value === values.ClientID) || null}
                  />
                </Grid> */}

                <Grid item xs={6} sm={3}>
                  <Controls.MuiAutocomplete
                    options={sites}
                    label="Site"
                    error={errors.SiteID}
                    onChange={(event: MultiSelectListModel) => {
                      handleInputChange({
                        target: {
                          name: "SiteID",
                          value: event ? event.value : "",
                        },
                      });

                      if (event) {
                        getProjectDirectory(event.value);
                      }
                    }}
                    value={
                      sites.find((item: any) => item.value === values.SiteID) ||
                      null
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Controls.Select
                    name="VendorId"
                    label="Vendor"
                    options={vendors}
                    value={vendors.length > 0 ? values.VendorId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.VendorId}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Controls.Select
                    name="ProcurementTypeId"
                    label="Procurement Type"
                    required
                    disabled={procurementTypeId ? true : false}
                    showEmptyItem={true}
                    options={procurementTypes}
                    value={
                      procurementTypes.length > 0
                        ? values.ProcurementTypeId
                        : ""
                    }
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.ProcurementTypeID}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Grid>
                {/* <Grid item xs={12} sm={3}>
                  <Controls.Select
                    showEmptyItem={false}
                    name="ProjectDirectoryId"
                    label="Project Directory"
                    required
                    value={
                      projectDirectores.length > 0
                        ? values.ProjectDirectoryId
                        : ""
                    }
                    onChange={handleInputChange}
                    options={projectDirectores}
                    error={errors.ProjectDirectoryId}
                  />
                </Grid> */}
              </Grid>
            </React.Fragment>
          </CardContent>
        </form>
      </Card>

      <Card>
        <CardContent>
          <div style={{ height: 650, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BOQPOB2BID}
              rows={boqpos}
              columns={columns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={false}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    BOQPOB2BID: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </div>
        </CardContent>
      </Card>

      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={viewDocsOpen}
        onClose={closeViewDocsDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          View Documents and Terms & Condition
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeViewDocsDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
          <>
            <Grid item xs={12} sm={3}>
              <Stack direction="row" spacing={1} marginY={1}>
                {/* {selectedVendors.length > 0 &&  selectedPaymentTermIds.length > 0 && selectedboqDocFileIds.length > 0 && ( */}

                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure you want to Edit Data ?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          handleSubmitSendToVendorsForQuotation(
                            values.Boqpob2bId
                          );
                        },
                      });
                    }}
                  >
                    Edit Data
                  </Button>
                </span>
              </Stack>
            </Grid>
            {/* <div style={{ width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.BoqdocFileId}
                rows={viewDocsFiles}
                columns={boqDocumentviewColumns}
                columnHeaderHeight={30}
                autoHeight={true}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
              />
            </div> */}

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={valuefordocterms}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeforDocsTerms}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Docs" value="1" />
                    <Tab label="Terms & Condition" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <>
                    <div style={{ width: "100%" }}>
                      <DataGrid
                        getRowId={(row) => row.BoqdocFileId}
                        rows={viewDocsFiles}
                        columns={boqDocumentviewColumns}
                        checkboxSelection
                        // rowSelectionModel={selectedPaymentTermIds}

                        disableRowSelectionOnClick
                        rowSelectionModel={viewDocsFiles
                          .filter((term) => term.IsSelected)
                          .map((term) => term.BoqdocFileId)}
                        onRowSelectionModelChange={
                          handleSelectionModelChangeforDoc
                        }
                        columnHeaderHeight={30}
                        autoHeight={true}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              // Hide columns Id, the other columns will remain visible
                              Id: false,
                            },
                          },
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                      <CardActions
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="contained"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => goToNextTab()}
                          >
                            Next
                          </Button>
                        </Stack>
                      </CardActions>
                    </div>
                  </>
                </TabPanel>
                <TabPanel value="2">
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.PaymentTermId}
                      rows={mpaymentTerms}
                      columns={BoqPoB2BPaymentTermsColumns}
                      checkboxSelection
                      // rowSelectionModel={selectedPaymentTermIds}
                      disableRowSelectionOnClick
                      rowSelectionModel={mpaymentTerms
                        .filter((term) => term.IsSelected)
                        .map((term) => term.PaymentTermId)}
                      onRowSelectionModelChange={
                        handleSelectionModelChangeforpayment
                      }
                      columnHeaderHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                    />
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </>
        </DialogContent>
      </Dialog>
      {/* <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={viewPaymentTermsOpen}
        onClose={closeViewPaymentTermsDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          View Term's And Conditions
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={closeViewPaymentTermsDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
          <>

            <p>
              {" "}


            </p>

            <p> </p>

            <div style={{ width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.PaymentTermId}
                rows={mpaymentTerms}
                columns={BoqPoB2BPaymentTermsColumns}
                checkboxSelection
                // rowSelectionModel={selectedPaymentTermIds}

                disableRowSelectionOnClick
                rowSelectionModel={mpaymentTerms
                  .filter((term) => term.IsSelected)
                  .map((term) => term.PaymentTermId)}
                onRowSelectionModelChange={handleSelectionModelChange}
                columnHeaderHeight={30}
                autoHeight={true}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
              />
            </div>

          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeViewPaymentTermsDialog} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}

export default BoqPosList;
