import { Title } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Controls from "../../utility/Controls.tsx/Controls";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { globalService } from "../../services/GlobalService";
import useForm from "../../utility/hooks/UseForm";
import { BoqTitleModel } from "../../models/BoqTitleModel";
import { boqTitleService } from "../../services/BoqTitleService";
import { boqPackageService } from "../../services/BoqPackageService";

const BoqTitleForm = (...props: any) => {
  const { BoqpackageId, BoqtitleId }: any = useParams();
  let navigate = useNavigate();
  // const { auth } = React.useContext(AuthContext);
  const mode = BoqtitleId ? "Edit" : "Create";
  const [packageName, setPackageName] = useState();

  const validate = (fieldValues: BoqTitleModel = values) => {
    let temp: any = { ...errors };
    if ("BoqtitleName" in fieldValues)
      temp.BoqtitleName = fieldValues.BoqtitleName
        ? ""
        : "BOQ Title Name is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqTitleService.initialFieldValues, validate, BoqpackageId);

  const newSubTitle = () => {
    setValues(boqTitleService.initialFieldValues);
  };

  function setFormValue(model: BoqTitleModel) {
    let newModel = {
      BoqtitleId: model.BoqtitleId,
      BoqpackageId: model.BoqpackageId,
      BoqtitleName: model.BoqtitleName,
      Active: model.Active,
    };
    return newModel;
  }
  useEffect(() => {
    values.BoqpackageId = BoqpackageId;
    if (BoqtitleId) {
      getByTitleID();
      setErrors({});
    } else newSubTitle();
    if (BoqpackageId) 
      {
        getByPackageId();
      }
  }, [BoqtitleId]);

  const getByTitleID = () => {
    boqTitleService.getById(BoqtitleId).then((response: { data: any }) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    });
  };
  const getByPackageId = () => {
    boqPackageService.getById(BoqpackageId).then((response: { data: any }) => {
      if (response) {
        let result = response.data;
        setPackageName(result.list.BoqpackageName);
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (BoqtitleId) {
          boqTitleService.put(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success("Title edited successfully.");
              resetForm();
              navigate("/boqTitles");
            } else {
              globalService.error(result.message);
            }
          }
        });
      } else {
        boqTitleService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success("Title added successfully.");
              resetForm();
              navigate("/boqTitles");
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  return (
    <div>
      {/* <Title> Sub Title</Title> */}
      <Typography variant="h5" align="center">
        BOQ Title
      </Typography>
      <Typography variant="body1" align="right">
        {" "}
        <b>BOQ Package :</b> {packageName}
      </Typography>
      <>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      required
                      label="Title Name"
                      name="BoqtitleName"
                      value={values.BoqtitleName}
                      onChange={handleInputChange}
                      error={errors.BoqtitleName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}  className={mode !== "Edit" ? "hidden" : ""}>
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
                </Grid>
              </React.Fragment>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Stack spacing={2} direction="row">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/boqTitles")}
                >
                  Back To List
                </Button>
              </Stack>
            </CardActions>
          </Card>
        </form>
      </>
    </div>
  );
};

export default BoqTitleForm;
