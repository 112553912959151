import React from 'react'
import { toast } from 'react-toastify';
import { AuthModel } from '../models/UserModel';
import dayjs from 'dayjs';
import { editableInputTypes } from '@testing-library/user-event/dist/utils';
import { easing } from '@mui/material';
import { SelectListModel } from '../models/ApiResponse';

///////////////Toast Notifications/////////////////////
const pageTitle = "Home";
//https://fkhadra.github.io/react-toastify/introduction/
const success = (msg: string) => toast.success(msg, {
  position: "top-left",
  theme: "colored",
});

function error(msg: string) {
  return toast.error(msg, {
    position: "top-left",
    theme: "colored",
  });
}

function warning(msg: string) {
  return toast.warning(msg, {
    position: "top-left",
    theme: "colored",
  });
}

function info(msg: string) {
  return toast.info(msg, {
    position: "top-left",
    theme: "colored",
  });
}
///////////////Ends Toast Notifications/////////////////////


function getDocTypeMenuText(docType: string) {
  switch (docType) {
    case 'OP':
      return 'Opening Balance';
    case 'CP':
      return 'Cash Payment';
    case 'CR':
      return 'Cash Receipt';
    case 'BP':
      return 'Bank Payment';
    case 'BR':
      return 'Bank Receipt';
    case 'PB':
      return 'Purchase Bill';
    case 'EB':
      return 'Expense Bill';
    case 'JV':
      return 'Journal Voucher';
    case 'SB':
      return 'Society Bill';
    case 'MC':
      return 'Member Collection';
    case 'CV':
      return 'Collection Revesal';
    case 'YC':
      return 'Year End Closing Transaction';
    default:
      return '';
  }
}

function convertLocalToUTCDate(date: Date) {
  if (!date) {
    return date
  }

  //date = new Date(date);
  // var now = new Date();
  // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  //date.setUTCHours(0);
  return date;
}

function getISODateOnly(date: Date) {
  let dateOnly = null;
  if (date)
    dateOnly = date.toISOString().split('T')[0];
  return dateOnly;
}

function getISODateOnlyForDatePicker(date: Date) {
  let dateOnly = null;
  if (date)
    dateOnly = getISODateOnly(convertLocalToUTCDate(date));
  return dateOnly;
}

function validateEmail(email: string) {

  let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  return regex.test(email);
}

function getBoqUploadNatures() {
  const result = [
    { Value: 'Package', Text: 'Package' },
    { Value: 'DontProcess', Text: 'DontProcess' },
    { Value: 'Information', Text: 'Information' },
  ];
  return result;
}

// function getActivityTypes() {
//   const result = [
//     { Value: 'B2B', Text: 'B2B' },
//     { Value: 'Buy', Text: 'Buy' },
//     { Value: 'Factory Work', Text: 'Factory Work' },
//     { Value: 'Mixed', Text: 'Mixed' },
//     { Value: 'Site Works', Text: 'Site Works' },
//   ];
//   return result;
// }

const addMonths = (date: Date, months: number) => {
  date.setMonth(date.getMonth() + months);
  return date;
}

function convertJsonToBlob(obj: any) {
  const str = JSON.stringify(obj);
  const bytes = new TextEncoder().encode(str);
  const blob = new Blob([bytes], {
    type: "application/json;charset=utf-8"
  });
  return blob;
}

function convertJsonToBlobExcel(obj: any) {
  const str = JSON.stringify(obj);
  const bytes = new TextEncoder().encode(str);
  const blob = new Blob([bytes], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });
  return blob;
}

const roleMatch = (allowedRoles: string[], auth: AuthModel) => {
  var isMatch = false;
  auth?.Roles?.find((role: any) => allowedRoles?.includes(role))
    ? (isMatch = true)
    : (isMatch = false);
  // (allowedRoles?.includes(auth?.Roles)) ? isMatch = true : isMatch = false;
  return isMatch;
};

const formatDateTime = (value: any) => {
  try {
    return value ? dayjs(value).format("DD-MMM-YYYY hh:mm A") : "";
  }
  catch (e: any) {
    console.log(e);
    return "";
  }
}

const mapToMultiSelectList = (list: SelectListModel[]) => {
  const options = list.map(({ Text, Value }) => ({
    label: Text,
    value: Value,
  }));
  return options;
}

const ScrollableCellRenderer = ({ value }: any) => (
  <div className="newline" style={{ maxHeight: "100px", overflowY: "auto", textAlign: "left" }}>{value}</div>
);

// Generate columns based on dynamicHeaders
const generateColumnHeadersDynamically = (headers: any[], rightAlign: boolean = false) => {
  return headers.map((header) => ({
    field: header,
    headerName: header,
    width: 200,
    headerAlign: "right",
    cellClassName: rightAlign ? "cell-right" : '',
    valueFormatter: (params: any) => {
      return rightAlign && !isNaN(params.value) ? globalService.formatToIndianCurrency(params.value) : params.value;
    },
  }));
  // return Object.keys(headers).map((key) => ({
  //   field: key,
  //   headerName: key,
  //   //headerName: headers[key],
  //   width: 150,
  // }));
};

// const isFormValid = (errors: any): boolean => {
//   const checkValues = (obj: any): boolean =>
//     Object.values(obj).every(
//       (x) => (typeof x === 'string' && x === '') || (typeof x === 'object' && checkValues(x))
//     );

//   return checkValues(errors);
// };

function formatToIndianCurrency(amount: number) {
  return new Intl.NumberFormat('en-IN', {
    //style: 'currency',
    //currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
}
function formatToPercentage(value: number) {
  return new Intl.NumberFormat('en-IN', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value / 100); // Divide by 100 to convert the number to a percentage
}

// Function to format the y-axis ticks
const yAxisTickFormatter = (value: any) => {
  if (value >= 10000000) {
    return `${(value / 10000000).toFixed(1)}Cr`;
  } else if (value >= 100000) {
    return `${(value / 100000).toFixed(1)}L`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}k`;
  }
  return value;
};

export const globalService = {
  pageTitle,
  success,
  error,
  info,
  warning,
  formatDateTime,
  convertLocalToUTCDate,
  getBoqUploadNatures,
  validateEmail,
  getDocTypeMenuText,
  addMonths,
  convertJsonToBlobExcel,
  //getActivityTypes
  //isFormValid,
  roleMatch,
  mapToMultiSelectList,
  getISODateOnly,
  getISODateOnlyForDatePicker,
  ScrollableCellRenderer,
  generateColumnHeadersDynamically,
  formatToIndianCurrency,
  formatToPercentage,
  yAxisTickFormatter
};