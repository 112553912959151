import { Title } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Controls from "../../utility/Controls.tsx/Controls";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { BoqSubTitleModel } from "../../models/BoqSubTitleModel";
import { boqSubTitleService } from "../../services/BoqSubTitleService";
import { useEffect } from "react";
import { globalService } from "../../services/GlobalService";
import useForm from "../../utility/hooks/UseForm";
import { boqPackageService } from "../../services/BoqPackageService";
import { SelectListModel } from "../../models/ApiResponse";
import { boqTitleService } from "../../services/BoqTitleService";

const BoqSubTitleForm = (...props: any) => {
  const { BoqsubTitleId,BoqpackageId }: any = useParams();
  let navigate = useNavigate();
  // const { auth } = React.useContext(AuthContext);
  const mode = BoqsubTitleId ? "Edit" : "Create";
  const [packageName, setPackageName] = useState();
  const [titleList, setTitleList] = useState<SelectListModel[]>([]);

  const validate = (fieldValues: BoqSubTitleModel = values) => {
    
    let temp: any = { ...errors };
    if ("BoqtitleId" in fieldValues)
      temp.BoqtitleId = fieldValues.BoqtitleId 
        ? ""
        : "Please Select BOQ Title Name";
    if ("BoqsubTitleName" in fieldValues)
      temp.BoqsubTitleName = fieldValues.BoqsubTitleName
        ? ""
        : "BOQ SubTitle Name is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      boqSubTitleService.initialFieldValues,
      validate,
      BoqpackageId
    );

  const newSubTitle = () => {
    setValues(boqSubTitleService.initialFieldValues);
  };

  function setFormValue(model: BoqSubTitleModel) {
    let newModel = {
      BoqtitleId:model.BoqtitleId,
      BoqsubTitleId: model.BoqsubTitleId,
      BoqsubTitleName: model.BoqsubTitleName,
      Active: model.Active,
    };
    return newModel;
  }

  useEffect(() => {
    if (BoqsubTitleId) {
        getSubTitleByID();
      setErrors({});
    } else newSubTitle();
    if(BoqpackageId) getByPackageId();
    if(titleList.length === 0) getTitleList();
  }, [BoqsubTitleId]);

  const getTitleList = () => {
    boqTitleService.getTitleSelectList().then((response) =>{
        setTitleList(response.data);
    });
  }

  const getSubTitleByID = () => {
    boqSubTitleService.GetById(BoqsubTitleId).then((response: { data: any }) => {
        if (response) {
          let result = response.data;
          setValues(setFormValue(result.list));
        }
      });
  };

  const getByPackageId = () => {
    boqPackageService.getById(BoqpackageId).then((response: { data: any }) => {
      if (response) {
        let result = response.data;
        setPackageName(result.list.BoqpackageName);
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (BoqsubTitleId) {
        boqSubTitleService.put(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success("SubTitle edited successfully.");
              resetForm();
              navigate("/boqSubTitles");
            } else {
              globalService.error(result.message);
            }
          }
        });
      } else {
        boqSubTitleService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success("SubTitle added successfully.");
              resetForm();
              navigate("/boqSubTitles");
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  return (
    <div>
      <Typography variant="h5" align="center">
        BOQ SubTitle
      </Typography>
      <Typography variant="body1" align="right">
        {" "}
        <b>BOQ Package :</b> {packageName}
      </Typography>
      <>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <React.Fragment>
                <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Controls.Select
                    required
                    name="BoqtitleId"
                    label="Title Name"
                    options={titleList}
                    value={titleList.length > 0 ? values.BoqtitleId : ""}
                    onChange={(e: any) => {
                        handleInputChange(e);
                      }}
                     error={errors.BoqtitleId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      required
                      label="SubTitle Name"
                      name="BoqsubTitleName"
                      value={values.BoqsubTitleName}
                      onChange={handleInputChange}
                      error={errors.BoqsubTitleName}
                    />
                  </Grid>
                  <Grid
                    item xs={12} sm={4} className={mode !== "Edit" ? "hidden" : ""}
                  >
                    <Controls.Checkbox
                      label="Active"
                      name="Active"
                      value={values.Active}
                      onChange={handleInputChange}
                      error={errors.Active}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Stack spacing={2} direction="row">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/boqSubTitles")}
                >
                  Back To List
                </Button>
              </Stack>
            </CardActions>
          </Card>
        </form>
      </>
    </div>
  );
};

export default BoqSubTitleForm;
