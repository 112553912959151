import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { expenseHeadService } from "../../services/MExpenseHeadService";
import { ExpenseHeadModel } from "../../models/MExpenseHeadModel";
import { resolve } from "path";

export const MExpenseHeadsForm = (...props: any) => {

  const { ExpenseHeadId } = useParams();
  let navigate = useNavigate();
  const mode = ExpenseHeadId ? "Edit" : "Create";

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("ExpenseHeadName" in fieldValues)
      temp.ExpenseHeadName = fieldValues.ExpenseHeadName ? "" : "Expense Head Name is required";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");

  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(expenseHeadService.initialFieldValues, validate, ExpenseHeadId);

  const newExpenseHead = () => {
    setValues(expenseHeadService.initialFieldValues);
  };

  function setFormValue(model: ExpenseHeadModel) {
    let newModel = {
      ExpenseHeadId: model.ExpenseHeadId,
      ExpenseHeadName: model.ExpenseHeadName || "",
      IsItForRent: model.IsItForRent,
    };
    return newModel;
  }

  useEffect(() => {
    if (ExpenseHeadId) {
      getExpenseHeadById(ExpenseHeadId);
      setErrors({});
    } else newExpenseHead();
  }, [ExpenseHeadId]);

  const getExpenseHeadById = (ExpenseHeadId: any) => {
    expenseHeadService.getById(ExpenseHeadId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (ExpenseHeadId) {
        expenseHeadService.put(values).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/mExpenseHeads")
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        expenseHeadService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              navigate("/mExpenseHeads")
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  }


  return (
    <>
      <Typography variant="h5" align="center">
        Expense Head Form
      </Typography>

      <form autoComplete="off" noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Expense Head Name"
                    name="ExpenseHeadName"
                    value={values.ExpenseHeadName}
                    onChange={handleInputChange}
                    error={errors.ExpenseHeadName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}
                // className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="IsItForRent"
                    label="Is It For Rent"
                    value={values.IsItForRent}
                    onChange={handleInputChange}
                  />
                </Grid>

              </Grid>

            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/mExpenseHeads")}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </>
  )
}
