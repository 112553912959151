import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { MBoqshortFormsModel } from "../../models/MBoqshortFormsModel";
import { mBoqshortFormsService } from "../../services/MBoqshortFormsService";
import { boqPackageService } from "../../services/BoqPackageService";


const MBoqshortFormsForm = (...props: any) => {
    const {BoqshortFormId,BoqpackageId}:any = useParams();
    let navigate = useNavigate();
    const [packageName, setPackageName] = useState();
    const mode = BoqshortFormId ? "Edit" : "Create";

    const validate = (fieldValues = values) => {
        let temp: any = {...errors};
  
        if("BoqshortFormName" in fieldValues)
            temp.BoqshortFormName = fieldValues.BoqshortFormName ? "" : "Zone Name is required";
        setErrors({
            ...temp,
        });
  
        if(fieldValues === values)
            return Object.values(temp).every((x) => x === "");
  
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mBoqshortFormsService.initialFieldValues, validate, BoqshortFormId);

    const newZone = () => {
      setValues(mBoqshortFormsService.initialFieldValues);
    };
   
    function setFormValue(model: MBoqshortFormsModel) {
        let newModel = {
            BoqshortFormId : model.BoqshortFormId,
            BoqshortFormName: model.BoqshortFormName || "",
            Active: model.Active,
        };
        return newModel;
      }

      useEffect(() => {
        if(BoqpackageId) getByPackageId();
        if(BoqshortFormId) {
          getZone(BoqshortFormId);
            setErrors({});
        } else newZone();
      }, [BoqshortFormId]);
    
      const getZone = (BoqshortFormId: any) => {
        mBoqshortFormsService.getById(BoqshortFormId).then((response) => {
            if(response) {
                let result = response.data;
                setValues(setFormValue(result.list));
            }
        });
      };
      const getByPackageId = () => {
        boqPackageService.getById(BoqpackageId).then((response: { data: any }) => {
          if (response) {
            let result = response.data;
            setPackageName(result.list.BoqpackageName);
          }
        });
      };
      const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if(validate()) {
          values.BoqpackageId = BoqpackageId;
           if(BoqshortFormId) {
            
            mBoqshortFormsService.put(values).then((response: any) => {
                let result = response.data;
                if(response) {
                  
                    globalService.success(result.message);
                    navigate("/mboqshortForms/" + BoqpackageId)
                } else {
                    globalService.error(result.message);
                }
            });
           } else {
            mBoqshortFormsService.post(values).then((response: any) => {
                if(response) {
                    let result = response.data;
                    if(result.isSuccess){
                        globalService.success(result.message);
                        navigate("/mboqshortForms/" + BoqpackageId)
                    } else {
                        globalService.error(result.message);
                      }
                } 
            });
           }
        }
      }
  

  return (
    <>
     <Typography>
     BOQ Short Form
      </Typography>
      <Typography variant="body1" align="right">
        {" "}
        <b>BOQ Package :</b> {packageName}
      </Typography>
      <form
        autoComplete="off" noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Short Form Name"
                    name="BoqshortFormName"
                    value={values.BoqshortFormName}
                    onChange={handleInputChange}
                    error={errors.BoqshortFormName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>

              </Grid>

            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/mboqshortForms/" + BoqpackageId )}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>

      </form>
    </>
  )
}

export default MBoqshortFormsForm