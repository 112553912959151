import { Guid } from "guid-typescript";
import { ExpenseRegisterModel } from "../models/ExpenseRegisterModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialExpenseRegisterValues : ExpenseRegisterModel = {
    UserId: "",
    ExpenseHdrStatusId: "",
    FromDt: null,
    ToDt: null
}


const url = 'ExpenseRegister';

function getExpensesRegister( payload: ExpenseRegisterModel) {
    
    return customAxios.post(`${url}/GetExpensesRegister`, payload, axiosRetryConfig);
}


export const ExpenseRegisterService = {
    initialExpenseRegisterValues,
    getExpensesRegister
}