import React from "react";
import { Route, Routes } from "react-router-dom";
import BoqPackageList from "../components/masters/BoqPackageList";
import Home from "../components/user/Home";
import BoqTitleList from "../components/masters/BoqTitleList";
import BoqSubTitleList from "../components/masters/BoqSubTitleList";
import BoqItemList from "../components/masters/BoqItemList";
import DefaultLayout from "../components/layout/DefaultLayout";
import BoqPackageForm from "../components/masters/BoqPackageForm";
import BoqSubTitleForm from "../components/masters/BoqSubTitleForm";
import BoqItemForm from "../components/masters/BoqItemForm";
import BoqUpload from "../components/transactions/BoqUpload";
import BoqItemAttributeList from "../components/masters/BoqItemAttributeList";
import BoqTitleAttributeList from "../components/masters/BoqTitleAttributeList";
import PageNotFound from "../components/helper/PageNotFound";
import BoqSubTitleAttributesList from "../components/masters/BoqSubTitleAttributesList";
import BoqSubTitleAttributesForm from "../components/masters/BoqSubTitleAttributesForm";
import BoqTitleAttributeForm from "../components/masters/BoqTitleAttributeForm";
import BoqItemAttributeForm from "../components/masters/BoqItemAttributeForm";
import BoqSubTitleAlternativeTextList from "../components/masters/BoqSubTitleAlternativeTextList";
import BoqTitleForm from "../components/masters/BoqTitleForm";
import BoqItemAlternativeTextList from "../components/masters/BoqItemAlternativeTextList"
import BoqtitleAlternativeTextsList from "../components/masters/BoqtitleAlternativeTextsList";
import BoqtitleAlternativeTextForm from "../components/masters/BoqtitleAlternativeTextForm";
import BoqSubTitleAlternativeTextForm from "../components/masters/BoqSubTitleAlternativeTextForm";
import BoqItemAlternativeTextForm from "../components/masters/BoqItemAlternativeTextForm";
import MVendorList from "../components/masters/MVendorList";
import MVendorForm from "../components/masters/MVendorForm";
import BoqDynamicUpload from "../components/transactions/BoqDynamicUpload";
import Requisition from "../components/transactions/Requisition";
import QuoteInput from "../components/transactions/QuoteInput";
import Login from "../components/user/Login";

import MDepartmentsList from "../components/masters/MDepartmentsList";
import MDepartmentForm from "../components/masters/MDepartmentForm";
import MDesignationList from "../components/masters/MDesignationList";
import MDesignationForm from "../components/masters/MDesignationForm";
import MEmployeeList from "../components/masters/MEmployeeList";
import MEmployeeForm from "../components/masters/MEmployeeForm";
import MZoneList from "../components/masters/MZoneList";
import MZoneForm from "../components/masters/MZoneForm";
import ZoneApproverList from "../components/masters/ZoneApproverList";
import ZoneApproverForm from "../components/masters/ZoneApproverForm";
import BoqPackageMakeList from "../components/masters/BoqPackageMakeList";
import BoqPackageMakeForm from "../components/masters/BoqPackageMakeForm";
import Example from "../components/user/Example";
import MExpenseHeadsList from "../components/masters/MExpenseHeadsList";
import { MExpenseHeadsForm } from "../components/masters/MExpenseHeadsForm";
import ExpenseDtlsList from "../components/masters/ExpenseDtlsList";
import ExpenseDtlsForm from "../components/masters/ExpenseDtlsForm";
import ExpenseHdrsList from "../components/masters/ExpenseHdrsList";
import ExpenseHdrsForm from "../components/masters/ExpenseHdrsForm";
import ExpenseQueriesList from "../components/masters/ExpenseQueriesList";
import ExpenseApprovalList from "../components/masters/ExpenseApprovalList";
import ExpenseForAccountList from "../components/masters/ExpenseForAccountList";
import RateComparison from "../components/transactions/RateComparison";
import Dashboard from "../components/user/Dashboard";
import ExpenseRegisterList from "../components/masters/ExpenseRegisterList";
import RequestForQuoteList from "../components/transactions/RequestForQuoteList";
import BoqPosList from "../components/transactions/BoqPosList";
import TallyBatchesForm from "../components/masters/TallyBatchesForm";
import AppSettingForm from "../components/masters/AppSettingForm";
import BOQPODtlList from "../components/masters/BOQPODtlList";
import Boqpob2bsForm from "../components/transactions/Boqpob2bsForm";
import MPaymentTermsList from "../components/masters/MPaymentTermsList";
import MPaymentTermsForm from "../components/masters/MPaymentTermsForm";
import PoApprovalList from "../components/masters/PoApprovalList";
import RentAgreementsList from "../components/masters/RentAgreementsList";
import RentAgreementsForm from "../components/masters/RentAgreementsForm";
import BoqDashboard from "../components/user/BoqDashboard";
import RentDuesList from "../components/masters/RentDuesList";
import MBoqshortFormsList from "../components/masters/MBoqshortFormsList";
import MBoqshortFormsForm from "../components/masters/MBoqshortFormsForm";
import QuoteInputExcelUpload from "../components/transactions/QuoteInputExcelUpload";
import MUomsList from "../components/masters/MUomsList";
import MUomsForm from "../components/masters/MUomsForm";
import MItemsList from "../components/masters/MItemsList";
import MItemForm from "../components/masters/MItemForm";
import MUomalternativeTextsList from "../components/masters/MUomalternativeTextsList";
import MUomalternativeTextsForm from "../components/masters/MUomalternativeTextsForm";
import ResetPasswordForm from "../components/masters/ChangePasswordForm";


function AppRoutes() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="example" element={<Example />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="expansedashboard" element={<Dashboard />} />
        <Route path="boqdashboard" element={<BoqDashboard />} />

        <Route path="boqPackages" element={<BoqPackageList />} />
        <Route path="addBoqPackage" element={<BoqPackageForm />} />
        <Route path="editBoqPackage/:BoqpackageId" element={<BoqPackageForm />} />

        <Route path="boqpackageMakes/:BoqpackageId" element={<BoqPackageMakeList />} />
        <Route path="addboqpackageMake/:BoqpackageId" element={<BoqPackageMakeForm />} />
        <Route path="editboqpackageMake/:BoqpackageId/:BoqpackageMakeId" element={<BoqPackageMakeForm />} />

        <Route path="boqTitles" element={<BoqTitleList />} />
        <Route path="addTitle/:BoqpackageId" element={<BoqTitleForm />} />
        <Route path="editTitle/:BoqpackageId/:BoqtitleId" element={<BoqTitleForm />} />

        <Route path="boqSubTitles" element={<BoqSubTitleList />} />
        <Route path="addSubTitle/:BoqpackageId" element={<BoqSubTitleForm />} />
        <Route path="editSubTitle/:BoqpackageId/:BoqsubTitleId" element={<BoqSubTitleForm />} />

        <Route path="boqItems" element={<BoqItemList />} />
        <Route path="addItem/:BoqpackageId" element={<BoqItemForm />} />
        <Route path="editItem/:BoqpackageId/:BoqitemId" element={<BoqItemForm />} />

        <Route path="boqTitleAttribute/:BoqtitleId/:AttributeType" element={<BoqTitleAttributeList />} />
        <Route path="addTitleAttribute/:BoqtitleId/:AttributeType" element={<BoqTitleAttributeForm />} />
        <Route path="editTitleAttribute/:BoqtitleId/:AttributeType/:BoqtitleAttributeId" element={<BoqTitleAttributeForm />} />

        <Route path="boqSubTitlesAttributes/:BoqsubTitleId/:AttributeType" element={<BoqSubTitleAttributesList />} />
        <Route path="addboqSubTitlesAttribute/:BoqsubTitleId/:AttributeType" element={<BoqSubTitleAttributesForm />} />
        <Route path="editboqSubTitlesAttribute/:BoqsubTitleId/:AttributeType/:BoqsubTitleAttributeId" element={<BoqSubTitleAttributesForm />} />

        <Route path="boqItemAttributes/:BoqitemId/:AttributeType" element={<BoqItemAttributeList />} />
        <Route path="addItemAttribute/:BoqitemId/:AttributeType" element={<BoqItemAttributeForm />} />
        <Route path="editItemAttribute/:BoqitemId/:AttributeType/:BoqitemAttributeId" element={<BoqItemAttributeForm />} />

        <Route path="boqSubTitlesAlternativeTexts/:BoqsubTitleId" element={<BoqSubTitleAlternativeTextList />} />
        <Route path="boqItemAttribute/:BoqitemId/:AttributeType" element={<BoqItemAttributeList />} />

        <Route path="boqtitleAlternativeTexts/:BoqtitleId" element={<BoqtitleAlternativeTextsList />} />
        <Route path="addboqtitleAlternativeText/:BoqtitleId" element={<BoqtitleAlternativeTextForm />} />
        <Route path="editboqtitleAlternativeText/:BoqtitleId/:BoqtitleAlternativeTextId" element={<BoqtitleAlternativeTextForm />} />

        <Route path="boqSubTitlesAlternativeTexts/:BoqsubTitleId" element={<BoqSubTitleAlternativeTextList />} />
        <Route path="addSubTitlesAlternativeText/:BoqsubTitleId" element={<BoqSubTitleAlternativeTextForm />} />
        <Route path="editSubTitlesAlternativeText/:BoqsubTitleId/:BoqsubTitleAlternativeTextId" element={<BoqSubTitleAlternativeTextForm />} />

        <Route path="boqItemAlternativeTexts/:BoqitemId" element={<BoqItemAlternativeTextList />} />
        <Route path="addItemAlternativeText/:BoqitemId" element={<BoqItemAlternativeTextForm />} />
        <Route path="editItemAlternativeText/:BoqitemId/:BoqitemAlternativeTextId" element={<BoqItemAlternativeTextForm />} />

        <Route path="mExpenseHeads" element={<MExpenseHeadsList />} />
        <Route path="addExpenseHead" element={<MExpenseHeadsForm />} />
        <Route path="editExpenseHead/:ExpenseHeadId" element={<MExpenseHeadsForm />} />

        <Route path="rentAgreements" element={<RentAgreementsList />} />
        <Route path="addrentAgreements" element={<RentAgreementsForm />} />
        <Route path="editrentAgreement/:RentAgreementId" element={<RentAgreementsForm />} />

        <Route path="mDepartments" element={<MDepartmentsList />} />
        <Route path="addDepartment" element={<MDepartmentForm />} />
        <Route path="editDepartment/:DepartmentId" element={<MDepartmentForm />} />

        <Route path="mDesignations" element={<MDesignationList />} />
        <Route path="addDesignation" element={<MDesignationForm />} />
        <Route path="editDesignation/:DesignationId" element={<MDesignationForm />} />

        <Route path="mEmployees" element={<MEmployeeList />} />
        <Route path="addEmployee" element={<MEmployeeForm />} />
        <Route path="editEmployee/:EmployeeId" element={<MEmployeeForm />} />

        <Route path="mZones" element={<MZoneList />} />
        <Route path="addZone" element={<MZoneForm />} />
        <Route path="editZone/:ZoneId" element={<MZoneForm />} />

        <Route path="zoneApprovers/:ZoneId" element={<ZoneApproverList />} />
        <Route path="addzoneApprovers/:ZoneId" element={<ZoneApproverForm />} />
        {/*   <Route path="editZone/:ZoneId" element={<MZoneForm />} /> */}

        <Route path="expenseDtls/:ExpenseHdrId" element={<ExpenseDtlsList />} />
        <Route path="addExpenseDtls/:ExpenseHdrId" element={<ExpenseDtlsForm />} />
        <Route path="editExpenseDtls/:ExpenseHdrId/:ExpenseDtlId" element={<ExpenseDtlsForm />} />

        <Route path="vendors" element={<MVendorList />} />
        <Route path="addVendor" element={<MVendorForm />} />
        <Route path="editVendors/:VendorId" element={<MVendorForm />} />

        <Route path="expenses" element={<ExpenseHdrsList />} />
        <Route path="addExpense" element={<ExpenseHdrsForm />} />
        <Route path="editExpense/:ExpenseHdrId" element={<ExpenseHdrsForm />} />

        <Route path="expenseQueries/:ExpenseHdrId" element={<ExpenseQueriesList />} />

        <Route path="expenseRegister" element={<ExpenseRegisterList />} />
        {/* <Route path="addExpenseQuery/:ExpenseHdrId" element={<ExpenseQueriesForm />} />
        <Route path="editExpenseQuery/:ExpenseHdrId/:ExpenseQueryId" element={<ExpenseQueriesForm />} />  */}

        <Route path="expenseApproval/:UserId" element={<ExpenseApprovalList />} />

        <Route path="expenseForAccount" element={<ExpenseForAccountList />} />

        <Route path="appSetting" element={<AppSettingForm />} />
        {/* <Route path="appSetting/:AppSettingId" element={<AppSettingForm />} /> */}

        <Route path="mPaymentTerms" element={< MPaymentTermsList />} />
        <Route path="addPaymentTerm" element={< MPaymentTermsForm />} />
        <Route path="editPaymentTerm/:PaymentTermId" element={< MPaymentTermsForm />} />

        <Route path="boqPODtls/:BOQPOB2BID" element={<BOQPODtlList />} />

        <Route path="boqUpload" element={<BoqUpload />} />
        <Route path="boqDynamicUpload" element={<BoqDynamicUpload />} />
        <Route path="requisition" element={< Requisition />} />

        <Route path="quoteInputForVendor" element={< QuoteInput />} />
        <Route path="editposForVendor" element={< BoqPosList />} />
        <Route path="Boqrfqitems/:procurementTypeId" element={< RequestForQuoteList />} />
        <Route path="ratecomparison/:procurementTypeId" element={< RateComparison />} />
        <Route path="quoteInput/:procurementTypeId" element={< QuoteInput />} />
        <Route path="editpos/:procurementTypeId" element={< BoqPosList />} />
        <Route path="poApproval/:procurementTypeId" element={< PoApprovalList />} />

        <Route path = "quoteInputExcelUpload/:VendorId" element={<QuoteInputExcelUpload />} />

        <Route path="editBoqpob2b/:Boqpob2bid" element={< Boqpob2bsForm />} />
        <Route path="tallybatches" element={< TallyBatchesForm />} />

        <Route path="rentDues" element={<RentDuesList />} />
        <Route path="mboqshortForms" element={<MBoqshortFormsList/>} />
        <Route path="addmboqshortForms" element={<MBoqshortFormsForm/>} />
        <Route path="editmboqshortForms/:BoqshortFormId" element={<MBoqshortFormsForm/>} />
        <Route path="mUOMS" element={<MUomsList />} />
        <Route path="addMUoms" element={<MUomsForm />} />
        <Route path="editMUoms/:Uomid" element={<MUomsForm />} />
        
        <Route path="mItems" element={<MItemsList/>} />
        <Route path="addmItem" element={<MItemForm/>} />
        <Route path="editmItem/:ItemId" element={<MItemForm/>} />

        <Route path="ChangePassword/:userId" element={<ResetPasswordForm/>} />

        <Route path="mboqshortForms/:BoqpackageId" element={<MBoqshortFormsList/>} />
        <Route path="addmboqshortForms/:BoqpackageId" element={<MBoqshortFormsForm/>} />
        <Route path="editmboqshortForms/:BoqpackageId/:BoqshortFormId" element={<MBoqshortFormsForm/>} />

        <Route path="mUomalternativeTexts/:Uomid" element={<MUomalternativeTextsList/>} />
        <Route path="addmUomalternativeTexts/:Uomid" element={<MUomalternativeTextsForm/>} />
        <Route path="editmUomalternativeTexts/:Uomid/:UomalternativeTextId" element={<MUomalternativeTextsForm/>} />
      </Route>

      {/* catch all for page not found */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AppRoutes;
