import {
  Link,
  Container,
  Box,
  Button,
  Checkbox,
  createTheme,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { userService } from "../../services/UserService";
import { globalService } from "../../services/GlobalService";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../utility/context/AuthContext";
import { AuthModel, LoginModel } from "../../models/UserModel";
import useForm from "../../utility/hooks/UseForm";
import { encryptionDecryptionService } from "../../services/EncryptionDecryptionService";
import Controls from "../../utility/Controls.tsx/Controls";
import { red } from "@mui/material/colors";

const Login: React.FC = () => {
  //const { auth, setAuth } = useAuth() as AuthContextType;
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const theme = createTheme();
  const [appSetting, setAppSetting] = useState<any>(null);

  useEffect(() => {
    initialChange();
  }, []);

  const initialChange = () => {
    if (auth?.Token) {
      localStorage.clear();
      setAuth(null);
    }
  }
  const validate: any = (fieldValues: LoginModel = values) => {
    let temp: any = { ...errors };
    if ("PasswordHash" in fieldValues)
      temp.Password = fieldValues.PasswordHash ? "" : "Password is required.";

    if ("UserName" in fieldValues)
      temp.UserName = fieldValues.UserName ? "" : "UserName is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(userService.initialLoginFieldValues, validate, null);

  const newUser = () => {
    setValues(userService.initialLoginFieldValues);
  };


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      let payload = {
        UserName: encryptionDecryptionService.encrypt(values.UserName),
        PasswordHash: encryptionDecryptionService.encrypt(values.PasswordHash)
      };
      userService.login(payload).then((response) => {
        if (response) {
          let result = response.data;

          if (result?.isSuccess) {
            const accessToken = result?.row?.JwtToken;

            // let roles: string[] = result?.row?.RoleNames;
            //dont comment this. Its used in AuthContext. Without localstorage, its unable to fetch auth

            localStorage.setItem("currentUser", JSON.stringify(result.row));

            setAuth({
              Token: accessToken,
              Id: result?.row?.Id,
              Name: result?.row?.FullName,
              UserName: result?.row?.UserName,
              Roles: result?.row?.RoleNames
            });
            localStorage.setItem("token", accessToken); //this is used in customAxios
            navigate("/");
            navigate(from, { replace: true });
            //setAuth1(true); //or conditional state
          } else {
            globalService.error(result.message);
          }
          //console.log(response.data);
        }
      });
    } else {
      globalService.error("Incorrect UserName/Password.");
    }
  };

  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     const data = new FormData(event.currentTarget);
  //     console.log({
  //       email: data.get("email"),
  //       password: data.get("password"),
  //     });
  //   };

  return (
    <>
      {/* <Container component="main" maxWidth="lg"> */}
      {/* <Typography variant="h3" align="center">
        ANJ The Next Wave
        </Typography> */}

      <Box
        sx={{
          margin: -1,
        }}
      >
        <Grid container
          justifyContent="center"
          alignItems="center"
          //className="login"
          sx={{
            height: '100vh',
            backgroundImage: 'url(/images/loginBg.jpg)',
            // backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <CssBaseline />
          {/* <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              // backgroundImage: "url(https://source.unsplash.com/random)",
              //
              backgroundImage: "url(/images/login.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          /> */}
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            component={Paper}
            elevation={10}
            square
            sx={{borderRadius:2, backgroundColor:"#efefef", opacity:0.8}}
          >
            <Box
              sx={{
                my: 3,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                className="headerImg"
                pt={0}
                component="img"
                justifyContent="flex-start" 
                sx={{
                  //maxHeight: { xs: 250, md: 300 }, // Decreased maxHeight for xs
                  //maxWidth: { xs: 70, md: 200 }, // Decreased maxWidth for xs
                }}
                alt=""
                alignItems="flex-start"
                src="/images/logo1.svg"
                style={{ cursor: "pointer", height: 100, marginLeft:"-56%" }}
              />

              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Controls.Input
                  required
                  id="UserName"
                  label="UserName"
                  name="UserName"
                  autoFocus
                  value={values.UserName}
                  onChange={handleInputChange}
                  error={errors.UserName}
                />
                <Controls.Input
                  margin="normal"
                  required
                  fullWidth
                  name="PasswordHash"
                  label="Password"
                  type="password"
                  value={values.PasswordHash}
                  id="PasswordHash"
                  autoComplete="current-password"
                  autoFocus
                  onChange={handleInputChange}
                  error={errors.PasswordHash}
                />
                {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                {/* <Grid container>
                 <Grid item xs>
                    <Link href="#" variant="body2" color="#16b0e3">
                      Forgot password?
                    </Link>
                  </Grid>
                   <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid> 
                </Grid>*/}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* </Container> */}
    </>
  );
};

export default Login;
