import { Stack,
    IconButton,
    Card,
    CardContent,
    Button,
    Typography,
    CardActions,} from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmDialog from "../helper/ConfirmDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BoqItemAlternativeTextService } from '../../services/BoqItemAlternativeTextervice';
import { PageTitleInfoRequestModel, PageTitleInfoResponseModel } from '../../models/BoqItemModel';
import { boqitemService } from '../../services/BoqItemService';

const BoqItemAlternativeTextList:React.FC = () => {
    const {BoqitemId}: any = useParams();
    const prevPgState = useLocation();

    const [boqItemAlternativeText, setBoqItemAlternativeText] = useState([]);
    const [titleInfo, setTitleInfo] = useState<PageTitleInfoResponseModel>(boqitemService.initialPageTitleInfoResponseValues);
    const [confirmDialog, setConfirmDialog] = useState({
      isOpen: false,
      title: "",
      item: "",
      onConfirm: () => {},
    });

    const navigate = useNavigate();
    useEffect(() => {
      getBoqItemAlternativeText();
      getPageTitle();
    }, []);

    const getBoqItemAlternativeText = () => {
        BoqItemAlternativeTextService.GetByBoqItemId(BoqitemId).then((response) => {
          let result = response.data;
          setBoqItemAlternativeText(result.list);
        });
      };
      const getPageTitle = () => {
        let model: PageTitleInfoRequestModel = {
          BoqItemId: BoqitemId,
          AttributeType: '',
          BoqSubTitleId: '',
          BoqTitleId: '',
          BoqPackageId: '',
        };
        boqitemService
          .getPageTitleInfo(model)
          .then((response) => {
            setTitleInfo(response.data.row);
          });
      };
       
  const itemAltTextcolumns: GridColDef[] = [
    { field: "BoqitemAlternativeTextName", headerName: "BOQ Item Alternative Text Name ", width: 130, flex: 1 },
    {
      field: "Active",
      headerName: "Active",
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            {/* {params.row.Active && "Yes"}
              {!params.row.Active && "No"} */}
            {params.row.Active && <DoneIcon color="secondary" />}
            {!params.row.Active && <CloseIcon />}
          </Stack>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              color="primary"
              aria-label="add an alarm"
               onClick={() => navigate("/editItemAlternativeText/" + BoqitemId + "/" + params.row.BoqitemAlternativeTextId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this Item Alternatives ?",
                  item: "You can't undo this operation",
                  onConfirm: () => {
                    removeBoqItemAlternativeText(params.row.BoqitemAlternativeTextId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const removeBoqItemAlternativeText = (BoqitemAlternativeTextId: any) => {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
  
      BoqItemAlternativeTextService.remove(BoqitemAlternativeTextId).then((response) => {
        let result = response.data;
        if (response) {
          //   globalService.success(result.message);
          getBoqItemAlternativeText();
        }
      });  
  }
  return (
   <>
   <Stack direction="row" spacing={0} justifyContent="space-between">
         <Typography variant="h5" align="center">
       BOQ Item Alternatives
        </Typography>
        {(titleInfo ) &&
          <Typography variant="body1"> <b>BOQ Package :</b> {titleInfo.BoqPackageName}
            <br />
            <b>BOQ Title Name :</b> {titleInfo.BoqTitleName}
            <br />
            <b> BOQ SubTitle Name: </b>{titleInfo.BoqSubTitleName}
            <br />
            <b> BOQ Item Name: </b>{titleInfo.BoqItemName}
            {/* <br />
            <b> BOQ Attribute: </b>{titleInfo.AttributeTypeName} */}
          </Typography>
        }
      </Stack>

      <Card>
      <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addItemAlternativeText/" + BoqitemId )}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BoqitemAlternativeTextId}
              rows={boqItemAlternativeText}
              columns={itemAltTextcolumns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    boqTitleId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
          </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back To List
            </Button>
          </Stack>
        </CardActions>

      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
   </>
  )
}
export default BoqItemAlternativeTextList
