import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { ZoneModel } from "../models/MZonesModel";

const initialFieldValues: ZoneModel = {
    ZoneId: Guid.EMPTY,
    ZoneName: '',
    Active: true
}

const url = "mZones";

function post(payload: ZoneModel) {
    return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}

function put(payload: ZoneModel) {
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(ZoneId: number) {
    return customAxios.get(`${url}/GetById/${ZoneId}`, axiosRetryConfig);
}

function getAll () {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

export const mZonesService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll
}