import { Guid } from "guid-typescript";

import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { BoqItemAlternativeTextModel } from "../models/BoqItemAlternativeTextModel";

const initialFieldValues: BoqItemAlternativeTextModel = {
    BoqitemAlternativeTextId: Guid.EMPTY,
    BoqitemId:"",
    BoqsubTitleId:"",
    BoqitemAlternativeTextName:"",
    Active: false,
}

const url = "MBoqItemAlternativeTexts";

function post(payload:BoqItemAlternativeTextModel ){
    
    return customAxios.post(`${url}/Add`,payload,axiosRetryConfig);
}
function put(payload: BoqItemAlternativeTextModel){
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}
function remove(id: number){
    return customAxios.delete(`${url}/${id}`,axiosRetryConfig);
}

function GetById(BoqitemAlternativeTextId: number){
    return customAxios.get(`${url}/GetById/${BoqitemAlternativeTextId}`,axiosRetryConfig);
}


// function GetByBoqsubTitleId(BoqsubTitleId: number){
//     return customAxios.get(`${url}/GetByBoqsubTitleId/${BoqsubTitleId}`,axiosRetryConfig);
// }

function GetByBoqItemId(BoqitemId: number){
    return customAxios.get(`${url}/GetByBoqItemId/${BoqitemId}`,axiosRetryConfig);
}


export const BoqItemAlternativeTextService = {
    initialFieldValues,
    post,
    put,
    remove,
    GetById,
    // GetByBoqtitleId,
    GetByBoqItemId
};