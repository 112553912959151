import { Autocomplete, TextField, FormControl, FormHelperText } from '@mui/material';
import { MultiSelectListModel } from '../../models/ApiResponse';

const MuiAutocomplete = (props: any) => {
  const { options, name, label, error, onChange, value, required, ...other } = props;
  return (
    <FormControl error={!!error} fullWidth>
      <Autocomplete
        options={options}
        size='small'
        getOptionLabel={(option: MultiSelectListModel) => { return option.label || ''; }}
        value={value || null}
        
        //open={false}
        name={name}
        onChange={(event, newValue) => onChange(newValue)}
        isOptionEqualToValue={(option: MultiSelectListModel, value: any) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required={required}
            variant="standard"
            helperText={error ? error : ''}
            autoComplete="new-name"
            error={!!error}
            // inputProps={{
            //   ...props.inputProps,
            //   autoComplete: 'new-password',  // Set a non-standard value
            //   form: { autoComplete: 'off' }, // Additional autofill prevention
            // }}
          // {...(error && { error: true, helperText: error })}
          />
        )}
        {...other}
      />
      {/* {error && <FormHelperText>{error}</FormHelperText>} */}
    </FormControl>
  );
};

export default MuiAutocomplete;

