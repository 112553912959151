import {
    Stack,
    IconButton,
    Card,
    CardContent,
    Button,
    Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { mItemsService } from "../../services/MItemsService"; 
const MItemsList: React.FC = () => {

    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { },
    });

    useEffect(() => {
        if(items.length === 0) getItems();
    }, []);

    const getItems = () => {
        mItemsService.getAll().then((response) => {
            let result = response.data;
            setItems(result.list);
        })
    }

    const ItemsColumns: GridColDef[] = [
        { field: "ItemType", headerName: "ItemType ", flex: 1,  },
        { field: "ItemName", headerName: "Item Name", flex: 1,  },
        { field: "Uomid", headerName: "UOM Name", flex: 1,
            valueGetter: (params) => params.row?.Uom.Uomname,
        },
        { field: "Hsncode", headerName: "HSN Code", flex: 1,  },
        { field: "TaxPerc", headerName: "Tax Perc ", width: 130, flex: 1,  },
        {
            field: "Active",
            headerName: "Active",
            width: 130,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack>
                        {params.row.Active && <DoneIcon color="success" />}
                        {!params.row.Active && <CloseIcon color="error" />}
                    </Stack>
                );
            },
        },
      
        {
            field: "Actions",
            headerName: "Actions",
            type: "number",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={0}>
                        <IconButton size="small"
                            color="primary"
                            aria-label="add an alarm"
                            onClick={() => navigate("/editmItem/" + params.row.ItemId)}
                        >
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small"
                            aria-label="delete" color="error"
                            onClick={() => {
                                
                                setConfirmDialog({
                                    isOpen: true,
                                    title: 'Are you sure to delete this Item ?',
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                        removeItem(params.row.ItemId);
                                    },
                                });
                            }}
                        >
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>

                    </Stack>
                );
            },
        },
    ];

    const removeItem = (ItemId: any) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });

        mItemsService.remove(ItemId).then((response) => {
            if (response) {
                getItems();
            }
        });
    };


    return (
        <>
            <Typography variant="h5" align="center">
                Items
            </Typography>
            <Card>
                <CardContent>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => navigate("/addmItem")}
                    >
                        Add Record
                    </Button>

                    <div style={{ width: "100%" }}>
                        <DataGrid
                            getRowId={(row) => row.ItemId}
                            rows={items}
                            columns={ItemsColumns}
                            columnHeaderHeight={30}
                            autoHeight={true}
                            // checkboxSelection
                            // disableRowSelectionOnClick
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}

                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns Id, the other columns will remain visible
                                        BoqshortFormId: false,
                                    },
                                },
                                pagination: { paginationModel: { pageSize: 50 } },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                        />

                    </div>

                </CardContent>
            </Card>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />

        </>
    )
}

export default MItemsList