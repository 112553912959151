import {
  CssBaseline,
  Container,
  Box,
  Toolbar,
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AuthContext } from "../../utility/context/AuthContext";
import { ROLES, config } from "../../utility/Config";
import { useLocation, useNavigate } from "react-router-dom";
// import Index from "./Index";

const Home: React.FC = () => {
  const { auth } = React.useContext(AuthContext);
  const navigate = useNavigate();
  let count = 0;
  useEffect(() => {
    
    console.log('Count=' + ++count);
    GoToHome();
  }, []);

  function GoToHome() {
    if (auth && auth.Roles) {
      if (auth.Roles.some((x) => x === ROLES.Admin)) {
        navigate("/boqdashboard");
      } 
       else if (auth.Roles.some((x) => x === ROLES.Vendor)) {
        navigate("/quoteInputForVendor");
      } else if (auth.Roles.some((x) => x === ROLES.Accounts)) {
        navigate("/expansedashboard");
      }
      else if (auth.Roles.some((x) => x === ROLES.Expense)) {
        navigate("/expansedashboard");
      }
      else if (auth.Roles.some((x) => x === ROLES.BOQ_Manager)) {
        navigate("/boqPackages");
      }
    } else {
      navigate("/");
    }
  }

  return (
    <>
      <img src="images/banner.jpg" width="100%" />
      <React.Fragment>
        <CssBaseline />
        <Container>
        <Box>
            <>
              <Typography align="justify" paddingBottom={2}>
                <b style={{ fontSize: "20px" }}>Next Wave</b><br />
                After being in the Interiors Industry for more than 40 years,
                and often pioneering the Build Concepts, and introducing global
                Design Concepts in India, we asked ourselves, “What Next?” We
                realized that globally Workspaces are evolving, and in India we
                have to be the harbingers of that change. We, as experts in
                Workspace Design and Build, are the torchbearers of the ‘Next’
                in the way people interact, use technology, work and grow.
                Bringing the best and the right global work practices, workspace
                designs and the build execution… That’s The Next Wave at ANJ –
                Lead the change. We have identified 5 pillars where we and you,
                together, can make a difference for the better – the way we
                think, the approach, and execute the ‘Next’ concepts.{" "}
              </Typography>
            
              <Typography align="justify" paddingBottom={2}>
                <b style={{ fontSize: "20px" }}>The 5 focus areas are:</b><br />
                1. Future Workspaces <br />
                2. Technology <br />
                3. Transparency <br />
                4. Teamwork and <br />
                5. Sustainability.<br />
              </Typography>
              <Typography align="justify" paddingBottom={2}>
                <b style={{ fontSize: "20px" }}>Future workspace</b><br />
                The way we work, to the workspaces we create, and the technology
                we put into the workspaces, that’s the Next in Design and Build.
              </Typography>

              <Typography align="justify" paddingBottom={2}>
                <b style={{ fontSize: "20px" }}>Technology</b><br />
                Adapting and using traditional worker’s skills with advanced
                machinery in our state-of-the-art factory, and experimenting and
                innovating in our innovation lab.{" "}
              </Typography>

              <Typography align="justify" paddingBottom={2}>
                <b style={{ fontSize: "20px" }}>Team Work</b><br />
                With The Next Wave initiatives we are metamorphosing into an
                employee centric organisation with a focus on Diversity,
                Recognition, Intrapreneurship and Well-being.
              </Typography>

              <Typography align="justify" paddingBottom={2}>
                <b style={{ fontSize: "20px" }}>Sustainability</b><br />
                Working on hands-on sustainable practices are a part of The Next
                Wave initiatives. We do it by conserving energy at work sites,
                using green materials in projects, planting saplings, cleaning
                up beaches, etc. are just a few of our sustainability
                initiatives at ANJ. Below videos capture a few of The Next Wave
                initiatives and possibilities we at ANJ are putting in practice.{" "}
              </Typography>
            </>
          </Box>
        </Container>
      </React.Fragment>
    </>
  );
};

export default Home;
