import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  Divider,
  DialogProps,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  CardHeader,
  CardActions,
  Link,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { expenseDtlsService } from "../../services/ExpenseDtlsService";
import { ExpenseHdrsService } from "../../services/ExpenseHdrsService";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useForm from "../../utility/hooks/UseForm";
import { ROLES, config } from "../../utility/Config";
import { AuthContext } from "../../utility/context/AuthContext";
import { globalService } from "../../services/GlobalService";
import ExpenseDtlsForm from "./ExpenseDtlsForm";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { expenseDtlFileService } from "../../services/ExpenseDtlFileService";

const ExpenseDtlsList = (props: any) => {
  //ExpenseHdrId is also coming in prop
  const { callFrom } = props;
  const [expenseDtls, setExpenseDtls] = useState([]);
  const { ExpenseHdrId }: any = useParams();
  //use 'expenseHeaderId' only this in ur api's
  let expenseHeaderId = callFrom ? props.ExpenseHdrId : ExpenseHdrId;
  const [expenseHdr, setExpenseHdr] = useState<any>(
    ExpenseHdrsService.initialFieldValues
  );

  const navigate = useNavigate();
  const { auth } = React.useContext(AuthContext);
  // const isAdmin = globalService.roleMatch([ROLES.Admin], auth);
  const isAccounts = globalService.roleMatch([ROLES.Accounts], auth);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [expenseDtlOpen, setExpenseDtlOpen] = React.useState(false);
  const [expenseDtlId, setExpenseDtlId] = React.useState(null);
  const [expenseDtlDocOpen, setExpenseDtlDocOpen] = React.useState(false);
  const [expenseDtlFiles, setExpenseDtlFiles] = useState([]);



  const openExpenseDtlDocDialog = (ExpenseDtlId: any) => {
    setExpenseDtlDocOpen(true);
    getExpenseDtlFiles(ExpenseDtlId);
  };

  const closeExpenseDtlDocDialog = () => {
    setExpenseDtlDocOpen(false);
  };

  const openExpenseDtlDialog = (expenseDtlId: any) => {
    setExpenseDtlId(expenseDtlId);
    setExpenseDtlOpen(true);
  };
  const closeExpenseDtlDialog = () => {
    setExpenseDtlOpen(false);
  };

  useEffect(() => {
    getExpenseHdrId(expenseHeaderId);
    getExpenseDtls();
  }, [expenseHeaderId]);

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      expenseDtlsService.initialFieldValues,
      validate,
      props.setCurrentId
    );

  const getExpenseDtls = () => {
    expenseDtlsService.getAllByExpenseHdr(expenseHeaderId).then((response) => {
      let result = response.data;
      setExpenseDtls(result.list);
    props.refreshExpenseHeader()
    });
  };

  const ScrollableContent = ({ value }: any) => (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>{value}</div>
  );

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(`${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`, "_blank");
  };


  
  const removeExpenseDtls = (ExpenseDtlId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    expenseDtlsService.remove(ExpenseDtlId).then((response) => {
      if (response) {
        getExpenseDtls();
      }
    });
  };

  const getExpenseHdrId = (ExpenseHdrId: any) => {
    ExpenseHdrsService.getById(ExpenseHdrId).then((response) => {
      if (response) {
        let result = response.data;
        setExpenseHdr(result.row);

        values.ExpenseHdrStatusId = result.row.ExpenseHdrStatusId;
      }
    });
  };


  const getExpenseDtlFiles = (ExpenseDtlId: any) => {
    values.ExpenseDtlId = ExpenseDtlId;
    expenseDtlFileService
      .getAllFileByExpenseDtl(values.ExpenseDtlId)
      .then((response) => {
        let result = response.data;
        setExpenseDtlFiles(result.list);
        values.FileName = result.list.FileName;
      });
  };


  const expenseDtlsColumns: GridColDef[] = [
    {
      field: "Files",
      headerName: "Files",
      width: 120,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <IconButton
              size="small"
              color="primary"
              aria-label="edit expense"
              onClick={
                () => {
                  openExpenseDtlDocDialog(params.row.ExpenseDtlId);
                }
              }
            >
              <AttachFileIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "ExpenseHeadName",
      headerName: "Expense",
      flex: 1,
    //  width: 180,
      valueGetter: (params) => params.row.MExpenseHeads?.ExpenseHeadName,
    },
    // {
    //   field: "ClientName", headerName: "Client", width: 170,
    //   valueGetter: (params) => params.row.ProjectDirectory?.Client?.ClientName,
    // },
    // {
    //   field: "SiteName", headerName: "Site", width: 130,
    //   valueGetter: (params) => params.row.ProjectDirectory?.Site?.SiteName,
    // },
    // {
    //   field: "ProjectShortName", headerName: "Project", width: 130,
    //   valueGetter: (params) => params.row.ProjectDirectory?.ProjectShortName,
    // },
    {
      field: "BasicAmount",
      headerName: "Basic Amount",
      width: 120,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    { field: "Cgst", headerName: "CGST", width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",},
    { field: "Sgst", headerName: "SGST", width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",},
    { field: "Igst", headerName: "IGST", width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",},
    { field: "Gsttotal", headerName: "GST Total", width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",  valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },},
    {
      field: "InvoiceAmt",
      headerName: "Invoice Amount",
      width: 120,
      headerAlign: "right",
      cellClassName: "cell-right",  valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    { field: "Remark", headerName: "Remark",  flex: 1, },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      width: 100,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            {!globalService.roleMatch([ROLES.Accounts], auth) ? (
              values.ExpenseHdrStatusId === 0 && (
                <>
                  <IconButton
                    size="small"
                    color="primary"
                    aria-label="edit expense"
                    onClick={
                      () => openExpenseDtlDialog(params.row.ExpenseDtlId)
                      // navigate(
                      //   "/editExpenseDtls/" +
                      //     expenseHeaderId +
                      //     "/" +
                      //     params.row.ExpenseDtlId
                      // )
                    }
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  {/* <IconButton
                  size="small"
                  aria-label="delete"
                  color="error"
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: 'Are you sure to delete this Expense Detail?',
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                    //    removeExpenseDtls(params.row.ExpenseDtlId);
                      },
                    });
                  } }
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton> */}
                </>
              )
            ) :
              <>
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="edit expense"
                  onClick={
                    () => openExpenseDtlDialog(params.row.ExpenseDtlId)
                  }
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </>
            }

          </Stack>
        );
      },
    },

    // {
    //   field: "QueryInfo",
    //   headerName: "Query Info",
    //   width: 130,
    //   flex: 1,
    //   headerClassName: "bold-font",
    //   renderCell: (params) => {
    //     return (
    //       <Stack>
    //         <Button
    //           className="btnGrid"
    //           variant="contained"
    //           onClick={() =>
    //             navigate("/expenseQueries/"
    //               + params.row.ExpenseHdrId,
    //               {
    //                 state: {
    //                   row: params.row,
    //                 },
    //               })
    //           }
    //         >
    //           Query Info
    //         </Button>
    //       </Stack>
    //     );
    //   },

    // },
  ];

  const expenseDtlFileColumns: GridColDef[] = [
    {
      field: "ExpenseHeadName",
      headerName: "Expense Head Name",
      flex: 1,
      valueGetter: (params) => params.row.ExpenseDtl?.MExpenseHeads?.ExpenseHeadName,
    },
  
    {
      field: "FileName",
      headerName: "File Name",
      width: 500,
      renderCell: (params) => (
        <>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleFileClick(event, params.row)}
          >
            {params.row.FileName}
          </Link>
      
        </>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },

  ];

  return (
    <>
      {!callFrom && (
        <Typography variant="h5" align="center">
          Expense Details
        </Typography>
      )}
      <Card>
        {callFrom && <CardHeader title="Expense Details" />}
        <CardContent>
          {!callFrom && expenseHdr && expenseHdr.ExpenseVoucherNo && (
            <fieldset style={{ marginTop: 8, marginBottom: 12 }}>
              <legend>Project Directory</legend>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <Typography className="label">Project</Typography>
                  <Typography variant="body2">{expenseHdr?.ProjectDirectory?.Client?.ClientName}-{expenseHdr?.ProjectDirectory?.Site?.SiteName}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography className="label">Voucher No</Typography>
                  <Typography variant="body2">
                    {expenseHdr?.ExpenseVoucherNo}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography className="label">Voucher Dt</Typography>
                  <Typography variant="body2">
                    {expenseHdr
                      ? dayjs(expenseHdr.ExpenseVoucherDate).format(
                        "DD-MMM-YYYY"
                      )
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography className="label">Status</Typography>
                  <Typography variant="body2">
                    {expenseHdr?.ExpenseHdrStatus}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography className="label">Total Amount</Typography>
                  <Typography variant="body2">
                   {globalService.formatToIndianCurrency(expenseHdr?.TotInvoiceAmt)}
                    
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className="label">Expense Description</Typography>
                  <ScrollableContent value={expenseHdr?.ExpenseDescription} />
                </Grid>
              </Grid>
            </fieldset>
          )}

          <Stack spacing={2} direction="row">
            {values.ExpenseHdrStatusId === 0 && (
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                //onClick={() => navigate("/addExpenseDtls/" + expenseHeaderId)}
                onClick={() => openExpenseDtlDialog(null)}
              >
                Expense Detail
              </Button>
            )}
            {/* <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/expenseQueries/" + expenseHeaderId)
                  // + params.row.ExpenseHdrId,
                  // {
                  //   state: {
                  //     row: params.row,
                  //   },
                  // })
              }
            >
              Query Info
            </Button> */}
          </Stack>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.ExpenseDtlId}
              rows={expenseDtls}
              columns={expenseDtlsColumns}
              columnHeaderHeight={30}
              autoHeight={true}
              // checkboxSelection
              // disableRowSelectionOnClick
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    ExpenseDtlId: false,
                    Cgst: globalService.roleMatch([ROLES.Accounts], auth) ? true : false,
                    Sgst: globalService.roleMatch([ROLES.Accounts], auth) ? true : false,
                    Igst: globalService.roleMatch([ROLES.Accounts], auth) ? true : false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            //onClick={() => navigate("/expenses")}
            //onClick={() => goToExpenses(params.row)}
            >
              Back
            </Button>
          </Stack>
        </CardActions>
      </Card>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={expenseDtlOpen}
        onClose={closeExpenseDtlDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {expenseDtlId ? "Edit " : "Add "} Expense Detail
          {/* <i style={{ fontSize: 15 }}>{selectedFolderName}</i> */}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeExpenseDtlDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
          <>
            <ExpenseDtlsForm
              ExpenseDtlId={expenseDtlId}
              ExpenseHdrId={expenseHeaderId}
              onCloseExpenseDtlDialog={closeExpenseDtlDialog}
              refreshExpenseDetails={getExpenseDtls}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeExpenseDtlDialog} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
              fullWidth={true}
              maxWidth={maxWidth}
              open={expenseDtlDocOpen}
              onClose={closeExpenseDtlDocDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Expense Detail Documents
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={closeExpenseDtlDocDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
                <>

                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.ExpenseDtlFileId}
                      rows={expenseDtlFiles}
                      columns={expenseDtlFileColumns}
                      columnHeaderHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                    />
                  </div>

                </>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeExpenseDtlDocDialog} variant="outlined">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

    </>
  );
};

export default ExpenseDtlsList;
