import { Guid } from "guid-typescript";
import { EditForPoModel, PaymentTermsModel } from "../models/MPaymentTermsModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: PaymentTermsModel = {
  PaymentTermId: Guid.EMPTY,
  PaymentTerm: "",
  Active: true,
  PaymentTermTypeId: "",
  PaymentTermType: "",
};

const url = "mPaymentTerms";

function post(payload: PaymentTermsModel) {
  payload.PaymentTermTypeId = Number(payload.PaymentTermTypeId);
  return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}

function put(payload: PaymentTermsModel) {
  payload.PaymentTermTypeId = Number(payload.PaymentTermTypeId);
  return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}

function remove(id: number) {
  return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(PaymentTermId: number) {
  return customAxios.get(`${url}/GetById/${PaymentTermId}`, axiosRetryConfig);
}

function getAll() {
  return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}
function getAllRFQPaymentTerms () {
    return customAxios.get(`${url}/GetAllRFQPaymentTerms`, axiosRetryConfig);
}

function getAllPOPaymentTerms () {
  return customAxios.get(`${url}/GetAllPOPaymentTerms`, axiosRetryConfig);
}

function getPaymentTermsTypeSelectList() {
  return customAxios.get(
    `${url}/GetPaymentTermsTypeSelectList`,
    axiosRetryConfig
  );
}
function getPaymentTermsByBoqpob2bId(boqpob2bId: any) {
  
  return customAxios.get(`${url}/GetPaymentTermsByBoqpob2bId/${boqpob2bId}`, axiosRetryConfig);
}
function getPaymentTermsByBoqrfqId(boqrfqId: any) {
  
  return customAxios.get(`${url}/GetPaymentTermsByBoqrfqId/${boqrfqId}`, axiosRetryConfig);
}

function GetMPaymentTermsForPoEdit (boqpob2bId : any) {
  return customAxios.get(`${url}/GetMPaymentTermsForPoEdit/${boqpob2bId}`, axiosRetryConfig);
}

function EditForPo(payload: EditForPoModel) {
  return customAxios.post(`${url}/EditForPo`, payload,axiosRetryConfig);
}
export const mPaymentTermsService = {
    initialFieldValues,
    post,
    put,
    getAllRFQPaymentTerms,
    getAllPOPaymentTerms,
    remove,
    getById,
    getAll,
    GetMPaymentTermsForPoEdit,
    getPaymentTermsTypeSelectList,
    getPaymentTermsByBoqpob2bId,
    getPaymentTermsByBoqrfqId,
    EditForPo,
}

