import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ZoneApproverService } from "../../services/ZoneApproverService";
import { mZonesService } from "../../services/MZoneService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ZoneApproverList = () => {
  const { ZoneId }: any = useParams();
  const navigate = useNavigate();
  const [zoneApprovers, setZoneApprovers] = useState([]);
  const [zoneName, setZoneName] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });

  useEffect(() => {
    getZoneApprovers();
    if (ZoneId) {
      getZone();
    }
  }, []);

  const getZone = () => {
    mZonesService.getById(ZoneId).then((response) => {
      let result = response.data.list;
      setZoneName(result.ZoneName);
    });
  };

  const getZoneApprovers = () => {
    ZoneApproverService.getAll(ZoneId).then((response) => {

      let result = response.data;
      setZoneApprovers(result.list);
    });
  };

  const zoneApproversColumns: GridColDef[] = [
    {
      field: "CreatedById", headerName: "User Name", width: 150,
      valueGetter: (params) => params.row.ApproverUser?.UserName,
    },
    {
      field: "EmpName",
      headerName: "Employee Name",
      width: 130,
      flex: 1,
      
      valueGetter: (params) => params.row.ApproverUser?.MEmployees[0]?.EmpName,
    },
    {
      field: "EmpCode",
      headerName: "Employee Code",
      width: 130,
      flex: 1,
      
      valueGetter: (params) => params.row.ApproverUser?.MEmployees[0]?.EmpCode,

    },
    {
      field: "Mobile",
      headerName: "Mobile",
      width: 130,
      flex: 1,
      
      valueGetter: (params) => params.row.ApproverUser?.MEmployees[0]?.Mobile,

    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            {/* <IconButton size="small"
                  color="primary"
                  aria-label="add an alarm"
                  onClick={() => navigate("/editDepartment/" + params.row.DepartmentId)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton> */}
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this Department` ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeZoneApprover(params.row.ZoneApproversId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const removeZoneApprover = (ZoneApproversId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    ZoneApproverService.remove(ZoneApproversId).then((response) => {
      if (response) {
        getZoneApprovers();
      }
    });
  };

  return (
    <>
      <Typography variant="h5" align="center">
        Zone Approvers of <b>{zoneName}</b>
      </Typography>

      <Card>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addzoneApprovers/" + ZoneId)}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.ZoneApproversId}
              rows={zoneApprovers}
              columns={zoneApproversColumns}
              columnHeaderHeight={30}
              autoHeight={true}
              // checkboxSelection
              // disableRowSelectionOnClick
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    ZoneApproversId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/mZones")}
          >
            Back
          </Button>

        </CardContent>
      </Card>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default ZoneApproverList;
