import { Guid } from "guid-typescript";
import { BoqItemModel, PageTitleInfoRequestModel, PageTitleInfoResponseModel } from "../models/BoqItemModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqItemModel = {
    BoqitemId: Guid.EMPTY,
    BoqsubTitleId: "",
    BoqtitleId: "",
    BoqitemName: "",
    Active: false,
}

const initialPageTitleInfoResponseValues: PageTitleInfoResponseModel = {
    BoqItemName: "",
    BoqSubTitleName: "",
    BoqTitleName: "",
    BoqPackageName: "",
    AttributeTypeName: '',
}

const url = "MBoqitems";

function getAll() {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

function post(payload: BoqItemModel) {
    return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}
function put(payload: BoqItemModel) {
    return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}
function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getAllBoqsubTitleId(boqsubTitleId: number) {
    return customAxios.get(`${url}/GetAll/${boqsubTitleId}`, axiosRetryConfig);
}

function getByIdboqitemId(boqitemId: number) {
    return customAxios.get(`${url}/GetById/${boqitemId}`, axiosRetryConfig);
}

function getAllMBoqitemsByPackageID(boqpackageId: number) {
    return customAxios.get(`${url}/GetAllMBoqitemsByPackageID/${boqpackageId}`, axiosRetryConfig);
}

function getPageTitleInfo(payload: PageTitleInfoRequestModel) {
    return customAxios.post(`${url}/GetPageTitleInfo`, payload, axiosRetryConfig);
}

function getItemSelectListBySubTitleId(subTitleId: string) {
    return customAxios.get(`${url}/GetItemSelectListBySubTitleId/${subTitleId}`, axiosRetryConfig);
}

export const boqitemService = {
    initialFieldValues,
    initialPageTitleInfoResponseValues,
    getAll,
    post,
    put,
    remove,
    getAllBoqsubTitleId,
    getByIdboqitemId,
    getAllMBoqitemsByPackageID,
    getPageTitleInfo,
    getItemSelectListBySubTitleId
};