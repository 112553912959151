import {
  InsertDataForRFQModel,
  RFQBOQItemDtlsForVendors,
  RFQBOQItemDtlsForVendorsResultModel,
  RequestForQuoteModel,
  UpdateIsSelectedResultDTO,
} from "../models/RequestForQuoteModel";
import { config } from "../utility/Config";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialRFQBOQItemDtlsForVendorsValues: RFQBOQItemDtlsForVendors = {
  BoqrfqitemId: "",
  BOQUploadId: "",
  ClientName: "",
  SiteName: "",
  Project: "",
  Title: "",
  VendorMake: "",
  SubTitle: "",
  ItemName: "",
  Specifications: "",
  Location: "",
  Unit: "",
  Qty: 0,
  SupplyRate: 0,
  InstallationRate: 0,
  Rate: 0,
  BOQSize: "",
  BOQMake: "",
  BOQRFQStatus: "",
  BOQRequisitionHdrStatus: "",
  RateSubmittedOn: null,
  Remarks: "",
  VendorRemarks: "",
  ANJRemarks: "",
  IsSelected: false,
  BOQRFQID: "",
};

const initialFieldValues: RequestForQuoteModel = {
  //VendorId: '',
  BoqrfqitemId: "",
  Boqrfqid: "",
  BoquploadId: "",
  SupplyRate: 0,
  InstallationRate: 0,
  Rate: 0,
  VendorRemarks: "",
  VendorMake: "",
  Anjremarks: "",
  BoqrfqitemStatusId: "",
  BoqrfqitemStatus: "",
  RateSubmittedOn: null,
  OrgQuotedSupplyRate: 0,
  OrgQuotedInstallationRate: 0,
  OrgQuotedRate: 0,
};

const initialInsertDataForRFQValues: InsertDataForRFQModel = {
  VendorIDs: [],
  BOQUploadIDs: [],
  CreatedByID: "",
  BOQPackageID: "",
  ProcurementTypeID: "",
  PaymentTermIDs: [],
  BOQDocFileIDs: [],
  ProjectDirectoryID: "",
};

const initialUpdateIsSelectedValues: UpdateIsSelectedResultDTO = {
  BoqrfqitemId: "",
  BOQUploadId: "",
};
const initialRFQBOQItemDtlsForVendorsResultValues: RFQBOQItemDtlsForVendorsResultModel =
  {
    vendorID: "",
    boqrfqId: "",
    boqrfqitemStatusId: "",
    FromDt: null,
    ToDt: null,
  };

const url = "/Boqrfqitems";

function getQuotesRcvdFromVendorForPOCreationNew(
  BOQUploadId: string,
  ShowQuotedRatesOnly?: boolean
) {
  let payload = {
    BOQUploadID: BOQUploadId,
    ShowQuotedRatesOnly: ShowQuotedRatesOnly,
  };
  return customAxios.post(
    `${url}/GetQuotesRcvdFromVendorForPOCreationNew`,
    payload,
    axiosRetryConfig
  );
}

function getRFQBOQItemDtlsForVendors(
  payload: RFQBOQItemDtlsForVendorsResultModel
) {
  return customAxios.post(
    `${url}/GetRFQBOQItemDtlsForVendors`,
    payload,
    axiosRetryConfig
  );
}

function getRFQs(vendorID: string) {
  return customAxios.get(`${url}/GetRFQs/${vendorID}`, axiosRetryConfig);
}

function put(payload: RequestForQuoteModel) {
  return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}

function post(payload: InsertDataForRFQModel) {
  return customAxios.post(
    `${url}/InsertDataForBOQRFQs`,
    payload,
    axiosRetryConfig
  );
}

function getByID(BoqrfqitemId: string) {
  return customAxios.get(`${url}/GetById/${BoqrfqitemId}`, axiosRetryConfig);
}

function editBOQRFQs(payloads: any[]) {
  //BoqrfqitemId,BOQUploadId, InstallationRate, SupplyRate, VendorRemarks, ANJRemarks ,VendorMake
  return customAxios.put(`${url}/EditBOQRFQs`, payloads, axiosRetryConfig);
}
function updateIsSelected(payload: UpdateIsSelectedResultDTO) {
  return customAxios.post(`${url}/UpdateIsSelected`, payload, axiosRetryConfig);
}
function selectRFQs(vendorID: string) {
  return customAxios.get(`${url}/SelectRFQs/${vendorID}`, axiosRetryConfig);
}

function uploadExcel(files: File[], payload: any) {
  
  const formData = new FormData();
  if (files != null && files.length > 0) {
    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
  }

  formData.append("data", JSON.stringify(payload));
  return customAxios.post(
    `${url}/UploadExcel`,
    formData,
    config.formDataConfig
  );
}

export const RFQService = {
  initialRFQBOQItemDtlsForVendorsValues,
  initialFieldValues,
  initialInsertDataForRFQValues,
  initialUpdateIsSelectedValues,
  getQuotesRcvdFromVendorForPOCreationNew,
  getRFQBOQItemDtlsForVendors,
  put,
  post,
  initialRFQBOQItemDtlsForVendorsResultValues,
  getByID,
  editBOQRFQs,
  updateIsSelected,
  getRFQs,
  selectRFQs,
  uploadExcel,
};
