import React, { useEffect } from "react";
import { Grid, TextField, CardActions, Card, CardContent, Button, Typography, Stack } from "@mui/material";
import Title from '../helper/Title'
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { mUomsService } from "../../services/MUomsService";
import { MUomsModel } from "../../models/MUomsModel";


const MUomsForm = (...props: any) =>{

  let navigate = useNavigate();
  const { Uomid } : any = useParams();
  const mode = Uomid ? "Edit" : "Create";

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    if ("Uomname" in fieldValues)
      temp.Uomname = fieldValues.Uomname ? "" : "UOM Name is required.";
    if ("Uomcode" in fieldValues)
      temp.Uomcode = fieldValues.Uomcode ? "" : "UOM Code is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mUomsService.initialFieldValues, validate, props.setCurrentId);

  const newUser = () => {
    setValues(mUomsService.initialFieldValues);
  };

  function setFormValue(model: MUomsModel) {
    let newModel = {
      Uomid: model.Uomid,
      Uomname: model.Uomname,
      Uomcode: model.Uomcode,
      Active: model.Active,
    }
    return newModel;
  }

  useEffect(() => {
    if (Uomid) {
      getUomName();
      setErrors({});
    } else newUser();
  }, [Uomid]);

  const getUomName = () => {
    mUomsService.getById(Uomid).then((response) => {
      
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    })
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (Uomid) {
        mUomsService.put(values).then((response: any) => {
          
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/mUOMS");
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        mUomsService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              resetForm();
              navigate("/mUOMS");
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  return (
    <>
      <Typography variant="h5"  align="center">
        UOMS
      </Typography>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="UOM Name"
                    required
                    name="Uomname"
                    value={values.Uomname}
                    onChange={handleInputChange}
                    error={errors.Uomname}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="UOM Code"
                    required
                    name="Uomcode"
                    value={values.Uomcode}
                    onChange={handleInputChange}
                    error={errors.Uomcode}
                  />
                </Grid>
            
                <Grid item xs={12} sm={4} className={mode !== "Edit" ? "hidden" : ""}>
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">Submit</Button>
              <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/mUOMS">Back </Button>
            </Stack>

          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default MUomsForm