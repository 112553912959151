import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";

import { BoqItemModel } from "../../models/BoqItemModel";
import { useEffect } from "react";
import Controls from "../../utility/Controls.tsx/Controls";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useForm from "../../utility/hooks/UseForm";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  MultiSelectListModel,
  SelectListModel,
} from "../../models/ApiResponse";
import { boqTitleService } from "../../services/BoqTitleService";
import { boqSubTitleService } from "../../services/BoqSubTitleService";
import { boqPackageService } from "../../services/BoqPackageService";
import { BoqposService } from "../../services/BoqPosService";
import { insertDataFBoqpob2bsmodel } from "../../models/BoqposModel";
import dayjs from "dayjs";
import { mSiteService } from "../../services/MSiteService";
import { ourOfficesService } from "../../services/OurOfficesService";

const Boqpob2bsForm = (...props: any) => {
  const { Boqpob2bid }: any = useParams();
  const [ourOfficeList, setOurOfficeList] = useState<MultiSelectListModel[]>(
    []
  );
  const [sitnamenameList, setSiteNameList] = useState<MultiSelectListModel[]>(
    []
  );
  const [ourOffice, setOurOffice] = useState<any>(
    ourOfficesService.initialFieldValues
  );
  const [shipToSite, setShipToSite] = useState<any>(
    mSiteService.initialFieldValues
  );

  // useState<any>(
  //     societyBillsService.initialFieldValuesforinsertDataFBoqpob2bsmodel
  //   );
  const [Boqpob2bId, setBoqpob2bId] = useState<any>(
    BoqposService.initialFieldValuesforinsertDataFBoqpob2bsmodel
  );
  let navigate = useNavigate();
  const validate = (fieldValues: BoqItemModel = values) => {
    let temp: any = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      BoqposService.initialFieldValuesforinsertDataFBoqpob2bsmodel,
      validate,
      Boqpob2bid
    );

  const newItem = () => {
    setValues(BoqposService.initialFieldValuesforinsertDataFBoqpob2bsmodel);
  };
  function setFormValue(model: insertDataFBoqpob2bsmodel) {
    let newModel = {
      Boqpob2bid: model.Boqpob2bid,
      Boqrfqid: model.Boqrfqid,
      BillToOurOfficesId: model.BillToOurOfficesId,
      ShipToSiteId: model.ShipToSiteId,
      ProjectDirectoryId: model.ProjectDirectoryId,
      Boqpob2bno: model.Boqpob2bno,
      SerialNo: model.SerialNo || " ",
      Boqpob2bdate: model.Boqpob2bdate,
      VendorId: model.VendorId,
      Remarks: model.Remarks,
      Boqpob2bstatusId: model.Boqpob2bstatusId,
      ProcurementTypeId: model.ProcurementTypeId,
      ApprovedRejectedOn: model.ApprovedRejectedOn,
      RejectedRemarks: model.RejectedRemarks,
      BoqpackageId: model.BoqpackageId,
      PaymentTerms: model.PaymentTerms,
      ExpectedDateOfDelivery: model.ExpectedDateOfDelivery,
      ApprovedRejectedByUserId: model.ApprovedRejectedByUserId || "",
      Boqpob2bstatus: model.Boqpob2bstatus,
      PoGrossTotal: model.PoGrossTotal || "",
    };
    return newModel;
  }
  const getSiteNameList = () => {
    mSiteService.getSiteNameSelectList().then((response) => {
      setSiteNameList(response.data);
    });
  };
  const getOurOfficeList = () => {
    ourOfficesService.getOurOfficeSelectList().then((response) => {
      setOurOfficeList(response.data);
    });
  };
  useEffect(() => {
    if (sitnamenameList.length === 0) getSiteNameList();
    if (ourOfficeList.length === 0) getOurOfficeList();
    if (Boqpob2bid) {
      getBoqpob2bid(Boqpob2bid);
      setErrors({});
    } else newItem();
  }, [Boqpob2bid]);

  const getOurOffice = (ourOfficeId: any) => {
    ourOfficesService.getById(ourOfficeId).then((response) => {
      if (response) {
        let result = response.data;

        setOurOffice(result.list);
      }
    });
  };

  const getShipToSite = (SiteId: any) => {
    mSiteService.getById(SiteId).then((response) => {
      if (response) {
        let result = response.data;

        setShipToSite(result.list);
      }
    });
  };

  const getBoqpob2bid = (Boqpob2bid: any) => {
    BoqposService.getByIdBoqpob2bid(Boqpob2bid).then((response) => {
      if (response) {
        let result = response.data;

        // result.data.BoqtitleId = result.data.BoqsubTitle?.Boqtitle?.BoqtitleId;
        setBoqpob2bId(result.data);
        
        setValues(setFormValue(result.data));
        if (result.data.BillToOurOfficesId && result.data.ShipToSiteId) {
          var ourOfficeId = result.data.BillToOurOfficesId;
          var ShipToSiteId = result.data.ShipToSiteId;
          getOurOffice(ourOfficeId);
          getShipToSite(ShipToSiteId);
        }
      }
    });
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    BoqposService.put(values).then((response: any) => {
      let result = response.data;
      if (response) {
        globalService.success(result.message);
        navigate(-1);
      } else {
        globalService.error(result.message);
      }
    });
  };

  return (
    <div>
      <Typography variant="h5" align="center">
        Edit BOQ PO's
      </Typography>

      <>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={8} sm={4}>
                    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                      <Typography className="label">Project </Typography>
                      <Typography variant="body2">
                        {Boqpob2bId.ProjectDirectory?.ProjectShortName}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={4}>
                    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                      <Typography className="label"> PO No. </Typography>
                      <Typography variant="body2">
                        {Boqpob2bId.Boqpob2bno}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={4}>
                    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                      <Typography className="label"> PO Date </Typography>
                      <Typography variant="body2">
                        {dayjs(Boqpob2bId.Boqpob2bdate).format("DD-MMM-YYYY")}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={8} sm={4}>
                    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                      <Typography className="label">Vendor Name </Typography>
                      <Typography variant="body2">
                        {Boqpob2bId.Vendor?.VendorName}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={4}>
                    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                      <Typography className="label">PO Status </Typography>
                      <Typography variant="body2">
                        {Boqpob2bId.Boqpob2bstatus}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={4}>
                    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                      <Typography className="label">CreatedOn </Typography>
                      <Typography variant="body2">
                        {dayjs(Boqpob2bId.CreatedOn).format("DD-MM-YYYY")}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </React.Fragment>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Controls.MuiAutocomplete
                      options={ourOfficeList}
                      label="Bill To Our Office"
                      required
                      error={errors.BillToOurOfficesId}
                      onChange={(event: MultiSelectListModel) => {
                        const ourOfficeId = event ? event.value : "";
                        handleInputChange({
                          target: {
                            name: "BillToOurOfficesId",
                            value: ourOfficeId,
                          },
                        });
                        getOurOffice(ourOfficeId);
                        // }
                      }}
                      value={
                        ourOfficeList.find(
                          (item: any) =>
                            item.value === values.BillToOurOfficesId
                        ) || null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controls.MuiAutocomplete
                      options={sitnamenameList}
                      label="Ship To Site"
                      required
                      error={errors.ShipToSiteId}
                      onChange={(event: MultiSelectListModel) => {
                        const ShipToSiteId = event ? event.value : "";
                        handleInputChange({
                          target: {
                            name: "ShipToSiteId",
                            value: ShipToSiteId,
                          },
                        });
                        getShipToSite(ShipToSiteId);
                      }}
                      value={
                        sitnamenameList.find(
                          (item: any) => item.value === values.ShipToSiteId
                        ) || null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controls.ReactDatePicker
                      label="Expected Date Of Delivery"
                      value={values.ExpectedDateOfDelivery}
                      onChange={(date: Date) =>
                        handleInputChange({
                          target: {
                            value:
                              globalService.getISODateOnlyForDatePicker(date),
                            name: "ExpectedDateOfDelivery",
                          },
                        })
                      }
                      minDate={Boqpob2bId.Boqpob2bdate}
                      error={errors.ExpectedDateOfDelivery}
                    />
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: 900,
                    }}
                  >
                    <fieldset
                      style={{
                        flex: 1,

                        marginLeft: 10,
                        marginTop: 8,
                        marginBottom: 12,
                      }}
                    >
                      <legend>Bill To Our Office</legend>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography className="label">Address</Typography>
                          <Typography variant="body2">
                            {ourOffice?.Address1}
                          </Typography>

                          <Typography variant="body2">
                            {ourOffice?.Address2}
                          </Typography>

                          <Typography variant="body2">
                            {ourOffice?.Address3}
                          </Typography>

                          <Typography className="label">City</Typography>
                          <Typography variant="body2">
                            {ourOffice?.City}
                          </Typography>

                          <Grid item xs={12} sm={2}>
                            <Typography className="label">PinCode</Typography>
                            <Typography variant="body2">
                              {ourOffice?.PinCode}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Typography className="label">State</Typography>
                            <Typography variant="body2">
                              {ourOffice?.State?.State}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </fieldset>
                    <fieldset
                      style={{
                        flex: 1,
                        marginRight: 10,
                        marginTop: 8,
                        marginBottom: 12,
                      }}
                    >
                      <legend>Ship To Site</legend>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={8}>
                          <Typography className="label">Address</Typography>
                          <Typography variant="body2">
                            {shipToSite?.Address}
                          </Typography>

                          <Grid item xs={12} sm={2}>
                            <Typography className="label">State</Typography>
                            <Typography variant="body2">
                              {shipToSite?.State?.State}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </fieldset>
                  </div>
                  <Grid item xs={18} sm={8}>
                    <Controls.Input
                      required
                      label="Remarks"
                      name="Remarks"
                      multiline
                      rows={2}
                      value={values.Remarks}
                      onChange={handleInputChange}
                      error={errors.Remarks}
                    />
                  </Grid>
                  {/* <Grid item xs={20} sm={12}>
                    <Controls.Input
                      required
                      label="Term's And Conditions"
                      name="PaymentTerms"
                      multiline
                      rows={3}
                      value={values.PaymentTerms}
                      onChange={handleInputChange}
                      error={errors.PaymentTerms}
                    />
                  </Grid> */}
                </Grid>
              </React.Fragment>
            </CardContent>
          </Card>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back To List
              </Button>
            </Stack>
          </CardActions>
        </form>
      </>
    </div>
  );
};

export default Boqpob2bsForm;
