import React, { useState, useEffect } from "react";

import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  CardActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmDialog from "../helper/ConfirmDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { boqtitleAlternativeTextService } from "../../services/BoqtitleAlternativeTextsService";
import { PageTitleInfoRequestModel, PageTitleInfoResponseModel } from "../../models/BoqItemModel";
import { boqitemService } from "../../services/BoqItemService";

const BoqtitleAlternativeTextsList: React.FC = () => {
  const [titleAlternatives, setTitleAlternative] = useState([]);
  const { BoqtitleId }: any = useParams();
  const [titleInfo, setTitleInfo] = useState<PageTitleInfoResponseModel>(boqitemService.initialPageTitleInfoResponseValues);
  const prevPgState = useLocation();
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '', onConfirm: () => { }})
  const navigate = useNavigate();
  useEffect(() => {
    getTitleAlternativeText();
    getPageTitle();
  }, []);

  const getTitleAlternativeText = () => {
    boqtitleAlternativeTextService.getAllByBoqtitleId(BoqtitleId).then((response) => {
      let result = response.data;
      setTitleAlternative(result.list);
    });
  };

  const getPageTitle = () => {
    let model: PageTitleInfoRequestModel = {
      BoqItemId: '',
      AttributeType: '',
      BoqSubTitleId:'',
      BoqTitleId: BoqtitleId,
      BoqPackageId: '',
    };
    boqitemService
      .getPageTitleInfo(model)
      .then((response) => {
        setTitleInfo(response.data.row);
      });
  };

  const columns: GridColDef[] = [
    { field: "BoqtitleAlternativeTextName", headerName: "BOQ Title Alternative Text ", width: 130, flex: 1 ,},
    {
      field: "Active",
      headerName: "Active",
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            {/* {params.row.Active && "Yes"}
            {!params.row.Active && "No"} */}
            {params.row.Active && <DoneIcon color="secondary" />}
            {!params.row.Active && <CloseIcon />}
          </Stack>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editboqtitleAlternativeText/" +
              BoqtitleId +
              "/" + params.row.BoqtitleAlternativeTextId)}
            >
              <EditIcon fontSize="inherit"  />
            </IconButton>
          <IconButton size="small"  aria-label="delete"  color="error"
            onClick={() => {

              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to delete this package ?',
                subTitle: "You can't undo this operation",
                onConfirm: () => { removetitleAlternativeText(params.row.BoqtitleAlternativeTextId) }
              })
            }}>
            <DeleteIcon fontSize="inherit"  />
          </IconButton>

          </Stack>
        );
      },
    },
  ];

  const removetitleAlternativeText = (BoqtitleAlternativeTextId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
    boqtitleAlternativeTextService.remove(BoqtitleAlternativeTextId).then((response) => {
        let result=response.data;
        if (response) {
         // globalService.success(result.message);
         getTitleAlternativeText();
        }
      });
  }

  return (
    <>
     
      <Stack direction="row" spacing={0} justifyContent="space-between">
        <Typography variant="h5" align="center">
        BOQ Title Alternative Text
        </Typography>
        {(titleInfo ) &&
          <Typography variant="body1">
            <b>BOQ Package :</b> {titleInfo.BoqPackageName}
            <br />
            <b>BOQ Title Name :</b> {titleInfo.BoqTitleName}
            {/* <br />
            <b> BOQ SubTitle Name: </b>{titleInfo.BoqSubTitleName} 
             <br />
            <b> BOQ Attribute: </b>{titleInfo.AttributeTypeName} */}
          </Typography>
        }
        <Typography> {}</Typography>
      </Stack>
      <Card>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addboqtitleAlternativeText/" + BoqtitleId )}
          >
            Add Record
          </Button>
          <div style={{width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BoqtitleAlternativeTextId}
              rows={titleAlternatives}
              columns={columns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    BoqtitleAlternativeTextId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/boqTitles/")}
            >
              Back To List
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default BoqtitleAlternativeTextsList