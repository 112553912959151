import { MMailerModel } from "../models/MMailerModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: MMailerModel = {
    VendorIds: "",
    BoqPob2bId: "",
    
}

const url = "MMailer";

function sendPOMailToVendor(payload:MMailerModel ){
    
    return customAxios.post(`${url}/SendPOMailToVendor`,payload,axiosRetryConfig);
}

export const mMailerService = {
    initialFieldValues,
    sendPOMailToVendor
}