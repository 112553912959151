import {
    Stack,
    IconButton,
    Card,
    CardContent,
    Button,
    Typography,
  } from "@mui/material";
  import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
  import React, { useEffect, useState } from 'react'
  import { useNavigate } from "react-router-dom";
  import EditIcon from "@mui/icons-material/Edit";
  import DeleteIcon from "@mui/icons-material/Delete";
  import DoneIcon from "@mui/icons-material/Done";
  import CloseIcon from "@mui/icons-material/Close";
  import ConfirmDialog from "../helper/ConfirmDialog";
  import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { mDepartmentsService } from "../../services/MDepartmentService";

const MDepartmentsList: React.FC = () => {

    const [departments,setDepartments] = useState([]);

    const navigate = useNavigate();

    const [confirmDialog, setConfirmDialog] = useState({
      isOpen: false,
      title: "",
      subTitle: "",
      onConfirm: () => { },
    });

    useEffect(() => {
        getDepartment();
    }, []);

    const getDepartment = () => {
        mDepartmentsService.getAll().then((response) => {
            let result =  response.data;
            setDepartments(result.list);
        })
    }


    const departmentColumns: GridColDef [] = [
        { field: "DepartmentName", headerName: "Department Name", width: 130, flex: 1,  },
        {
          field: "Active",
          headerName: "Active",
          width: 130,
          flex: 1,
          renderCell: (params) => {
            return (
              <Stack>
    
                {/* {params.row.Active && "Yes"}
                    {!params.row.Active && "No"} */}
                {params.row.Active && <DoneIcon color="success" />}
                {!params.row.Active && <CloseIcon color="error" />}
              </Stack>
            );
          },
        },
        {
          field: "Actions",
          headerName: "Actions",
          type: "number",
          flex: 1,
          renderCell: (params) => {
            return (
              <Stack direction="row" spacing={0}>
                <IconButton size="small"
                  color="primary"
                  aria-label="add an alarm"
                  onClick={() => navigate("/editDepartment/" + params.row.DepartmentId)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton size="small"
                  aria-label="delete" color="error"
                  onClick={() => {
                    
                    setConfirmDialog({
                      isOpen: true,
                      title: 'Are you sure to delete this Department` ?',
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        removeDepartment(params.row.DepartmentId);
                      },
                    });
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
    
              </Stack>
            );
          },
        },
    ];

    const removeDepartment = (DepartmentId: any) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });

        mDepartmentsService.remove(DepartmentId).then((response) => {
        if(response) {
            getDepartment();
        }
        });
    };

  return (
    <>
    <Typography variant="h5" align="center">
        Department
    </Typography>

    <Card>
        <CardContent>
            <Button
             variant="contained"
             startIcon={<AddCircleOutlineIcon />}
             onClick={() => navigate("/addDepartment")}
             >
                Add Record
            </Button>
            <div style={{ width: "100%" }}>
                <DataGrid
                getRowId={(row) => row.DepartmentId}
                rows={departments}
                columns={departmentColumns}
                columnHeaderHeight={30}
                autoHeight={true}
                // checkboxSelection
                // disableRowSelectionOnClick
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
  
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns Id, the other columns will remain visible
                      DepartmentId: false,
                    },
                  },
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                 />

            </div>

        </CardContent>
    </Card>

    <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

    </>
  )
}

export default MDepartmentsList