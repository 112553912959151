import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate, useParams } from "react-router-dom";
import { mEmployeesService } from "../../services/MEmployeeService";
import { EmployeeModel } from "../../models/MEmployeeModel";
import { Folderpath, config } from "../../utility/Config";
import Controls from "../../utility/Controls.tsx/Controls";
import { Margin } from "@mui/icons-material";
import useForm from "../../utility/hooks/UseForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import ConfirmDialog from "../helper/ConfirmDialog";

export default function MEmployeeList() {
  const [employees, setEmployees] = useState<EmployeeModel[]>([]);
  //([]);
  //<EmployeeModel[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });


  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    setErrors({
      ...temp,
    });
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mEmployeesService.initialFieldValues, validate, null);

  const newEmployee = () => {
    
    setValues(mEmployeesService.initialFieldValues);
    setValues((prevValues: any) => ({
      ...prevValues,
      Active: true
    }));
  };

  useEffect(() => {
    newEmployee();
    //getEmployees();
    getEmployees(true);
  }, []);

  //const getEmployees = () => {
    const getEmployees = (active: boolean) => {
    
    mEmployeesService.getAll(active).then((response) => {
   // mEmployeesService.getAll().then((response) => {
   debugger   
   let result = response.data;
      setEmployees(result.list);
    });
  };

  const handleSearch = (event: string) => {
    setSearchTerm(event);
  };

  const filteredEmployees = employees.filter(
    (employee) =>
      employee.EmpName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.Mobile.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const employeesColumns : GridColDef[] = [
    { field: "EmpName", headerName: "Employee Name", width: 180, },
    { field: "EmpCode", headerName: "Emp Code",  width: 100,  },
    { field: "ReportingTo", headerName: "Reporting To",  flex: 1, 
      valueGetter: (params) => params.row.ReportingTo?.EmpName,
     },
    { field: "UserName", headerName: "User Name",  width: 180, 
      valueGetter: (params) => params.row.User?.UserName,
     },
     { field: "Designation", headerName: "Designation",  width: 180, 
      valueGetter: (params) => params.row.Designation?.Designation,
     },
     { field: "Department", headerName: "Department",  width: 130, 
      valueGetter: (params) => params.row.Department?.DepartmentName,
     },
    { field: "Email", headerName: "Email",  width: 180,   },
    { field: "Mobile", headerName: "Mobile",  width: 120, },
    // {
    //   field: "Active",
    //   headerName: "Active",
    //   width: 80,
    //  // flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Stack>

    //         {/* {params.row.Active && "Yes"}
    //             {!params.row.Active && "No"} */}
    //         {params.row.Active && <DoneIcon color="success" />}
    //         {!params.row.Active && <CloseIcon color="error" />}
    //       </Stack>
    //     );
    //   },
    // },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      width: 80,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editEmployee/" + params.row.EmployeeId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small"
              aria-label="delete" color="error"
              onClick={() => {
                
                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure to delete this Employee ?',
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeEmployee(params.row.EmployeeId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>

          </Stack>
        );
      },
    },

  ]


  const removeEmployee = (EmployeeId: any) => {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
    });

    mEmployeesService.remove(EmployeeId).then((response) => {
    if(response) {
      getEmployees(true);
    }
    });
};

  return (
    <>
      <Typography variant="h5" align="center" margin={1}>
        Employee
      </Typography>
      <Card >
        <CardContent>

          <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ padding: '0 10px' }}>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => navigate("/addEmployee/")}

              >
                Add Employee
              </Button>
            </Grid>
            <Grid item>
              <Controls.Checkbox
                name="Active"
                label="Active"
                checked={values.Active}
                onChange={(e: any) => {
                  handleInputChange(e);
                  getEmployees(e.target.checked);
                 // getEmployees();
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              Total Employees: {employees.length}
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <Controls.Input
                label="Search Employee"
                variant="standard"
                value={searchTerm}
                style={{ width: '100%', maxWidth: '285px' }}
                onChange={(e: any) => handleSearch(e.target.value)}

              />
            </Grid> */}

          </Grid>
          <div style={{ width: "100%" }}>
                <DataGrid
                getRowId={(row) => row.EmployeeId}
                rows={employees}
                columns={employeesColumns}
                columnHeaderHeight={30}
                autoHeight={true}
                // checkboxSelection
                // disableRowSelectionOnClick
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
  
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns Id, the other columns will remain visible
                      EmployeeId: false,
                    },
                  },
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                 />

            </div>

          {/* <div style={{ alignItems: "center", justifyContent: "space-between" }}>
            <Grid container spacing={1} marginTop={2}>
              {filteredEmployees.map((employee) => (
                <Grid item
                  key={employee.EmployeeId}
                  md={3}
                  xs={6}
                  sx={{
                    //width: 310,
                    maxWidth: "100%",
                    textAlign: "center",
                    boxShadow: 3,
                    position: "relative",
                    //margin: 1,
                    padding: 1
                  }}
                  onDoubleClick={() => navigate("/editEmployee/" + employee.EmployeeId)}
                >
                  <CardContent sx={{ alignItems: "center", textAlign: "center", backgroundColor: employee.Active ? '#e3f1e3c7' : '#d9d0d06e' }}>
                    <Box sx={{ position: "relative", display: "inline-block" }}>
                      {employee.ProfileImageName ? (
                        <Avatar
                          src={`${process.env.REACT_APP_BASE_URL}/${Folderpath.Employee}/${employee.EmployeeId}/${employee.ProfileImageName}`}
                          sx={{ width: 70, height: 74 }}
                        />) : (
                        <div style={{ width: 70, height: 74, backgroundColor: "lightgray", borderRadius: "50%" }}>
                          <Typography variant="h4" style={{ textAlign: "center", lineHeight: "74px" }}>
                            {employee.EmpName && employee.EmpName.length > 0 ? employee.EmpName.charAt(0).toUpperCase() : ""}
                          </Typography>
                        </div>
                      )}
                      <Chip
                        size="small"
                        variant="outlined"
                        color="primary"
                        sx={{
                          position: "absolute",
                          right: 30,
                          transform: "translate(50%, -50%)",
                          border: "3px solid",
                          borderColor: "white",
                          background: "white",
                        }}
                        label={` ${employee.EmpCode}`}
                      />
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{ mt: 2, alignItems: "center", textAlign: "center" }}
                    >
                      {` ${employee.EmpName}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ alignItems: "center", textAlign: "center" }}
                    >
                      {employee.ReportingToId ? employees.find(emp => emp.EmployeeId === employee.ReportingToId)?.EmpName : ""}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ alignItems: "center", textAlign: "center" }}
                    >
                      {
                        employee.User?.UserName && (
                          <>
                            UserName:{`${employee.User?.UserName}`}
                          </>
                        )
                      }
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ alignItems: "center", textAlign: "center" }}
                    >
                      <MailOutlineOutlinedIcon fontSize="inherit" /> {` ${employee.Email}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ alignItems: "center", textAlign: "center" }}
                    >
                      <CallOutlinedIcon fontSize="inherit" /> {` ${employee.Mobile}`}
                    </Typography>
                  </CardContent>
                </Grid>
              ))}
            </Grid>
          </div> */}

        </CardContent>
      </Card>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

    </>
  );
}
