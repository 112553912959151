import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { ExpenseHeadModel } from "../models/MExpenseHeadModel";

const initialFieldValues: ExpenseHeadModel = {
    ExpenseHeadId: Guid.EMPTY,
    ExpenseHeadName:'',
    IsItForRent:false
}

const url = "expenseHeads";

function getAll(){
    return customAxios.get(`${url}/GetAll`,axiosRetryConfig);
}

function post(payload:ExpenseHeadModel ){
    return customAxios.post(`${url}/Add`,payload,axiosRetryConfig);
}
function put(payload: ExpenseHeadModel){
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}
function remove(id: number){
    
    return customAxios.delete(`${url}/${id}`,axiosRetryConfig);
}

function getById(ExpenseHeadId: number) {
    return customAxios.get(`${url}/GetById/${ExpenseHeadId}`, axiosRetryConfig);
  }

  function getExpenseHeadSelectList() {
    return customAxios.get(`${url}/GetExpenseHeadSelectList`, axiosRetryConfig);
}


export const expenseHeadService = {
    initialFieldValues,
    getAll,
    post,
    put,
    remove,
    getById,
    getExpenseHeadSelectList,
 
};