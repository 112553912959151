import React, { useState, useEffect } from "react";
import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmDialog from "../helper/ConfirmDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { globalService } from "../../services/GlobalService";
import { boqTitleService } from "../../services/BoqTitleService";
import { boqPackageService } from "../../services/BoqPackageService";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { SelectListModel } from "../../models/ApiResponse";
import { config, AttributeTypes } from "../../utility/Config";
import BoqTitleForm from "./BoqTitleForm";

const BoqTitleList = (...props: any) => {
  const [boqTitle, setBoqTitle] = useState([]);
  const [packagesList, setPackagesList] = useState<SelectListModel[]>([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (packagesList.length === 0) {
      getPackageSelectLists();
    } else {
      getAllMBoqTitlesByPackageID();
    }
    // getBoqTitle();
  }, []);

  const validate = (fieldValues:any = values) => {
    let temp: any = { ...errors };
    if ("BoqpackageId" in fieldValues)
      temp.BoqpackageId = fieldValues.BoqpackageId 
        ? ""
        : "Please Select BOQ Package";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqTitleService.initialFieldValues, validate, props.setCurrentId);

  const getBoqTitle = () => {
    boqTitleService.getAll().then((response) => {
      let result = response.data;
      setBoqTitle(result.list);
    });
  };

  const getPackageSelectLists = () => {
    boqPackageService.getPackageSelectList().then((response) => {
      setPackagesList(response.data);
      // setValues(response.data);
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate()) {
      if (values.BoqpackageId) {
        boqTitleService.getAllMBoqTitlesByPackageID(values.BoqpackageId).then((response) => {
          let result = response.data;
          if (response) {
            setBoqTitle(result.list);
          } else {
            globalService.error(result.message);
          }
        });
      }else{
        globalService.error("Please Select BOQ Package");
      }
    }
  };

  const getAllMBoqTitlesByPackageID = () => {
    boqTitleService.getAllMBoqTitlesByPackageID(values.BoqpackageId).then((response) => {
      let result = response.data;
      setBoqTitle(result.list);
    });
  };
  
  const columns: GridColDef[] = [
    {
      field: "BoqtitleName",
      headerName: "Title Name",
      width: 130,
      flex: 1,
      headerClassName: "bold-font",
    },
    {
      field: "AlternativeText",
      headerName: "Alternative Text",
      width: 130,
      flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqtitleAlternativeTexts/" + params.row.BoqtitleId, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Alternatives
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "Attributes",
      headerName: "Attributes",
      flex: 1,
      // width: 400,
      headerClassName: "bold-font",
      // type: "number",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate(
                  "/boqTitleAttribute/" +
                    params.row.BoqtitleId +
                    "/" +
                    AttributeTypes.Size,
                  {
                    state: {
                      row: params.row,
                    },
                  }
                )
              }
            >
              Size
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate(
                  "/boqTitleAttribute/" +
                    params.row.BoqtitleId +
                    "/" +
                    AttributeTypes.Make,
                  {
                    state: {
                      row: params.row,
                    },
                  }
                )
              }
            >
              Make
            </Button>
            {/* <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate(
                  "/boqTitleAttribute/" +
                    params.row.BoqtitleId +
                    "/" +
                    AttributeTypes.Color,
                  {
                    state: {
                      row: params.row,
                    },
                  }
                )
              }
            >
              Color
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqTitleAttribute/" + params.row.BoqtitleId + "/" + AttributeTypes.Material,
                  {
                    state: {
                      row: params.row,
                    },
                  }
                )
              }
            >
              Material
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate(
                  "/boqTitleAttribute/" +
                    params.row.BoqtitleId +
                    "/" +
                    AttributeTypes.Activity,
                  {
                    state: {
                      row: params.row,
                    },
                  }
                )
              }
            >
              Activity
            </Button> */}
          </Stack>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() =>
                navigate("/editTitle/" + params.row.BoqpackageId + "/" + params.row.BoqtitleId )
              }
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: `Are you sure to delete "${params.row?.BoqtitleName}" Title ?`,
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeBoqTitle(params.row.BoqtitleId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

 
  const removeBoqTitle = (BoqtitleId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    boqTitleService.remove(BoqtitleId).then((response) => {
      let result = response.data;
      if (response) {
          globalService.success(result.message);
          getAllMBoqTitlesByPackageID();
      }
    });
  };

  const packageValidate = () => {
    var boqPackId = values.BoqpackageId;
    if (boqPackId) {
      navigate("/addtitle/" + boqPackId);
    } else {
      globalService.error("Please Select Package Name.");
      //  alert("Please select package name.")
    }
  };

  return (
    <>
      <Typography variant="h5" align="center">
        BOQ Titles
      </Typography>
      <Card>
        <CardContent>
          <form noValidate onSubmit={handleSubmit}>
            <React.Fragment>
              <Grid container spacing={3} marginBottom={1}>
                <Grid item xs={12} sm={4}>
                  <Controls.Select
                    name="BoqpackageId"
                    label="BOQ Packages"
                    required
                    // showEmptyItem={true}
                    options={packagesList}
                    value={packagesList.length > 0 ? values.BoqpackageId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button type="submit" variant="contained">
                    Go
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          </form>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            // onClick={() => navigate("/addtitle/" + values.BoqpackageId)}
            onClick={() => packageValidate()}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BoqtitleId}
              rows={boqTitle}
              columns={columns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    boqTitleId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
          
          {/* <Box>
            <BoqTitleForm
              callFrom={"meeting"}
              clientId={values.ClientId}
           
             
            />
          </Box> */}
        </CardContent>
        
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default BoqTitleList;
