import { Guid } from "guid-typescript";
import { ExpenseFileModel } from "../models/ExpenseDtlFileModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { config } from "../utility/Config";

const initialFieldValues: ExpenseFileModel = {
  ExpenseDtlFileId: Guid.EMPTY,
  ExpenseDtlId: "",
  FileName: "",
  CreatedOn: null
}

const url = 'ExpenseDtlFile';

function remove(ExpenseDtlFileId: number) {

  return customAxios.delete(`${url}/${ExpenseDtlFileId}`, axiosRetryConfig);
}

function getById(ExpenseDtlFileId: number) {
  return customAxios.get(`${url}/GetById/${ExpenseDtlFileId}`, axiosRetryConfig);
}

function getAllFileByExpenseDtl(ExpenseDtlId: any) {

  return customAxios.get(`${url}/GetAllFileByExpenseDtl/${ExpenseDtlId}`, axiosRetryConfig);
}

function getAllFileByExpenseHeaderId(ExpenseHeaderId: any) {
  return customAxios.get(`${url}/GetAllFilesByExpenseHeaderId/${ExpenseHeaderId}`, axiosRetryConfig);
}

export const expenseDtlFileService = {
  initialFieldValues,
  remove,
  getById,
  getAllFileByExpenseDtl,
  getAllFileByExpenseHeaderId
}
