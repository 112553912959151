import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BoqItemAttributeModel } from '../../models/BoqItemAttributeModel';
import useForm from '../../utility/hooks/UseForm';
import { boqItemAttributeService } from '../../services/BoqItemAttributeService';
import { globalService } from '../../services/GlobalService';
import { Card, CardContent, CardActions, Grid, Typography, Button, Stack } from '@mui/material';
import Controls from '../../utility/Controls.tsx/Controls';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BoqItemAttributeForm = (...props: any) => {

    const { BoqitemId, BoqitemAttributeId, AttributeType,AttributeTypeName }: any = useParams();
    let navigate = useNavigate();
    const mode = BoqitemId ? 'Edit' : 'Create';

    const validate = (fieldValues: BoqItemAttributeModel = values) => {
        let temp: any = { ...errors };
        if ("BoqitemAttributeName" in fieldValues)
            temp.BoqitemAttributeName = fieldValues.BoqitemAttributeName ? ""  
        : "BOQ SubTitle Attribute Name is required.";
        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    }


    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(boqItemAttributeService.initialFieldValues, validate, props.setCurrentId);

    const newItemAttribute = () => {
        setValues(boqItemAttributeService.initialFieldValues);

    };

    function setFormValue(model: BoqItemAttributeModel) {
        let newModel = {
            BoqitemAttributeId: model.BoqitemAttributeId,
            BoqitemAttributeName: model.BoqitemAttributeName,
            AttributeType: model.AttributeType,
            AttributeTypeName : model.AttributeTypeName,
            Active: model.Active,
            BoqsubTitleId: model.BoqsubTitleId,
            BoqitemId: model.BoqitemId
            // Abrv:model.Abrv,
        }
        return newModel;
    }

    useEffect(() => {
        if (BoqitemAttributeId) {
            getBoqItemAttributeName(BoqitemAttributeId);
            setErrors({});
        } else newItemAttribute();
    }, [BoqitemAttributeId]);

    const getBoqItemAttributeName = (BoqitemAttributeId: any) => {

        boqItemAttributeService.GetById(BoqitemAttributeId).then((response) => {
            if (response) {

                let result = response.data;
                setValues(setFormValue(result.data));
            }
        })
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validate()) {
            
            if (BoqitemAttributeId) {
                boqItemAttributeService.put(values).then((response: any) => {
                    let result = response.data;
                    if (response) {
                        globalService.success(result.message);
                        navigate(-1);
                    } else {
                        globalService.error(result.message);
                    }
                });
            } else {
                
                values.AttributeType = AttributeType;
            values.AttributeTypeName = AttributeTypeName;
            values.BoqitemId = BoqitemId;
                boqItemAttributeService.post(values).then((response: any) => {
                    if (response) {
                        let result = response.data;

                        if (result.isSuccess) {
                            globalService.success(result.message);
                            resetForm();
                            navigate(-1);
                        } else {
                            globalService.error(result.message);
                        }
                    }
                });
            }
        }
    };
    return (
        <>
            <Typography variant="h5" align="center">
                BOQ Item Attributes Form
            </Typography>
            <form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
            >
                <Card>
                    <CardContent>
                        <React.Fragment>
                            <Grid container spacing={3}>

                                <Grid item xs={12} sm={4}>
                                    <Controls.Input
                                        label="BoqitemAttributeName"
                                        name="BoqitemAttributeName"
                                        value={values.BoqitemAttributeName}
                                        onChange={handleInputChange}
                                        error={errors.BoqitemAttributeName}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}
                                 className={mode !== "Edit" ? "hidden" : ""}
                                 >
                                    <Controls.Checkbox
                                        name="Active"
                                        label="Active"
                                        value={values.Active}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>

                        </React.Fragment>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Stack spacing={2} direction="row">
                            <Button type="submit" variant="contained">Submit</Button>
                            <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>Back To List </Button>
                        </Stack>

                    </CardActions>
                </Card>
            </form>
        </>
    )
}

export default BoqItemAttributeForm


