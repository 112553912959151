import React, { useState, useEffect } from "react";

import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  CardActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmDialog from "../helper/ConfirmDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { mUomalternativeTextService } from "../../services/MUomalternativeTextsService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { mUomsService } from "../../services/MUomsService";
import { PageUomInfoRequestModel, PageUomInfoResponseModel } from "../../models/MUomsModel";

const MUomalternativeTextsList: React.FC = () => {
  const [mUomalternativeTexts, setMUomalternativeTexts] = useState([]);
  const { Uomid }: any = useParams();
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '', onConfirm: () => { } })
  const [UomInfo, setUomInfo] = useState<PageUomInfoResponseModel>(mUomsService.initialPageUomInfoResponseValues);

  const navigate = useNavigate();
  useEffect(() => {
    getPageUom();
    getmUomalternativeTexts();
  }, []);

  const getmUomalternativeTexts = () => {
    mUomalternativeTextService.getAll(Uomid).then((response) => {
      let result = response.data;
      setMUomalternativeTexts(result.list);
    });
  };

  const columns: GridColDef[] = [
    { field: "UomalternativeTextName", headerName: "UOM Alternative Text Name", flex: 1 },
    {
      field: "Active",
      headerName: "Active",
      //   width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            {params.row.Active && <DoneIcon color="success" />}
            {!params.row.Active && <CloseIcon color="error" />}
          </Stack>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",

      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editmUomalternativeTexts/" + Uomid + "/" + params.row.UomalternativeTextId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small" aria-label="delete" color="error"
              onClick={() => {

                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure to delete this UOM Alternative Text ?',
                  subTitle: "You can't undo this operation",
                  onConfirm: () => { removeUomalternativeTexts(params.row.UomalternativeTextId) }
                })
              }}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>

          </Stack>
        );
      },
    },
  ];

  const removeUomalternativeTexts = (UomalternativeTextId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
    mUomalternativeTextService.remove(UomalternativeTextId).then((response) => {
      let result = response.data;
      if (response) {
        // globalService.success(result.message);
        getmUomalternativeTexts();
      }
    });
  }

  const getPageUom = () => {
    let model: PageUomInfoRequestModel = {
      Uomid: Uomid,
    };
    mUomsService
      .getPageUomInfo(model)
      .then((response) => {
        setUomInfo(response.data.row);
      });
  };

  return (
    <>
      <Stack direction="row" spacing={0} justifyContent="space-between">
        <Typography variant="h5" align="center">
          UOM Alternative Texts
        </Typography>
        <Typography variant="body1">
          <b>UOM Name :</b> {UomInfo.Uomname}
          <br />
          <b>UOM Code :</b> {UomInfo.Uomcode}
        </Typography>
      </Stack>
      <Card>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addmUomalternativeTexts/" + Uomid)}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.UomalternativeTextId}
              rows={mUomalternativeTexts}
              columns={columns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    UomalternativeTextId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/mUOMS")}
            >
              Back To List
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}


export default MUomalternativeTextsList