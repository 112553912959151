import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { ZoneApproversModel } from "../models/ZoneApproversModel";

const initialFieldValues: ZoneApproversModel = {
    ZoneApproversId: Guid.EMPTY,
    ZoneId: '',
    ApproverUserId: '',
}

const url = "zoneApprovers";

function post(payload: ZoneApproversModel) {
    return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}

function put(payload: ZoneApproversModel) {
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(ZoneApproversId: number) {
    return customAxios.get(`${url}/GetById/${ZoneApproversId}`, axiosRetryConfig);
}

function getAll (ZoneId: number) {
    
    return customAxios.get(`${url}/GetAll/${ZoneId}`, axiosRetryConfig);
}

function getUserSelectListForZoneApprove() {
    return customAxios.get(`${url}/GetUserSelectListForZoneApprove`, axiosRetryConfig);
}


export const ZoneApproverService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll,
    getUserSelectListForZoneApprove,
}