import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { mDesignationsService } from "../../services/MDesignationService";
import { DesignationModel } from "../../models/MDesignationModel";

const MDesignationForm = (...props: any) => {
  const {DesignationId} = useParams();
  let navigate = useNavigate();
  const mode = DesignationId ? "Edit" : "Create";

  const validate = (fieldValues = values) => {
    let temp: any = {...errors};

    if("Designation" in fieldValues)
        temp.Designation = fieldValues.Designation ? "" : "Designation is required";
    setErrors({
        ...temp,
    });

    if(fieldValues === values)
        return Object.values(temp).every((x) => x === "");

}

const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
useForm(mDesignationsService.initialFieldValues, validate, DesignationId);

const newDesignation = () => {
  setValues(mDesignationsService.initialFieldValues);
}

function setFormValue(model: DesignationModel) {
  let newModel = {
    DesignationId : model.DesignationId,
    Designation: model.Designation || "",
      Active: model.Active,
  };
  return newModel;
}


useEffect(() => {
  if(DesignationId) {
    getDesignation(DesignationId);
      setErrors({});
  } else newDesignation();
}, [DesignationId]);

const getDesignation = (DesignationId: any) => {
  mDesignationsService.getById(DesignationId).then((response) => {
      if(response) {
          let result = response.data;
          setValues(setFormValue(result.list));
      }
  });
};

const handleSubmit = (e: React.FormEvent) => {
  e.preventDefault();
  if(validate()) {
     if(DesignationId) {
      mDesignationsService.put(values).then((response: any) => {
          let result = response.data;
          if(response) {
              globalService.success(result.message);
              navigate("/mDesignations")
          } else {
              globalService.error(result.message);
          }
      });
     } else {
      mDesignationsService.post(values).then((response: any) => {
          if(response) {
              let result = response.data;
              if(result.isSuccess){
                  globalService.success(result.message);
                  navigate("/mDesignations")
              } else {
                  globalService.error(result.message);
                }
          } 
      });
     }
  }
}


  return (
 
    <>
     <Typography variant="h5" align="center">
     Designation
      </Typography>

      <form
        autoComplete="off" noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Designation"
                    name="Designation"
                    value={values.Designation}
                    onChange={handleInputChange}
                    error={errors.Designation}
                  />
                </Grid>

                <Grid item xs={12} sm={4}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>

              </Grid>

            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/mDesignations" )}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>

      </form>
    </>

    
  )
}

export default MDesignationForm