
import { Guid } from "guid-typescript";
import { ExpenseQueriesModel } from "../models/ExpenseQueriesModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: ExpenseQueriesModel = {
    ExpenseQueryId: Guid.EMPTY,
    ExpenseHdrId: "",
    Remark: "",
}

const url = 'expenseQueries';

function post(payload: ExpenseQueriesModel) {
    return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}

function put(payload: ExpenseQueriesModel) {
    return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}


function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(ExpenseQueryId: number) {
    return customAxios.get(`${url}/GetById/${ExpenseQueryId}`, axiosRetryConfig);
}

function getAll() {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

function getAllByExpenseHdr(ExpenseHdrId: number) {
    return customAxios.get(`${url}/GetAllByExpenseHdr/${ExpenseHdrId}`, axiosRetryConfig);
}


export const expenseQueriesService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll,
    getAllByExpenseHdr

}