import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { MItemModel } from "../models/MItemModel";

const initialFieldValues: MItemModel = {
    ItemId: Guid.EMPTY,
    ItemName: '',
    Uomid: '',
    Hsncode:'',
    ItemType:'',
    Qty:0,
    TaxPerc:0,
    Active: true
}

const url = "MItems";

function post(payload: MItemModel) {
    return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}

function put(payload: MItemModel) {
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(itemId: number) {
    return customAxios.get(`${url}/GetById/${itemId}`, axiosRetryConfig);
}

function getAll () {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}


export const mItemsService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll,
}