import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Controls from "../../utility/Controls.tsx/Controls";
import { MultiSelectListModel, SelectListModel } from "../../models/ApiResponse";
import { mClientService } from "../../services/MClientService";
import useForm from "../../utility/hooks/UseForm";
import { mSiteService } from "../../services/MSiteService";
import { projectDirectoryService } from "../../services/ProjectDirectoryService";
import { ProjectDirectoryModel } from "../../models/ProjectDirectoryModel";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { boqUploadService } from "../../services/BoqUploadService";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBox } from "@mui/icons-material";
import { MVendorService } from "../../services/MVendorService";
import { MVendorModel } from "../../models/MVendorModel";
import Draggable from "react-draggable";
import { MProcurementTypesService } from "../../services/MProcurementTypesService";
import { globalService } from "../../services/GlobalService";
import { boqrequisitionsService } from "../../services/BoqRequisitionService";
import ConfirmDialog from "../helper/ConfirmDialog";

const Requisition = () => {
  const [rateComparison, setRateComparison] = useState([]);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [clients, setClients] = useState<MultiSelectListModel[]>([]);
  const [selectedBoqUploadIds, setSelectedBoqUploadIds] = useState<string[]>(
    []
  );
  const [selectedVendors, setSelectedVendorUploadIds] = useState<any[]>([]);
  const [procurementType, setProcurementType] = useState<SelectListModel[]>([]);
  const [boqItems, setBoqItems] = useState<any[]>([]);
  const [sites, setSites] = useState<SelectListModel[]>([]);
  const [packages, setPackages] = useState<any[]>([]);
  const [vendorRows, setVendorRows] = useState<MVendorModel[]>([]);
  const [projectDirectory, setProjectDirectory] =
    useState<ProjectDirectoryModel>(projectDirectoryService.initialFieldValues);

  const [open, setOpen] = React.useState(false);
  const [openRateComparison, setOpenRateComparison] = React.useState(false);
  const [openForVendor, setOpenForVendor] = React.useState(false);
  const draggableRef = useRef(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    item: "",
    onConfirm: () => { },
  });

  const handleVendorDetailOpen = (row: any) => {
    getAllVendor();
    setOpen(true);
  };

  const handleVendorDetailClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleOpenforRateComparison = (
    BOQUploadId: any,
    ShowQuotedRatesOnly?: boolean
  ) => {
    getRateComparison(BOQUploadId, ShowQuotedRatesOnly);
    setOpenRateComparison(true);
  };

  const handleCloseforrateComparison = () => {
    setOpenRateComparison(false);
  };

  const handleOpenforVendor = (row: any) => {
    setOpenForVendor(true);
  };
  const handleCloseforVendor = () => {
    setOpenForVendor(false);
  };

  useEffect(() => {
    //const node = draggableRef.current;
    if (sites.length === 0) getSites();
    if (procurementType.length === 0) getProcurementType();
  }, []);

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    // if ("ClientID" in fieldValues)
    //   temp.ClientID = fieldValues.ClientID ? "" : "Client is required.";

    if ("SiteID" in fieldValues)
      temp.SiteID = fieldValues.SiteID ? "" : "Site is required.";

    if ("BOQPackageID" in fieldValues)
      temp.BOQPackageID = fieldValues.BOQPackageID
        ? ""
        : "Package is required.";

    if ("ProcurementTypeID" in fieldValues)
      temp.ProcurementTypeID = fieldValues.ProcurementTypeID
        ? ""
        : "Procurement Type is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };


  const handleConvertedBoqSelectionChange = (selectedIds: any) => {
    setSelectedBoqUploadIds(selectedIds);
  };

  const handleConvertedVendorSelectionChange = (selectedVendorsIds: any) => {
    setSelectedVendorUploadIds(selectedVendorsIds);
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqUploadService.initialFieldValues, validate, "");

  // const{valuesforreq : any}=
  // useForm(boqrequisitionsService.initialInsertDataForRequisitionsValues, validate, "");

  const getRateComparison = (
    BOQUploadId: any,
    ShowQuotedRatesOnly?: boolean
  ) => {
    boqrequisitionsService
      .getQuotesRcvdFromVendorForPOCreation(BOQUploadId, ShowQuotedRatesOnly)
      .then((response) => {
        let result = response.data;
        setRateComparison(result.list);
      });
  };

  // const getClients = () => {
  //   mClientService.GetAllMultiSelectList().then((response: any) => {
  //     setClients(response.data);
  //   });
  // };

  const getProcurementType = () => {
    MProcurementTypesService.getSelectListForLoggedInUser().then(
      (response: any) => {
        setProcurementType(response.data);
      }
    );
  };
  const getSites = () => {
    mSiteService.GetMultiSelectListByUploadedBoqs().then((response: any) => {
      setSites(response.data);
    });
  };
  const getBoqPackages = (ProjectDirectoryID: any) => {
    projectDirectoryService
      .getSelectList(ProjectDirectoryID)
      .then((response: any) => {
        setPackages(response.data);
      });
  };
  const getProjectDirectory = (siteId: any) => {
    projectDirectoryService
      .GetBySiteId(siteId)
      .then((response) => {
        let result = response.data;
        setProjectDirectory(result.row);
        if (result.row)
          values.ProjectDirectoryID = result.row.ProjectDirectoryId;
        else values.ProjectDirectoryID = "";
        getBoqPackages(values.ProjectDirectoryID);
      });
  };

  // const getBOQItem = (BOQPackageId: any) => {

  //   var ProjectDirectoryId = projectDirectory.ProjectDirectoryId;
  //
  //   boqUploadService
  //     .GetBOQItemDtls(ProjectDirectoryId, BOQPackageId,ProcurementTypeId)
  //     .then((response) => {
  //       let result = response.data;
  //       setBoqItems(result.list);
  //     });
  // };

  const GetBOQItemDtls = () => {
    //e.preventDefault();
    if (validate()) {
      values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;
      boqUploadService.GetBOQItemDtls(values).then((response) => {
        let result = response.data;
        setBoqItems(result.list);
      });
    }
  };

  const columns: GridColDef[] = [
    // {
    //   field: "BOQUploadID",
    //   headerName: "BOQ Upload",
    //   width: 130,
    //   flex: 1,
    //   
    // },
    {
      field: "ClientSrNo",
      headerName: "Client SrNo",
      // width: 130,
      flex: 1,

    },
    {
      field: "Title",
      headerName: "Title",
      width: 150,
      // flex: 1,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    {
      field: "SubTitle",
      headerName: "Sub Title",
      width: 150,
      // flex: 1,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    {
      field: "ItemName",
      headerName: "Item Name",
      width: 150,
      // flex: 1,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    {
      field: "Specifications",
      headerName: "Specifications",
      width: 400,
      // flex: 1,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    {
      field: "BOQUploadStatus",
      headerName: "Status",
      width: 100,
      // flex: 1,

    },
    {
      field: "RFQSendToVendors",
      headerName: "RFQ Send To Vendors",
      width: 100,
      // flex: 1,

      renderCell: (params) => (
        <>
          {/* <IconButton
        size="small"
        color="secondary"
        aria-label="add an alarm"
        onClick={() => handleOpenforRateComparison(params.row.BOQUploadId, null)}
      >
         {params.row.RFQSendToVendors}
      </IconButton> */}
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() =>
              handleOpenforRateComparison(params.row.BOQUploadId, null)
            }
          >
            {params.row.RFQSendToVendors}
          </Link>
        </>
      ),
    },
    {
      field: "Location",
      headerName: "Location",
      width: 150,
      // flex: 1,

    },
    { field: "Unit", headerName: "Unit", flex: 1, },
    { field: "Qty", headerName: "Qty", flex: 1,
      headerAlign: "right",
      cellClassName: "cell-right"},
    {
      field: "BOQMake ",
      headerName: "Make ",
      width: 100,
      // flex: 1,

    },
    {
      field: "BOQSize ",
      headerName: "Size",
      width: 100,
      // flex: 1,

    },

    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   type: "number",
    //   width:150,
    //   // flex: 1,
    //   renderCell: (params) => (
    // <Button
    //   variant="contained"
    //   color="primary"
    //   // onClick={() => handleButtonClick(params.row)}
    // >
    //  Select Vendor
    // </Button>
    //   ),
    // },
    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   width: 200,
    //   // flex: 1,
    //   renderCell: (params) => (
    //     <FormControl>
    //       <InputLabel id="multiselect-label">Fullfillment</InputLabel>
    //       <Select
    //         labelId="singleselect-label"
    //         id="singleselect"
    //         value={"Buy"} // Set the selected value // Render the selected values
    //       >
    //         <MenuItem value="Buy">Buy</MenuItem>
    //         <MenuItem value="SiteWork">Site Work</MenuItem>
    //         <MenuItem value="FactoryWork">Factory Work</MenuItem>
    //         <MenuItem value="B2B">B2B</MenuItem>
    //         {/* Add more MenuItem components for additional options */}
    //       </Select>

    //     </FormControl>

    //   ),

    // },
  ];

  const getAllVendor = () => {
    MVendorService.getAll().then((response) => {
      let result = response.data;
      setVendorRows(result.list);
    });
  };

  const handleSubmitSendToVendorsForQuotation = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    //e.preventDefault();
    values.VendorIDs = selectedVendors.toString();
    values.BOQUploadIDs = selectedBoqUploadIds.toString();
    //values.CreatedByID = "2BC4C9FD-B990-4805-86B2-BE69689E552E";

    boqrequisitionsService.post(values).then((response: any) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          globalService.success(result.message);
          handleCloseforVendor();
          handleVendorDetailClose(null, "");
          GetBOQItemDtls();
        }
      }
    });
  };
  // const vendorRows = [
  //   { Id: 1, MobileNumber: '9541236587', Address: 'Kandivali', Email: 'Snow@gmail.com', VendorName: 'Snow' },
  //   { Id: 2, MobileNumber: '88965412578', Address: 'Washi', Email: 'Lannister@gmail.com', VendorName: 'Lannister' },
  //   { Id: 3, MobileNumber: '95471236589', Address: 'Goregaon', Email: 'Mark@gmail.com', VendorName: 'Mark' },
  //   { Id: 4, MobileNumber: '99558423658', Address: 'Malad', Email: 'Stark@gmail.com', VendorName: 'Stark' },
  //   { Id: 5, MobileNumber: '8856992314', Address: 'Tilak Nagar', Email: 'Targaryen@gmail.com', VendorName: 'Targaryen' },
  //   { Id: 6, MobileNumber: '2546984523', Address: 'Aeroli', Email: 'Melisandre@gmail.com', VendorName: 'Melisandre' },
  //   { Id: 7, MobileNumber: '887452369417', Address: 'Thane', Email: 'Clifford@gmail.com', VendorName: 'Clifford' },
  //   { Id: 8, MobileNumber: '8741258691', Address: 'Bhandup', Email: 'Frances@rediffmail.com', VendorName: 'Frances' },
  //   { Id: 9, MobileNumber: '889654771236', Address: 'Ghansoli', Email: 'Roxie@yahoo.com', VendorName: 'Roxie' },
  // ];

  const vendorColumns: GridColDef[] = [
    { field: "VendorName", headerName: "Vendor Name", flex: 1 },
    { field: "VendorCode", headerName: "Vendor Code", width: 100 },
    { field: "Email", headerName: "Email", width: 200 },
    { field: "Address1", headerName: "Address1", flex: 1 },
    { field: "Spocname", headerName: "Spoc Name", flex: 1 },
    { field: "Spocmobile", headerName: "Spoc Mobile", width: 130 },
    // { field: 'Address', headerName: 'Address', flex: 1 },
  ];

  const rateComparisonColumns: GridColDef[] = [
    { field: "BOQRequisitionNo", headerName: " Req No", width: 140 },
    {
      field: "BOQRequisitionDate",
      headerName: "Req Date",
      width: 120,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    { field: "VendorName", headerName: " Vendor", flex: 1 },
    { field: "SPOCName", headerName: " Spoc Name", flex: 1 },
    { field: "SPOCMobile", headerName: " Spoc Mobile", width: 100 },
    {
      field: "SupplyRate",
      headerName: "Supply Rate",
      width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",
      
valueFormatter: (params) => {
  return globalService.formatToIndianCurrency(params.value);
},

    },
    {
      field: "InstallationRate",
      headerName: "Installation Rate",
      width: 150,

    },
    { field: "Rate", headerName: "Rate", flex: 1,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
              return globalService.formatToIndianCurrency(params.value);
            }, },
    { field: "VendorRemarks", headerName: "Vendor Remarks", flex: 1 },
    { field: "ANJRemarks", headerName: "ANJ Remarks", flex: 1 },
  ];

  return (
    <div>
      <Typography variant="h5" align="center">
        BOQ Requisition{" "}
      </Typography>
      <Card>
        <CardContent>
          <React.Fragment>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={3}>
                <Controls.MuiAutocomplete
                  options={clients}
                  label="Client"
                  error={errors.ClientID}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "ClientID",
                        value: event ? event.value : "",
                      }
                    });
                    setSites([]);
                    if (event) {
                      
                      getSites(event.value);
                      setValues((prevValues: any) => ({
                        ...prevValues,
                        SiteID: ""
                      }));
                      setProjectDirectory(projectDirectoryService.initialFieldValues);
                    }
                  }}
                  value={clients.find((item: any) => item.value === values.ClientID) || null}
                />
               
              </Grid> */}
              <Grid item xs={12} sm={3}>
                <Controls.MuiAutocomplete
                  options={sites}
                  label="Site"
                  error={errors.SiteID}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "SiteID",
                        value: event ? event.value : "",
                      },
                    });
                    if (event) {
                      getProjectDirectory(event.value);
                    }
                  }}
                  value={
                    sites.find((item: any) => item.value === values.SiteID) ||
                    null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <form
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    GetBOQItemDtls();
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Controls.Select
                        name="BOQPackageID"
                        label="BOQ Package"
                        required
                        showEmptyItem={true}
                        options={packages}
                        value={packages.length > 0 ? values.BOQPackageID : ""}
                        onChange={(e: any) => {
                          handleInputChange(e);
                          // Submit form on change
                        }}
                        error={errors.BOQPackageID}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controls.Select
                        name="ProcurementTypeId"
                        label="Procurement Type"
                        required
                        showEmptyItem={true}
                        options={procurementType}
                        value={
                          procurementType.length > 0
                            ? values.ProcurementTypeId
                            : ""
                        }
                        onChange={(e: any) => {
                          handleInputChange(e);
                        }}
                        error={errors.ProcurementTypeID}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button type="submit" variant="contained">
                        Go
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {projectDirectory && projectDirectory.ProjectDirectoryId && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant="h5"
                      align="center"
                      className="MuiCardHeader-title"
                    >
                      Project Directory
                    </Typography>
                    <Divider></Divider>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={3}>
                        <Typography className="label">Site Address</Typography>
                        <Typography variant="body2">
                          {projectDirectory.SiteAddress}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Typography className="label">Project Name </Typography>
                        <Typography variant="body2">
                          {projectDirectory.ProjectShortName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Typography className="label">
                          Project Detail{" "}
                        </Typography>
                        <Typography variant="body2">
                          {projectDirectory.ProjectDetails}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Typography className="label">Area </Typography>
                        <Typography variant="body2">
                          {projectDirectory.Area}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Typography className="label">Start Date </Typography>
                        <Typography variant="body2">
                          {dayjs(projectDirectory.ProjectStartOn).format(
                            "DD-MMM-YYYY"
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Typography className="label">End Date </Typography>
                        <Typography variant="body2">
                          {dayjs(projectDirectory.ProjectEndOn).format(
                            "DD-MMM-YYYY"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </React.Fragment>
          <div style={{ width: "100%" }}>
            <Grid item xs={12} sm={3}>
              <Stack direction="row" spacing={1} marginY={1}>
                {selectedBoqUploadIds.length > 0 && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleVendorDetailOpen}
                    >
                      Select Vendor's to Send Quotation
                    </Button>
                  </Grid>
                )}
                <p> {selectedBoqUploadIds.length} BOQ's selected.</p>
              </Stack>
            </Grid>
            <DataGrid
              getRowId={(row) => row.BOQUploadId + "_" + row.ClientSrNo}
              rows={boqItems}
              columns={columns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              checkboxSelection
              rowSelectionModel={selectedBoqUploadIds}
              disableRowSelectionOnClick
              onRowSelectionModelChange={handleConvertedBoqSelectionChange}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    BOQUploadId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "left" }}>
          <Stack spacing={2} direction="row">
            {selectedBoqUploadIds.length > 0 && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVendorDetailOpen}
                >
                  Select Vendor's to Send Quotation
                </Button>
              </Grid>
            )}
          </Stack>
        </CardActions>
      </Card>

      <Draggable nodeRef={draggableRef}>
        <Dialog
          ref={draggableRef}
          fullWidth={true}
          maxWidth={maxWidth}
          onClose={handleVendorDetailClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, textAlign: "center" }}
            id="customized-dialog-title"
          >
            <Grid>Vendor Details</Grid>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleVendorDetailClose(null, "")}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme: any) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <>
              <Grid item xs={12} sm={3}>
                <Stack direction="row" spacing={1} marginY={1}>
                  {selectedVendors.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handleOpenforVendor}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title:
                            " Are you Sure to Send Mail's to Vendor For Quotation ?",
                          item: "You can't undo this operation",
                          onConfirm: () => {
                            handleSubmitSendToVendorsForQuotation();
                          },
                        });
                      }}
                    >
                      Send to Vendor For Quotation
                    </Button>
                  )}
                  <p> {selectedVendors.length} Vendor's selected.</p>
                </Stack>
              </Grid>
              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.VendorId}
                  rows={vendorRows}
                  columns={vendorColumns}
                  columnHeaderHeight={30}
                  autoHeight={true}
                  checkboxSelection
                  disableRowSelectionOnClick
                  rowSelectionModel={selectedVendors}
                  onRowSelectionModelChange={
                    handleConvertedVendorSelectionChange
                  }
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns Id, the other columns will remain visible
                        Id: false,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                  }}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
                {selectedVendors.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title:
                          " Are you Sure to Send Mail's to Vendor For Quotation ?",
                        item: "You can't undo this operation",
                        onConfirm: () => {
                          handleSubmitSendToVendorsForQuotation();
                        },
                      });
                    }}
                  >
                    Send to Vendor For Quotation
                  </Button>
                )}
              </div>
            </>
          </DialogContent>
        </Dialog>
      </Draggable>

      {/* <div>
        <Draggable nodeRef={draggableRef}>
        <div ref={draggableRef}>
          <Dialog
            open={openForVendor}
            onClose={handleCloseforVendor}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you Sure to Send Mail's to Vendor For Quotation
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseforVendor}>No</Button>
              <Button onClick={handleSubmit} autoFocus>
                yes
              </Button>
            </DialogActions>
          </Dialog>
          </Div>
        </Draggable>
      </div> */}
      <div>
        <Draggable nodeRef={draggableRef}>
          <Dialog
            ref={draggableRef}
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleCloseforrateComparison}
            aria-labelledby="customized-dialog-title"
            open={openRateComparison}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, textAlign: "center" }}
              id="customized-dialog-title"
            >
              <Grid>List Of RFQ's </Grid>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseforrateComparison}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <>
                {/* <Grid item xs={12} sm={3}>
                  <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }}>
                   
                    <p> {selectedVendors.length} Vendor's selected.</p>
                  </Stack>
                </Grid> */}
                <div style={{ width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.VendorId}
                    rows={rateComparison}
                    columns={rateComparisonColumns}
                    columnHeaderHeight={30}
                    autoHeight={true}
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    // rowSelectionModel={selectedVendors}
                    // onRowSelectionModelChange={
                    //   handleConvertedVendorSelectionChange
                    // }
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          BOQUploadId: false,
                        },
                      },
                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
              </>
            </DialogContent>
          </Dialog>
        </Draggable>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default Requisition;
