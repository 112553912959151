import { Guid } from "guid-typescript";
import { DesignationModel } from "../models/MDesignationModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";


const initialFieldValues: DesignationModel = {
    DesignationId: Guid.EMPTY,
    Designation: '',
    Active: true
}

const url = 'mDesignations';

function post (payload: DesignationModel) {
    return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}

function put(payload: DesignationModel) {
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(DesignationId: number) {
    return customAxios.get(`${url}/GetById/${DesignationId}`, axiosRetryConfig);
}

function getAll () {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

export const mDesignationsService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll

}