import { Guid } from "guid-typescript";
import { BoqSubTitleModel } from "../models/BoqSubTitleModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { BoqsubTitleAttributeModel } from "../models/BoqsubTitleAttributeModel";

const initialFieldValues: BoqsubTitleAttributeModel = {
    BoqsubTitleAttributeId:Guid.EMPTY,
    BoqtitleId:"",
    BoqsubTitleId:"",
    AttributeType:0,
    AttributeTypeName:"",
    BoqsubTitleAttributeName: "",
    Active: false
}


const url = "MBoqsubTitleAttribute";

function post(payload:BoqsubTitleAttributeModel ){
    
    return customAxios.post(`${url}/Add`,payload,axiosRetryConfig);
}
function put(payload: BoqsubTitleAttributeModel){
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}
function remove(id: number){
    return customAxios.delete(`${url}/${id}`,axiosRetryConfig);
}

function GetById(boqitemAttributeId: number){
    return customAxios.get(`${url}/GetById/${boqitemAttributeId}`,axiosRetryConfig);
}


function GetAllMBoqsubTitleAttributesByAttType(BoqsubTitleId: number ,AttributeType: number){
    return customAxios.get(`${url}/GetAllMBoqsubTitleAttributesByAttType/${BoqsubTitleId}/${AttributeType}`,axiosRetryConfig);
}

function getAll(){
    return customAxios.get(`${url}/GetAll`,axiosRetryConfig);
}

export const BoqsubTitleAttributeService = {
    initialFieldValues,
    post,
    put,
    remove,
    GetById,
    getAll,
    GetAllMBoqsubTitleAttributesByAttType
};