import { Guid } from "guid-typescript";
import { BoqtitleAlternativeTextsModel } from "../models/BoqtitleAlternativeTextsModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqtitleAlternativeTextsModel = {
    BoqtitleAlternativeTextId:Guid.EMPTY,
    BoqtitleId:"",
    BoqtitleAlternativeTextName:"",
   Active:false,
}

const url = "MBoqtitleAlternativeTexts";

function getAllByBoqTitle(boqtitleId: number){
    return customAxios.get(`${url}/GetAll/${boqtitleId}`,axiosRetryConfig);
}

function post(payload:BoqtitleAlternativeTextsModel ){
    
    return customAxios.post(`${url}/Add`,payload,axiosRetryConfig);
}
function put(payload: BoqtitleAlternativeTextsModel){
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}
function remove(id: number){
    return customAxios.delete(`${url}/${id}`,axiosRetryConfig);
}

function getById(BoqtitleAlternativeTextId: number){
    return customAxios.get(`${url}/GetById/${BoqtitleAlternativeTextId}`,axiosRetryConfig);
}

function getAllByBoqtitleId(BoqtitleId: number){
    return customAxios.get(`${url}/GetAllByBoqtitleId/${BoqtitleId}`,axiosRetryConfig);
}

function getAll(){
    return customAxios.get(`${url}/GetAll`,axiosRetryConfig);
}

export const boqtitleAlternativeTextService = {
    initialFieldValues,
    getAllByBoqTitle,
    getAllByBoqtitleId,
    post,
    put,
    remove,
    getById,
    getAll,
 
};