import { Guid } from "guid-typescript";
import { DepartmentModel } from "../models/MDepartmentModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: DepartmentModel = {
    DepartmentId: Guid.EMPTY,
    DepartmentName: '',
    Active: true
}

const url = "mDepartments";

function post(payload: DepartmentModel) {
    return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}

function put(payload: DepartmentModel) {
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(DepartmentId: number) {
    return customAxios.get(`${url}/GetById/${DepartmentId}`, axiosRetryConfig);
}

function getAll () {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

export const mDepartmentsService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll
}