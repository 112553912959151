import { BOQItemDtlsModel, BOQItemDtlsRateComparisonModel, BoqUploadRequestModel, BoqUploadUpdateDTO, MakeListUploadRequestModel, RateComparisonRequestDTO } from "../models/BoqUploadModel";
import { config } from "../utility/Config";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqUploadRequestModel = {
    ClientID: "",
    SiteID: "",
    ProjectDirectoryID: "",
    BOQPackageID: "",
    UpdateRates: true,
    WorkSheetName: '',
    ProcurementTypeId: ''
}
const initialFieldValuesForRateComparisonRequest: RateComparisonRequestDTO = {
    BOQPackageID: '',
    ProjectDirectoryID: '',
    ProcurementTypeId: '',
    WorkSheetName: '',
    VendorID: '',
    ShowRecordsOfRatesEnteredYN: null
}

const initialBoqUploadUpdateValues: BoqUploadUpdateDTO = {
    BOQUploadId: '',
    BOQMake: '',
    BOQSize: '',
    boqMakeValue: '',
    BOQActivity: '',
    BOQItemId: '',
    BOQTitleId: '',
    BOQSubTitleId: '',
    NewBoqTitle: '',
    NewBoqSubTitle: '',
    NewBoqItem: '',
    NewBoqShortForm:'',
    AddType: '', //item, title, subtitle
    Location: '',
    Unit: '',
    Remarks: '',
    Specifications: '',
    Qty: 0,
    Rate: 0,
    Amount: 0,
    SupplyRate: 0,
    InstallationRate: 0,
    ImageFileName:'',
    BOQShortFormID:'',
}

const initialBOQItemDtlsValues: BOQItemDtlsModel = {
    ProjectDirectoryId: '',
    BOQPackageId: '',
    UploadedSeqNo: 0,
    BOQUploadId: '',
    ClientSrNo: '',
    boqMakeValue: '',
    ANJSrNo: '',
    Title: '',
    SubTitle: '',
    ItemName: '',
    Specifications: '',
    RFQSendToVendors: 0,
    Location: '',
    Unit: '',
    Qty: 0,
    Rate: 0,
    BOQUploadStatus: '',
    BOQShortFormID: '',
    Amount: 0,
    BOQSize: '',
    BOQMake: '',
    BOQColor: '',
    BOQMaterial: '',
    BOQActivity: '',
    SupplyRate: 0,
    InstallationRate: 0,
    BOQTitleId: '',
    BOQSubTitleId: '',
    BOQItemId: '',
    MappedTitleName: '',
    MappedSubTitleName: '',
    MappedItemName: '',
    Remarks: '',
    ProjectShortName: '',
}

const initialItemDtlsRateComparisonValues: BOQItemDtlsRateComparisonModel = {
    ProjectDirectoryId: '',
    BOQPackageId: '',
    UploadedSeqNo: 0,
    BOQUploadId: '',
    ClientSrNo: '',
    boqMakeValue: '',
    ANJSrNo: '',
    Title: '',
    SubTitle: '',
    ItemName: '',
    Specifications: '',
    RFQSendToVendors: 0,
    Location: '',
    Unit: '',
    Qty: 0,
    Rate: 0,
    BOQUploadStatus: '',
    Amount: 0,
    BOQSize: '',
    BOQMake: '',
    BOQColor: '',
    BOQMaterial: '',
    BOQActivity: '',
    SupplyRate: 0,
    InstallationRate: 0,
    BOQTitleId: '',
    BOQSubTitleId: '',
    BOQItemId: '',
    MappedTitleName: '',
    MappedSubTitleName: '',
    MappedItemName: '',
    Remarks: '',
    ProjectShortName: '',
    RFQReceivedFromVendors: '',
    SelectedBOQRFQItemID: '',
}
const url = "/BoqUpload";

function EditBoqUpload(payload: BoqUploadUpdateDTO) {
    
    return customAxios.put(`${url}/EditBoqUpload`, payload, axiosRetryConfig);
}

function EditBoqUploads(payloads: any[]) {
    //BOQUploadId, BOQMake, BOQSize
    return customAxios.put(`${url}/EditBoqUploads`, payloads, axiosRetryConfig);
}
function EditBoqUploadImage(payload: BoqUploadUpdateDTO[],imageFileName : File) {
    const formData = new FormData();
    if (imageFileName != null) {
        formData.append("BOQUploadImage", imageFileName);
    }
    formData.append("data", JSON.stringify(payload));
    return customAxios.put(`${url}/EditBoqUploadImage`, formData, config.formDataConfig);
}


function BoqHistoryImport(file: File, payload: BoqUploadRequestModel) {
    const formData = new FormData();
    if (file != null)
        formData.append("file", file);
    // if (files != null && files.length > 0) {
    //     for (var i = 0; i < files.length; i++) {
    //         formData.append("file", files[i]);
    //     }
    // }
    formData.append("data", JSON.stringify(payload));
    return customAxios.post(
        `${url}/BoqHistoryImport`,
        formData,
        config.formDataConfig
    );
}

function MakeListImport(file: File, payload: MakeListUploadRequestModel) {
    const formData = new FormData();
    if (file != null)
        formData.append("file", file);
    formData.append("data", JSON.stringify(payload));
    return customAxios.post(
        `${url}/MakeListImport`,
        formData,
        config.formDataConfig
    );
}

function GetAllWorkSheetsInExcel(file: File) {
    const formData = new FormData();
    if (file != null)
        formData.append("file", file);
    // if (files != null && files.length > 0) {
    //     for (var i = 0; i < files.length; i++) {
    //         formData.append("file", files[i]);
    //     }
    // }
    //formData.append("data", JSON.stringify(payload));
    return customAxios.post(
        `${url}/GetAllWorkSheetsInExcel`,
        formData,
        config.formDataConfig
    );
}

function BoqDynamicHistoryImport(files: File[], payload: any) {
    const formData = new FormData();
    if (files != null && files.length > 0) {
        for (var i = 0; i < files.length; i++) {
            formData.append("file", files[i]);
        }
    }
    formData.append("data", JSON.stringify(payload));
    return customAxios.post(
        `${url}/BoqDynamicHistoryImport`,
        formData,
        config.formDataConfig
    );
}

function GetBoqExcelUploadHistory(projectDirectoryId: string, boqPackageId: string) {
    return customAxios.get(`${url}/GetBoqExcelUploadHistory?projectDirectoryId=${projectDirectoryId}&&boqPackageId=${boqPackageId}`, axiosRetryConfig);
}

// function GetBOQItemDtls(ProjectDirectoryID: string, BOQPackageID: string, ProcurementTypeId: string,WorkSheetName: string = '') {
//     var payload: any = {
// //         BOQPackageID: BOQPackageID,
// //         ProjectDirectoryID: ProjectDirectoryID,
// //         ProcurementTypeId: ProcurementTypeId,
// //         WorkSheetName: WorkSheetName
//     }
//     return customAxios.post(`${url}/GetBOQItemDtls`, payload, axiosRetryConfig);
// }

function GetBOQItemDtls(payload: RateComparisonRequestDTO) {
    return customAxios.post(`${url}/GetBOQItemDtls`, payload, axiosRetryConfig);
}
function getBOQItemDtlsForBOQUpload(payload: RateComparisonRequestDTO) {
    return customAxios.post(`${url}/GetBOQItemDtlsForBOQUpload`, payload, axiosRetryConfig);
}

function getBOQItemDtlsRateComparisons(payload: RateComparisonRequestDTO) {
    return customAxios.post(`${url}/GetBOQItemDtlsRateComparisons`, payload, axiosRetryConfig);
}


function getPackageWiseRateComparison(ProjectDirectoryID: string) {
    return customAxios.get(`${url}/GetPackageWiseRateComparison/${ProjectDirectoryID}`,  axiosRetryConfig);
}

function GetBOQItemDtlsRateComparisonSummary(payload: RateComparisonRequestDTO) {
    return customAxios.post(`${url}/GetBOQItemDtlsRateComparisonSummary`, payload, axiosRetryConfig);
}

function AllocateProcurementType(boqUploadIds: string[], ProcurementType: string) {
    var payload: any = {
        BoqUploadIds: boqUploadIds,
        ProcurementTypeId: ProcurementType
    }
    return customAxios.post(`${url}/AllocateProcurementType`, payload, axiosRetryConfig);
}

function AddTitleSubTitleOrItemByType(payload: BoqUploadUpdateDTO) {
    return customAxios.post(`${url}/AddTitleSubTitleOrItemByType`, payload, axiosRetryConfig);
}

function GetUploadedBoqs(siteId: string) {
    return customAxios.get(`${url}/GetUploadedBoqs/${siteId}`, axiosRetryConfig);
}

function GetUploadedMakeLists(projectDirectoryId: string) {
    return customAxios.get(`${url}/GetUploadedMakeLists?projectDirectoryId=${projectDirectoryId}`, axiosRetryConfig);
}

function GetMakeListsByProjectDirectoryAndPackage(projectDirectoryId: string, boqPackageId: string) {
    return customAxios.get(`${url}/GetMakeListsByProjectDirectoryAndPackage/${projectDirectoryId}/${boqPackageId}`, axiosRetryConfig);
}

function EditMakeLists(payloads: any[]) {
    //BOQUploadId, I_ItemName, I_Specifications, I_InstallationRate, I_SupplyRate, I_Rate, I_Make, I_Size 
    return customAxios.put(`${url}/EditMakeLists`, payloads, axiosRetryConfig);
}

export const boqUploadService = {
    initialFieldValues,
    initialBoqUploadUpdateValues,
    initialBOQItemDtlsValues,
    EditBoqUpload,
    EditBoqUploads,
    BoqHistoryImport,
    MakeListImport,
    BoqDynamicHistoryImport,
    GetBoqExcelUploadHistory,
    initialItemDtlsRateComparisonValues,
    GetAllWorkSheetsInExcel,
    GetBOQItemDtls,
    getBOQItemDtlsRateComparisons,
    GetBOQItemDtlsRateComparisonSummary,
    GetUploadedBoqs,
    getBOQItemDtlsForBOQUpload,
    initialFieldValuesForRateComparisonRequest,
    AllocateProcurementType,
    AddTitleSubTitleOrItemByType,
    GetUploadedMakeLists,
    GetMakeListsByProjectDirectoryAndPackage,
    EditMakeLists,
    EditBoqUploadImage,
    getPackageWiseRateComparison,
}