import { Guid } from "guid-typescript";
import { BoqTitleModel } from "../models/BoqTitleModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqTitleModel = {
    BoqtitleId: Guid.EMPTY,
    BoqtitleName: "",
    BoqpackageId: "",
    Active: false,
}

const url = "MBoqTitles";

function getAll() {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

function post(payload: BoqTitleModel) {
    return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}
function put(payload: BoqTitleModel) {
    return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}
function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(Id: number) {
    return customAxios.get(`${url}/GetById/${Id}`, axiosRetryConfig);
}
function getAllMBoqTitlesByPackageID(boqpackageId: number) {
    return customAxios.get(`${url}/GetAllMBoqTitlesByPackageID/${boqpackageId}`, axiosRetryConfig);
}

function getTitleSelectList() {
    return customAxios.get(`${url}/GetSelectList`, axiosRetryConfig);
}

function getTitleSelectListByPackageId(packageId: string) {
    return customAxios.get(`${url}/GetSelectListByPackageId/${packageId}`, axiosRetryConfig);
}

export const boqTitleService = {
    initialFieldValues,
    getAll,
    post,
    put,
    remove,
    getById,
    getAllMBoqTitlesByPackageID,
    getTitleSelectList,
    getTitleSelectListByPackageId
};