import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../utility/context/AuthContext";
import { ROLES, config } from "../../utility/Config";
import { Paper, Stack, alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { globalService } from "../../services/GlobalService";

export default function Header() {
  //const navigate = useNavigate();
  const location = useLocation();
  let showTopHeader = false;
  const navigate = useNavigate();
  //const [open, setOpen] = React.useState(true);
  if (location.pathname == "/") showTopHeader = true;
  // const navigateLogin = () => { navigate('/login');};
  const { auth } = React.useContext(AuthContext);
  let isLoggedIn = auth?.Token ? true : false;
  // const [societySubscription, setSocietySubscription] = useState(null);

  // const [accountAnchorEl, setAccountAnchorEl] =
  //   React.useState<null | HTMLElement>(null);

  // const openAccount = Boolean(accountAnchorEl);

  // const handleClick = (
  //   event: React.MouseEvent<HTMLElement>,
  //   mainMenu: string
  // ) => {
  //   if (mainMenu === "account") setAccountAnchorEl(event.currentTarget);

  // };

  // const handleClose = () => {
  //   setAccountAnchorEl(null);
  // };


   function GoHome() {
    if (auth && auth.Roles) {
      if (auth.Roles.some((x) => x === ROLES.Admin)) {
        navigate("/boqPackages");
      } else if (auth.Roles.some((x) => x === ROLES.Vendor)) {
        navigate("/quoteInput");
      } else if (auth.Roles.some((x) => x === ROLES.Vendor)) {
        navigate("/quoteInputForVendor");
      } else if (auth.Roles.some((x) => x === ROLES.Accounts)) {
        navigate("/dashboard");
      }
      else if (auth.Roles.some((x) => x === ROLES.Expense)) {
        navigate("/dashboard");
      }
      else if (auth.Roles.some((x) => x === ROLES.BOQ_Manager)) {
        navigate("/boqPackages");
      }
    } else {
      navigate("/");
    }
  }

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <>

      <Stack sx={{
        width: '100%',
        backgroundImage: 'url(/images/top1.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        //borderRadius: '8px',
        //padding: '20px',
        color: 'white',
        display: 'flex',
        //alignItems: 'center',
        //justifyContent: 'center',
        //textAlign: 'center'
      }}
        //spacing={2}
        direction={"row"}
        //direction={{ xs: 'column', sm: 'row' }}
        justifyContent={"space-between"}
      //margin={1}
      //display={"-webkit-box"}
      >
        {isLoggedIn ? (
          <>
            {globalService.roleMatch([ROLES.Vendor], auth) &&
              <>
                <Box
                  className="headerImg"
                  m={1}
                  pt={0}
                  component="img"
                  sx={{
                    maxHeight: { xs: 250, md: 300 }, // Decreased maxHeight for xs
                    maxWidth: { xs: 70, md: 200 }, // Decreased maxWidth for xs
                  }}
                  alt=""
                  src="/images/logo1.svg"
                  style={{ cursor: "pointer", height: 80 }}
                  onClick={() => GoHome()}
                />
                {/* <Box alignItems="center" >
              <Button
                id="account-button"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                // variant="contained"
                //className={`nav-btn`}
                disableElevation
                onClick={(e) => handleClick(e, "account")}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  fontSize: { xs: '13px', md: 'inherit' },
                  margin: { xs: '2px', md: '2px' },
                  textTransform: "none",
                  color: "black"
                }}
              >
                {auth?.UserName}
                <br />
                {auth?.Roles?.join(", ")}
              </Button>
              <StyledMenu
                id="account-menu"
                MenuListProps={{
                  "aria-labelledby": "account-button",
                }}
                anchorEl={accountAnchorEl}
                open={openAccount}
                onClose={handleClose}
              >
                <Link color="inherit" href={"/resetPassword/" + auth.Id} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Change Password
                  </MenuItem>
                </Link>
                <Link color="inherit" onClick={() => logout()} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Logout
                  </MenuItem>
                </Link>
              </StyledMenu>
            </Box> */}
                <Box
                  className="headerImg"
                  m={1}
                  pt={0}
                  component="img"
                  sx={{
                    maxHeight: { xs: 100, md: 250 }, // Decreased maxHeight for xs
                    maxWidth: { xs: 100, md: 250 }, // Decreased maxWidth for xs
                    filter: "brightness(100%) contrast(110%)"
                  }}
                  alt=""
                  src="/images/next2.png"
                  style={{ cursor: "pointer", height: 80 }}
                  onClick={() => GoHome()}
                />

              </>
            }
          </>
        ) : (
          <>
            <Box
              className="headerImg"
              m={1}
              pt={0}
              component="img"
              sx={{
                maxHeight: { xs: 100, md: 250 }, // Decreased maxHeight for xs
                maxWidth: { xs: 70, md: 200 }, // Decreased maxWidth for xs
              }}
              alt=""

              src="/images/logo1.svg"
              style={{ cursor: "pointer" }}
              onClick={() => GoHome()}
            />
            {location.pathname !== "/login" && <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                size="medium"
                sx={{ marginLeft: "auto" ,  marginRight: "10px",}}
                style={{ textTransform: "none" }}
              >
                <Link
                  href={"/login"}
                  sx={{
                    marginLeft: "auto",
                    color: "#fff",
                    textDecoration: "none",
                  }}
                >
                  LOGIN
                </Link>
              </Button>
            </Box>}
            {/* <Box
              className="headerImg"
              m={1}
              pt={0}
              component="img"
              sx={{
                maxHeight: { xs: 100, md: 250 }, // Decreased maxHeight for xs
                maxWidth: { xs: 100, md: 250 }, // Decreased maxWidth for xs
                filter: "brightness(100%) contrast(110%)"
              }}
              alt=""
              src="/images/next2.png"
              style={{ cursor: "pointer" }}
              onClick={() => GoHome()}
            /> */}
          </>
        )}
      </Stack>

    </>
  );
}

//export default Header;
