import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { RentAgreementsService } from '../../services/RentAgreementsService';
import useForm from '../../utility/hooks/UseForm';
import Controls from '../../utility/Controls.tsx/Controls';
import { globalService } from '../../services/GlobalService';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../helper/ConfirmDialog';

const RentDuesList = (props: any) => {
  const [rentDues, setRentDues] = useState([]);
  const [selectedrentIds, setSelectedRentIds] = useState<[]>([]);
  const navigate = useNavigate();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    //subTitle: "",
    onConfirm: () => { },
  });


  useEffect(() => {
    getRentDues();
  }, []);

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      RentAgreementsService.initialFieldValuesforGetRent,
      validate,
      props.setCurrentId
    );

  const getRentDues = () => {
    RentAgreementsService.getRentDues(values.FromDt).then((response) => {
      let result = response.data;
      setRentDues(result.list);
    });
  };
  function getDefaultDate() {
    const currentDate = new Date();
    const utcDate = globalService.getISODateOnlyForDatePicker(currentDate);
    return utcDate;
  }


  const handleGo = () => {
    if (validate()) {
      getRentDues();
    }
  };

  const handleConvertedRentSelectionChange = (selectedIds: any) => {
    setSelectedRentIds(selectedIds);
  };

  const RentDuesColumns: GridColDef[] = [
    {
      field: " Action",
      headerName: "Actions",
      width: 180,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack>
            <Button
              variant="contained"
              className="btnGrid"
              color="primary"
            // onClick={() =>
            //   navigate("/rentAgreements/" + params.row.RentAgreementId, {
            //     state: {
            //       row: params.row,
            //     },
            //   })
            // }
            >
              Generate Rent Expense
            </Button>
            <Button
              variant="contained"
              className="btnGrid"
              color="primary"
            // onClick={() =>
            //   navigate("/rentAgreements/" + params.row.RentAgreementId, {
            //     state: {
            //       row: params.row,
            //     },
            //   })
            // }
            >
              Generate Rent Deposit Expense
            </Button>
            <Button
              variant="contained"
              className="btnGrid"
              color="primary"
            // onClick={() =>
            //   navigate("/rentAgreements/" + params.row.RentAgreementId, {
            //     state: {
            //       row: params.row,
            //     },
            //   })
            // }
            >
              Generate Rent Refund Expense
            </Button>
          </Stack>
        );
      },
    },
    { field: "Project", headerName: "Project", width: 200 },
    {
      field: "RentAgreementNo",
      headerName: "Agreement No",
      width: 150,
    },
    {
      field: "RentAgreementStartOn",
      headerName: "Agreement Start On",
      width: 150,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "RentAgreementEndOn",
      headerName: "Agreement End On",
      width: 150,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "RentPerMonth", headerName: "Per Month", width: 150,
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "SecurityDeposit", headerName: "Security Deposit", width: 150,
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    { field: "ContactName", headerName: "Contact Name", width: 150 },
    { field: "ContactMail", headerName: "Contact Mail", width: 150 },
    { field: "ContactMobile", headerName: "Contact Mobile", width: 150 },
    { field: "Remark", headerName: "Remark", width: 100 },
  ];

  return (
    <>
      <Typography variant="h5" align="center">
        Rent Dues
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={2}>
              <Controls.ReactDatePicker
                label="As On"
                name="FromDt"
                max={new Date()}
                value={values.FromDt = values.FromDt || getDefaultDate()}
                onChange={(date: Date) =>
                  handleInputChange({
                    target: {
                      value: globalService.getISODateOnlyForDatePicker(date),
                      name: "FromDt",
                    },
                  })
                }
                error={errors.FromDt}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Button type="button" variant="contained" onClick={handleGo}>
                Go
              </Button>
            </Grid>
          </Grid>
          <div style={{ width: "100%" }}>
            <Grid item xs={12} sm={3}>
              <Stack direction="row" spacing={1} marginY={1}>
                {selectedrentIds.length > 0 && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handlecreatePoOpen}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title:
                            "Are you sure, want to send for Generate Rent's?",
                          onConfirm: () => {
                            // handleSelectForinsertDataForBOQPO();
                          },
                        });
                      }}
                    >
                      Generate Rent's
                    </Button>
                  </Grid>
                )}
                <p> {selectedrentIds.length} Rent's selected.</p>
              </Stack>
            </Grid>
            <DataGrid
              getRowId={(row) => row.RentAgreementId}
              rows={rentDues}
              columns={RentDuesColumns}
              columnHeaderHeight={30}
              autoHeight
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              checkboxSelection
              rowSelectionModel={selectedrentIds}
              isRowSelectable={(params) =>
                params.row.ShowRentGenerateExp === "Y"
              }
              disableRowSelectionOnClick
              onRowSelectionModelChange={
                handleConvertedRentSelectionChange
              }
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    RentAgreementsId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default RentDuesList;
