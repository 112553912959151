import {
    Stack,
    IconButton,
    Card,
    CardContent,
    Button,
    Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { mZonesService } from "../../services/MZoneService";

const MZoneList: React.FC = () => {
    const [zones, setZones] = useState([]);

    const navigate = useNavigate();

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { },
    });

    useEffect(() => {
        
        getZone();
    }, []);

    const getZone = () => {
        mZonesService.getAll().then((response) => {
            let result = response.data;
            setZones(result.list);
        })
    }

    const zoneColumns: GridColDef[] = [
        { field: "ZoneName", headerName: "Zone Name", width: 130, flex: 1,  },
        {
            field: "Active",
            headerName: "Active",
            width: 130,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack>

                        {/* {params.row.Active && "Yes"}
                    {!params.row.Active && "No"} */}
                        {params.row.Active && <DoneIcon color="success" />}
                        {!params.row.Active && <CloseIcon color="error" />}
                    </Stack>
                );
            },
        },
        {
            field: "ZoneApprovers",
            headerName: "Zone Approvers",
            width: 130,
            flex: 1,
            headerClassName: "bold-font",
            renderCell: (params) => {
              return (
                <Stack>
                  <Button
                    className="btnGrid"
                    variant="contained"
                    onClick={() =>
                      navigate("/zoneApprovers/" + params.row.ZoneId, {
                        state: {
                          row: params.row,
                        },
                      })
                    }
                  >
                    Approver
                  </Button>
                </Stack>
              );
            },

        },
        {
            field: "Actions",
            headerName: "Actions",
            type: "number",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={0}>
                        <IconButton size="small"
                            color="primary"
                            aria-label="add an alarm"
                            onClick={() => navigate("/editZone/" + params.row.ZoneId)}
                        >
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small"
                            aria-label="delete" color="error"
                            onClick={() => {
                                
                                setConfirmDialog({
                                    isOpen: true,
                                    title: 'Are you sure to delete this Zone ?',
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                        removeZone(params.row.ZoneId);
                                    },
                                });
                            }}
                        >
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>

                    </Stack>
                );
            },
        },
    ];

    const removeZone = (ZoneId: any) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });

        mZonesService.remove(ZoneId).then((response) => {
            if (response) {
                getZone();
            }
        });
    };


    return (
        <>
            <Typography variant="h5" align="center">
                Zone
            </Typography>
            <Card>
                <CardContent>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => navigate("/addZone")}
                    >
                        Add Record
                    </Button>

                    <div style={{ width: "100%" }}>
                        <DataGrid
                            getRowId={(row) => row.ZoneId}
                            rows={zones}
                            columns={zoneColumns}
                            columnHeaderHeight={30}
                            autoHeight={true}
                            // checkboxSelection
                            // disableRowSelectionOnClick
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}

                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns Id, the other columns will remain visible
                                        ZoneId: false,
                                    },
                                },
                                pagination: { paginationModel: { pageSize: 50 } },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                        />

                    </div>

                </CardContent>
            </Card>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />

        </>
    )
}

export default MZoneList