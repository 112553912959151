import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { GetRentAgreementsModel, RentAgreementsModel } from "../models/RentAgreementsModel";
import { config } from "../utility/Config";


const initialFieldValues: RentAgreementsModel = {
    RentAgreementId: Guid.EMPTY,
    ProjectDirectoryId: "",
    RentAgreementNo: "",
    RentAgreementStartOn: null,
    RentAgreementEndOn: null,
    RentExpenseGenerationOn: null,
    RentPerMonth: 0,
    SecurityDeposit: 0,
    RefundAmount: 0,
    SecurityDepositRefundedOn: null,
    ContactName: "",
    ContactMail: "",
    ContactMobile: "",
    Panno: "",
    BankName: "",
    Ifsccode: "",
    AccountNo: "",
    Remark: "",
    ClientId: "",
    SiteId: "",
    FilePath:"",
    ProjectDirectory: null,
    RentAgreementStatus: false
}
const initialFieldValuesforGetRent: GetRentAgreementsModel={
    UserID: "",
    RentAgreementStatusID: 0,
    FromDt: null,
    ToDt: null
}
const url = "RentAgreements";

function post(payload: RentAgreementsModel) {
    
    return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}

function put(payload: RentAgreementsModel , files : File[]) {
    const formData = new FormData(); 
    if (files != null && files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        formData.append("RentAgreementFile", files[i]);
      }
    }
    formData.append("data", JSON.stringify(payload));
    return customAxios.put(`${url}/Edit`, formData, config.formDataConfig);
};

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(ZoneApproversId: number) {
    return customAxios.get(`${url}/GetById/${ZoneApproversId}`, axiosRetryConfig);
}

function getAll () {  
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

function GetRentAgreement(payload: GetRentAgreementsModel) {
    
    return customAxios.post(`${url}/GetRentAgreement`, payload,axiosRetryConfig);
}

function getAllFileByRentAgreements (RentAgreementId:string) {  
    return customAxios.get(`${url}/GetAllFileByRentAgreements/${RentAgreementId}`, axiosRetryConfig);
}

function getRentDues(dateToChk : Date) {
    return customAxios.get(`${url}/GetRentDues/${dateToChk}`, axiosRetryConfig);
}

export const RentAgreementsService = {
    initialFieldValues,
    GetRentAgreement,
    initialFieldValuesforGetRent,
    post,
    put,
    remove,
    getById,
    getAll,
    getAllFileByRentAgreements,
    getRentDues
}