import React, { useEffect, useRef, useState } from 'react'
import { MultiSelectListModel, SelectListModel } from '../../models/ApiResponse';
import { ProjectDirectoryModel } from '../../models/ProjectDirectoryModel';
import { projectDirectoryService } from '../../services/ProjectDirectoryService';
import { useNavigate } from 'react-router-dom';
import { boqUploadService } from '../../services/BoqUploadService';
import { boqPackageService } from '../../services/BoqPackageService';
import { globalService } from '../../services/GlobalService';
import SearchIcon from '@mui/icons-material/Search';
import { mClientService } from '../../services/MClientService';
import { mSiteService } from '../../services/MSiteService';
import useForm from '../../utility/hooks/UseForm';
import ConfirmDialog from '../helper/ConfirmDialog';
import { Typography, Card, CardContent, Grid, Divider, CardHeader, Button, Stack, IconButton } from '@mui/material';
import { GridColDef, DataGrid, GridToolbar } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import Controls from '../../utility/Controls.tsx/Controls';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import PostAddIcon from '@mui/icons-material/PostAdd';

const BoqDynamicUpload = () => {
    const [clients, setClients] = useState<MultiSelectListModel[]>([]);
    const [sites, setSites] = useState<SelectListModel[]>([]);
    const [packages, setPackages] = useState<SelectListModel[]>([]);
    const [natures, setNatures] = useState<SelectListModel[]>([]);
    const [projectDirectory, setProjectDirectory] = useState<ProjectDirectoryModel>(projectDirectoryService.initialFieldValues);
    const [boqWorkSheets, setBoqWorkSheets] = useState<any[]>([]);
    const [boqExcelUploadErrors, setBoqExcelUploadErrors] = useState<any[]>([]);
    const dynamicFileInputRef = useRef<HTMLInputElement>(null);
    const [dynamicBoqFile, setDynamicBoqFile] = useState<File | null>();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { },
    });

    const validate = (fieldValues = values) => {
        let temp: any = { ...errors };
       
        if ("SiteID" in fieldValues)
            temp.SiteID = fieldValues.SiteID ? "" : "Site is required.";

        // if ("ProjectDirectoryID" in fieldValues)
        //     temp.ProjectDirectoryID = fieldValues.ProjectDirectoryID ? "" : "Project Directory is required.";

        // if ("BOQPackageID" in fieldValues)
        //     temp.BOQPackageID = fieldValues.BOQPackageID ? "" : "BOQ Package is required.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(boqUploadService.initialFieldValues, validate, '');

    const newBoqUpload = () => {
        setValues(boqUploadService.initialFieldValues);
    };

    const navigate = useNavigate();

    useEffect(() => {
        // if (dynamicBoqs.length == 0)
        //     getDynamicBoqs();
        if (sites.length == 0)
            getSites();

        if (packages.length == 0)
            getBoqPackages();

        if (natures.length == 0)
            setNatures(globalService.getBoqUploadNatures);
    }, []);

    // const getClients = () => {
    //     mClientService.GetAllMultiSelectList().then((response: any) => {
    //         setClients(response.data);
    //     });
    // };

    const getSites = () => {
        mSiteService.GetAllMultiSelectList().then((response: any) => {
            setSites(response.data);
        });
    };

    const getBoqPackages = () => {
        boqPackageService.getPackageSelectList().then((response: any) => {
            setPackages(response.data);
        });
    };

    const getProjectDirectory = (siteId: any) => {
        projectDirectoryService.GetBySiteId(siteId).then((response) => {
            let result = response.data;
            setProjectDirectory(result.row);
            if (result.row)
                values.ProjectDirectoryID = result.row.ProjectDirectoryId;
            else
                values.ProjectDirectoryID = '';
        });
    };
    const handleButtonClick = () => {
        if (dynamicFileInputRef.current) {
            dynamicFileInputRef.current.click(); // Trigger click event on file input
        }
    };
    const resetBoqUploadFile = () => {

        if (dynamicFileInputRef.current) {
            dynamicFileInputRef.current.value = ''; // Reset file input value
        }
        setDynamicBoqFile(null);
    };
    const onDynamicBoqFileChange = (fileInput: any) => {
        setDynamicBoqFile(fileInput.target.files[0]);
    };

    const getBoqWorskeets = () => {
        if (!dynamicBoqFile) {
            globalService.error('Please select BOQ excel file.');
            return;
        }
        if (validate()) {
            boqUploadService.GetAllWorkSheetsInExcel(dynamicBoqFile).then((response) => {
                if (response) {
                    let result = response.data;
                    if (result) {
                        if (result.isSuccess) {
                            setBoqWorkSheets(result.list);
                        } else {
                            globalService.error(result.message);
                        }
                    }
                }
            });
        }
    };

    const uploadDynamicBoqFile = () => {

        let k = boqWorkSheets;
        if (!dynamicBoqFile) {
            globalService.error('Please select BOQ excel file.');
            return;
        }
        values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;
        if (validate()) {
            // boqUploadService.BoqHistoryImport(dynamicBoqFile, values).then((response) => {
            //     if (response) {
            //         
            //         let result = response.data;
            //         if (result.list != null) {
            //             setBoqExcelUploadErrors(result.list);
            //         }
            //         if (result.isSuccess) {
            //             globalService.success(result.message);
            //         } else {
            //             globalService.error(result.message);
            //         }
            //     }
            // });
        }
    };
    const handleRowEdit = (updatedRow: any) => {
        // Perform your POST request with updatedRow data
        console.log('Updated row:', updatedRow);
    };

    const handleGridDropdownChange = (e: any, index: number, type: string) => {
        const { name, value } = e.target;
        const updatedBoqWorkSheets = [...boqWorkSheets];
        //updatedBoqWorkSheets[index].Nature = { ...updatedBoqWorkSheets[index], value };
        if (type == 'nature')
            updatedBoqWorkSheets[index].Nature = value;
        else if (type == 'package')
            updatedBoqWorkSheets[index].BOQPackageID = value;
        setBoqWorkSheets(updatedBoqWorkSheets);
    };

    const worksheetColumns: GridColDef[] = [
        { field: "ExistingWorkSheetName", headerName: "Worksheet Name", width: 130, flex: 1 },
        {
            field: "Nature", headerName: "Nature", width: 200,
            renderCell: (params) => {
                return (
                    <Grid container sx={{ marginTop: 1, marginBottom: 1 }}>
                        <Grid item xs={12} sm={12} >
                            <Controls.Select
                                sx={{ marginTop: 2 }}
                                name={"Natures_" + params.row.Id}
                                label="Nature"
                                required
                                showEmptyItem={true}
                                options={natures}
                                value={boqWorkSheets[params.row.Id].Nature ? boqWorkSheets[params.row.Id].Nature : ''}
                                onChange={(e: any) => {
                                    handleGridDropdownChange(e, params.row.Id, 'nature');
                                }}
                                error={errors.Nature}
                            />
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: "BOQPackageID", headerName: "BOQ Package", width: 130, flex: 1,
            renderCell: (params) => {
                if (params.row.Nature === 'Package') {
                    return (
                        <Grid container sx={{ marginTop: 1, marginBottom: 1 }}>
                            <Grid item xs={12} sm={12} >
                                <Controls.Select
                                    name={"BOQPackageID_" + params.row.Id}
                                    label="Package"
                                    required
                                    showEmptyItem={true}
                                    options={packages}
                                    value={boqWorkSheets[params.row.Id].BOQPackageID ? boqWorkSheets[params.row.Id].BOQPackageID : ''}
                                    onChange={(e: any) => {
                                        handleGridDropdownChange(e, params.row.Id, 'package');
                                    }}
                                    error={errors.BOQPackageID}
                                />
                            </Grid>
                        </Grid>
                    );
                }
                else {
                    return <span></span>;
                }
            },
        },
    ];

    const errorColumns: GridColDef[] = [
        { field: "AnjsrNo", headerName: "AnjsrNo", width: 130, flex: 1 },
        { field: "ClientSrno", headerName: "ClientSrno", width: 130, flex: 1 },
        { field: "ItemName", headerName: "ItemName", width: 130, flex: 1 },
        { field: "Specification", headerName: "Specification", width: 130, flex: 1 },
        { field: "Location", headerName: "Location", },
        { field: "Unit", headerName: "Unit", },
        { field: "Rate", headerName: "Rate", headerAlign: "right",
            cellClassName: "cell-right", valueFormatter: (params) => {
                return globalService.formatToIndianCurrency(params.value);
              }, },
        { field: "Qty", headerName: "Qty", headerAlign: "right",
            cellClassName: "cell-right" },
        { field: "Amount", headerName: "Amount", headerAlign: "right",
            cellClassName: "cell-right", valueFormatter: (params) => {
                return globalService.formatToIndianCurrency(params.value);
              },},
        { field: "Remarks", headerName: "Remarks", },
        { field: "Size", headerName: "Size", flex: 1 },
        { field: "Make", headerName: "Make", flex: 1 },
        { field: "ImportRemarks", headerName: "ImportRemarks", flex: 1 },
    ];

    return (
        <div>
            <Typography variant="h5" align='center'>Upload Dynamic BOQ </Typography>
            <Card>
                <CardContent>
                    <React.Fragment>
                        <Grid container spacing={3}>
                          
                            <Grid item xs={12} sm={3}>
                            <Controls.MuiAutocomplete
                  options={sites}
                  label="Site"
                  error={errors.SiteID}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "SiteID",
                        value: event ? event.value : "",
                      },
                    });

                    if (event) {
                      getProjectDirectory(event.value);
                    }
                  }}
                  value={
                    sites.find((item: any) => item.value === values.SiteID) ||
                    null
                  }
                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <input id="dynamicBoqSelectedFile"
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={dynamicFileInputRef}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={(event: any) => {
                                        onDynamicBoqFileChange(event);
                                    }}
                                />
                                <Button startIcon={<PostAddIcon />}
                                    variant="contained" color='success' onClick={handleButtonClick} >Browse...</Button>
                                {dynamicBoqFile && (
                                    <Stack direction="row" spacing={1}>
                                        <p>{dynamicBoqFile.name}</p>
                                        {/* Display other file details if needed */}
                                        <IconButton
                                            size="small"
                                            aria-label="delete"
                                            color="error"
                                            onClick={resetBoqUploadFile}
                                        >
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </Stack>
                                )}
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    startIcon={<SearchIcon />}
                                    onClick={() => getBoqWorskeets()}
                                >
                                    Show WorkSheets
                                </Button>
                            </Grid>
                        </Grid>
                        {boqWorkSheets && boqWorkSheets.length > 0 &&
                            <>
                                {/* <Stack direction="row" spacing={0} justifyContent="space-between"> */}
                                <Typography variant="h5" align='center' className='MuiCardHeader-title'>WorkSheet Mapping</Typography>
                                {/* </Stack> */}
                                <div>
                                    <DataGrid
                                        getRowId={(row) => row.Id}
                                        rows={boqWorkSheets}
                                        columns={worksheetColumns}
                                        columnHeaderHeight={30}
                                        //rowHeight={30}
                                        autoHeight={true}
                                        getRowHeight={() => "auto"}
                                        getEstimatedRowHeight={() => 200}
                                        // editMode='row'
                                        // onEditCellChangeCommitted={handleRowEdit}
                                        //loading={loading}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    // Hide columns Id, the other columns will remain visible
                                                    Id: false,
                                                },
                                            },
                                            pagination: { paginationModel: { pageSize: 50 } },
                                        }}
                                        slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                        pageSizeOptions={[10, 25, 50, 100]}
                                    />
                                </div>
                                <Stack direction="row" spacing={0} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        sx={{ marginTop: 2 }}
                                        variant="contained"
                                        color='primary'
                                        startIcon={<UploadIcon />}
                                        onClick={() => uploadDynamicBoqFile()}
                                    >
                                        Upload BOQ
                                    </Button>
                                </Stack>
                            </>
                        }
                    </React.Fragment>
                </CardContent>
            </Card>

            {boqExcelUploadErrors && boqExcelUploadErrors.length > 0 &&
                < Card >
                    <CardHeader title="BOQ Uploads" />
                    <CardContent>
                        <Grid container spacing={1}>

                        </Grid>
                        {boqExcelUploadErrors && boqExcelUploadErrors.length > 0 &&
                            <>
                                <Stack direction="row" spacing={0} justifyContent="space-between">
                                    <Typography variant="h5" align='center' className='MuiCardHeader-title'>Error Lists</Typography>
                                    {/* <Typography style={{ color: 'red', fontSize: '0.75rem' }}>Note: Start Date / End Date should be prefix with single quote (') in excel. (e.g. '01/Apr/2023)
                        </Typography> */}
                                </Stack>
                                <div>
                                    <DataGrid
                                        getRowId={(row) => row.BoqexcelUploadHistoryImportId}
                                        rows={boqExcelUploadErrors}
                                        columns={errorColumns}
                                        columnHeaderHeight={30}
                                        //rowHeight={30}
                                        autoHeight={true}
                                        getRowHeight={() => "auto"}
                                        getEstimatedRowHeight={() => 200}
                                        //loading={loading}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    // Hide columns Id, the other columns will remain visible
                                                    BoqexcelUploadHistoryImportId: false,
                                                },
                                            },
                                            pagination: { paginationModel: { pageSize: 50 } },
                                        }}
                                        slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                        pageSizeOptions={[10, 25, 50, 100]}
                                    />
                                </div>
                            </>
                        }
                    </CardContent>
                </Card>
            }

            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </div >
    )
}

export default BoqDynamicUpload
