import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Link,
  Typography,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  CardActions,
  DialogProps,
  Tab,
  Autocomplete,
  DialogActions,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import React, { useEffect, useState } from "react";
import { SelectListModel } from "../../models/ApiResponse";
import { MVendorService } from "../../services/MVendorService";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { boqrequisitionsService } from "../../services/BoqRequisitionService";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BoqrequisitionsModel } from "../../models/BoqRequisitionModel";
import { globalService } from "../../services/GlobalService";
import { useNavigate, useParams } from "react-router-dom";
import Draggable from "react-draggable";
import { AuthContext } from "../../utility/context/AuthContext";
import { ROLES, config } from "../../utility/Config";
import dayjs from "dayjs";
import { RFQService } from "../../services/RequestForQuoteService";
import { RequestForQuoteModel } from "../../models/RequestForQuoteModel";
import { BoqDocFileService } from "../../services/BoqdocFilesService";
import { BOQRFQPaymentTermsService } from "../../services/BOQRFQPaymentTermsService";
import { mPaymentTermsService } from "../../services/MPaymentTermsService";
import { MProcurementTypesService } from "../../services/MProcurementTypesService";
import UploadIcon from '@mui/icons-material/Upload';

const QuoteInput = (...props: any) => {
  const [vendors, setVendor] = useState<SelectListModel[]>([]);
  const [procurementTypes, setProcurementTypes] = useState<SelectListModel[]>([]);
  let navigate = useNavigate();
  const { procurementTypeId } = useParams();
  const [showCard, setShowCard] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [tabValue, setTabValue] = useState("0");
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  const [quotations, setQuotations] = useState<any[]>([]);
  const [actualQuotations, setActualQuotations] = useState<any[]>([]);
  const [viewDocsOpen, setViewDocsOpen] = React.useState(false);
  const [viewPaymentTermsOpen, setViewPaymentTermsOpen] = React.useState(false);
  const [viewDocsFiles, setViewDocsFiles] = useState([]);
  const [viewPaymentTerms, setViewPaymentTerms] = useState([]);
  const [allRfq, setAllRfq] = useState<any[]>([]);
  const [rfqsList, setRfqsList] = useState<SelectListModel[]>([]);



  const { auth } = React.useContext(AuthContext);

  const handleRateInputOpen = (row: any) => {
    setOpen(true);
  };
  const handleRateInputClose = () => {
    setOpen(false);
  };

  const openviewDocsDialog = (boqrfqId: any) => {
    setViewDocsOpen(true);
    getByBoqrfqId(boqrfqId);
  };

  const openviewPaymentTermsDialog = (bOQrfqID: any) => {
    setViewPaymentTermsOpen(true)
    getPaymentTermsByBoqrfqIdId(bOQrfqID);
  };

  const closeViewDocsDialog = () => {
    setViewDocsOpen(false);
  };
  const closeViewPaymentTermsDialog = () => {
    setViewPaymentTermsOpen(false);
  };

  // const validate1 = (fieldValues: any = values) => {
  //   let temp: any = { ...errors };
  //   if ("SupplyRate" in fieldValues)
  //     temp.SupplyRate =
  //       fieldValues.SupplyRate >= 0
  //         ? ""
  //         : fieldValues.SupplyRate <= 0
  //         ? "Supply Rate must be grater then 0"
  //         : "Supply Rate field is required";
  //   if ("InstallationRate" in fieldValues)
  //     temp.InstallationRate =
  //       fieldValues.InstallationRate >= 0
  //         ? ""
  //         : fieldValues.InstallationRate <= 0
  //         ? "Installation Rate must be grater then 0"
  //         : "Installation Rate field is required";
  //   if ("VendorRemarks" in fieldValues)
  //     temp.VendorRemarks = fieldValues.VendorRemarks
  //       ? ""
  //       : "Vendor Remarks field is required";
  //   setErrors({
  //     ...temp,
  //   });
  //   if (fieldValues === values)
  //     return Object.values(temp).every((x) => x === "");
  // };

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    if ("VendorId" in fieldValues)
      temp.VendorId = fieldValues.VendorId ? "" : "Please Select Vendor";
    if ("SupplyRate" in fieldValues)
      temp.SupplyRate =
        fieldValues.SupplyRate >= 0
          ? ""
          : fieldValues.SupplyRate <= 0
            ? "Supply Rate must be grater then 0"
            : "Supply Rate field is required";
    if ("InstallationRate" in fieldValues)
      temp.InstallationRate =
        fieldValues.InstallationRate >= 0
          ? ""
          : fieldValues.InstallationRate <= 0
            ? "Installation Rate must be grater then 0"
            : "Installation Rate field is required";
    if ("VendorRemarks" in fieldValues)
      temp.VendorRemarks = fieldValues.VendorRemarks
        ? ""
        : "Vendor Remarks field is required";

    if ("VendorMake" in fieldValues)
      temp.VendorMake = fieldValues.VendorMake
        ? ""
        : "Vendor Make field is required";
    if ("Anjremarks" in fieldValues)
      temp.Anjremarks = fieldValues.Anjremarks
        ? ""
        : "Anjremarks field is required";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const newUser = () => {
    setValues(MVendorService.initialFieldValues);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      RFQService.initialFieldValues,
      validate,
      props.setCurrentId
    );

  useEffect(() => {
    if (vendors.length === 0) getVendor();
    if (procurementTypes.length === 0) getProcurementType();
    //else {
    //   getAllMBoqTitlesByPackageID();
    // }
    // getBoqTitle();
  }, []);

  // Effect to log the tab value whenever it changes
  useEffect(() => {
    console.log("Tab changed:", tabValue);
  }, [tabValue]);

  function setFormValue(model: RequestForQuoteModel) {
    let newModel = {
      BoqrfqitemId: model.BoqrfqitemId,
      Boqrfqid: model.Boqrfqid || "",
      BoquploadId: model.BoquploadId,
      SupplyRate: model.SupplyRate || 0,
      InstallationRate: model.InstallationRate || 0,
      Rate: model.Rate || 0,
      VendorRemarks: model.VendorRemarks || "",
      VendorMake: model.VendorMake || "",
      Anjremarks: model.Anjremarks || "",
      BoqrfqitemStatusId: model.BoqrfqitemStatusId || "0",
      BoqrfqitemStatus: model.BoqrfqitemStatus || "",
      RateSubmittedOn: model.RateSubmittedOn || null,
      OrgQuotedSupplyRate: model.OrgQuotedSupplyRate || 0,
      OrgQuotedInstallationRate: model.OrgQuotedInstallationRate || 0,
      OrgQuotedRate: model.OrgQuotedRate || 0,
      //VendorId: model.VendorId || values.VendorId,
    };
    return newModel;
  }

  const getRateValue = (BoqrfqitemId: any) => {
    RFQService.getByID(BoqrfqitemId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.row));
      }
    });
  };

  const getVendor = () => {
    MVendorService.getMVendorSelectListByUserId(auth.Id).then((response) => {
      setVendor(response.data);
      if (response.data.length > 0) {
        let vendorId = response.data[0].Value;
        values.VendorId = vendorId;
        handleVendorChange(vendorId);
        //getRFQs(vendorId);
      }
    });
  };

  // const getRFQs = (vendorId:any) => {
  //   RFQService.selectRFQs(vendorId).then((response) => {
  //     setRfqsList(response.data);
  //   //   if (response.data.length > 0) {
  //   //   let boqrfqid = response.data[0].Value;
  //   //   values.Boqrfqid = boqrfqid;
  //   //   handleRfqChange(boqrfqid);
  //   // }
  //   });
  // };

  const handleVendorChange = (vendorId: any) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      VendorId: vendorId,
    }));
    if (tabValue === "1") {
      handleSubmitforhistory(values)
    } else {
      //getRequisitionBOQItemDtlsForVendors(vendorId," ", tabValue,null,null)
      getRFQsList(vendorId);
    }
  };

  const handleRfqChange = (boqrfqid: any) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      Boqrfqid: boqrfqid,
    }));
    values.Boqrfqid = boqrfqid;
    getRequisitionBOQItemDtlsForVendors(values.VendorId, boqrfqid, tabValue,null,null);
    setShowCard(true);
  };

  const getRFQsList = (vendorId: any) => {
    RFQService.getRFQs(vendorId).then((response) => {
      let result = response.data.list;
      setAllRfq(result)
    })
  }

  const getRequisitionBOQItemDtlsForVendors = (
    vendorId: any,
    boqrfqId: any,
    tabValue: any = "0",
    FromDt: any,
    ToDt: any,
  ) => {
    //vendorId, boqrfqId, tabValue
    values.vendorID =  vendorId;
    values.boqrfqId =  boqrfqId;
    values.boqrfqitemStatusId =  tabValue;
    values.FromDt =  FromDt;
    values.ToDt =  ToDt;
    RFQService
      .getRFQBOQItemDtlsForVendors(values)
      .then((response) => {
        let result = response.data.list;
        setQuotations(result);
        setActualQuotations(result);
      });
  };
  const handleSubmitforhistory = (e: React.FormEvent) => {
    e.preventDefault();
    getRequisitionBOQItemDtlsForVendors(values.VendorId, '', tabValue,values.FromDt,values.ToDt)
  };
  const getByBoqrfqId = (boqrfqId: any) => {
    //values.ProjectDirectoryID = ProjectDirectoryId;
    BoqDocFileService
      .getByBoqrfqId(boqrfqId)
      .then((response) => {
        let result = response.data;
        setViewDocsFiles(result.list);
      });
  };

  const getPaymentTermsByBoqrfqIdId = (boqrfqId: any) => {
    mPaymentTermsService.getPaymentTermsByBoqrfqId(boqrfqId).then((response) => {
      let result = response.data;
      setViewPaymentTerms(result.list);
    })
  };

  const handleProcessQuotationRowUpdate = (newRow: any, oldRow: any) => {
    const updatedField = Object.keys(newRow).find(
      (key) => newRow[key] !== oldRow[key]
    );
    let actualData = actualQuotations.filter(
      (x) => x.BoqrfqitemId === newRow.BoqrfqitemId
    )[0];

    //let isError = false;
    if (newRow.InstallationRate < 0) {
      globalService.error("InstallationRate cannot be < 0");
      newRow.InstallationRate = actualData.InstallationRate;
    }
    if (newRow.SupplyRate < 0) {
      globalService.error("SupplyRate cannot be < 0");
      newRow.SupplyRate = actualData.SupplyRate;
    }

    if (
      (newRow.InstallationRate &&
        actualData.InstallationRate !== newRow.InstallationRate) ||
      (newRow.SupplyRate && actualData.SupplyRate !== newRow.SupplyRate) ||
      (newRow.VendorRemarks &&
        actualData.VendorRemarks !== newRow.VendorRemarks) ||
      (newRow.VendorMake && actualData.VendorMake !== newRow.VendorMake) ||
      (newRow.ANJRemarks && actualData.ANJRemarks !== newRow.ANJRemarks) ||
      (newRow.InstallatioANJRemarksnRate &&
        actualData.ANJRemarks !== newRow.ANJRemarks)
    ) {
      newRow.IsEdited = true;
    } else {
      newRow.IsEdited = false;
    }
    setQuotations((prevRows: any) =>
      prevRows.map((row: any) =>
        row.BoqrfqitemId === newRow.BoqrfqitemId ? newRow : row
      )
    );
    return newRow;
  };

  const getQuotationRowClassName = (params: any) => {
    let isRowEdited = quotations.some(
      (x) => x.BoqrfqitemId === params.id && x.IsEdited == true
    );
    return isRowEdited ? "editedRow" : "";
  };

  const handleProcessQuotationRowUpdateError = (error: any) => {
    console.error(error);
  };

  const EditBoqRequisitions = () => {
    var modifiedRows = quotations.filter((x) => x.IsEdited == true);
    if (modifiedRows.length <= 0) {
      globalService.error("No records to modify.");
      return;
    }
    const filteredArray = modifiedRows.map(
      ({
        BoqrfqitemId,
        InstallationRate,
        SupplyRate,
        VendorRemarks,
        VendorMake,
        ANJRemarks,
      }) => ({
        BoqrfqitemId,
        InstallationRate,
        SupplyRate,
        VendorRemarks,
        VendorMake,
        ANJRemarks,
      })
    );
    RFQService
      .editBOQRFQs(filteredArray)
      .then((response) => {
        if (response) {
          let result = response.data;
          if (result.isSuccess) {
            globalService.success(result.message);
            getRequisitionBOQItemDtlsForVendors(values.VendorId, ' ', tabValue,null,null);
          } else {
            globalService.error(result.message);
          }
        }
      });
  };

  const handleTabChange = (event: React.SyntheticEvent, newvalue: string) => {
    if (values.VendorId != "") {
      setTabValue(newvalue);
      getRequisitionBOQItemDtlsForVendors(values.VendorId, "", newvalue,null,null);
    } else {
      setTabValue(newvalue);
      globalService.error("Please Select Vendor");
    }
  };


  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(`${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`, "_blank");
  };

  const allRFQsColumn: GridColDef[] = [
    {
      field: "Project",
      headerName: "Project",

      flex: 1,
      renderCell: (params) => {
        return (
          <div onClick={() => handleRfqChange(params.row.BOQRFQID)}>
            {params.value}
          </div>
        )
      },
    },
    {
      field: "BOQRFQNo",
      headerName: "RFQ No",
      width: 150,
      //width: 100,
      // renderCell: (params) =>  {
      //   return (
      //     <div onClick={() => handleRfqChange(params.row.BOQRFQID)}>
      //       {params.value}
      //     </div>
      //   ) 
      // },
      renderCell: (params) => (
        <>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleRfqChange(params.row.BOQRFQID)}
          >
            {params.value}
          </Link>
        </>
      ),
    },
    {
      field: "BOQRFQDate",
      headerName: "RFQ Date",
      width: 150,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",

    },
    {
      field: "TotItems",
      headerName: "Total Items",
      width: 190,
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    {
      field: "TotFilledItems",
      headerName: "Total Filled Items",
      width: 190,
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    {
      field: "TotPendingItems",
      headerName: "Total Pending Items",
      width: 190,
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    // {
    //   field: "BOQRFQStatus",
    //   headerName: "RFQ Status",
    //   flex:1,
    //   renderCell: (params) =>  {
    //     return (
    //       <div onClick={() => handleRfqChange(params.row.BOQRFQID)}>
    //         {params.value}
    //       </div>
    //     ) 
    //   },
    //   //width: 100,
    // },
  ];

  const quoteColumns: GridColDef[] = [

    // {//   field: "SubTitle",
    //   headerName: "SubTitle",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Link
    //       sx={{ cursor: "pointer" }}
    //       //   onClick={() => getMeetingPopup(params.row)} //onClick={() => getMeetingPopup(params.row)}
    //     >
    //       {params.row.SiteName}
    //     </Link>
    //   ),
    // },
    // {
    //   field: "Project",
    //   headerName: "Project",
    //   width: 100,
    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    { field: "ClientSrNo", headerName: "Client Sr. No.", flex: 1 },

    {
      field: "BOQDescription",
      headerName: "BOQ Description",
      width: 350,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    { field: "Unit", headerName: "Unit", flex: 1 },
    {
      field: "Qty", headerName: "Qty",
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    // { field: "SupplyRate", headerName: "Supply Rate", flex: 1 },
    // { field: "InstallationRate", headerName: "Installation Rate", flex: 1 },
    // { field: "Rate", headerName: "Rate", flex: 1 },

    { field: "BOQMake", headerName: "BOQ Make" },
    {
      field: "SupplyRate",
      headerName: "Supply Rate",
      type: "number",

      headerAlign: "right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
      width: 80,
      editable: true,

    },
    {
      field: "InstallationRate",
      headerName: "Installation Rate",

      type: "number",
      width: 80,
      editable: true,
      cellClassName: "editableColumn",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "VendorMake",
      headerName: "Vendor Make",
      width: 150,
      editable: true,
      cellClassName: "editableColumn",
    },
    {
      field: "VendorRemarks",
      headerName: "Vendor Remarks",
      width: 200,
      editable: true,
      cellClassName: "editableColumn",
    },

    {
      field: "ANJRemarks", headerName: "ANJ Remarks", width: 150,
      editable: globalService.roleMatch([ROLES.Vendor], auth) ? false : true,
      cellClassName: 'editableColumn'
    },
    //{ field: "BOQSize", headerName: "BOQ Size" },
    // { field: "BOQRequisitionHdrStatus", headerName: "BOQ RequisitionHdr Status" },
    // { field: "VendorRemarks", headerName: "Vendor Remarks" },
    // { field: 'Action', headerName: 'Action', flex: 1,
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 120,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Stack direction="row" spacing={0}>
    //           <Button
    //             variant="contained"
    //             onClick={() => {
    //               handleRateInputOpen(params.row);
    //               getRateValue(params.row.BoqrfqitemId);
    //             }}
    //           >
    //             Rate Input
    //           </Button>
    //         </Stack>
    //       </>
    //     );
    //   },
    // },
    // { field: "BOQRFQNo", headerName: "RFQ No" },
    // { field: "BOQRFQDate", headerName: "RFQ Date",
    //   valueFormatter: (params) =>
    //     params.value ? dayjs(params.value).format("DD-MMM-YYYY") : ""
    // },
    {
      field: "ViewDocs",
      headerName: "Docs",
      width: 80,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={
                () => {
                  openviewDocsDialog(params.row.BOQRFQID);
                }
              }
            >
              View ({params.row.TotFiles})
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "BOQRFQID",
      headerName: "Terms & Condition",
      width: 80,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={
                () => {
                  openviewPaymentTermsDialog(params.row.BOQRFQID);
                }
              }
            >
              View ({params.row.TotTerms})
            </Button>
          </Stack>
        );
      },
    },

  ];
  const quoteColumnsforHistory: GridColDef[] = [
    // {
    //   field: "Project",
    //   headerName: "Project",
    //   width: 100,
    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    { field: "ClientSrNo", headerName: "Client Sr. No.", width: 100, },
    {
      field: "BOQRFQNo",
      headerName: "RFQ No",

      width: 100,
      renderCell: (params) => {
        return (
          <div onClick={() => handleRfqChange(params.row.BOQRFQID)}>
            {params.value}
          </div>
        )
      },
    },
    {
      field: "BOQRFQDate",
      headerName: "RFQ Date",
      width: 130,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",

    },
    {
      field: "BOQDescription",
      headerName: "BOQ Description",
      width: 350,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    // { field: "Location", headerName: "Location", width: 100 },
    {
      field: "Unit", headerName: "Unit",
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    {
      field: "Qty", headerName: "Qty",
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    { field: "BOQMake", headerName: "BOQ Make" },
    {
      field: "SupplyRate",
      headerName: "Supply Rate",
      width: 100,

      headerAlign: "right",
      cellClassName: "cell-right",valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "InstallationRate",
      headerName: "Installation Rate",
      width: 100,

      headerAlign: "right",
      cellClassName: "cell-right",valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "Rate", headerName: "Rate", width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    // { field: "Remarks", headerName: "Remarks", width: 200 },
    { field: "VendorMake", headerName: "Vendor Make", width: 150 },
    { field: "VendorRemarks", headerName: "Vendor Remarks", width: 200, renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />, },
    {
      field: "ANJRemarks", headerName: "ANJ Remarks", width: 200, cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    {
      field: "RateSubmittedOn",
      headerName: "Rate Submitted On",
      width: 130,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "ViewDocs",
      headerName: "Docs",
      width: 80,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={
                () => {
                  openviewDocsDialog(params.row.ProjectDirectoryID);
                }
              }
            >
              View ({params.row.TotFiles})
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "BOQRFQID",
      headerName: "Terms & Condition",
      width: 80,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={
                () => {
                  openviewPaymentTermsDialog(params.row.BOQRFQID);
                }
              }
            >
              View ({params.row.TotTerms})
            </Button>
          </Stack>
        );
      },
    },
  ];

  const boqDocumentviewColumns: GridColDef[] = [

    {
      field: "FileName", headerName: "File Name", flex: 1,
      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={(event) => handleFileClick(event, params.row)}
        >
          {params.row.FileName}
        </Link>
      ),
    },
    {
      field: "UserName",
      headerName: "Created By",
      width: 200,
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
  ]

  const BoqPaymentTermsColumns: GridColDef[] = [

    {
      field: "PaymentTerm", headerName: "Term & Conditions", flex: 1,
    },
  ];
  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 6);
    return oneMonthsAgo;
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //  values.VendorId =
    if (validate()) {
      values.OrgQuotedSupplyRate = values.SupplyRate;
      values.OrgQuotedInstallationRate = values.InstallationRate;
      values.OrgQuotedRate = values.Rate;

      RFQService.put(values).then((response) => {
        let result = response.data;
        if (response) {
          globalService.success(result.message);
          handleRateInputClose();
          getRequisitionBOQItemDtlsForVendors(values.VendorId, '', tabValue,null,null);
        } else {
          globalService.error(result.message);
        }
      });
    }
  };
  const getProcurementType = () => {
    MProcurementTypesService.getAllSelectList().then(
      (response: any) => {
        setProcurementTypes(response.data);
        if (procurementTypeId) {
          setValues((prevValues: any) => ({
            ...prevValues,
            ProcurementTypeId: procurementTypeId //response.data[0].Value,
          }));
        }
      }
    );
  };
  return (
    <>
      <Typography variant="h5" align="center">
        Quote Input
      </Typography>
      <TabContext value={tabValue}>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="size-small-outlined"
              size="small"
              options={vendors}
              getOptionLabel={(option) => option.Text}
              value={
                vendors.find((item) => item.Value === values.VendorId) || null
              }
              onChange={(event, newValue) => {
                handleInputChange({
                  target: {
                    name: "VendorId",
                    value: newValue ? newValue.Value : "",
                  },
                });
                handleVendorChange(newValue.Value);
                getRequisitionBOQItemDtlsForVendors("", "", tabValue,null,null)
                //getRFQs(newValue.Value)
              }}
              disabled={globalService.roleMatch([ROLES.Vendor], auth)}
              renderInput={(params) => (
                <Controls.Input
                  {...params}
                  label="Vendors"
                  error={!!errors.VendorId}
                  helperText={errors.VendorId}
                />
              )}
            />
          </Grid>
      
        
          <Grid item xs={12} sm={2}>
            <Controls.Select
              name="ProcurementTypeId"
              label="Procurement Type"
              required
              disabled={procurementTypeId ? true : false}
              showEmptyItem={true}
              options={procurementTypes}
              value={
                procurementTypes.length > 0
                  ? values.ProcurementTypeId
                  : ""
              }
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              error={errors.ProcurementTypeID}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Autocomplete
              id="size-small-outlined"
              size="small"
              options={rfqsList}
              getOptionLabel={(option) => option.Text}
              value={
                rfqsList.find((item) => item.Value === values.Boqrfqid) || null
              }
              onChange={(event, newValue) => {
                handleInputChange({
                  target: {
                    name: "Boqrfqid",
                    value: newValue ? newValue.Value : "",
                  },
                });
                handleRfqChange(newValue.Value);
              }}
              disabled={globalService.roleMatch([ROLES.Vendor], auth)}
              renderInput={(params) => (
                <Controls.Input
                  {...params}
                  label="RFQ No."
                  error={!!errors.Boqrfqid}
                  helperText={errors.Boqrfqid}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <TabList onChange={handleTabChange} aria-label="BOQ">
              <Tab label="Pending" value="0" />
              <Tab label="History" value="1" />
            </TabList>
          </Grid>
        </Grid>
        <>
          <TabPanel value="0">

            <Card>
              <CardContent>
                <div style={{ height: "auto", width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.BOQRFQID}
                    rows={allRfq}
                    columns={allRFQsColumn}
                    columnHeaderHeight={30}
                    autoHeight={false}
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          Id: false,
                          ANJRemarks: globalService.roleMatch([ROLES.Vendor], auth) ? false : true
                        },
                      },
                      pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
              </CardContent>

            </Card>
            {showCard && <Card>
              <>
                <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      sx={{ marginTop: 2 }}
                      variant="contained"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={() => EditBoqRequisitions()}
                    >
                      Submit Quote's
                    </Button>

                    <Button
            style={{ marginRight: '2vh' }}
            variant="contained"
            color="success"
            startIcon={<UploadIcon />}
            disabled={true} 
            onClick={() =>
              navigate("/quoteInputExcelUpload/"  + values.VendorId)
            }
          >
            Quote Import Excel
          </Button>
                  </Stack>
                </CardActions>
              </>
              <CardContent>
                <div style={{ height: 650, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.BoqrfqitemId}
                    rows={quotations}
                    columns={quoteColumns}
                    columnHeaderHeight={30}
                    autoHeight={false}
                    getRowClassName={getQuotationRowClassName}
                    processRowUpdate={handleProcessQuotationRowUpdate}
                    onProcessRowUpdateError={
                      handleProcessQuotationRowUpdateError
                    }
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          Id: false,
                          ANJRemarks: globalService.roleMatch([ROLES.Vendor], auth) ? false : true,
                        },
                      },
                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                <Stack spacing={2} direction="row">
                  <Button
                    sx={{ marginTop: 2 }}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() => EditBoqRequisitions()}
                  >
                    Submit Quote's
                  </Button>
                </Stack>
              </CardActions>
            </Card>}
          </TabPanel>
          <TabPanel value="1">
          <form autoComplete="off" noValidate onSubmit={handleSubmitforhistory}>
            <Card>
              <CardContent>
                <React.Fragment>
                  <Grid container spacing={2}>
                  <Grid item xs={4} sm={2}>
                      <Controls.ReactDatePicker
                        label="From Date"
                        // min={values.FromDt}
                        max={new Date()}
                        value={
                          (values.FromDt = values.FromDt || globalService.getISODateOnlyForDatePicker(getDefaultDate()))
                        }
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnlyForDatePicker(date),
                              name: "FromDt",
                            },
                          });
                        }}
                        error={errors.FromDt}
                      />
                    </Grid>

                    <Grid item xs={4} sm={2}>
                      <Controls.ReactDatePicker
                        label="To Date"
                        min={values.FromDt}
                        max={new Date()}
                        value={(values.ToDt = values.ToDt || globalService.getISODateOnlyForDatePicker(new Date()))}
                        onChange={(date: any) => {
                          handleInputChange({
                            target: {
                              value: globalService.getISODateOnlyForDatePicker(date),
                              name: "ToDt",
                            },
                          });
                        }}
                        error={errors.ToDt}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <Button type="submit" variant="contained">
                        Go
                      </Button>
                    </Grid>
                  </Grid>

                  <CardContent>
                  <div style={{ height: 650, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.BoqrfqitemId}
                    rows={quotations}
                    columns={quoteColumnsforHistory}
                    columnHeaderHeight={30}
                    autoHeight={false}
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          Id: false,
                          ANJRemarks: globalService.roleMatch([ROLES.Vendor], auth) ? false : true,
                        },
                      },
                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
                  </CardContent>
                </React.Fragment>
              </CardContent>
            </Card>
          </form>
            {/* <Card>
              <CardContent>
                <div style={{ height: 650, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.BoqrfqitemId}
                    rows={quotations}
                    columns={quoteColumnsforHistory}
                    columnHeaderHeight={30}
                    autoHeight={false}
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          Id: false,
                          ANJRemarks: globalService.roleMatch([ROLES.Vendor], auth) ? false : true,
                        },
                      },
                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
              </CardContent>
            </Card> */}
          </TabPanel>
        </>
      </TabContext>

      <div>
        <Draggable>
          <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleRateInputClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, textAlign: "center" }}
              id="customized-dialog-title"
            >
              <Grid>Rate Inputs</Grid>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleRateInputClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card>
                  <CardContent>
                    <React.Fragment>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Controls.Input
                            label="Supply Rate"
                            name="SupplyRate"
                            value={values.SupplyRate}
                            onChange={handleInputChange}
                            error={errors.SupplyRate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controls.Input
                            label="Installation Rate"
                            name="InstallationRate"
                            value={values.InstallationRate}
                            onChange={handleInputChange}
                            error={errors.InstallationRate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Controls.Input
                            label="Vendor Remark"
                            name="VendorRemarks"
                            value={values.VendorRemarks}
                            onChange={handleInputChange}
                            error={errors.VendorRemarks}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Controls.Input
                            label="Vendor Make"
                            name="VendorMake"
                            value={values.VendorMake}
                            onChange={handleInputChange}
                            error={errors.VendorMake}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Controls.Input
                            label="Anj Remarks"
                            name="Anjremarks"
                            value={values.Anjremarks}
                            onChange={handleInputChange}
                            error={errors.Anjremarks}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  </CardContent>
                  <CardActions
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Stack spacing={2} direction="row">
                      <Button type="submit" variant="contained">
                        Submit
                      </Button>
                    </Stack>
                  </CardActions>
                </Card>
              </form>
            </DialogContent>
          </Dialog>
        </Draggable>
      </div>


      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={viewDocsOpen}
        onClose={closeViewDocsDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          View Documents
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeViewDocsDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
          <>

            <div style={{ width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.BoqdocFileId}
                rows={viewDocsFiles}
                columns={boqDocumentviewColumns}
                columnHeaderHeight={30}
                autoHeight={true}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
              />
            </div>

          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeViewPaymentTermsDialog} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={viewPaymentTermsOpen}
        onClose={closeViewPaymentTermsDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          View Term's And Conditions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeViewPaymentTermsDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
          <>
            <div style={{ width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.PaymentTermId}
                rows={viewPaymentTerms}
                columns={BoqPaymentTermsColumns}
                columnHeaderHeight={30}
                autoHeight={true}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
              />
            </div>

          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeViewPaymentTermsDialog} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuoteInput;
