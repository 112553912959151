import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { ZoneModel } from "../models/MZonesModel";
import { OurOfficesModel } from "../models/OurOfficesModel";

const initialFieldValues: OurOfficesModel = {
    OurOfficesId: Guid.EMPTY,
    OurOfficesDesc: '',
}

const url = "OurOffices";


function getById(OurOfficesId: number) {
    return customAxios.get(`${url}/GetById/${OurOfficesId}`, axiosRetryConfig);
}

function getOurOfficeSelectList() {
    return customAxios.get(`${url}/GetOurOfficeSelectList`, axiosRetryConfig);
}

export const ourOfficesService = {
    initialFieldValues,
    getById,
    getOurOfficeSelectList

}