
import { Guid } from "guid-typescript";
import { MProcurementTypesModel } from "../models/MProcurementTypesModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";


const initialFieldValues: MProcurementTypesModel = {
  ProcurementTypeId: Guid.EMPTY,
  ProcurementTypeName: "",
  Active: false,
};

const url = "MProcurementTypes";

function getAllSelectList() {
  return customAxios.get(`${url}/GetAllSelectList`, axiosRetryConfig);
}

function getSelectListForLoggedInUser() {
  return customAxios.get(`${url}/GetSelectListForLoggedInUser`, axiosRetryConfig);
}

function getForMenu() {
  return customAxios.get(`${url}/GetForMenu`, axiosRetryConfig);
}
export const MProcurementTypesService = {
  initialFieldValues,
  getAllSelectList,
  getSelectListForLoggedInUser,
  getForMenu,
};
