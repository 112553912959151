import { Card, CardHeader, CardContent, Typography, CardActions, Stack, Button, AppBar, Tab, Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Draggable from 'react-draggable'
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from '../../utility/hooks/UseForm'
import { mEmployeesService } from '../../services/MEmployeeService'
import { MultiSelectListModel, SelectListModel } from '../../models/ApiResponse'
import { globalService } from '../../services/GlobalService'
import { userService } from '../../services/UserService'

const Example = () => {

    const [tabValue, setTabValue] = React.useState("1");
    const [nestedTabValue, setNestedTabValue] = React.useState('21');
    const draggableRef = useRef(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [stateList, setStateList] = useState<MultiSelectListModel[]>([]);
    //const [mappedStateList, setMappedStateList] = useState<MultiSelectListModel[]>([]);

    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' }
    ];
    // const stateList = [
    //     { Value: '76f718ff-7333-4c73-ad6d-2b88947d04e1', Text: 'Andaman & Nicobar Islands' },
    //     { Value: '11f718ff-7333-4c73-ad6d-2b88947d04e1', Text: 'Andhra Pradesh' },
    //     { Value: '12f718ff-7333-4c73-ad6d-2b88947d04e2', Text: 'Arunachal Pradesh' },
    //     { Value: '13f718ff-7333-4c73-ad6d-2b88947d04e3', Text: 'Assam' },
    //     { Value: '14f718ff-7333-4c73-ad6d-2b88947d04e4', Text: 'Bihar' },
    //     { Value: '15f718ff-7333-4c73-ad6d-2b88947d04e5', Text: 'Chandigarh' },
    //     { Value: '16f718ff-7333-4c73-ad6d-2b88947d04e6', Text: 'Chhattisgarh' }
    // ]

    const handleMultiSelectChange = (selected: any) => {
        setSelectedOptions(selected);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleNestedTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setNestedTabValue(newValue);
    };

    const validate = (fieldValues = values) => {
        let temp: any = { ...errors };

        if ("StateId" in fieldValues)
            temp.StateId = fieldValues.StateId ? "" : "State  is required.";

        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === '');
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(mEmployeesService.initialFieldValues, validate, '');

    useEffect(() => {
        if (stateList.length === 0) getStateList();
    }, []);

    const getStateList = () => {
        userService.getStateSelectList().then((response) => {
            setStateList(response.data);
            //setMappedStateList(globalService.mapToMultiSelectList(response.data));
        });
    }

    const handleSubmit = (e: React.FormEvent) => {
        
        e.preventDefault();
        if (validate()) {
            globalService.success("No Errors");
        }

    }
    return (

        <>
            <Draggable nodeRef={draggableRef}>
                <div ref={draggableRef} style={{ width: '100px', height: '100px', backgroundColor: 'lightblue' }}>
                    Drag me
                </div>
            </Draggable>

            <div>
                <Card>
                    <CardHeader title="Title Goes Here" />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles, with over 6,000
                            species, ranging across all continents except Antarctica
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Stack spacing={2} direction="row">
                            <Button type="submit" variant="contained">Submit</Button>
                            <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/admin">Back To List</Button>
                        </Stack>
                    </CardActions>
                </Card>

                <TabContext value={tabValue}>
                    <AppBar position="static">
                        <TabList onChange={handleTabChange} aria-label="nested tabs example">
                            <Tab label="Tab 1" value="1" />
                            <Tab label="Tab 2" value="2" />
                            <Tab label="Tab 3" value="3" />
                        </TabList>
                    </AppBar>
                    <TabPanel value="1">Tab 1 Content</TabPanel>
                    <TabPanel value="2">
                        <TabContext value={nestedTabValue}>
                            <AppBar position="static">
                                <TabList onChange={handleNestedTabChange} aria-label="nested tabs example">
                                    <Tab label="Nested Tab 1" value="21" />
                                    <Tab label="Nested Tab 2" value="22" />
                                    <Tab label="Nested Tab 3" value="23" />
                                </TabList>
                            </AppBar>
                            <TabPanel value="21">Nested Tab 1 Content</TabPanel>
                            <TabPanel value="22">Nested Tab 2 Content</TabPanel>
                            <TabPanel value="23">Nested Tab 3 Content</TabPanel>
                        </TabContext>
                    </TabPanel>
                    <TabPanel value="3">Tab 3 Content</TabPanel>
                </TabContext>
            </div>
            <form autoComplete="off" noValidate
                onSubmit={handleSubmit}>
                <Card>
                    <CardHeader title="Title Goes Here" />
                    <CardContent>
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    <Controls.MuiAutocomplete
                                        options={stateList}
                                        label="Select State"
                                        error={errors.StateId}
                                        onChange={(event: MultiSelectListModel) => {
                                            handleInputChange({
                                                target: {
                                                    name: "StateId",
                                                    value: event ? event.value : "",
                                                }
                                            });
                                        }}
                                        value={stateList.find((item) => item.value === values.StateId) || null}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    </CardContent>
                    <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                        <Stack spacing={2} direction="row">
                            <Button type="submit" variant="contained">
                                Submit
                            </Button>
                        </Stack>
                    </CardActions>
                </Card>

                <Grid container spacing={3}>
                    <Grid item xs={12}>

                        <Typography variant="h6">Header</Typography>

                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <Typography variant="h6">Main Content</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                Left Content
                            </Grid>
                            <Grid item xs={12} md={6}>
                                Right Content
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <Typography variant="h6">Sidebar</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                Sidebar Top
                            </Grid>
                            <Grid item xs={6}>
                                Sidebar Bottom
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>

                        <Typography variant="h6">Footer</Typography>

                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default Example



