import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MultiSelectListModel,
  SelectListModel,
} from "../../models/ApiResponse";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import PersonIcon from "@mui/icons-material/Person";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import LanIcon from "@mui/icons-material/Lan";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Collapse,
  Stack,
  IconButton,
  CardContent,
  Button,
  CardActions,
  Typography,
  Card,
  Grid,
  Divider,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps,
  Box,
  Tab,
  Tabs,
  styled,
  AppBar,
  Autocomplete,
  TextField,
  Link,
  Paper,
  Avatar,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderEditCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import ConfirmDialog from "../helper/ConfirmDialog";
import { globalService } from "../../services/GlobalService";
import useForm from "../../utility/hooks/UseForm";
import { mSiteService } from "../../services/MSiteService";
import { boqPackageService } from "../../services/BoqPackageService";
import { projectDirectoryService } from "../../services/ProjectDirectoryService";
import Controls from "../../utility/Controls.tsx/Controls";
import { ProjectDirectoryModel } from "../../models/ProjectDirectoryModel";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { boqUploadService } from "../../services/BoqUploadService";
import {
  BOQItemDtlsModel,
  BoqUploadRequestModel,
  BoqUploadUpdateDTO,
} from "../../models/BoqUploadModel";
import BoqUploadForm from "./BoqUploadForm";
import { boqExcelUploadHistoryService } from "../../services/BoqExcelUploadHistoryService";
import { IconButtonProps } from "@mui/material/IconButton";
import { MProcurementTypesService } from "../../services/MProcurementTypesService";
import Draggable from "react-draggable";
import { makeStyles } from "@mui/styles";
import PreviewIcon from "@mui/icons-material/Preview";
import { config, Folderpath } from "../../utility/Config";
import { BoqDocFileService } from "../../services/BoqdocFilesService";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

// const useStyles = makeStyles({
//     editedRow: {
//         backgroundColor: '#76f9bd !important', // Change this to your desired color
//     },
//     editableColumn: {
//         border: '1px solid #8d8d8f !important',
//         // padding: '3px !important',
//         margin: '0.5px'
//     }
// });

const BoqUpload = () => {
  //const classes = useStyles();
  ///////////////Collapsible Card ////////////////////
  const [expandConBoq, setExpandConBoq] = React.useState(false);
  const [expandUploadedBoq, setExpandUploadedBoq] = React.useState(true);
  const [expandMakelist, setExpandMakelist] = React.useState(false);
  const [expandClientBoq, setExpandClientBoq] = React.useState(false);
  //////////////////Ends Collapsible Card/////////////
  //const [showConvertedOutputColumns, setShowConvertedOutputColumns] = useState(false);
  const [tabValue, setTabValue] = React.useState("1");
  const [nestedTabValue, setNestedTabValue] = React.useState("21");
  //const [clients, setClients] = useState<MultiSelectListModel[]>([]);
  const [sites, setSites] = useState<SelectListModel[]>([]);
  const [packages, setPackages] = useState<SelectListModel[]>([]);
  const [procurementTypesList, setProcurementTypesList] = useState<
    SelectListModel[]
  >([]);
  const [boqUploadImageFile, setBoqUploadImageFile] = useState<File | null>(
    null
  );
  const [isCompactView, setIsCompactView] = useState(true);
  const BoqUploadFileInputRef = useRef<HTMLInputElement>(null);

  const [selectedProcurementType, setselectedProcurementType] =
    useState<string>("");
  const [projectDirectory, setProjectDirectory] =
    useState<ProjectDirectoryModel>(projectDirectoryService.initialFieldValues);
  //this actual value will never change. Its used for validations and color change
  const [actualConvertedBoqs, setActualConvertedBoqs] = useState<any[]>([]);
  const [convertedBoqs, setConvertedBoqs] = useState<any[]>([]);

  const [uploadedBoqs, setUploadedBoqs] = useState<any[]>([]);
  const [selectedBoqToUpdate, setSelectedBoqToUpdate] =
    useState<BOQItemDtlsModel>(boqUploadService.initialBOQItemDtlsValues);
  const [clientBoqs, setClientBoqs] = useState<any[]>([]);

  const makeListFileInputRef = useRef<HTMLInputElement>(null);
  //this actual value will never change. Its used for validations and color change
  const [actualMakeLists, setActualMakeLists] = useState<any[]>([]);
  const [makeLists, setMakeLists] = useState<any[]>([]);
  const [makeListUploadErrors, setMakeListUploadErrors] = useState<any[]>([]);
  const [makeListBoqFile, setMakeListBoqFile] = useState<File | null>();
  let EmployeeId = "";
  const mode = EmployeeId ? "Edit" : "Create";
  const boqFileInputRef = useRef<HTMLInputElement>(null);

  const docFileInputRef = useRef<HTMLInputElement>(null);
  // const [docFilesUpload, setDocFilesUpload] = useState<File[]>([]);
  const [docFiles, setDocFiles] = useState([]);
  //const [boqDocFiles, setBoqDocFiles] = useState<any[]>([]);
  const [docsListByProjectDirectoryId, setDocsListByProjectDirectoryId] =
    useState([]);

  const [existingBoqFile, setExistingBoqFile] = useState<File | null>();
  const [boqExcelUploadErrors, setBoqExcelUploadErrors] = useState<any[]>([]);
  // const [rateBoqFile, setRateBoqFile] = useState<File[]>([]);
  const [boqUpload, setBoqUpload] = useState<BoqUploadUpdateDTO>(
    boqUploadService.initialBoqUploadUpdateValues
  );
  const [open, setOpen] = React.useState(false);
  const [imageopen, setImageopen] = React.useState(false);
  const [openProjDirectory, setOpenProjDirectory] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [selectedBoqUploadIds, setSelectedBoqUploadIds] = useState<string[]>(
    []
  );
  const [
    selectedUploadBoqProjectDirectoryId,
    setSelectedUploadBoqProjectDirectoryId,
  ] = useState<string>("");
  const [selectedUploadBoqPackageId, setSelectedUploadBoqPackageId] =
    useState<string>("");
  const [selectedUploadedBoqPackageName, setSelectedUploadedBoqPackageName] =
    useState<string>("");
  const [selectedUploadBoqSheetName, setSelectedUploadBoqSheetName] =
    useState<string>("");

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const handleExpandClick = (cardName: string) => {
    switch (cardName) {
      case "converted_boq":
        setExpandConBoq(!expandConBoq);
        return "";
      case "uploaded_boq":
        setExpandUploadedBoq(!expandUploadedBoq);
        return "";
      case "makelist":
        setExpandMakelist(!expandMakelist);
        return "";
      case "client_boq":
        setExpandClientBoq(!expandClientBoq);
        return "";
      default:
        return "";
    }
  };

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    // if ("ClientID" in fieldValues)
    //   temp.ClientID = fieldValues.ClientID ? "" : "Client is required.";

    if ("SiteID" in fieldValues)
      temp.SiteID = fieldValues.SiteID ? "" : "Site is required.";

    if ("ProjectDirectoryID" in fieldValues)
      temp.ProjectDirectoryID = fieldValues.ProjectDirectoryID
        ? ""
        : "Project Directory is required.";

    if ("BOQPackageID" in fieldValues)
      temp.BOQPackageID = fieldValues.BOQPackageID
        ? ""
        : "BOQ Package is required.";

    if ("WorkSheetName" in fieldValues)
      temp.WorkSheetName = fieldValues.WorkSheetName
        ? ""
        : "WorkSheet Name is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqUploadService.initialFieldValues, validate, "");

  const newBoqUpload = () => {
    setValues(boqUploadService.initialFieldValues);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // if (existingBoqs.length == 0)
    //     getExistingBoqs();
    if (sites.length === 0) getSites();

    if (packages.length === 0) getBoqPackages();

    if (procurementTypesList.length === 0) getProcurementTypesList();
    // if (convertedBoqs.length == 0)
    //     xxgetConvertedOutput();
  }, []);

  const handleButtonClick = () => {
    if (BoqUploadFileInputRef.current) {
      BoqUploadFileInputRef.current.click(); // Trigger click event on file input
    }
  };

  const resetBoqUploadImageFile = () => {
    if (BoqUploadFileInputRef.current) {
      BoqUploadFileInputRef.current.value = ""; // Reset file input value
    }
    setBoqUploadImageFile(null);
  };
  const onBoqUploadImageChange = (fileInput: any) => {
    const selectFile = fileInput.target.files[0];
    if (selectFile && selectFile.size <= 102400) {
      setBoqUploadImageFile(selectFile);
    } else {
      globalService.error("File size must be 100kb or less");
      setBoqUploadImageFile(null);
    }
  };
  const getProcurementTypesList = () => {
    MProcurementTypesService.getSelectListForLoggedInUser().then((response) => {
      setProcurementTypesList(response.data);
      if (response.data.length == 1) {
        setValues((prevValues: any) => ({
          ...prevValues,
          ProcurementTypeId: response.data[0].Value,
        }));
      }
    });
  };

  // const getClients = () => {
  //   mClientService.GetAllMultiSelectList().then((response: any) => {
  //     setClients(response.data);
  //   });
  // };

  const getSites = () => {
    mSiteService.GetAllMultiSelectList().then((response: any) => {
      setSites(response.data);
    });
  };

  const getBoqPackages = () => {
    boqPackageService.getPackageSelectList().then((response: any) => {
      setPackages(response.data);
    });
  };

  const getProjectDirectory = (siteId: any) => {
    if (siteId) {
      projectDirectoryService.GetBySiteId(siteId).then((response) => {
        let result = response.data;
        setProjectDirectory(result.row);
        if (result.row) {
          setValues((prevValues: any) => ({
            ...prevValues,
            ProjectDirectoryID: result.row.ProjectDirectoryId,
          }));
          getMakeLists(result.row.ProjectDirectoryId);
          getDocsListByProjectDirectoryId(result.row.ProjectDirectoryId);
        } else {
          setValues((prevValues: any) => ({
            ...prevValues,
            ProjectDirectoryID: "",
          }));
          setDocsListByProjectDirectoryId([]);
        }
      });
    }
  };

  //don't use row as both the table changes are calling this
  //const getConvertedBoqs = (row: any) => {
  const getConvertedBoqs = (
    ProjectDirectoryID: any,
    BOQPackageID: any,
    sheetName: string
  ) => {
    setSelectedUploadBoqProjectDirectoryId(ProjectDirectoryID);
    setSelectedUploadBoqPackageId(BOQPackageID);
    setSelectedUploadBoqSheetName(sheetName);
    values.ProjectDirectoryID = ProjectDirectoryID;
    values.BOQPackageID = BOQPackageID;
    values.WorkSheetName = sheetName;
    values.ProcurementTypeId = "";
    boqUploadService.getBOQItemDtlsForBOQUpload(values).then((response) => {
      console.log(response.data.list);
      setConvertedBoqs(response.data.list);
      setActualConvertedBoqs(response.data.list);
      // setSelectedActivityType();
    });
    getOriginalClientExcelFormat(ProjectDirectoryID, BOQPackageID);
  };

  const getOriginalClientExcelFormat = (
    ProjectDirectoryID: any,
    BOQPackageID: any
  ) => {
    if (ProjectDirectoryID) {
      boqExcelUploadHistoryService
        .GetOriginalClientExcelFormat(ProjectDirectoryID, BOQPackageID)
        .then((response: any) => {
          setClientBoqs(response.data.list);
        });
    }
  };

  const getMakeLists = (ProjectDirectoryID: any) => {
    if (ProjectDirectoryID) {
      boqUploadService
        .GetUploadedMakeLists(ProjectDirectoryID)
        .then((response: any) => {
          setMakeLists(response.data.list);
          setActualMakeLists(response.data.list);
        });
    } else {
      setMakeLists([]);
      setActualMakeLists([]);
    }
  };

  const handleImageSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    boqUploadService
      .EditBoqUploadImage(values, boqUploadImageFile)
      .then((response: any) => {
        let result = response.data;
        if (response) {
          globalService.success(result.message);
          getConvertedBoqs(
            values.ProjectDirectoryID,
            values.BOQPackageID,
            values.SheetName
          );
          // getOriginalClientExcelFormat(
          //   values.ProjectDirectoryID,
          //   values.BOQPackageID
          // );
          // getMakeLists(values.ProjectDirectoryID);
          // showCompactView();
          handleImage();
          setBoqUploadImageFile(null);
        } else {
          globalService.error(result.message);
        }
      });
  };

  const GetUploadedBoqs = (siteId: string) => {
    if (siteId) {
      boqUploadService.GetUploadedBoqs(siteId).then((response) => {
        setUploadedBoqs(response.data.list);
      });
    } else {
      setUploadedBoqs([]);
    }
  };

  // const handleClientChange = (clientId: string) => {
  //   //GetUploadedBoqs(clientId);
  //   setSites([]);
  //   getSites(clientId);
  //   setValues((prevValues: any) => ({
  //     ...prevValues,
  //     SiteID: "", // Clear SiteID
  //     ClientID: clientId, // Update ClientID
  //   }));
  //   setConvertedBoqs([]);
  //   setClientBoqs([]);
  //   setProjectDirectory(projectDirectoryService.initialFieldValues);
  //   setMakeLists([]);
  // };

  const handleSiteChange = (siteId: string) => {
    getProjectDirectory(siteId);
    GetUploadedBoqs(siteId);
    setValues((prevValues: any) => ({
      ...prevValues,
      SiteID: siteId, // Clear SiteID
    }));
    setConvertedBoqs([]);
    setClientBoqs([]);
    setProjectDirectory(projectDirectoryService.initialFieldValues);
    setMakeLists([]);
    refreshUploadFileControls();
  };

  const onExistingBoqFileChange = (fileInput: any) => {
    console.log(boqFileInputRef.current);
    setExistingBoqFile(fileInput.target.files[0]);
  };

  const onMakeListBoqFileChange = (fileInput: any) => {
    console.log(makeListFileInputRef.current);
    setMakeListBoqFile(fileInput.target.files[0]);
  };

  const onDocFileChange = (fileInput: any) => {
    console.log(docFileInputRef.current);
    const filesArray: File[] = Array.from(fileInput.target.files);

    // Check file sizes
    const filesLessThan10MB = filesArray.filter(
      (file) => file.size <= 10 * 1024 * 1024
    ); // 10MB in bytes

    // Update state with the array of files if all files are less than 10MB
    if (filesLessThan10MB.length === filesArray.length) {
      setDocFiles(filesLessThan10MB);
    } else {
      // Display an error message or handle the condition where file size exceeds 10MB
      console.error("One or more files exceed the size limit of 10 MB");
    }
  };

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(
      `${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`,
      "_blank"
    );
  };

  const uploadExistingBoqFile = () => {
    if (!existingBoqFile) {
      globalService.error("Please select Existing BOQ excel file.");
      return;
    }
    values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;
    if (validate()) {
      boqUploadService
        .BoqHistoryImport(existingBoqFile, values)
        .then((response) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              GetUploadedBoqs(values.SiteID);
              setClientBoqs(result.clientBoqs);
              refreshUploadFileControls();
              setBoqExcelUploadErrors([]);
            } else {
              globalService.error(result.message);
              setBoqExcelUploadErrors(result.list);
            }
          }
        });
    }
  };

  const uploadMakelistBoqFile = () => {
    if (!makeListBoqFile) {
      globalService.error("Please select Makelist BOQ excel file.");
      return;
    }
    values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;
    boqUploadService
      .MakeListImport(makeListBoqFile, values)
      .then((response) => {
        if (response) {
          let result = response.data;
          if (result.isSuccess) {
            globalService.success(result.message);
            setMakeLists(result.makeLists);
            refreshUploadFileControls();
            setMakeListUploadErrors([]);
          } else {
            globalService.error(result.message);
            setMakeLists(result.makeLists);
            setMakeListUploadErrors(result.list);
          }
        }
      });
  };

  const uploadBoqDocs = () => {
    if (!docFiles || docFiles.length == 0) {
      globalService.error("Please select File less than 5Mb to upload.");
      return;
    }
    values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;

    BoqDocFileService.docsImport(values, docFiles).then((response) => {
      let result = response.data;

      if (result.isSuccess) {
        globalService.success(result.message);
        setDocsListByProjectDirectoryId(result.docList);
        refreshUploadFileControls();
        setDocFiles([]);
      } else {
        globalService.error(result.message);
        setDocsListByProjectDirectoryId([]);
        //setDocFilesUpload([]);
        // setDocFilesUpload(result.list);
      }
    });
  };

  const getDocsListByProjectDirectoryId = (projectDirectoryId: any) => {
    BoqDocFileService.getByProjectDirectoryId(projectDirectoryId).then(
      (response) => {
        let result = response.data;
        setDocsListByProjectDirectoryId(result.list);
      }
    );
  };

  const refreshUploadFileControls = () => {
    setValues((prevValues: any) => ({
      ...prevValues,
      BOQPackageID: "",
      WorkSheetName: "",
      UpdateRates: true,
      //ProjectDirectoryID:""
    }));
    resetUploadedFileInput();
  };
  // const submitBoqUpload = () => {
  //     var modifiedBoqs = convertedBoqs.filter(x => x.BOQMake && x.IsEdit == true);
  //     if (modifiedBoqs.length <= 0)
  //         globalService.error('No records modified.');

  //     const filteredArray = modifiedBoqs.map(({ BOQUploadId, BOQMake }) => ({ BOQUploadId, BOQMake }));
  //     boqUploadService.EditBoqUploads(filteredArray).then((response) => {
  //         if (response) {
  //             let result = response.data;
  //             if (result.isSuccess) {
  //                 globalService.success(result.message);
  //             } else {
  //                 globalService.error(result.message);
  //             }
  //         }
  //     });
  // }

  const handleGridChange = (e: any, index: number, type: string) => {
    const { name, value } = e.target;
    const updatedConvertedBoqs = [...convertedBoqs];
    updatedConvertedBoqs[index].IsEdit = true;
    if (type == "make") updatedConvertedBoqs[index].Boqmake = value;
    setConvertedBoqs(updatedConvertedBoqs);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = () => {
    setImageopen(false);
  };
  const editBoqUpload = (row: any) => {
   
    console.log(row);
    setSelectedBoqToUpdate(row);
    setBoqUpload(row);
    
    setOpen(true);
  };

  const uploadImage = (row: any) => {
    values.BOQUploadId = row.BOQUploadId;
    values.ImageURL = row.ImageURL;
    setImageopen(true);
  };

  const showProjectDirectory = () => {
    setOpenProjDirectory(true);
  };
  const handleCloseProjDirectory = () => {
    setOpenProjDirectory(false);
  };
  //const handleProcessConvertedOutputRowUpdate = (newRow: any, oldRow: any) => {
  const handleProcessConvertedOutputRowUpdate = (newRow: any) => {
    //const updatedField = Object.keys(newRow).find(key => newRow[key] !== oldRow[key]);
    let actualData = actualConvertedBoqs.filter(
      (x) => x.BOQUploadId === newRow.BOQUploadId
    )[0];
    if (newRow.I_InstallationRate < 0) {
      globalService.error("InstallationRate cannot be < 0");
      newRow.I_InstallationRate = actualData.I_InstallationRate;
    }
    if (newRow.I_SupplyRate < 0) {
      globalService.error("SupplyRate cannot be < 0");
      newRow.I_SupplyRate = actualData.I_SupplyRate;
    }
    if (newRow.I_Qty < 0) {
      globalService.error("Qty cannot be < 0");
      newRow.I_Qty = actualData.I_Qty;
    }

    if (
      (newRow.I_ItemName && actualData.I_ItemName !== newRow.I_ItemName) ||
      (newRow.I_Specifications &&
        actualData.I_Specifications !== newRow.I_Specifications) ||
      (newRow.I_InstallationRate &&
        actualData.I_InstallationRate !== newRow.I_InstallationRate) ||
      (newRow.I_SupplyRate &&
        actualData.I_SupplyRate !== newRow.I_SupplyRate) ||
      (newRow.I_Qty && actualData.I_Qty !== newRow.I_Qty) ||
      (newRow.I_Make && actualData.I_Make !== newRow.I_Make) ||
      (newRow.I_Size && actualData.I_Size !== newRow.I_Size)
    ) {
      newRow.IsEdited = true;
    } else {
      newRow.IsEdited = false;
    }
    setConvertedBoqs((prevRows: any) =>
      prevRows.map((row: any) =>
        row.BOQUploadId === newRow.BOQUploadId ? newRow : row
      )
    );
    return newRow;
  };

  const getConvertedOutputRowClassName = (params: any) => {
    let isRowEdited = convertedBoqs.some(
      (x) => x.BOQUploadId === params.id && x.IsEdited == true
    );
    return isRowEdited ? "editedRow" : "";
  };

  const handleProcessConvertedOutputRowUpdateError = (error: any) => {
    console.error(error);
  };

  // const handleEditCellChange = (params: any) => {
  //
  //     const updatedRows = actualConvertedBoqs.map((row: any) =>
  //         row.BOQUploadId === params.id ? { ...row, [params.field]: params.value } : row
  //     );
  //     setConvertedBoqs(updatedRows);
  // };

  const EditBoqUploads = () => {
    var modifiedBoqs = convertedBoqs.filter((x) => x.IsEdited == true);
    if (modifiedBoqs.length <= 0) {
      globalService.error("No records to modify.");
      return;
    }
    const filteredArray = modifiedBoqs.map(
      ({
        BOQUploadId,
        I_ItemName,
        I_Specifications,
        I_InstallationRate,
        I_SupplyRate,
        I_Qty,
        I_Make,
        I_Size,
      }) => ({
        BOQUploadId,
        I_ItemName,
        I_Specifications,
        I_InstallationRate,
        I_SupplyRate,
        I_Qty,
        I_Make,
        I_Size,
      })
    );
    boqUploadService.EditBoqUploads(filteredArray).then((response) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          globalService.success(result.message);
        } else {
          globalService.error(result.message);
        }
      }
    });
  };

  const handleProcessMakeListRowUpdate = (newRow: any, oldRow: any) => {
    const updatedField = Object.keys(newRow).find(
      (key) => newRow[key] !== oldRow[key]
    );
    // if (newRow.age < 0 || newRow.age > 100) {
    //     throw new Error('Age must be between 0 and 100.');
    //   }
    let actualData = actualMakeLists.filter(
      (x) => x.BoqpackageMakeId === newRow.BoqpackageMakeId
    )[0];

    if (actualData.IMaterial !== newRow.IMaterial) {
      newRow.IsEdited = true;
    } else {
      newRow.IsEdited = false;
    }
    setMakeLists((prevRows: any) =>
      prevRows.map((row: any) =>
        row.BoqpackageMakeId === newRow.BoqpackageMakeId ? newRow : row
      )
    );
    return newRow;
  };

  const getMakeListRowClassName = (params: any) => {
    let isRowEdited = makeLists.some(
      (x) => x.BoqpackageMakeId === params.id && x.IsEdited == true
    );
    return isRowEdited ? "editedRow" : "";
  };

  const handleProcessMakeListRowUpdateError = (error: any) => {
    console.error(error);
  };

  const EditMakeLists = () => {
    var modifiedMakeList = makeLists.filter((x) => x.IsEdited == true);
    if (modifiedMakeList.length <= 0) {
      globalService.error("No records to modify.");
      return;
    }
    const filteredArray = modifiedMakeList.map(
      ({ BoqpackageMakeId, IMaterial }) => ({ BoqpackageMakeId, IMaterial })
    );
    boqUploadService.EditMakeLists(filteredArray).then((response) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          globalService.success(result.message);
        } else {
          globalService.error(result.message);
        }
      }
    });
  };

  const convertedBoqColumns: GridColDef[] = [
    { field: "ANJSrNo", headerName: "ANJ SrNo", width: 80 },
    {
      field: "ImageFileName",
      headerName: "Image",
      width: 60,
      renderCell: (params) => (
        <div className="zoom">
          <Stack>
            {params.row.ImageURL ? (
              <Avatar
                alt=" "
                src={`${process.env.REACT_APP_BASE_URL}${params.row.ImageURL}`}
                sx={{
                  width: "40px",
                  height: "40px",
                  marginTop: 2,
                  border: "0.5px solid black",
                }}
              />
            ) : (
              <Avatar
                src=""
                alt=" "
                sx={{ width: 40, height: 40, marginTop: 2 }}
              >
                <PersonIcon />
              </Avatar>
            )}
            {/* {
            <img
              src={`${process.env.REACT_APP_BASE_URL}${params.row.ImageURL}`}
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
          } */}
          </Stack>
        </div>
      ),
    },
    { field: "ClientSrNo", headerName: "Client SrNo", width: 80 },
    {
      field: "ProcurementTypeName",
      headerName: "Procurement Type Name",
      width: 100,
    },
    {
      field: "BOQDescription",
      headerName: "BOQ Description",
      width: 400,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    {
      field: "BasicRate",
      headerName: "Basic Rate",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return params.value;
      },
    },
    // {
    //   field: "Title",
    //   headerName: "Title",
    //   width: 200,
    //   cellClassName:"cell-left",
    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    // {
    //   field: "SubTitle",
    //   headerName: "Sub Title",
    //   width: 200,
    //   cellClassName:"cell-left",
    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },

    // {
    //   field: "ItemName",
    //   headerName: "Item",
    //   width: 200,
    //   cellClassName:"cell-left",
    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },

    // {
    //   field: "Specifications",
    //   headerName: "Specifications",
    //   width: 200,
    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    { field: "Location", headerName: "Location" },
    { field: "Unit", headerName: "Unit" },
    {
      field: "Qty",
      headerName: "Qty",
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    {
      field: "BOQMake",
      headerName: "BOQ Make",
      width: 200,
      // renderCell: (params) => {
      //     const rowIndex = convertedBoqs.findIndex(row => row.BOQUploadId === params.id); // Get the row index
      //     return (
      //         <Grid container sx={{ marginTop: 1, marginBottom: 1 }}>
      //             <Grid item xs={12} sm={12} >
      //                 <Controls.Input
      //                     label="BOQ Make"
      //                     name={"BoqMake_" + params.id}
      //                     value={convertedBoqs[rowIndex].BOQMake}
      //                     onChange={(e: any) => handleGridChange(e, rowIndex, 'make')}
      //                 />
      //             </Grid>
      //         </Grid>
      //     )
      // }
    },
    { field: "BOQSize", headerName: "BOQ Size" },

    {
      field: "SupplyRate",
      headerName: "Supply Rate",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "InstallationRate",
      headerName: "Installation Rate",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "Rate",
      headerName: "Rate",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "Amount",
      headerName: "Amount",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },

    // { field: "Boqcolor", headerName: "BOQ Color" },
    {
      field: "Remarks",
      headerName: "Remarks",
      flex: 1,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    { field: "MappedTitleName", headerName: "Mapped Title", width: 200 },
    { field: "MappedSubTitleName", headerName: "Mapped Sub Title", width: 200 },
    { field: "MappedItemName", headerName: "Mapped Item", width: 200 },
    {
      field: "I_ItemName",
      headerName: "I_ItemName",
      width: 200,
      editable: true,

      cellClassName: "editableColumn",
      renderEditCell: (params: GridRenderEditCellParams) => (
        <Controls.TextareaCellEditor {...params} />
      ),

      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    {
      field: "I_Specifications",
      headerName: "I_Specifications",
      width: 200,
      cellClassName: "editableColumn",
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => (
        <Controls.TextareaCellEditor {...params} />
      ),
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    {
      field: "I_InstallationRate",
      headerName: "I_InstallationRate",
      width: 80,
      type: "number",
      editable: true,
      cellClassName: "editableColumn",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "I_SupplyRate",
      headerName: "I_SupplyRate",
      width: 80,
      headerAlign: "right",
      type: "number",
      editable: true,
      cellClassName: "editableColumn",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "I_Rate",
      headerName: "I_Rate",
      width: 80,
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    // {
    //   field: "I_Qty",
    //   headerName: "I_Qty",
    //   width: 80,
    //   type: "number",
    //   editable: true,
    //   cellClassName: "editableColumn",
    // },
    {
      field: "I_Make",
      headerName: "I_Make",
      width: 200,
      editable: true,
      cellClassName: "editableColumn",
    },
    {
      field: "I_Size",
      headerName: "I_Size",
      width: 200,
      editable: true,
      cellClassName: "editableColumn",
    },
    { field: "UploadedSeqNo", headerName: "Uploaded SeqNo." },
    { field: "BoquploadStatus", headerName: "Status" },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      renderCell: (params) => {
        return (
          <>
            <Stack direction="row" spacing={0}>
              <IconButton
                size="small"
                color="primary"
                aria-label="add an alarm"
                onClick={() => editBoqUpload(params.row)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <Button
                variant="outlined"
                className="btnGrid"
                onClick={() => uploadImage(params.row)}
              >
                Upload
              </Button>
            </Stack>
          </>
        );
      },
    },
  ];

  const boqUploadErrorColumns: GridColDef[] = [
    { field: "AnjsrNo", headerName: "ANJ SrNo.", width: 80 },
    { field: "ClientSrno", headerName: "Client Srno.", width: 80 },
    {
      field: "ItemName",
      headerName: "Item Name",
      width: 400,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    {
      field: "Specification",
      headerName: "Specification",
      width: 400,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    { field: "Location", headerName: "Location" },
    { field: "Unit", headerName: "Unit" },
    {
      field: "Rate",
      headerName: "Rate",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "Qty",
      headerName: "Qty",
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    {
      field: "Amount",
      headerName: "Amount",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    { field: "Remarks", headerName: "Remarks" },
    { field: "Size", headerName: "Size", flex: 1 },
    { field: "Make", headerName: "Make", flex: 1 },
    {
      field: "ImportRemarks",
      headerName: "Import Remarks",
      width: 200,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
  ];

  const makelistBoqErrorColumns: GridColDef[] = [
    { field: "Package", headerName: "Package", width: 200 },
    { field: "Material", headerName: "Material", flex: 1 },
    { field: "Makes", headerName: "Makes", flex: 1 },
    { field: "ItemType", headerName: "Item Type" },
    {
      field: "ImportRemarks",
      headerName: "Import Remarks",
      width: 200,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
  ];
  const clientBoqColumns: GridColDef[] = [
    // { field: "UploadedSeqNo", headerName: "UploadedSeqNo", width: 130, flex: 1 },
    { field: "AnjsrNo", headerName: "ANJ SrNo", width: 80 },
    { field: "ClientSrno", headerName: "Client SrNo", width: 80 },
    {
      field: "ItemName",
      headerName: "Item Name",
      width: 300,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    // {
    //   field: "Specification",
    //   headerName: "Specification",
    //   width: 400,
    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    { field: "Location", headerName: "Location", flex: 1 },
    { field: "Unit", headerName: "Unit", flex: 1 },
    {
      field: "Qty",
      headerName: "Qty",
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    {
      field: "Rate",
      headerName: "Rate",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "SupplyRate",
      headerName: "Supply Rate",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "InstallationRate",
      headerName: "Installation Rate",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "Amount",
      headerName: "Amount",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      width: 300,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    { field: "UploadedSeqNo", headerName: "Uploaded SeqNo." },
    { field: "SheetName", headerName: "Sheet Name" },
  ];

  const makeListBoqColumns: GridColDef[] = [
    { field: "ProjectDirectoryName", headerName: "Project Directory", flex: 1 },
    { field: "BoqPackageName", headerName: "Boq Package", flex: 1 },
    { field: "Material", headerName: "Material", flex: 1 },
    {
      field: "IMaterial",
      headerName: "I_Material",
      width: 200,
      editable: true,
      cellClassName: "editableColumn",
    },
    { field: "Makes", headerName: "Makes", flex: 1 },
    { field: "ItemType", headerName: "ItemType", width: 300 },
  ];

  const uploadedBoqColumns: GridColDef[] = [
    // {
    //   field: "ClientName",
    //   headerName: "Client",
    //   width: 200,

    // },

    {
      field: "BOQPackageName",
      headerName: "BOQ Package",
      flex: 1,
      renderCell: (params) => (
        <a
          href="#"
          className="link"
          onClick={(event) => {
            event.preventDefault();
            getConvertedBoqs(
              params.row.ProjectDirectoryID,
              params.row.BOQPackageID,
              params.row.SheetName
            );
            setSelectedUploadedBoqPackageName(params.row.BOQPackageName);
            getOriginalClientExcelFormat(
              params.row.ProjectDirectoryID,
              params.row.BOQPackageID
            );
            getMakeLists(params.row.ProjectDirectoryID);
            showCompactView();
          }}
        >
          {params.value}
        </a>
      ),
    },
    //{ field: "ProjectShortName", headerName: "Project Name", width: 250 },
    // {
    //   field: "ProjectStartOn",
    //   headerName: "Project Start On",
    //   width: 130,
    //   renderCell: (params) => {
    //     return dayjs(params.value).format("DD-MMM-YYYY");
    //   },
    // },
    // {
    //   field: "ProjectEndOn",
    //   headerName: "Project End On",
    //   width: 130,
    //   renderCell: (params) => {
    //     return dayjs(params.value).format("DD-MMM-YYYY");
    //   },
    // },
    //{ field: "Area", headerName: "Area", flex: 1 },
    {
      field: "TotBOQRecs",
      headerName: "BOQ Items",
      flex: 1,
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    {
      field: "BOQAmount",
      headerName: "BOQ Amount",
      flex: 1,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    { field: "SheetName", headerName: "Sheet Name", flex: 1 },
    {
      field: "CreatedOn",
      headerName: "Created On",
      width: 200,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY HH:mm:ss") : "",
    },
    // {
    //     field: 'Actions',
    //     headerName: 'Actions',
    //     type: 'number',
    //     flex: 1,
    //     renderCell: (params) => {
    //         return (<Stack direction="row" spacing={0}>

    //             <IconButton size="small" color="primary" aria-label="add an alarm" onClick={() => navigate("/standardAcCategorie/" + params.row.CategoryId)}>
    //                 <RefreshIcon fontSize="inherit" />
    //             </IconButton>

    //         </Stack>);
    //     }
    // }
  ];

  const boqDocFileColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={(event) => handleFileClick(event, params.row)}
        >
          {params.row.FileName}
        </Link>
      ),
    },
    // {
    //   field: "UserName",
    //   headerName: "Created By",
    //   width: 150,
    //   flex: 1,
    //   //valueGetter: (params) => params.row.CreatedBy,
    // },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
  ];

  const initialColumnVisibility: any = {
    Location: false,
    BOQMake: false,
    BOQSize: false,
    SupplyRate: false,
    InstallationRate: false,
    MappedTitleName: false,
    MappedSubTitleName: false,
    MappedItemName: false,
    I_ItemName: false,
    I_Specifications: false,
    I_InstallationRate: false,
    I_SupplyRate: false,
    I_Rate: false,
    I_Make: false,
    I_Size: false,
    UploadedSeqNo: false,
    BoquploadStatus: false,
    ANJSrNo: false,
  };

  //show/hide columns dynamically
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialColumnVisibility
  );

  const showCompactView = () => {
    setColumnVisibilityModel(initialColumnVisibility);
    setIsCompactView(true);
  };

  const toggleColumnVisibility = () => {
    setIsCompactView(!isCompactView);
    let fields = [
      "Location",
      "BOQMake",
      "BOQSize",
      "BasicRate",
      "SupplyRate",
      "InstallationRate",
      "MappedTitleName",
      "MappedSubTitleName",
      "MappedItemName",
      "I_ItemName",
      "I_Specifications",
      "I_InstallationRate",
      "I_SupplyRate",
      "I_Rate",
      "I_Make",
      "I_Size",
      "UploadedSeqNo",
      "BoquploadStatus",
      "ANJSrNo",
    ];

    setColumnVisibilityModel((prevModel: any) => {
      const newModel = { ...prevModel };
      fields.forEach((field) => {
        newModel[field] = !prevModel[field];
      });
      return newModel;
    });
  };

  const handleBrowseButtonClick = () => {
    if (nestedTabValue === "21" && boqFileInputRef.current) {
      boqFileInputRef.current.click(); // Trigger click event on file input
    } else if (nestedTabValue === "22" && makeListFileInputRef.current) {
      makeListFileInputRef.current.click();
    } else if (nestedTabValue == "23" && docFileInputRef.current) {
      docFileInputRef.current.click();
    }
  };

  const resetUploadedFileInput = () => {
    if (nestedTabValue === "21") {
      if (boqFileInputRef.current) {
        boqFileInputRef.current.value = ""; // Reset file input value
      }
      setExistingBoqFile(null);
    } else if (nestedTabValue === "22") {
      if (makeListFileInputRef.current) {
        makeListFileInputRef.current.value = ""; // Reset file input value
      }
      setMakeListBoqFile(null);
    } else if (nestedTabValue === "23") {
      if (docFileInputRef.current) {
        docFileInputRef.current.value = ""; // Reset file input value
      }
      setDocFiles(null);
    }
  };

  const allocateProcurementType = () => {
    if (!selectedProcurementType)
      return globalService.error("Please select Procurement Type.");
    if (selectedBoqUploadIds.length <= 0)
      return globalService.error(
        "Select atleast 1 Converted Output to allocate."
      );
    boqUploadService
      .AllocateProcurementType(selectedBoqUploadIds, selectedProcurementType)
      .then((response) => {
        if (response) {
          let result = response.data;
          if (result) {
            if (result.isSuccess) {
              globalService.success(result.message);
              getConvertedBoqs(
                selectedUploadBoqProjectDirectoryId,
                selectedUploadBoqPackageId,
                selectedUploadBoqSheetName
              );
              setSelectedBoqUploadIds([]);
            } else {
              globalService.error(result.message);
            }
          }
        }
      });
  };

  const handleConvertedBoqSelectionChange = (selectedIds: any) => {
    setSelectedBoqUploadIds(selectedIds);
  };

  // const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const fileInput = event.target.files;
  //   if (!fileInput) {
  //     return;
  //   }

  //   const selectedFiles = Array.from(fileInput) as File[];
  //   const validFiles = selectedFiles.filter(
  //     (file) => file.size <= 5 * 1024 * 1024
  //   );

  //   if (validFiles.length < selectedFiles.length) {
  //     globalService.error("File size must be 5MB or less");
  //   }

  //   setDocFilesUpload((prevFiles) => [...prevFiles, ...validFiles]);
  // };

  const removeBoqDocFile = (index: number) => {
    setDocFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleNestedTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setNestedTabValue(newValue);
  };
  const [open1, setOpen1] = useState(false);

  const handleToggle = () => {
    setOpen1(!open1);
  };

  return (
    <div>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Digital BOQ{" "}
      </Typography>

      <TabContext value={tabValue}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={3}>
            <Controls.MuiAutocomplete
              options={clients}
              label="Client"
              error={errors.ClientID}
              onChange={(event: MultiSelectListModel) => {
                handleInputChange({
                  target: {
                    name: "ClientID",
                    value: event ? event.value : "",
                  },
                });
                setSites([]);
                if (event) {
                  handleClientChange(event.value);
                }
              }}
              value={
                clients.find((item: any) => item.value === values.ClientID) ||
                null
              }
            />
          </Grid> */}
          <Grid item xs={12} sm={3}>
            <Stack direction={"row"}>
              <Controls.MuiAutocomplete
                options={sites}
                label="Site"
                error={errors.SiteID}
                onChange={(event: MultiSelectListModel) => {
                  handleInputChange({
                    target: {
                      name: "SiteID",
                      value: event ? event.value : "",
                    },
                  });

                  if (event) {
                    handleSiteChange(event.value);
                  }
                }}
                value={
                  sites.find((item: any) => item.value === values.SiteID) ||
                  null
                }
              />
              {/* <Controls.Select
                name="SiteID"
                showEmptyItem={true}
                label="Sites"
                required
                value={sites.length > 0 ? values.SiteID : ""}
                onChange={(e: any) => {
                  handleInputChange(e);
                  handleSiteChange(e.target.value);
                }}
                options={sites}
                error={errors.SiteID}
              /> */}
              {projectDirectory && projectDirectory.ProjectDirectoryId && (
                <IconButton
                  size="small"
                  color="error"
                  aria-label="add an alarm"
                  onClick={() => showProjectDirectory()}
                >
                  <LanIcon fontSize="inherit" />
                </IconButton>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TabList onChange={handleTabChange} aria-label="BOQ">
              <Tab label="BOQ History" value="1" />
              <Tab label="BOQ / MakeList Upload" value="2" />
            </TabList>
          </Grid>
        </Grid>

        <TabPanel value="1">
          <Card>
            <CardHeader
              title="BOQ's"
              action={
                <>
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/BOQUploadStandards.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="outlined" startIcon={<PreviewIcon />}>
                      <b>BOQ Upload Standards</b>
                    </Button>
                  </a>
                  <IconButton
                    size="small"
                    color="error"
                    aria-label="add an alarm"
                    onClick={() => GetUploadedBoqs(values.SiteID)}
                  >
                    <RefreshIcon fontSize="inherit" />
                  </IconButton>
                  <ExpandMore
                    expand={expandUploadedBoq}
                    onClick={() => handleExpandClick("uploaded_boq")}
                    aria-expanded={expandUploadedBoq}
                    aria-label="show more"
                  >
                    {expandUploadedBoq ? <RemoveIcon /> : <AddIcon />}
                  </ExpandMore>
                </>
              }
            />

            <Collapse in={expandUploadedBoq} timeout="auto" unmountOnExit>
              <CardContent>
                <div style={{ width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => Math.random()}
                    rows={uploadedBoqs}
                    columns={uploadedBoqColumns}
                    columnHeaderHeight={30}
                    autoHeight={true}
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          BoquploadId: false,
                          CreatedOn: false,
                          TotBOQRecs: false,
                        },
                      },
                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
              </CardContent>
            </Collapse>
          </Card>

          <Card>
            <CardHeader
              title="Converted Output"
              subheader={"Package : " + selectedUploadedBoqPackageName}
              action={
                <ExpandMore
                  expand={expandConBoq}
                  onClick={() => handleExpandClick("converted_boq")}
                  aria-expanded={expandConBoq}
                  aria-label="show more"
                >
                  {expandConBoq ? <RemoveIcon /> : <AddIcon />}
                </ExpandMore>
              }
            />

            <Collapse in={expandConBoq} timeout="auto" unmountOnExit>
              <CardContent>
                <Grid container sx={{ marginTop: 1, marginBottom: 1 }}>
                  <Grid item xs={12} sm={3}>
                    <Controls.Select
                      sx={{ marginTop: 2 }}
                      name={"ProcurementTypeId"}
                      label="Procurement Type"
                      required
                      showEmptyItem={true}
                      options={procurementTypesList}
                      value={
                        procurementTypesList.length > 0
                          ? values.ProcurementTypeId
                          : ""
                      }
                      onChange={(e: any) => {
                        handleInputChange(e);
                        setselectedProcurementType(e.target.value);
                      }}
                      // value={selectedActivityType}
                      // onChange={(e: any) => {
                      //     setSelectedActivityType(e.target.value);
                      // }}
                      error={errors.ProcurementTypeId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }}>
                      <Button
                        onClick={allocateProcurementType}
                        color="primary"
                        variant="contained"
                      >
                        Allocate
                      </Button>
                      <p> {selectedBoqUploadIds.length} BOQ's selected.</p>
                      <Button
                        onClick={toggleColumnVisibility}
                        color={isCompactView ? "primary" : "error"}
                        variant="contained"
                      >
                        {isCompactView ? "Full View" : "Compact View"}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                {/* <div style={{ position: "relative", width: "100%" }}> */}
                <>
                  <div style={{ height: 650, width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.BOQUploadId}
                      rows={convertedBoqs}
                      columns={convertedBoqColumns}
                      columnHeaderHeight={30}
                      editMode="row"
                      getRowClassName={getConvertedOutputRowClassName}
                      processRowUpdate={handleProcessConvertedOutputRowUpdate}
                      isRowSelectable={(params) =>
                        !isNaN(params.row.Qty) &&
                        !!params.row.Qty &&
                        params.row.BOQUploadStatus === "Pending"
                      }
                      onProcessRowUpdateError={
                        handleProcessConvertedOutputRowUpdateError
                      }
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      autoHeight={false}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                      checkboxSelection
                      rowSelectionModel={selectedBoqUploadIds}
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={
                        handleConvertedBoqSelectionChange
                      }
                      columnVisibilityModel={columnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel: any) =>
                        setColumnVisibilityModel(newModel)
                      }
                      // initialState={{
                      //   columns: {
                      //     columnVisibilityModel: {
                      //       // Hide columns Id, the other columns will remain visible
                      //       BOQUploadId: false,
                      //       // Location: showConvertedOutputColumns,
                      //       // BOQMake: showConvertedOutputColumns,
                      //       // BOQSize: showConvertedOutputColumns,
                      //       // SupplyRate: showConvertedOutputColumns,
                      //       // InstallationRate: showConvertedOutputColumns,
                      //       // MappedTitleName: showConvertedOutputColumns,
                      //       // MappedSubTitleName: showConvertedOutputColumns,
                      //       // MappedItemName: showConvertedOutputColumns,
                      //       // I_ItemName: showConvertedOutputColumns,
                      //       // I_Specifications: showConvertedOutputColumns,
                      //       // I_InstallationRate: showConvertedOutputColumns,
                      //       // I_SupplyRate: showConvertedOutputColumns,
                      //       // I_Rate: showConvertedOutputColumns,
                      //       // I_Make: showConvertedOutputColumns,
                      //       // I_Size: showConvertedOutputColumns,
                      //       // UploadedSeqNo: showConvertedOutputColumns,
                      //     },
                      //   },
                      //   pagination: { paginationModel: { pageSize: 100 } },
                      // }}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                      //pageSizeOptions={[10, 25, 50, 100]}
                    />
                  </div>

                  {/* <div
                    style={{
                      position: "absolute",
                      top: "2px", // Adjust top position as needed
                      right: "50%", // Adjust right position as needed
                      zIndex: 1000, // Ensure it's above the DataGrid
                      // background: "white", // Ensure button is visible against the grid
                      padding: "10px", // Add padding for better visibility
                      display: "flex",
                      justifyContent: "center",
                      alignItems: 'center',
                    }}
                  >
                    <CardActions
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Stack spacing={2} direction="row">
                        <Button
                          sx={{ marginTop: 2 }}
                          variant="contained"
                          color="primary"
                          startIcon={<SaveIcon />}
                          onClick={() => EditBoqUploads()}
                        >
                          Update BOQ's
                        </Button>
                      </Stack>
                    </CardActions>
                  </div> */}
                </>
                {/* </div> */}
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                <Stack spacing={2} direction="row">
                  <Button
                    sx={{ marginTop: 2 }}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() => EditBoqUploads()}
                  >
                    Update BOQ's
                  </Button>
                </Stack>
              </CardActions>
            </Collapse>
          </Card>
        </TabPanel>
        <TabPanel value="2">
          {projectDirectory && projectDirectory.ProjectDirectoryId && (
            <>
              <TabContext value={nestedTabValue}>
                <TabList
                  onChange={handleNestedTabChange}
                  aria-label="BOQ Tabs"
                  sx={{ backgroundColor: "ghostwhite" }}
                >
                  <Tab label="BOQ Upload" value="21" />
                  <Tab label="Make List Upload" value="22" />
                  <Tab label="Document Upload" value="23" />
                </TabList>
                <TabPanel value="21">
                  <Card>
                    <CardHeader title="BOQ Uploads" />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                          <Controls.Select
                            name="BOQPackageID"
                            label="BOQ Package"
                            required
                            showEmptyItem={true}
                            options={packages}
                            value={
                              packages.length > 0 ? values.BOQPackageID : ""
                            }
                            onChange={(e: any) => {
                              handleInputChange(e);
                            }}
                            error={errors.BOQPackageID}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Input
                            label="Work Sheet Name"
                            name="WorkSheetName"
                            value={values.WorkSheetName}
                            onChange={handleInputChange}
                            error={errors.WorkSheetName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Controls.Checkbox
                            label="Update Rates"
                            name="UpdateRates"
                            value={values.UpdateRates}
                            onChange={handleInputChange}
                            error={errors.UpdateRates}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Typography
                            style={{ color: "red", fontSize: "0.75rem" }}
                          >
                            {" "}
                            File Size Should be 10MB
                          </Typography>
                          <Grid item xs={6} sm={6}>
                            <input
                              type="file"
                              id="boqSelectedFile"
                              style={{ display: "none" }}
                              onChange={(event: any) => {
                                onExistingBoqFileChange(event);
                              }}
                              ref={boqFileInputRef}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                            <Button
                              startIcon={<PostAddIcon />}
                              variant="contained"
                              color="success"
                              onClick={() => handleBrowseButtonClick()}
                            >
                              Browse...
                            </Button>

                            {existingBoqFile && (
                              <Stack direction="row" spacing={1}>
                                <p>{existingBoqFile.name}</p>
                                {/* Display other file details if needed */}
                                <IconButton
                                  size="small"
                                  aria-label="delete"
                                  color="error"
                                  onClick={resetUploadedFileInput}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Stack>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Stack direction="row" spacing={0}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<UploadIcon />}
                              onClick={() => uploadExistingBoqFile()}
                            >
                              Upload BOQ
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                      {boqExcelUploadErrors &&
                        boqExcelUploadErrors.length > 0 && (
                          <>
                            <Stack
                              direction="row"
                              spacing={0}
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="h5"
                                align="center"
                                className="MuiCardHeader-title"
                              >
                                Error Lists
                              </Typography>
                              {/* <Typography style={{ color: 'red', fontSize: '0.75rem' }}>Note: Start Date / End Date should be prefix with single quote (') in excel. (e.g. '01/Apr/2023)
                        </Typography> */}
                            </Stack>
                            <div>
                              <DataGrid
                                getRowId={(row) =>
                                  row.BoqexcelUploadHistoryImportId
                                }
                                rows={boqExcelUploadErrors}
                                columns={boqUploadErrorColumns}
                                columnHeaderHeight={30}
                                //rowHeight={30}
                                autoHeight={true}
                                getRowHeight={() => "auto"}
                                getEstimatedRowHeight={() => 200}
                                //loading={loading}
                                initialState={{
                                  columns: {
                                    columnVisibilityModel: {
                                      // Hide columns Id, the other columns will remain visible
                                      BoqexcelUploadHistoryImportId: false,
                                    },
                                  },
                                  pagination: {
                                    paginationModel: { pageSize: 50 },
                                  },
                                }}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                  },
                                }}
                                pageSizeOptions={[10, 25, 50, 100]}
                              />
                            </div>
                          </>
                        )}
                    </CardContent>
                  </Card>
                </TabPanel>
                <TabPanel value="22">
                  <Card>
                    <CardHeader title="Make List Uploads" />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={3}>
                          <Controls.Input
                            label="Work Sheet Name"
                            name="WorkSheetName"
                            value={values.WorkSheetName}
                            onChange={handleInputChange}
                            error={errors.WorkSheetName}
                          />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <input
                            type="file"
                            id="makeListBoqSelectedFile"
                            style={{ display: "none" }}
                            onChange={(event: any) => {
                              onMakeListBoqFileChange(event);
                            }}
                            ref={makeListFileInputRef}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          />
                          <Button
                            startIcon={<PostAddIcon />}
                            variant="contained"
                            color="success"
                            onClick={() => handleBrowseButtonClick()}
                          >
                            Browse...
                          </Button>

                          {makeListBoqFile && (
                            <Stack direction="row" spacing={1}>
                              <p>{makeListBoqFile.name}</p>
                              {/* Display other file details if needed */}
                              <IconButton
                                size="small"
                                aria-label="delete"
                                color="error"
                                onClick={resetUploadedFileInput}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Stack>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Stack direction="row" spacing={0}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<UploadIcon />}
                              onClick={() => uploadMakelistBoqFile()}
                            >
                              Upload MakeList
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>

                      {makeListUploadErrors &&
                        makeListUploadErrors.length > 0 && (
                          <>
                            <Stack
                              direction="row"
                              spacing={0}
                              justifyContent="space-between"
                            >
                              <Typography
                                variant="h5"
                                align="center"
                                className="MuiCardHeader-title"
                              >
                                Error Lists
                              </Typography>
                            </Stack>
                            <div>
                              <DataGrid
                                getRowId={(row) => row.BoqpackageMakeImportId}
                                rows={makeListUploadErrors}
                                columns={makelistBoqErrorColumns}
                                columnHeaderHeight={30}
                                //rowHeight={30}
                                autoHeight={true}
                                getRowHeight={() => "auto"}
                                getEstimatedRowHeight={() => 200}
                                //loading={loading}
                                initialState={{
                                  columns: {
                                    columnVisibilityModel: {
                                      // Hide columns Id, the other columns will remain visible
                                      BoqpackageMakeImportId: false,
                                    },
                                  },
                                  pagination: {
                                    paginationModel: { pageSize: 50 },
                                  },
                                }}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                  toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                  },
                                }}
                                pageSizeOptions={[10, 25, 50, 100]}
                              />
                            </div>
                          </>
                        )}
                    </CardContent>
                  </Card>
                </TabPanel>

                <TabPanel value="23">
                  <Card>
                    <CardHeader title="Document Upload" />
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={6}>
                          <input
                            type="file"
                            //name="file"
                            id="docSelectedFile"
                            multiple
                            style={{ display: "none" }}
                            onChange={(event: any) => {
                              onDocFileChange(event);
                            }}
                            // onChange={onFileChange}
                            ref={docFileInputRef}
                            accept=".pdf, .doc, .docx, .xls, .xlsx, image/*"
                          />
                          <Button
                            startIcon={<PostAddIcon />}
                            variant="contained"
                            color="success"
                            onClick={() => handleBrowseButtonClick()}
                          >
                            Browse Doc Files
                          </Button>

                          <Typography
                            style={{ color: "red", fontSize: "0.75rem" }}
                          >
                            {" "}
                            File Size Should be 5MB
                          </Typography>

                          {docFiles.length > 0 &&
                            docFiles.map((file, index) => (
                              <Stack key={index} direction="row" spacing={1}>
                                <Typography>{file.name}</Typography>
                                {/* Display other file details if needed */}
                                <IconButton
                                  size="small"
                                  aria-label="delete"
                                  color="error"
                                  onClick={() => removeBoqDocFile(index)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Stack>
                            ))}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Stack direction="row" spacing={0}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<UploadIcon />}
                              onClick={() => uploadBoqDocs()}
                            >
                              Upload Documents
                            </Button>
                          </Stack>
                        </Grid>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            padding: "20px",
                          }}
                        >
                          <DataGrid
                            getRowId={(row) => row.BoqdocFileId}
                            rows={docsListByProjectDirectoryId}
                            columns={boqDocFileColumns}
                            columnHeaderHeight={30}
                            autoHeight={true}
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                          />
                        </div>
                      </Grid>
                    </CardContent>
                  </Card>
                </TabPanel>
              </TabContext>
            </>
          )}
        </TabPanel>
      </TabContext>
      <Card>
        <CardHeader
          title="Make Lists"
          action={
            <>
              {" "}
              <IconButton
                size="small"
                color="error"
                aria-label="add an alarm"
                onClick={() =>
                  getMakeLists(projectDirectory.ProjectDirectoryId)
                }
              >
                <RefreshIcon fontSize="inherit" />
              </IconButton>
              <ExpandMore
                expand={expandMakelist}
                onClick={() => handleExpandClick("makelist")}
                aria-expanded={expandMakelist}
                aria-label="show more"
              >
                {expandMakelist ? <RemoveIcon /> : <AddIcon />}
              </ExpandMore>
            </>
          }
        />

        <Collapse in={expandMakelist} timeout="auto" unmountOnExit>
          <CardContent>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.BoqpackageMakeId}
                rows={makeLists}
                columns={makeListBoqColumns}
                columnHeaderHeight={30}
                getRowClassName={getMakeListRowClassName}
                processRowUpdate={handleProcessMakeListRowUpdate}
                onProcessRowUpdateError={handleProcessMakeListRowUpdateError}
                //rowHeight={30}
                autoHeight={false}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                //loading={loading}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns Id, the other columns will remain visible
                      BoqpackageMakeId: false,
                    },
                  },
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </div>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button
                sx={{ marginTop: 2 }}
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => EditMakeLists()}
              >
                Update Make List's
              </Button>
            </Stack>
          </CardActions>
        </Collapse>
      </Card>

      <Card>
        <CardHeader
          title="Original Client Excel Format"
          action={
            <ExpandMore
              expand={expandClientBoq}
              onClick={() => handleExpandClick("client_boq")}
              aria-expanded={expandClientBoq}
              aria-label="show more"
            >
              {expandClientBoq ? <RemoveIcon /> : <AddIcon />}
            </ExpandMore>
          }
        />
        <Collapse in={expandClientBoq} timeout="auto" unmountOnExit>
          <CardContent>
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.BoqexcelUploadHistoryId}
                rows={clientBoqs}
                columns={clientBoqColumns}
                columnHeaderHeight={30}
                //rowHeight={30}
                autoHeight={false}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                //loading={loading}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns Id, the other columns will remain visible
                      BoqexcelUploadHistoryId: false,
                    },
                  },
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </div>
          </CardContent>
        </Collapse>
      </Card>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Draggable>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            BOQ Upload{" "}
            <i style={{ fontSize: 15 }}>
              {"Package : " + selectedUploadedBoqPackageName}
            </i>
            {/* {selectedBoqToUpdate.Title} */}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400 }}>
            <>
              <BoqUploadForm
                boqUpload={boqUpload}
                boqPackageId={selectedUploadBoqPackageId}
                projectDirectoryId={selectedUploadBoqProjectDirectoryId}
                onCloseDialog={handleClose}
                refreshConvertedBoqs={() =>
                  getConvertedBoqs(
                    selectedBoqToUpdate?.ProjectDirectoryId,
                    selectedBoqToUpdate?.BOQPackageId,
                    selectedUploadBoqSheetName
                  )
                }
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Draggable>

      <Draggable>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={openProjDirectory}
          onClose={handleCloseProjDirectory}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Project Directory</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseProjDirectory}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400 }}>
            <>
              <Card>
                <CardContent>
                  <React.Fragment>
                    <Grid container spacing={3}>
                      {projectDirectory &&
                        projectDirectory.ProjectDirectoryId && (
                          <>
                            {/* <fieldset>
                                <legend>Project Directory</legend> */}

                            <Grid item xs={6} sm={3}>
                              <Typography className="label">
                                Site Address
                              </Typography>
                              <Typography variant="body2">
                                {projectDirectory.SiteAddress}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography className="label">Name </Typography>
                              <Typography variant="body2">
                                {projectDirectory.ProjectShortName}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography className="label">Detail </Typography>
                              <Typography variant="body2">
                                {projectDirectory.ProjectDetails}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography className="label">Area </Typography>
                              <Typography variant="body2">
                                {projectDirectory.Area}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography className="label">
                                Start Date{" "}
                              </Typography>
                              <Typography variant="body2">
                                {dayjs(projectDirectory.ProjectStartOn).format(
                                  "DD-MMM-YYYY"
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography className="label">
                                End Date{" "}
                              </Typography>
                              <Typography variant="body2">
                                {dayjs(projectDirectory.ProjectEndOn).format(
                                  "DD-MMM-YYYY"
                                )}
                              </Typography>
                            </Grid>

                            {/* </fieldset> */}
                          </>
                        )}
                    </Grid>
                  </React.Fragment>
                </CardContent>
              </Card>
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseProjDirectory} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Draggable>

      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={imageopen}
        onClose={handleImage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Upload Image
          {/* <i style={{ fontSize: 15 }}>
              {"Package : " + selectedUploadedBoqPackageName}
            </i> */}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleImage}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ minHeight: 400 }}>
          <form autoComplete="off" noValidate onSubmit={handleImageSubmit}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={12} alignItems="center">
                <Box display="flex" flexDirection="column">
                  {
                    values.ImageURL && (
                      // <Avatar
                      //   alt="BoqUploadImage Image"
                      //   src={`${process.env.REACT_APP_BASE_URL}${values.ImageURL}`}
                      //   sx={{ width:'300px', height: '300px', marginTop: 2 , border: '1px solid black', }}
                      // />
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${values.ImageURL}`}
                        width="100%"
                        height={300}
                      />
                    )
                    // : (
                    //   <Avatar
                    //     src=""
                    //     alt="BoqUploadImage Image"
                    //     sx={{ width: 150, height: 150, marginTop: 2 }}
                    //   >
                    //     <PersonIcon />
                    //   </Avatar>
                    // )
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} alignItems="center">
                <Typography style={{ color: "red", fontSize: "0.75rem" }}>
                  {" "}
                  File Size Should be 100Kb
                </Typography>
                <Stack spacing={1} direction="row" alignItems="center">
                  <input
                    id="profileImageSelectedFile"
                    type="file"
                    style={{ display: "none" }}
                    ref={BoqUploadFileInputRef}
                    accept="image/*"
                    onChange={(event: any) => {
                      onBoqUploadImageChange(event);
                    }}
                  />
                  <Button
                    startIcon={<PostAddIcon />}
                    variant="contained"
                    color="success"
                    onClick={handleButtonClick}
                  >
                    Browse Image
                  </Button>
                  {boqUploadImageFile && (
                    <Stack direction="row" spacing={0} alignItems="center">
                      <p>{boqUploadImageFile.name}</p>
                      <IconButton
                        size="small"
                        aria-label="delete"
                        color="error"
                        onClick={resetBoqUploadImageFile}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Stack spacing={2} direction="row">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Stack>
            </CardActions>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImage} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BoqUpload;
