import { Guid } from 'guid-typescript'
import React from 'react'
import { ExpenseHdrsModel, GetExpensesModel, UpdateExpenseHdrStatusByApprover2DTO, UpdateExpenseHdrStatusDTO, UpdateForTallyModel, UpdateLevel1DTO, UpdateLevel2DTO, updateLevel2ApproveDTO } from '../models/ExpenseHdrsModel';
import { axiosRetryConfig, customAxios } from './AxiosHttpCommon';
import { ExpenseQueriesModel } from '../models/ExpenseQueriesModel';

const initialFieldValues: ExpenseHdrsModel = {
    ExpenseHdrId: Guid.EMPTY,
    ExpenseVoucherNo: "",
    ProjectDirectoryId: '',
    ExpenseVoucherDate: null,
    ExpenseDescription: "",
    ExpenseHdrStatusId: "",
    ExpenseHdrStatus: "",
    Level1ApprovedByUser: "",
    SerialNo: "",
    Level2ApprovedByUser: "",
    Level1ApprovalStatusDate: null,
    CreatedById: "",
    CreatedOn: "",
    ApproverUserId: "",
    UserName: "",
    UpdatedById: "",
    Level1ApprovedByUserId: "",
    Level1RejectedRemark: "",
    Level2ApprovalStatusDate: null,
    Level2ApprovedByUserId: "",
    Level2RejectedRemark: "",
    TotInvoiceAmt: 0,
    UpdatedOn: null,
    ClientId: "",
    SiteId: "",
    ProjectShortName: "",
    ProjectDirectory: null,
    files: [],
    FileName: "",
    UserId: "",
    ExpenseFor: "",
    VendorId: "",
}
const initialUpdateExpensesFieldValues: UpdateExpenseHdrStatusDTO = {
    ExpenseHdrId: Guid.EMPTY,
    ExpenseHdrStatusId: "",
    Level1ApprovedByUserId: "",
    UpdatedById: "",
    UpdatedOn: null,
    CreatedById: ""
}


const initialUpdateExpensesLevel1FieldValues: UpdateLevel1DTO = {
    ExpenseHdrId: Guid.EMPTY,
    ExpenseHdrStatusId: "",
    UpdatedById: "",
    UpdatedOn: null,
    Level1ApprovalStatusDate: null,
    Level1RejectedRemark: "",
}
const initialUpdateExpensesApprover2FieldValues: UpdateExpenseHdrStatusByApprover2DTO = {
    ExpenseHdrId: Guid.EMPTY,
    ExpenseHdrStatusId: "",
    UpdatedById: "",
    UpdatedOn: null,
    Level2ApprovedByUserId: "",
}
const initialUpdateExpensesLevel2FieldValues: UpdateLevel2DTO = {
    ExpenseHdrId: Guid.EMPTY,
    ExpenseHdrStatusId: "",
    UpdatedById: "",
    UpdatedOn: null,
    Level2ApprovalStatusDate: null,
    Level2RejectedRemark: "",
    Level2ApprovedByUserId: "",
}
const initialUpdateExpensesLevel2ApproveFieldValues: updateLevel2ApproveDTO = {
    ExpenseHdrId: Guid.EMPTY,
    ExpenseHdrStatusId: "",
    UpdatedById: "",
    UpdatedOn: null,
    Level2ApprovalStatusDate: null,
    Level2ApprovedByUserId: "",
}


const initialExpensesFieldValues: GetExpensesModel = {
    UserID: "",
    ExpenseHdrStatusID: "",
    FromDt: null,
    ToDt: null,
    ProjectDirectoryId: ''
}

const initialUpdateForTallyModelFieldValues: UpdateForTallyModel = {
    ExpenseHdrId: "",
    ExpenseHdrIds: [],
    ExpenseHdrStatusId: "",
    UpdatedById: "",
    UpdatedOn: null,

}
const url = 'Expenses';

function post(payload: ExpenseHdrsModel) {
    return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}

function put(payload: ExpenseHdrsModel) {
    return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(ExpenseHdrId: number) {
    return customAxios.get(`${url}/GetById/${ExpenseHdrId}`, axiosRetryConfig);
}

function getAll(payload: GetExpensesModel) {
    return customAxios.post(`${url}/GetAll`, payload, axiosRetryConfig);
}

function getExpenseHdrsSelectList() {
    return customAxios.get(`${url}/GetSelectList`, axiosRetryConfig);
}

function UpdateExpenseHdrStatusID(payload: UpdateExpenseHdrStatusDTO) {

    return customAxios.put(`${url}/UpdateExpenseHdrStatusByApprover1`, payload, axiosRetryConfig);
}

function updateLevel2Approve(payload: updateLevel2ApproveDTO) {

    return customAxios.put(`${url}/UpdateLevel2Approve`, payload, axiosRetryConfig);
}
function getZoneApprovers(userID: string) {
    return customAxios.get(`${url}/GetZoneApprovers/${userID}`, axiosRetryConfig
    );
}
function UpdateLevel1Reject(payload: UpdateLevel1DTO) {
    return customAxios.put(`${url}/UpdateLevel1Reject`, payload, axiosRetryConfig);
}

function UpdateForDraft(payload: ExpenseQueriesModel) {
    return customAxios.put(`${url}/UpdateForDraft`, payload, axiosRetryConfig);
}

function UpdateExpenseHdrStatusByApprover2(payload: UpdateExpenseHdrStatusByApprover2DTO) {
    return customAxios.put(`${url}/UpdateExpenseHdrStatusByApprover2`, payload, axiosRetryConfig);
}

function UpdateLevel2Reject(payload: UpdateLevel2DTO) {
    return customAxios.put(`${url}/UpdateLevel2Reject`, payload, axiosRetryConfig);
}

function statusUpdateForTallyTransfer(payload: UpdateForTallyModel) {

    return customAxios.put(`${url}/StatusUpdateForTallyTransfer`, payload, axiosRetryConfig);
}
function GetExpenseManagerList() {
    return customAxios.get(`${url}/GetExpenseManagerList`, axiosRetryConfig);
}

function getExpenseHdrsStatusSelectList() {

    return customAxios.get(`${url}/GetStatusSelectList`, axiosRetryConfig);
}

function getSelectListSelfVendor() {
    return customAxios.get(`${url}/GetSelectListSelfVendor`, axiosRetryConfig);
}

export const ExpenseHdrsService = {
    initialFieldValues,
    initialExpensesFieldValues,
    initialUpdateExpensesFieldValues,
    initialUpdateExpensesLevel1FieldValues,
    initialUpdateExpensesApprover2FieldValues,
    initialUpdateExpensesLevel2FieldValues,
    initialUpdateExpensesLevel2ApproveFieldValues,
    initialUpdateForTallyModelFieldValues,
    post,
    put,
    remove,
    getById,
    getAll,
    getExpenseHdrsSelectList,
    UpdateExpenseHdrStatusID,
    getZoneApprovers,
    UpdateLevel1Reject,
    UpdateExpenseHdrStatusByApprover2,
    UpdateLevel2Reject,
    updateLevel2Approve,
    statusUpdateForTallyTransfer,
    GetExpenseManagerList,
    getExpenseHdrsStatusSelectList,
    UpdateForDraft,
    getSelectListSelfVendor
}