
import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { DashBoardModel } from "../models/DashBoardModel";



const initialDashBoardValues: DashBoardModel = {
    FromDt: null,
    ToDt: null,
    UserId: '',

}

const url = "DashBoard";



function getDashBoardGetMonthWiseExpenses(payload: DashBoardModel) {

    return customAxios.post(`${url}/GetDashBoardGetMonthWiseExpenses`, payload, axiosRetryConfig);
}

function getDashBoardGetChargeWiseExpenses(payload: DashBoardModel) {
    return customAxios.post(`${url}/GetDashBoardGetChargeWiseExpenses`, payload, axiosRetryConfig);
}

function getDashBoardGetProjectWiseExpenses(payload: DashBoardModel) {
    return customAxios.post(`${url}/GetDashBoardGetProjectWiseExpenses`, payload, axiosRetryConfig);
}
function getDashBoardGetStatusWiseExpenses(payload: DashBoardModel) {
    return customAxios.post(`${url}/GetDashBoardGetStatusWiseExpenses`, payload, axiosRetryConfig);
}

function getReportingUsersUserID(UserID: string) {
    return customAxios.get(`${url}/GetReportingUsersUserID/${UserID}`, axiosRetryConfig);
}

function getBOQDashboardPackageWise(payload: DashBoardModel) {
    return customAxios.post(`${url}/BOQDashboardPackageWise`, payload, axiosRetryConfig);
}

function getBOQDashboardProcurementWise(payload: DashBoardModel) {
    return customAxios.post(`${url}/BOQDashboardProcurementWise`, payload, axiosRetryConfig);
}

function getBOQDashboardProjectWise(payload: DashBoardModel) {
    return customAxios.post(`${url}/BOQDashboardProjectWise`, payload, axiosRetryConfig);
}


export const DashboardService = {

    initialDashBoardValues,
    getDashBoardGetMonthWiseExpenses,
    getDashBoardGetChargeWiseExpenses,
    getDashBoardGetProjectWiseExpenses,
    getDashBoardGetStatusWiseExpenses,
    getReportingUsersUserID,
    getBOQDashboardPackageWise,
    getBOQDashboardProcurementWise,
    getBOQDashboardProjectWise,
};