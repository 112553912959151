import React, { useState, useEffect } from "react";
import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  CardActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmDialog from "../helper/ConfirmDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BoqsubTitleAttributeService } from "../../services/BoqsubTitleAttributeService";
import { PageTitleInfoRequestModel, PageTitleInfoResponseModel } from "../../models/BoqItemModel";
import { boqitemService } from "../../services/BoqItemService";

const BoqSubTitleAttributesList: React.FC = () => {
  const { BoqsubTitleId, AttributeType }: any = useParams();
  const prevPgState = useLocation();
  const [titleInfo, setTitleInfo] = useState<PageTitleInfoResponseModel>(boqitemService.initialPageTitleInfoResponseValues);
  const [boqSubTitleAttributes, setBoqSubTitleAttributes] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });
  const navigate = useNavigate();
  useEffect(() => {
    getBoqSubTitleAttributes();
    getPageTitle();
  }, []);

  const getBoqSubTitleAttributes = () => {
    BoqsubTitleAttributeService.GetAllMBoqsubTitleAttributesByAttType(
      BoqsubTitleId,
      AttributeType
    ).then((response) => {
      let result = response.data;
      
      setBoqSubTitleAttributes(result.list);
   
    });
  };

  const getPageTitle = () => {
    let model: PageTitleInfoRequestModel = {
      BoqItemId: '',
      AttributeType: AttributeType,
      BoqSubTitleId: BoqsubTitleId,
      BoqTitleId: '',
      BoqPackageId: '',
    };
    boqitemService
      .getPageTitleInfo(model)
      .then((response) => {
        setTitleInfo(response.data.row);
      });
  };


  const columns: GridColDef[] = [
    { field: "BoqsubTitleAttributeName", headerName: "BOQ SubTitle Attribute Name", width: 130, flex: 1 },
    {
      field: "Active",
      headerName: "Active",
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            {/* {params.row.Active && "Yes"}
              {!params.row.Active && "No"} */}
            {params.row.Active && <DoneIcon color="secondary" />}
            {!params.row.Active && <CloseIcon />}
          </Stack>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              color="primary"
              aria-label="add an alarm"
               onClick={() => navigate("/editboqSubTitlesAttribute/" + BoqsubTitleId +"/"+ AttributeType + "/" + params.row.BoqsubTitleAttributeId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this Bank ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeBoqTitle(params.row.BoqsubTitleAttributeId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const removeBoqTitle = (BoqsubTitleAttributeId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    BoqsubTitleAttributeService.remove(BoqsubTitleAttributeId).then((response) => {
      let result = response.data;
      if (response) {
        //   globalService.success(result.message);
        getBoqSubTitleAttributes();
      }
    });
  };

  return (
    <>
    <Stack direction="row" spacing={0} justifyContent="space-between">
        <Typography variant="h5" align="center">
       BOQ Sub Titles Attribute
        </Typography>
        {/* {(prevPgState && prevPgState.state && prevPgState.state.row) &&
          <Typography variant="body1"> <b>BOQ Package :</b> {prevPgState.state.row.Boqtitle?.Boqpackage.BoqpackageName} <br/>
          <b> BOQ Title Name: </b>{prevPgState.state.row.Boqtitle?.BoqtitleName} <br/>
          <b> BOQ SubTitle Name: </b>{prevPgState.state.row?.BoqsubTitleName} 
          </Typography>
        } */}
         {(titleInfo ) &&
          <Typography variant="body1"> 
            <b>BOQ Package :</b> {titleInfo.BoqPackageName}
            <br />
            <b>BOQ Title Name :</b> {titleInfo.BoqTitleName}
            <br />
            <b> BOQ SubTitle Name: </b>{titleInfo.BoqSubTitleName}
            <br />
            <b> BOQ Attribute: </b>{titleInfo.AttributeTypeName}
          </Typography>
        }
      
      </Stack>
      <Card>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addboqSubTitlesAttribute/" + BoqsubTitleId  +"/"+ AttributeType)}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BoqsubTitleAttributeId}
              rows={boqSubTitleAttributes}
              columns={columns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    boqTitleId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
          </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
               variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back To List
            </Button>
          </Stack>
        </CardActions>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default BoqSubTitleAttributesList;
