import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { boqTitleAttributeService } from '../../services/BoqTitleAttributeService';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddCircleOutline } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, IconButton, Stack, Typography } from "@mui/material";
import ConfirmDialog from '../helper/ConfirmDialog';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PageTitleInfoRequestModel, PageTitleInfoResponseModel } from '../../models/BoqItemModel';
import { boqitemService } from '../../services/BoqItemService';


const BoqTitleAttributeList: React.FC = () => {
  const [boqTitleAttribute, setTitleAttribute] = useState([]);
  const prevPgState = useLocation();
  const [titleInfo, setTitleInfo] = useState<PageTitleInfoResponseModel>(boqitemService.initialPageTitleInfoResponseValues);
    const [confirmDialog, setConfirmDialog] = useState({
      isOpen: false,
      title: "",
      subTitle: "",
      onConfirm: () => {},
    });
    const {AttributeType,BoqtitleId,AttributeTypeName}: any = useParams();
  
    const navigate = useNavigate();
    useEffect(() => {
      getTitleAttribute();
      getPageTitle();
    }, []);
  
    const getTitleAttribute = () => {
      
      boqTitleAttributeService.GetAllMBoqtitleAttributes(BoqtitleId,AttributeType).then((response) => {
        let result = response.data;
        
        setTitleAttribute(result.list);
       
      });
    };

    const getPageTitle = () => {
      let model: PageTitleInfoRequestModel = {
        BoqItemId: '',
        AttributeType: AttributeType,
        BoqSubTitleId: '',
        BoqTitleId: BoqtitleId,
        BoqPackageId: '',
      };
      boqitemService
        .getPageTitleInfo(model)
        .then((response) => {
          setTitleInfo(response.data.row);
        });
    };

    const titleattcolumn: GridColDef[] = [
      {field:"BoqtitleAttributeName", headerName: "Title Attribute Name",  flex: 1,},
      {
        field: "Actions",
        headerName: "Actions",
        type: "number",
        width: 100,
        // flex: 1,
        renderCell: (params) => {
          return (
            // eslint-disable-next-line react/jsx-no-undef
            <Stack direction="row" spacing={0}>
              <IconButton
                size="small"
                color="primary"
                aria-label="add an alarm"
               onClick={() => navigate("/editTitleAttribute/"+ BoqtitleId  +"/"+ AttributeType+ "/" + params.row.BoqtitleAttributeId)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                size="small"
                aria-label="delete"
                color="error"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this Title Attribute ?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {
                      removeBoqTitleAttribute(params.row.BoqtitleAttributeId);
                    },
                  });
                }}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          );
        },
      },
    ]

    const removeBoqTitleAttribute =(BoqtitleAttributeId : any) => {
      
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
  
      boqTitleAttributeService.remove(BoqtitleAttributeId).then((response) => {
        let result = response.data;
        if (response) {
          //   globalService.success(result.message);
          getTitleAttribute();
        }
      });
    }

  return (
    <>
   
   <Stack direction="row" spacing={0} justifyContent="space-between">
        <Typography variant="h5" align="center">
        Titles Attribute
        </Typography>
        {(titleInfo ) &&
          <Typography variant="body1"> 
            <b>BOQ Package :</b> {titleInfo.BoqPackageName}
            <br />
            <b>BOQ Title Name :</b> {titleInfo.BoqTitleName}
            <br />
            {/* <b> BOQ SubTitle Name: </b>{titleInfo.BoqSubTitleName}
            <br /> */}
            <b> BOQ Attribute: </b>{titleInfo.AttributeTypeName}
          </Typography>
        }
      </Stack>
          <Card>
            <CardContent>
            <Button
                variant="contained"
                startIcon={<AddCircleOutline />}
                onClick={() => navigate("/addTitleAttribute/"+ BoqtitleId  +"/"+ AttributeType)}
              >
                Add Record
              </Button>
              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.BoqtitleAttributeId}
                  columns={titleattcolumn}
                  rows={boqTitleAttribute}
                  columnHeaderHeight={30}
                  //rowHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                  //loading={loading}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns Id, the othe`r columns will remain visible
                        boqTitleId: false,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                  }}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </div>
    
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/boqTitles/")}
            >
              Back To List
            </Button>
          </Stack>
        </CardActions>
         
          </Card>
          <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
       </>
  )
}

export default BoqTitleAttributeList

