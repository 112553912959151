import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material'
import { MultiSelectListModel } from "../../models/ApiResponse";
import { makeStyles } from '@mui/styles';

//https://www.npmjs.com/package/react-multi-select-component
const useStyles = makeStyles({
  container: {
    position: 'relative',
    textAlign: 'center',
    color: 'white',
  },
  label: {
    position: "absolute",
    fontSize: '12px !important',
    marginTop: '-9px',
    marginBottom: '-9px',
    paddingLeft: '11px',
    zIndex: '2 !important'
  },
});

const ReactMultiSelect = (props: any) => {
  const classes = useStyles();
  const { setFieldValue, touched } = props;
  const { label, value, disabled, onChange, options, error } = props;
  return (
    <div >
      {
        <>
          <InputLabel className={classes.label}>{label}</InputLabel>
          <FormControl variant="standard" fullWidth size='small' disabled={disabled}
            {...(error && { error: true })}>
            <MultiSelect
              // className="dropdown-container"
              options={options}
              value={value}
              onChange={onChange}
              //isOpen={true}
              // onChange={(e: MultiSelectListModel) => {
              //   setFieldValue(value, e);
              // }}
              labelledBy="Select"

            />
            {error && <FormHelperText>{error}</FormHelperText>}
           
          </FormControl>
        </>
      }
    </div>
  )
}

export default ReactMultiSelect