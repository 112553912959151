import { Guid } from "guid-typescript";
import { ExpenseDtlsModel } from "../models/ExpenseDtlsModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { config } from "../utility/Config";

const initialFieldValues: ExpenseDtlsModel = {

    ExpenseDtlId: Guid.EMPTY,
    ExpenseHdrId: "",
    ExpenseHeadId: "",
    ProjectDirectoryId: "",
    BasicAmount: null,
    Cgst: null,
    Sgst: null,
    Igst: null,
    Gsttotal: null,
    InvoiceAmt: null,
    Active: true,
    Remark: "",
    files: [],
    FileName: "",
    ClientId: "",
    SiteId: "",
    ProjectShortName: "",
    ProjectDirectory: null,
    FilePath: "",
}



const url = 'expenseDtls';


  function post(payload: ExpenseDtlsModel, files: File[], )
   {
    const formData = new FormData();
    // if (FilesUpload != null) {
    //   formData.append("Expense", FilesUpload);
    // }
    if (files != null && files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        formData.append("Expense", files[i]);
      }
    }
    formData.append("data", JSON.stringify(payload));
   // formData.append("expenseFileDetails", JSON.stringify(expenseFilePayload));
    return customAxios.post(`${url}/Add`, formData, config.formDataConfig);
  }


  function put(payload: ExpenseDtlsModel, files: File[]) {
    const formData = new FormData(); 
    if (files != null && files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        formData.append("Expense", files[i]);
      }
    }
    
    formData.append("data", JSON.stringify(payload));
    return customAxios.put(`${url}/Edit`, formData, config.formDataConfig);
  }
  

function remove(id: number) {
  
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}


function getById(ExpenseDtlId: number) {
    return customAxios.get(`${url}/GetById/${ExpenseDtlId}`, axiosRetryConfig);
}

function getAll () {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

function getAllByExpenseHdr(ExpenseHdrId: string ){
    return customAxios.get(`${url}/GetAllByExpenseHdr/${ExpenseHdrId}`,axiosRetryConfig);
}


export const expenseDtlsService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll,
    getAllByExpenseHdr,
}