

import { ProjectDirectoryModel } from "../models/ProjectDirectoryModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: ProjectDirectoryModel = {
    ProjectDirectoryId: "",
    ClientId: "",
    SiteId: "",
    SiteAddress: "",
    ProjectTypeId: "",
    ProjectShortName: "",
    ProjectDetails: "",
    ProjectStartOn: "",
    ProjectEndOn: "",
    Area: "",
    ProjectDirectoryStatusId: 0,
    ProjectDirectoryStatus: "",
    Active: true,
    ProjectCode: ""
}


const url = "/ProjectDirectory";

function GetByClientIdAndSiteId(clientId: string, siteId: string) {

    return customAxios.get(`${url}/GetByClientIdAndSiteId?clientId=${clientId}&&siteId=${siteId}`, axiosRetryConfig);
}

function GetBySiteId(siteId: string) {
    return customAxios.get(`${url}/GetBySiteId?siteId=${siteId}`, axiosRetryConfig);
}

function getSelectList(ProjectDirectoryId: string) {
    return customAxios.get(`${url}/GetSelectList/${ProjectDirectoryId}`, axiosRetryConfig);
}


function getProjectSelectList(clientId: string, siteId: string) {
    return customAxios.get(`${url}/GetProjectSelectList/${clientId}/${siteId}`, axiosRetryConfig);
}
function getAllSelectList() {
    return customAxios.get(`${url}/GetAllSelectList`, axiosRetryConfig);
}

export const projectDirectoryService = {
    initialFieldValues,
    getAllSelectList,
    GetByClientIdAndSiteId,
    GetBySiteId,
    getSelectList,
    getProjectSelectList
}
