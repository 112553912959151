import { Guid } from "guid-typescript";
import { BoqItemAttributeModel } from "../models/BoqItemAttributeModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqItemAttributeModel = {
    BoqitemAttributeId: Guid.EMPTY,
    BoqitemId: "",
    BoqsubTitleId: "",
    AttributeType : 0,
    AttributeTypeName: "",
    BoqitemAttributeName: "",
    Active: true,
    ParentBoqitemAttributeId: ""
}

const url = "MBoqitemAttributes";

// function GetAllMBoqsubTitleAttributes(boqtitleId: number){
//     return customAxios.get(`${url}/GetAll/${boqtitleId}`,axiosRetryConfig);
// }

function post(payload:BoqItemAttributeModel ){
    
    return customAxios.post(`${url}/Add`,payload,axiosRetryConfig);
}
function put(payload: BoqItemAttributeModel){
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}
function remove(id: number){
    return customAxios.delete(`${url}/${id}`,axiosRetryConfig);
}

function GetById(boqitemAttributeId: number){
    return customAxios.get(`${url}/GetById/${boqitemAttributeId}`,axiosRetryConfig);
}

function GetAllMBoqitemAttributesByAttType(BoqitemId: number, AttributeType: number){

    return customAxios.get(`${url}/GetAllMBoqitemAttributesByAttType/${BoqitemId}/${AttributeType}`,axiosRetryConfig);
}

function getAll(){
    return customAxios.get(`${url}/GetAll`,axiosRetryConfig);
}

export const boqItemAttributeService = {
    initialFieldValues,
    // GetAllMBoqsubTitleAttributes,
    post,
    put,
    remove,
    GetById,
    GetAllMBoqitemAttributesByAttType,
    getAll
};