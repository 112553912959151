import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LanIcon from "@mui/icons-material/Lan";
import SaveIcon from "@mui/icons-material/Save";
import React, { useEffect, useRef, useState } from "react";
import Controls from "../../utility/Controls.tsx/Controls";
import {
  MultiSelectListModel,
  SelectListModel,
} from "../../models/ApiResponse";
import { mClientService } from "../../services/MClientService";
import useForm from "../../utility/hooks/UseForm";
import { mSiteService } from "../../services/MSiteService";
import { projectDirectoryService } from "../../services/ProjectDirectoryService";
import { ProjectDirectoryModel } from "../../models/ProjectDirectoryModel";
import dayjs from "dayjs";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { boqUploadService } from "../../services/BoqUploadService";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBox } from "@mui/icons-material";
import { MVendorService } from "../../services/MVendorService";
import { MVendorModel } from "../../models/MVendorModel";
import Draggable from "react-draggable";
import { MProcurementTypesService } from "../../services/MProcurementTypesService";
import { globalService } from "../../services/GlobalService";
import { boqrequisitionsService } from "../../services/BoqRequisitionService";
import { AuthContext } from "../../utility/context/AuthContext";
import { RFQService } from "../../services/RequestForQuoteService";
import ConfirmDialog from "../helper/ConfirmDialog";
import {
  BOQItemDtlsRateComparisonModel,
  RateComparisonRequestDTO,
} from "../../models/BoqUploadModel";
import { BoqposService } from "../../services/BoqPosService";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { mPaymentTermsService } from "../../services/MPaymentTermsService";
import { BoqDocFileService } from "../../services/BoqdocFilesService";
import ArrowBackIcon from "@mui/icons-material/ArrowForward";
import PostAddIcon from "@mui/icons-material/PostAdd";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import { ourOfficesService } from "../../services/OurOfficesService";
import { useParams } from "react-router-dom";

const RateComparison = (params: any) => {
  const { auth } = React.useContext(AuthContext);
  const { procurementTypeId } = useParams();
  // const {boqCandI,boqMEP , labourProcumentsvalue , procumentsvalue ,factorywork} = useParams();
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [clients, setClients] = useState<MultiSelectListModel[]>([]);
  const [selectedBoqUploadIds, setSelectedBoqUploadIds] = useState<[]>([]);
  const [rateComparisons, setRateComparisons] = useState([]);
  const [actualRateComparisons, setActualRateComparisons] = useState([]);
  const [selectedVendors, setSelectedVendorUploadIds] = useState<any[]>([]);
  const [procurementTypes, setProcurementTypes] = useState<SelectListModel[]>(
    []
  );
  const [rateSummary, setRateSummary] = useState<any[]>([]);
  const [boqItems, setBoqItems] = useState<any[]>([]);
  const [packageWiseBoqItems, setPackageWiseBoqItems] = useState<any[]>([]);
  const [insertDataForBOQPOs, SetInsertDataForBOQPO] = useState<any[]>([]);
  const [sites, setSites] = useState<SelectListModel[]>([]);
  const [packages, setPackages] = useState<any[]>([]);
  const [vendorRows, setVendorRows] = useState<MVendorModel[]>([]);
  const [showProjectDirectory, setShowProjectDirectory] =
    useState<boolean>(false);
  const [projectDirectory, setProjectDirectory] =
    useState<ProjectDirectoryModel>(null);
  const [open, setOpen] = React.useState(false);
  const [openrateComparison, setOpenRateComparison] = React.useState(false);
  const [openVendor, setOpenforVendor] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rateComparisionRowDetails, setRateComparisionRowDetails] =
    useState<BOQItemDtlsRateComparisonModel>(
      boqUploadService.initialItemDtlsRateComparisonValues
    );
  const docFileInputRef = useRef<HTMLInputElement>(null);
  const [tab, setTab] = React.useState("1");
  const draggableRef = useRef(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const [paymentTerms, setPaymentTerms] = useState([]);
  const [boqDocFiles, setBoqDocFile] = useState([]);
  const [docFiles, setDocFiles] = useState([]);
  const [visible, SetVisible] = useState<boolean>(false);

  const [nestedTabValue, setNestedTabValue] = React.useState("1");

  const [selectedPaymentTermIds, setSelectedPaymentTermIds] = useState<any[]>(
    []
  );
  const [selectedboqDocFileIds, setSelectedboqDocFileIds] = useState<any[]>([]);
  const [sitnamenameList, setSiteNameList] = useState<MultiSelectListModel[]>(
    []
  );
  const [ourOfficeList, setOurOfficeList] = useState<MultiSelectListModel[]>(
    []
  );

  const [ourOffice, setOurOffice] = useState<any>(
    ourOfficesService.initialFieldValues
  );
  const [shipToSite, setShipToSite] = useState<any>(
    mSiteService.initialFieldValues
  );

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    //subTitle: "",
    onConfirm: () => { },
  });

  const goToNextTab = () => {
    const nextValue = (parseInt(tab) + 1).toString();
    setTab(nextValue);
  };

  const handleOpen = (row: any) => {
    // getAllVendor();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let initialColumns: GridColDef[] = [
    // {
    //   field: "BOQUploadID",
    //   headerName: "BOQ Upload",
    //   width: 130,
    //   flex: 1,
    //
    // },
    {
      field: "ClientSrNo",
      headerName: "Client SrNo",
    },

    {
      field: "BOQDescription",
      headerName: "BOQ Description",
      width: 300,
      cellClassName: "cell-left",
      renderCell: (params) => (
        <globalService.ScrollableCellRenderer value={params.value} />
      ),
    },
    { field: "Unit", headerName: "Unit", flex: 1 },
    {
      field: "Qty",
      headerName: "Qty",
      flex: 1,
      headerAlign: "right",
      cellClassName: "cell-right",
    },

    {
      field: "RFQSendToVendors",
      headerName: "RFQ Send To Vendors",
      width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",
      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setRateComparisionRowDetails(params.row);
            handleOpenforRateComparison(params.row.BOQUploadId, null);
          }}
        >
          {params.row.RFQSendToVendors}
        </Link>
      ),
    },
    {
      field: "RFQReceivedFromVendors",
      headerName: "Quote Received",
      width: 100,
      headerAlign: "right",
      cellClassName: "cell-right",

      renderCell: (params) => (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setRateComparisionRowDetails(params.row);
            handleOpenforRateComparison(params.row.BOQUploadId, true);
          }}
        >
          {params.row.RFQReceivedFromVendors}
        </Link>
      ),
    },

    // {
    //   field: "Location",
    //   headerName: "Location",
    //   width: 150,
    //   // flex: 1,
    //
    // },
    {
      field: "SelectedVendorsName",
      headerName: "Selected Vendors Name",
      width: 150,

    },
    {
      field: "SelectedVendorsSupplyRate",
      headerName: "Selected Vendors Supply Rate",
      width: 150,
      headerAlign: "right",
      cellClassName: "cell-right",

    },
    {
      field: "SelectedVendorsInstallationRate",
      headerName: "Selected Vendors Installation Rate",
      width: 150,
      headerAlign: "right",
      cellClassName: "cell-right",

    },
    {
      field: "SelectedVendorsRate",
      headerName: "Selected Vendors Rate",
      width: 150,
      headerAlign: "right",
      cellClassName: "cell-right",

    },
    {
      field: "SelectedVendorsMake",
      headerName: "Selected Vendors Make",
      width: 150,
      headerAlign: "right",
      cellClassName: "cell-right",

    },
    // {
    //   field: "Location",
    //   headerName: "Location",
    //   width: 150,
    //   // flex: 1,
    // },

    {
      field: "BOQMake ",
      headerName: "Make ",
      width: 100,
      // flex: 1,
    },
    // {
    //   field: "BOQSize ",
    //   headerName: "Size",
    //   width: 100,
    //   // flex: 1,
    // },
    {
      field: "BOQUploadStatus",
      headerName: "Status",
      width: 100,
    },

    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   type: "number",
    //   width:150,
    //   // flex: 1,
    //   renderCell: (para,fms) => (
    // <Button
    //   variant="contained"
    //   color="primary"
    //   // onClick={() => handleButtonClick(params.row)}
    // >
    //  Select Vendor
    // </Button>
    //   ),
    // },
    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   width: 200,
    //   // flex: 1,
    //   renderCell: (params) => (
    //     <FormControl>
    //       <InputLabel id="multiselect-label">Fullfillment</InputLabel>
    //       <Select
    //         labelId="singleselect-label"
    //         id="singleselect"
    //         value={"Buy"} // Set the selected value // Render the selected values
    //       >
    //         <MenuItem value="Buy">Buy</MenuItem>
    //         <MenuItem value="SiteWork">Site Work</MenuItem>
    //         <MenuItem value="FactoryWork">Factory Work</MenuItem>
    //         <MenuItem value="B2B">B2B</MenuItem>
    //         {/* Add more MenuItem components for additional options */}
    //       </Select>

    //     </FormControl>

    //   ),

    // },
  ];


  // const handleSubmitToCreatePo = () => {
  //   setConfirmDialog({
  //     ...confirmDialog,
  //     isOpen: false,
  //   });

  //   values.PaymentTermIDs = selectedPaymentTermIds.toString();
  //   values.BOQUploadIDs = selectedBoqUploadIds.toString();
  //   values.BOQDocFileIDs = selectedboqDocFileIds.toString();

  //   //getRateComparisonDetail
  // }

  const handleOpenforRateComparison = (
    BOQUploadId: any,
    ShowQuotedRatesOnly?: boolean
  ) => {
    getRateComparison(BOQUploadId, ShowQuotedRatesOnly);
    setOpenRateComparison(true);
  };

  const handleRateComparison = (row: any) => {
    //handleCloseforrateComparison();
  };
  const handleCloseforrateComparison = () => {
    setOpenRateComparison(false);
  };

  const handleOpenforVendor = (row: any) => {
    setOpenforVendor(true);
  };
  const handleCloseforVendor = () => {
    setOpenforVendor(false);
  };

  useEffect(() => {
    if (sitnamenameList.length === 0) getSiteNameList();
    if (sitnamenameList.length === 0) getOurOfficeList();
    // if (clients.length === 0) getClients();

    if (sites.length === 0) getSites();
    if (procurementTypes.length === 0) getProcurementType();
    getPOPaymentTerms();
  }, []);

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    // if ("ClientID" in fieldValues)
    //   temp.ClientID = fieldValues.ClientID ? "" : "Client is required.";

    if ("SiteID" in fieldValues)
      temp.SiteID = fieldValues.SiteID ? "" : "Site is required.";

    if ("BOQPackageID" in fieldValues)
      temp.BOQPackageID = fieldValues.BOQPackageID
        ? ""
        : "Package is required.";

    if ("ProcurementTypeId" in fieldValues)
      temp.ProcurementTypeId = fieldValues.ProcurementTypeId
        ? ""
        : "Procurement Type is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleConvertedBoqSelectionChange = (selectedIds: any) => {
    setSelectedBoqUploadIds(selectedIds);
  };

  const handleConvertedVendorSelectionChange = (selectedVendorsIds: any) => {
    setSelectedVendorUploadIds(selectedVendorsIds);
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      boqUploadService.initialFieldValuesForRateComparisonRequest,
      validate,
      ""
    );

  // const{valuesforreq : any}=
  // useForm(boqrequisitionsService.initialInsertDataForRequisitionsValues, validate, "");

  // const getClients = () => {
  //   mClientService.GetAllMultiSelectList().then((response: any) => {
  //     setClients(response.data);
  //   });
  // };

  const getRateComparison = (
    BOQUploadId: any,
    ShowQuotedRatesOnly?: boolean
  ) => {
    RFQService.getQuotesRcvdFromVendorForPOCreationNew(
      BOQUploadId,
      ShowQuotedRatesOnly
    ).then((response) => {
      let result = response.data;
      setRateComparisons(result.list);
      setActualRateComparisons(result.list);
    });
  };

  const handleProcessRateComparisonRowUpdate = (newRow: any, oldRow: any) => {
    const updatedField = Object.keys(newRow).find(
      (key) => newRow[key] !== oldRow[key]
    );
    let actualData = actualRateComparisons.filter(
      (x) => x.BoqrfqitemId === newRow.BoqrfqitemId
    )[0];

    //let isError = false;
    if (newRow.InstallationRate < 0) {
      globalService.error("InstallationRate cannot be < 0");
      newRow.InstallationRate = actualData.InstallationRate;
    }
    if (newRow.SupplyRate < 0) {
      globalService.error("SupplyRate cannot be < 0");
      newRow.SupplyRate = actualData.SupplyRate;
    }

    if (updatedField === "SupplyRate" || updatedField === "InstallationRate") {
      newRow.Rate = (newRow.SupplyRate || 0) + (newRow.InstallationRate || 0);
    }

    if (
      (newRow.InstallationRate &&
        actualData.InstallationRate !== newRow.InstallationRate) ||
      (newRow.SupplyRate && actualData.SupplyRate !== newRow.SupplyRate) ||
      (newRow.VendorRemarks &&
        actualData.VendorRemarks !== newRow.VendorRemarks) ||
      (newRow.IsSelected && actualData.IsSelected !== newRow.IsSelected) ||
      (newRow.VendorMake && actualData.VendorMake !== newRow.VendorMake) ||
      (newRow.AnjRemarks && actualData.AnjRemarks !== newRow.AnjRemarks)
    ) {
      newRow.IsEdited = true;
    } else {
      newRow.IsEdited = false;
    }
    setRateComparisons((prevRows: any) =>
      prevRows.map((row: any) =>
        row.BoqrfqitemId === newRow.BoqrfqitemId ? newRow : row
      )
    );
    return newRow;
  };

  const getRateComparisonRowClassName = (params: any) => {
    let isRowEdited = rateComparisons.some(
      (x) => x.BoqrfqitemId === params.id && x.IsEdited == true
    );
    return isRowEdited ? "editedRow" : "";
  };

  const handleProcessRateComparisonRowUpdateError = (error: any) => {
    console.error(error);
  };

  const EditBoqRequisitions = () => {
    var modifiedRows = rateComparisons.filter((x) => x.IsEdited == true);
    if (modifiedRows.length <= 0) {
      globalService.error("No records to modify.");
      return;
    }
    const filteredArray = modifiedRows.map(
      ({
        BoqrfqitemId,
        InstallationRate,
        SupplyRate,
        VendorRemarks,
        AnjRemarks,
        VendorMake,
        IsSelected,
      }) => ({
        BoqrfqitemId,
        InstallationRate,
        SupplyRate,
        VendorRemarks,
        AnjRemarks,
        VendorMake,
        IsSelected,
      })
    );
    RFQService.editBOQRFQs(filteredArray).then((response) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          globalService.success(result.message);
          handleCloseforrateComparison();
        } else {
          globalService.error(result.message);
        }
      }
    });
  };

  const getProcurementType = () => {
    MProcurementTypesService.getAllSelectList().then((response: any) => {
      setProcurementTypes(response.data);
      setValues((prevValues: any) => ({
        ...prevValues,
        ProcurementTypeId: procurementTypeId, //response.data[0].Value,
      }));
    });
  };
  const getSites = () => {
    mSiteService.GetMultiSelectListByUploadedBoqs().then((response: any) => {
      setSites(response.data);
    });
  };
  const getBoqPackages = (ProjectDirectoryID: any) => {
    projectDirectoryService
      .getSelectList(ProjectDirectoryID)
      .then((response: any) => {
        setPackages(response.data);
        if (response.data.length > 0) {
          let PackageId = response.data[0].Value;
          setValues((prevValues: any) => ({
            ...prevValues,
            BOQPackageID: PackageId,
          }));
        }
      });
  };
  const getProjectDirectory = (siteId: any) => {
    projectDirectoryService.GetBySiteId(siteId).then((response) => {
      let result = response.data;
      setProjectDirectory(result.row);
      if (result.row) values.ProjectDirectoryID = result.row.ProjectDirectoryId;
      else values.ProjectDirectoryID = "";
      getBoqPackages(values.ProjectDirectoryID);
      getBoqDocFile();
      // getProcurementType();
    });
  };

  const getRateComparisonSummary = (payload: any) => {
    boqUploadService
      .GetBOQItemDtlsRateComparisonSummary(payload)
      .then((response) => {
        let result = response.data;
        setRateSummary(result.list);
      });
  };

  const getRateComparisonDetail = (payload: any) => {

    boqUploadService.getBOQItemDtlsRateComparisons(payload).then((response) => {
      let result = response.data;
      setBoqItems(result.list);
    });
  };

  let initialPackageWiseColumns: GridColDef[] = [
    {
      field: "BOQPackageName",
      headerName: "BOQ Package",
      flex: 1
    },
    {
      field: "ANJCost",
      headerName: "ANJ Cost",
      width: 200,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params: any) => {
        return !isNaN(params.value) ? globalService.formatToIndianCurrency(params.value) : "";
      },
    },
    {
      field: "L1",
      headerName: "L1",
      width: 200,
      headerAlign: "right",
      cellClassName: "cell-right",
      renderCell: (params) => {
        if (!isNaN(params.value))
          return globalService.formatToIndianCurrency(params.value)
        else if (params.value && params.value.includes('%')) {
          return <div>{params.value}</div>
        }
        else
          return <div className="text-red">{params.value}</div>;
      }
      // valueFormatter: (params: any) => {
      //   return !isNaN(params.value) ? globalService.formatToIndianCurrency(params.value) : params.value;
      // },
    },
  ];

  const [packageWiseColumns, setPackageWiseColumns] = useState<any[]>(initialPackageWiseColumns);

  const getPackageWiseRateComparison = (payload: any) => {
    setPackageWiseColumns(initialPackageWiseColumns);
    boqUploadService.getPackageWiseRateComparison(payload.ProjectDirectoryID).then((response) => {
      let result = response.data;
      setPackageWiseBoqItems(result.list);
      // Update the columns state by adding new columns
      // Simulate fetching new columns from an API or some dynamic source
      const newColumns = globalService.generateColumnHeadersDynamically(result.newColumns, true);
      setPackageWiseColumns((prevColumns) => [...prevColumns, ...newColumns]);
    });
  };

  const handleSelectForinsertDataForBOQPO = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    values.BOQUploadIDs = selectedBoqUploadIds;
    const idsString = values.BOQUploadIDs.join(",");
    values.PaymentTermIDs = selectedPaymentTermIds.toString();
    values.BOQDocFileIDs = selectedboqDocFileIds.toString();
    values.BOQUploadIDs = idsString;
    values.CreatedByID = auth.Id;
    values.BillToOurOfficesID = values.OurOfficesId;
    values.ShipToSiteID = values.SiteID;

    BoqposService.insertDataForBOQPO(values).then((response: any) => {
      let result = response.data;
      var SetInsertggddDataForBOQPO = result.list.Result[0].Info;
      globalService.success(` ${SetInsertggddDataForBOQPO}`);
    });

    let model: RateComparisonRequestDTO = {
      BOQPackageID: values.BOQPackageID,
      ProjectDirectoryID: values.ProjectDirectoryID,
      ProcurementTypeId: procurementTypeId,
      WorkSheetName: "",
      VendorID: values.VendorID,
      ShowRecordsOfRatesEnteredYN: values.ShowRecordsOfRatesEnteredYN,
    };
    handleCreatePoClose(null, "");
    getRateComparisonDetail(model);
  };
  const handleSubmit = () => {
    //e.preventDefault();
    if (validate()) {
      setValues((prevValues: any) => ({
        ...prevValues,
        ShowRecordsOfRatesEnteredYN:
          values.ShowRecordsOfRatesEnteredYN === false ||
            !values.ShowRecordsOfRatesEnteredYN
            ? null
            : true,
      }));
      values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;
      getRateComparisonSummary(values);
      getRateComparisonDetail(values);
      getPackageWiseRateComparison(values);
      setBoqItems([]);
      SetVisible(true);
      //getRateComparisonDetail(values);
    }
  };

  const handleVendorSelect = (row: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  const handleSelect = (row: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    RFQService.updateIsSelected(row).then((response: any) => {
      let result = response.data;
      if (response) {
        setSelectedRow(result.list);
        handleCloseforrateComparison();
        handleSubmit();
      }
    });
  };

  const handleConvertedPaymentTermSelectionChange = (
    selectedPaymentTermIds: any
  ) => {
    setSelectedPaymentTermIds(selectedPaymentTermIds);
  };

  const handleConvertedboqDocFileSelectionChange = (
    selectedboqDocFileIds: any
  ) => {
    setSelectedboqDocFileIds(selectedboqDocFileIds);
  };

  const handleBrowseButtonClick = () => {
    if (nestedTabValue == "3" && docFileInputRef.current) {
      docFileInputRef.current.click();
    }
  };

  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(
      `${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`,
      "_blank"
    );
  };

  const uploadBoqDocs = () => {
    if (!docFiles || docFiles.length == 0) {
      globalService.error("Please select File less than 5Mb to upload.");
      return;
    }
    values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;
    BoqDocFileService.docsImport(values, docFiles).then((response) => {
      let result = response.data;

      if (result.isSuccess) {
        globalService.success(result.message);
        // setDocsListByProjectDirectoryId(result.docList);
        // refreshUploadFileControls();
        setDocFiles([]);
        getBoqDocFile();
      } else {
        globalService.error(result.message);
        // setDocsListByProjectDirectoryId([]);
        //setDocFilesUpload([]);
        // setDocFilesUpload(result.list);
      }
    });
  };

  const onDocFileChange = (fileInput: any) => {
    console.log(docFileInputRef.current);
    const filesArray: File[] = Array.from(fileInput.target.files);

    // Check file sizes
    const filesLessThan5MB = filesArray.filter(
      (file) => file.size <= 5 * 1024 * 1024
    ); // 5MB in bytes

    // Update state with the array of files if all files are less than 5MB
    if (filesLessThan5MB.length === filesArray.length) {
      setDocFiles(filesLessThan5MB);
    } else {
      // Display an error message or handle the condition where file size exceeds 5MB
      console.error("One or more files exceed the size limit of 5MB");
    }
  };
  const removeBoqDocFile = (index: number) => {
    setDocFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const isDisabled = !(selectedPaymentTermIds.length > 0);
  const handlecreatePoOpen = (row: any) => {
    getPOPaymentTerms();
    setOpen(true);
  };

  const handleCreatePoClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const getPOPaymentTerms = () => {
    mPaymentTermsService.getAllPOPaymentTerms().then((reponse) => {
      let result = reponse.data;
      setPaymentTerms(result.list);
    });
  };

  const getBoqDocFile = () => {
    BoqDocFileService.getAll(values.ProjectDirectoryID).then((reponse) => {
      let result = reponse.data;
      setBoqDocFile(result.list);
    });
  };

  const getSiteNameList = () => {
    mSiteService.getSiteNameSelectList().then((response) => {
      setSiteNameList(response.data);
    });
  };

  const getOurOfficeList = () => {
    ourOfficesService.getOurOfficeSelectList().then((response) => {
      setOurOfficeList(response.data);
    });
  };

  const getOurOffice = (ourOfficeId: any) => {
    ourOfficesService.getById(ourOfficeId).then((response) => {
      if (response) {
        let result = response.data;

        setOurOffice(result.list);
      }
    });
  };

  const getShipToSite = (SiteId: any) => {

    mSiteService.getById(SiteId).then((response) => {
      if (response) {
        let result = response.data;

        setShipToSite(result.list);
      }
    });
  };

  const rateSummaryColumns: GridColDef[] = [
    {
      field: "IsSelected",
      headerName: "Select",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            {params.row.Amount > 0 && (
              <Button
                className="btnGrid"
                variant="contained"
                color="primary"
                disabled={isDisabled}
                onClick={handlecreatePoOpen}
              // onClick={() => {
              //   setConfirmDialog({
              //     isOpen: true,
              //     title: "Are you sure, want to create PO for this Vendor?",
              //     onConfirm: () => {
              //       handleVendorSelect(params.row);
              //     },
              //   });
              // }}
              >
                Create PO
              </Button>
            )}
          </Stack>
        );
      },
    },
    {
      field: "VendorName",
      headerName: " Vendor Name",
      flex: 1,
      // renderCell: (params) => (
      //   <a
      //     href="#"
      //     className="link"
      //     onClick={(event) => {
      //       event.preventDefault();

      //       let model: RateComparisonRequestDTO = {
      //         BOQPackageID: params.row.BOQPackageID,
      //         ProjectDirectoryID: params.row.ProjectDirectoryID,
      //         ProcurementTypeId: params.row.ProcurementTypeID,
      //         WorkSheetName: "",
      //         VendorID: params.row.VendorID,
      //         ShowRecordsOfRatesEnteredYN: values.ShowRecordsOfRatesEnteredYN,
      //       };

      //       getRateComparisonDetail(model);
      //     }}
      //   >
      //     {params.value}
      //   </a>
      // ),
    },
    { field: "SPOCName", headerName: " Spoc Name", flex: 1 },
    { field: "SPOCMobile", headerName: " Spoc Mobile", flex: 1 },
    {
      field: "Amount",
      headerName: " Amount",
      flex: 1,
      headerAlign: "right",
      cellClassName: "cell-right",
    },
    // { field: "SupplyRate", headerName: "Supply Rate", type: 'number', flex: 1 },
    // { field: "InstallationRate", headerName: "Installation Rate", type: 'number', flex: 1 },
    // { field: "Rate", headerName: "Rate", width: 80, },
  ];

  const rateComparisonColumns: GridColDef[] = [
    {
      field: "IsSelected",
      headerName: "Select",
      width: 90,
      renderCell: (params) => {
        const isSelected =
          params.id === rateComparisionRowDetails.SelectedBOQRFQItemID; //: selectedRowId;
        return (
          <Stack>
            {params.row.Rate > 0 && (
              <Button
                variant="contained"
                className="btnGrid"
                color={isSelected ? "inherit" : "primary"}
                //color={isSelected ?"secondary" : "primary"}
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure, want to Select this Vendor?",
                    // subTitle: "Once send, changes won't be allowed",
                    onConfirm: () => {
                      handleSelect(params.row);
                    },
                  });
                }}
              // onClick={() => {
              //   handleSelect(params.row)
              // }}
              >
                {isSelected ? "Selected" : "Select"}
              </Button>
            )}
          </Stack>
        );
      },
    },
    { field: "BOQRFQNo", headerName: " Req No", width: 120 },
    {
      field: "BOQRFQDate",
      headerName: "Req Date",
      width: 100,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    { field: "VendorName", headerName: " Vendor Name", flex: 1 },
    { field: "SPOCName", headerName: " Spoc Name", flex: 1 },
    { field: "SPOCMobile", headerName: " Spoc Mobile", width: 100 },
    {
      field: "SupplyRate",
      headerName: "Supply Rate",
      type: "number",
      width: 80,
      editable: true,
      cellClassName: "editableColumn",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "InstallationRate",
      headerName: "Installation Rate",
      type: "number",
      width: 80,
      editable: true,
      cellClassName: "editableColumn",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "Rate", headerName: "Rate", width: 80,
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "VendorRemarks",
      headerName: "Vendor Remarks",
      flex: 1,
      editable: true,
      cellClassName: "editableColumn",

    },
    {
      field: "VendorMake",
      headerName: "Vendor Make",
      flex: 1,
      editable: true,
      cellClassName: "editableColumn",
    },
    {
      field: "AnjRemarks",
      headerName: "Anj Remarks",
      flex: 1,
      editable: true,
      cellClassName: "editableColumn",
    },
  ];

  const paymentTermsColumns: GridColDef[] = [
    {
      field: "PaymentTerm",
      headerName: "Term's And Conditions",
      flex: 6,
      align: "left",
    },
  ];

  const boqDocFilesColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      width: 500,
      renderCell: (params) => (
        <>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleFileClick(event, params.row)}
          >
            {params.row.FileName}
          </Link>
        </>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },

    // { field: 'Address', headerName: 'Address', flex: 1 },
  ];

  // const getAllVendor = () => {
  //   MVendorService.getAll().then((response) => {
  //     let result = response.data;
  //     setVendorRows(result.list);
  //   });
  // };

  const handleSubmitForQuotation = (e: React.FormEvent) => {
    e.preventDefault();
    values.VendorIDs = selectedVendors.toString();
    values.BOQUploadIDs = selectedBoqUploadIds.toString();
    values.CreatedByID = auth.Id;
    // values.CreatedByID = "2BC4C9FD-B990-4805-86B2-BE69689E552E";
    RFQService.post(values).then((response: any) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          globalService.success(result.message);
          handleCloseforVendor();
        }
      }
    });
  };

  const vendorColumns: GridColDef[] = [
    { field: "VendorName", headerName: "Vendor Name", flex: 1 },
    { field: "VendorCode", headerName: "Vendor Code", width: 100 },
    { field: "Email", headerName: "Email", width: 150 },
    { field: "Address1", headerName: "Address1", flex: 1 },
    { field: "Spocname", headerName: "Spoc Name", flex: 1 },
    { field: "Spocmobile", headerName: "Spoc Mobile", flex: 1 },
    // { field: 'Address', headerName: 'Address', flex: 1 },
  ];



  return (
    <div>
      <Typography variant="h5" align="center">
        Rate Comparison & Create PO
      </Typography>
      <Card>
        <CardContent>
          <React.Fragment>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={3}>
                <Controls.MuiAutocomplete
                  options={clients}
                  label="Client"
                  error={errors.ClientID}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "ClientID",
                        value: event ? event.value : "",
                      },
                    });
                    if (event) {
                      getSites(event.value);
                      setValues((prevValues: any) => ({
                        ...prevValues,
                        SiteID: "",
                      }));
                      setProjectDirectory(
                        projectDirectoryService.initialFieldValues
                      );
                    }
                  }}
                  value={
                    clients.find(
                      (item: any) => item.value === values.ClientID
                    ) || null
                  }
                />
              </Grid> */}

              <Grid item xs={12} sm={3}>
                <Controls.MuiAutocomplete
                  options={sites}
                  label="Site"
                  error={errors.SiteID}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "SiteID",
                        value: event ? event.value : "",
                      },
                    });
                    getShipToSite(event.value);

                    if (event) {
                      getProjectDirectory(event.value);
                    }
                  }}
                  value={
                    sites.find((item: any) => item.value === values.SiteID) ||
                    null
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <form
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <Controls.Select
                        name="BOQPackageID"
                        label="BOQ Package"
                        required
                        showEmptyItem={true}
                        options={packages}
                        value={packages.length > 0 ? values.BOQPackageID : ""}
                        onChange={(e: any) => {
                          handleInputChange(e);
                          // Submit form on change
                        }}
                        error={errors.BOQPackageID}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controls.Select
                        name="ProcurementTypeId"
                        label="Procurement Type"
                        required
                        disabled
                        showEmptyItem={true}
                        options={procurementTypes}
                        value={
                          procurementTypes.length > 0
                            ? values.ProcurementTypeId
                            : ""
                        }
                        onChange={(e: any) => {
                          handleInputChange(e);
                        }}
                        error={errors.ProcurementTypeId}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Controls.Checkbox
                        label="Show Rates Records"
                        name="ShowRecordsOfRatesEnteredYN"
                        value={values.ShowRecordsOfRatesEnteredYN}
                        onChange={handleInputChange}
                        error={errors.ShowRecordsOfRatesEnteredYN}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button type="submit" variant="contained">
                        Go
                      </Button>
                      {projectDirectory && (
                        <IconButton
                          size="small"
                          color="error"
                          aria-label="add an alarm"
                          onClick={() =>
                            setShowProjectDirectory(
                              (showProjectDirectory) => !showProjectDirectory
                            )
                          }
                        >
                          <LanIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {showProjectDirectory &&
                projectDirectory &&
                projectDirectory.ProjectDirectoryId && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="h5"
                        align="center"
                        className="MuiCardHeader-title"
                      >
                        Project Directory
                      </Typography>
                      <Divider></Divider>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">
                            Site Address
                          </Typography>
                          <Typography variant="body2">
                            {projectDirectory.SiteAddress}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">
                            Project Name{" "}
                          </Typography>
                          <Typography variant="body2">
                            {projectDirectory.ProjectShortName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">
                            Project Detail{" "}
                          </Typography>
                          <Typography variant="body2">
                            {projectDirectory.ProjectDetails}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">Area </Typography>
                          <Typography variant="body2">
                            {projectDirectory.Area}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">Start Date </Typography>
                          <Typography variant="body2">
                            {dayjs(projectDirectory.ProjectStartOn).format(
                              "DD-MMM-YYYY"
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">End Date </Typography>
                          <Typography variant="body2">
                            {dayjs(projectDirectory.ProjectEndOn).format(
                              "DD-MMM-YYYY"
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
            </Grid>
          </React.Fragment>
        </CardContent>
      </Card>

      {visible && (
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Packagewise Comparison" value="1" />
              <Tab label="Rate Comparison Detail" value="2" />
              <Tab label="Rate Comparison Summary" value="3" />              
            </TabList>
            <TabPanel value="1">
              <Card>
                <CardContent>
                  <>
                    <div style={{ height: 650, width: "100%" }}>
                      <DataGrid
                        getRowId={(row) => row.rowId}
                        rows={packageWiseBoqItems}
                        columns={packageWiseColumns}
                        columnHeaderHeight={30}
                        //rowHeight={30}
                        autoHeight={false}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}

                        //loading={loading}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              // Hide columns Id, the other columns will remain visible
                              BOQPackageID: false,
                            },
                          },
                          pagination: { paginationModel: { pageSize: 50 } },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    </div>


                  </>
                </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value="2">
              <Card>
                <CardContent>
                  <>
                    <div style={{ height: 650, width: "100%" }}>
                      <Grid item xs={12} sm={3}>
                        <Stack direction="row" spacing={1} marginY={1}>
                          {selectedBoqUploadIds.length > 0 && (
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handlecreatePoOpen}
                              // onClick={() => {
                              //   setConfirmDialog({
                              //     isOpen: true,
                              //     title:
                              //       "Are you sure, want to Create Purchase Order?",
                              //     //  subTitle: "Once send, changes won't be allowed",
                              //     onConfirm: () => {
                              //       handleSelectForinsertDataForBOQPO();
                              //     },
                              //   });
                              // }}
                              >
                                Create PO
                              </Button>
                            </Grid>
                          )}
                          <p> {selectedBoqUploadIds.length} Rate's selected.</p>
                        </Stack>
                      </Grid>
                      <DataGrid
                        getRowId={(row) => row.BOQUploadId}
                        rows={boqItems}
                        columns={initialColumns}
                        columnHeaderHeight={30}
                        //rowHeight={30}
                        autoHeight={false}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}
                        checkboxSelection
                        rowSelectionModel={selectedBoqUploadIds}
                        isRowSelectable={(params) =>
                          !!params.row.SelectedVendorsName
                        }
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={
                          handleConvertedBoqSelectionChange
                        }
                        //loading={loading}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              // Hide columns Id, the other columns will remain visible
                              BOQUploadId: false,
                            },
                          },
                          pagination: { paginationModel: { pageSize: 50 } },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    </div>
                  </>
                </CardContent>
                <CardActions sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack spacing={2} direction="row">
                    {selectedBoqUploadIds.length > 0 && (
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpen}
                        >
                          Create PO
                        </Button>
                      </Grid>
                    )}
                  </Stack>
                </CardActions>
              </Card>
            </TabPanel>

            <TabPanel value="3">
              <Card>
                <CardContent>
                  <div style={{ height: 320, width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.VendorID}
                      rows={rateSummary}
                      columns={rateSummaryColumns}
                      columnHeaderHeight={30}
                      autoHeight={false}
                      rowSelection
                      //onCellDoubleClick={(params) => handleRateComparison(params.row)}

                      // getRowClassName={getRateComparisonRowClassName}
                      // processRowUpdate={handleProcessRateComparisonRowUpdate}
                      // onProcessRowUpdateError={handleProcessRateComparisonRowUpdateError}
                      getRowHeight={() => "auto"}
                      //getEstimatedRowHeight={() => 200}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns Id, the other columns will remain visible
                            BOQUploadId: false,
                          },
                        },
                        pagination: { paginationModel: { pageSize: 50 } },
                      }}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                      pageSizeOptions={[10, 25, 50, 100]}
                    />
                  </div>
                </CardContent>
              </Card>
            </TabPanel>

           

          </Box>
        </TabContext>
      )}

      <div>
        <Draggable nodeRef={draggableRef}>
          <Dialog
            ref={draggableRef}
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleCreatePoClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, textAlign: "center" }}
              id="customized-dialog-title"
            >
              Purchase Order Creation
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => handleCreatePoClose(null, "")}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent dividers>
              <>
                <Grid item xs={12} sm={3}>
                  <Stack direction="row" spacing={1} marginY={1}>
                    {/* {selectedVendors.length > 0 &&  selectedPaymentTermIds.length > 0 && selectedboqDocFileIds.length > 0 && ( */}
                    <Tooltip
                      title={
                        isDisabled
                          ? "Please select Vendor, Payment, and Doc files"
                          : ""
                      }
                    >
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isDisabled}
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: "Are you sure to Create PO?",
                              //  item: "You can't undo this operation",
                              onConfirm: () => {
                                handleSelectForinsertDataForBOQPO();
                              },
                            });
                          }}
                        >
                          Create PO
                        </Button>
                      </span>
                    </Tooltip>

                    <p>
                      {" "}
                      {selectedPaymentTermIds.length} Term's And Conditions
                      selected.{selectedboqDocFileIds.length} Doc File's
                      selected.
                    </p>
                  </Stack>
                </Grid>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleTabChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="others" value="1" />
                        <Tab label="Term's And Conditions" value="2" />
                        <Tab label="Doc File's" value="3" />
                      </TabList>
                    </Box>

                    <TabPanel value="2">
                      {" "}
                      <>
                        <div style={{ width: "100%" }}>
                          <DataGrid
                            getRowId={(row) => row.PaymentTermId}
                            rows={paymentTerms}
                            columns={paymentTermsColumns}
                            columnHeaderHeight={30}
                            autoHeight={true}
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowSelectionModel={selectedPaymentTermIds}
                            onRowSelectionModelChange={
                              handleConvertedPaymentTermSelectionChange
                            }
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  // Hide columns Id, the other columns will remain visible
                                  Id: false,
                                },
                              },
                              pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                              },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                          />
                        </div>
                        <CardActions
                          sx={{ display: "flex", justifyContent: "right" }}
                        >
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              startIcon={<ArrowBackIcon />}
                              onClick={() => goToNextTab()}
                            >
                              Next
                            </Button>
                          </Stack>
                        </CardActions>
                      </>
                    </TabPanel>

                    <TabPanel value="3">
                      {" "}
                      <>
                        <Grid item xs={12} sm={3}>
                          <input
                            type="file"
                            //name="file"
                            id="docSelectedFile"
                            multiple
                            style={{ display: "none" }}
                            onChange={(event: any) => {
                              onDocFileChange(event);
                            }}
                            // onChange={onFileChange}
                            ref={docFileInputRef}
                            accept=".pdf, .doc, .docx, .xls, .xlsx, image/*"
                          />
                          <Button
                            startIcon={<PostAddIcon />}
                            variant="contained"
                            color="primary"
                            onClick={() => handleBrowseButtonClick()}
                          >
                            Browse Doc Files
                          </Button>

                          <Typography
                            style={{ color: "green", fontSize: "0.75rem" }}
                          >
                            {" "}
                            File Size Should be 5MB
                          </Typography>

                          {docFiles.length > 0 &&
                            docFiles.map((file, index) => (
                              <Stack key={index} direction="row" spacing={1}>
                                <Typography>{file.name}</Typography>
                                {/* Display other file details if needed */}
                                <IconButton
                                  size="small"
                                  aria-label="delete"
                                  color="error"
                                  onClick={() => removeBoqDocFile(index)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </Stack>
                            ))}

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Stack direction="row" spacing={0}>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<UploadIcon />}
                                onClick={() => uploadBoqDocs()}
                              >
                                Upload Documents
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>

                        <div style={{ width: "100%" }}>
                          <DataGrid
                            getRowId={(row) => row.BoqdocFileId}
                            rows={boqDocFiles}
                            columns={boqDocFilesColumns}
                            columnHeaderHeight={30}
                            autoHeight={true}
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowSelectionModel={selectedboqDocFileIds}
                            onRowSelectionModelChange={
                              handleConvertedboqDocFileSelectionChange
                            }
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  // Hide columns Id, the other columns will remain visible
                                  Id: false,
                                },
                              },
                              pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                              toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 10 },
                              },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                          />
                        </div>
                      </>
                    </TabPanel>

                    <TabPanel value="1">
                      <Card>
                        <CardHeader title="Others" />
                        <CardContent>
                          <Grid container spacing={1}>
                            {/*<Grid item xs={12} sm={6}>
                              <Controls.MuiAutocomplete
                                options={sitnamenameList}
                                label="Ship To Site"
                                required
                                error={errors.ShipToSiteId}
                                onChange={(event: MultiSelectListModel) => {
                                  const ShipToSiteId = event ? event.value : "";
                                  handleInputChange({
                                    target: {
                                      name: "ShipToSiteId",
                                      value: ShipToSiteId,
                                    },
                                  });
                                  getShipToSite(ShipToSiteId)
                                }}
                                value={
                                  sitnamenameList.find(
                                    (item: any) => item.value === values.ShipToSiteId
                                  ) || null
                                }
                              />
                            </Grid> */}

                            <Grid item xs={12} sm={6}>
                              <Controls.MuiAutocomplete
                                options={ourOfficeList}
                                label="Bill To Our Office"
                                required
                                error={errors.OurOfficesId}
                                onChange={(event: MultiSelectListModel) => {
                                  const ourOfficeId = event ? event.value : "";
                                  handleInputChange({
                                    target: {
                                      name: "OurOfficesId",
                                      value: ourOfficeId,
                                    },
                                  });
                                  getOurOffice(ourOfficeId);
                                  // }
                                }}
                                value={
                                  ourOfficeList.find(
                                    (item: any) =>
                                      item.value === values.OurOfficesId
                                  ) || null
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Controls.MuiAutocomplete
                                options={sites}
                                label="Site"
                                disabled
                                error={errors.SiteID}
                                onChange={(event: MultiSelectListModel) => {
                                  handleInputChange({
                                    target: {
                                      name: "SiteID",
                                      value: event ? event.value : "",
                                    },
                                  });

                                  if (event) {
                                    getProjectDirectory(event.value);
                                  }
                                }}
                                value={
                                  sites.find(
                                    (item: any) => item.value === values.SiteID
                                  ) || null
                                }
                              />
                            </Grid>
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <fieldset
                              style={{
                                flex: 1,
                                marginLeft: 10,
                                marginTop: 8,
                                marginBottom: 12,
                              }}
                            >
                              <legend>Bill To Our Office</legend>
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                  <Typography className="label">
                                    Address
                                  </Typography>
                                  <Typography variant="body2">
                                    {ourOffice?.Address1}
                                  </Typography>

                                  <Typography variant="body2">
                                    {ourOffice?.Address2}
                                  </Typography>

                                  <Typography variant="body2">
                                    {ourOffice?.Address3}
                                  </Typography>

                                  <Typography className="label">
                                    City
                                  </Typography>
                                  <Typography variant="body2">
                                    {ourOffice?.City}
                                  </Typography>

                                  <Grid item xs={12} sm={2}>
                                    <Typography className="label">
                                      PinCode
                                    </Typography>
                                    <Typography variant="body2">
                                      {ourOffice?.PinCode}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={2}>
                                    <Typography className="label">
                                      State
                                    </Typography>
                                    <Typography variant="body2">
                                      {ourOffice?.State?.State}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </fieldset>
                            <fieldset
                              style={{
                                flex: 1,
                                marginRight: 10,
                                marginTop: 8,
                                marginBottom: 12,
                              }}
                            >
                              <legend>Ship To Site</legend>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={8}>
                                  <Typography className="label">
                                    Address
                                  </Typography>
                                  <Typography variant="body2">
                                    {shipToSite?.Address}
                                  </Typography>

                                  <Grid item xs={12} sm={2}>
                                    <Typography className="label">
                                      State
                                    </Typography>
                                    <Typography variant="body2">
                                      {shipToSite?.State?.State}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </fieldset>
                          </div>
                        </CardContent>
                      </Card>
                      <CardActions
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="contained"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => goToNextTab()}
                          >
                            Next
                          </Button>
                        </Stack>
                      </CardActions>
                    </TabPanel>
                  </TabContext>
                </Box>
              </>
            </DialogContent>
          </Dialog>
        </Draggable>
      </div>

      {/* <div>
        <Draggable>
          <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, textAlign: "center" }}
              id="customized-dialog-title"
            >
              <Grid>Vendor Details</Grid>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <>
                <Grid item xs={12} sm={3}>
                  <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }}>
                    {/* <Button onClick={allocateActivityType} color='error' variant="contained">Allocate</Button> 
                    <p> {selectedVendors.length} Vendor's selected.</p>
                  </Stack>
                </Grid>
                <div style={{ width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.VendorId}
                    rows={vendorRows}
                    columns={vendorColumns}
                    columnHeaderHeight={30}
                    autoHeight={true}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={selectedVendors}
                    onRowSelectionModelChange={
                      handleConvertedVendorSelectionChange
                    }
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          Id: false,
                        },
                      },
                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={handleOpenforVendor}
                  >
                    Send to Vendor For Quotation
                  </Button>
                </div>
              </>
            </DialogContent>
          </Dialog>
        </Draggable>
      </div> */}

      <div>
        <Draggable>
          <Dialog
            open={openVendor}
            onClose={handleCloseforVendor}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you Sure to Send Mail's to Vendor For Quotation
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseforVendor}>No</Button>
              <Button onClick={handleSubmitForQuotation} autoFocus>
                yes
              </Button>
            </DialogActions>
          </Dialog>
        </Draggable>
      </div>

      <div>
        <Draggable>
          <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleCloseforrateComparison}
            aria-labelledby="customized-dialog-title"
            open={openrateComparison}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, textAlign: "center" }}
              id="customized-dialog-title"
            >
              Rate Comparison
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseforrateComparison}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <>
                {rateComparisionRowDetails && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>
                          <div
                            style={{ maxHeight: "100px", overflowY: "auto" }}
                          >
                            <Typography className="label">Title </Typography>
                            <Typography variant="body2">
                              {rateComparisionRowDetails.Title}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                          <div
                            style={{ maxHeight: "100px", overflowY: "auto" }}
                          >
                            <Typography className="label">
                              Sub Title{" "}
                            </Typography>
                            <Typography variant="body2">
                              {rateComparisionRowDetails.SubTitle}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                          <div
                            style={{ maxHeight: "100px", overflowY: "auto" }}
                          >
                            <Typography className="label">
                              Item Name{" "}
                            </Typography>
                            <Typography variant="body2">
                              {rateComparisionRowDetails.ItemName}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                          <Typography className="label">Make </Typography>
                          <Typography component="span" variant="body2">
                            <globalService.ScrollableCellRenderer
                              value={rateComparisionRowDetails.BOQMake}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                          <Typography className="label">Qty </Typography>
                          <Typography component="span" variant="body2">
                            <globalService.ScrollableCellRenderer
                              value={rateComparisionRowDetails.Qty}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                          <Typography className="label">Unit </Typography>
                          <Typography component="span" variant="body2">
                            <globalService.ScrollableCellRenderer
                              value={rateComparisionRowDetails.Unit}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={12}>
                          <Typography className="label">
                            Specifications{" "}
                          </Typography>
                          <Typography variant="body2">
                            {rateComparisionRowDetails.Specifications}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={6} sm={3}>
                        <Typography className="label">Start Date </Typography>
                        <Typography variant="body2">
                          {rateComparisionRowDetails.Title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Typography className="label">End Date </Typography>
                        <Typography variant="body2">
                          {dayjs(projectDirectory.ProjectEndOn).format(
                            "DD-MMM-YYYY"
                          )}
                        </Typography>
                      </Grid> */}
                      </Grid>
                    </Grid>
                  </>
                )}
                {/* <Grid item xs={12} sm={3}>
                  <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }}>
                   
                    <p> {selectedVendors.length} Vendor's selected.</p>
                  </Stack>
                </Grid> */}
                <div style={{ width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.BoqrfqitemId}
                    rows={rateComparisons}
                    columns={rateComparisonColumns}
                    columnHeaderHeight={30}
                    autoHeight={true}
                    rowSelection
                    onCellDoubleClick={(params) =>
                      handleRateComparison(params.row)
                    }
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    // rowSelectionModel={selectedVendors}
                    // onRowSelectionModelChange={
                    //   handleConvertedVendorSelectionChange
                    // }
                    getRowClassName={getRateComparisonRowClassName}
                    processRowUpdate={handleProcessRateComparisonRowUpdate}
                    onProcessRowUpdateError={
                      handleProcessRateComparisonRowUpdateError
                    }
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          BOQUploadId: false,
                        },
                      },
                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    sx={{ marginTop: 2 }}
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() => EditBoqRequisitions()}
                  >
                    Update BOQ Requisition's
                  </Button>
                </Stack>
              </>
            </DialogContent>
          </Dialog>
        </Draggable>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default RateComparison;
