import { Guid } from "guid-typescript";
import { BoqPackageMakesModel } from "../models/BoqPackageMakeModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqPackageMakesModel = {
    BoqpackageMakeId: Guid.EMPTY,
    BoqpackageId: '',
    Makes:'',
    ItemType: '',
    Material: '',
    IMaterial: '',
    Active:true,
}

const url = 'MBoqpackageMakes';

function post(payload: BoqPackageMakesModel) {
    
    return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}

function put(payload: BoqPackageMakesModel) {
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(BoqpackageMakeId: number) {
    return customAxios.get(`${url}/GetById/${BoqpackageMakeId}`, axiosRetryConfig);
}

function getAllBoqpackageMakes (BoqpackageId: number) {
    
    return customAxios.get(`${url}/GetAll/${BoqpackageId}`, axiosRetryConfig);
}

export const boqPackageMakesService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAllBoqpackageMakes
}
