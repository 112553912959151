import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { DepartmentModel } from "../../models/MDepartmentModel";
import { mDepartmentsService } from "../../services/MDepartmentService";

const MDepartmentForm = (...props: any) => {

  const{DepartmentId} = useParams();
  let navigate = useNavigate();
  const mode = DepartmentId ? "Edit" : "Create";

  const validate = (fieldValues = values) => {
      let temp: any = {...errors};

      if("DepartmentName" in fieldValues)
          temp.DepartmentName = fieldValues.DepartmentName ? "" : "Department Name is required";
      setErrors({
          ...temp,
      });

      if(fieldValues === values)
          return Object.values(temp).every((x) => x === "");

  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mDepartmentsService.initialFieldValues, validate, DepartmentId);

    const newDepartment = () => {
      setValues(mDepartmentsService.initialFieldValues);
    };

    function setFormValue(model: DepartmentModel) {
      let newModel = {
        DepartmentId : model.DepartmentId,
        DepartmentName: model.DepartmentName || "",
          Active: model.Active,
      };
      return newModel;
    }

    useEffect(() => {
      if(DepartmentId) {
        getDepartment(DepartmentId);
          setErrors({});
      } else newDepartment();
    }, [DepartmentId]);
  
    const getDepartment = (DepartmentId: any) => {
      mDepartmentsService.getById(DepartmentId).then((response) => {
          if(response) {
              let result = response.data;
              setValues(setFormValue(result.list));
          }
      });
    };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      if(validate()) {
        if(DepartmentId) { 
          mDepartmentsService.put(values).then((response: any) => {
              let result = response.data;
              if(response) {
                  globalService.success(result.message);
                  navigate("/mDepartments")
              } else {
                  globalService.error(result.message);
              }
          });
         } else {
          mDepartmentsService.post(values).then((response: any) => {
              if(response) {
                  let result = response.data;
                  if(result.isSuccess){
                      globalService.success(result.message);
                      navigate("/mDepartments")
                  } else {
                      globalService.error(result.message);
                    }
              } 
          });
         }
      }
    }
  


  return (
    <>
      <Typography variant="h5" align="center">
        Department
      </Typography>
      <form
        autoComplete="off" noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label=" Department Name"
                    name="DepartmentName"
                    value={values.DepartmentName}
                    onChange={handleInputChange}
                    error={errors.DepartmentName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>

              </Grid>

            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/mDepartments" )}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>

      </form>
    </>
  )
}

export default MDepartmentForm