import React, { useState, useEffect } from "react";
import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  Box,
  Chip,
  Avatar,
  TextField, // Import TextField for the search bar
} from "@mui/material";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { MVendorService } from "../../services/MVendorService";
import { MVendorModel } from "../../models/MVendorModel";
import Controls from "../../utility/Controls.tsx/Controls";
const MVendorList: React.FC = () => {
  const [vendors, setVendors] = useState<MVendorModel[]>([]);
  const [filteredVendors, setFilteredVendors] = useState<MVendorModel[]>([]); // State for filtered vendors
  const [searchQuery, setSearchQuery] = useState<string>(""); // State for search query
  const navigate = useNavigate();

  useEffect(() => {
    getVendor();
  }, []);

  const getVendor = () => {
    MVendorService.getAll().then((response) => {
      let result = response.data;
      setVendors(result.list);
    });
  };

  // Function to handle search and filter vendors
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const filtered = vendors.filter((vendor) =>
      vendor.VendorName.toLowerCase().includes(query.toLowerCase()) ||
      vendor.VendorCode.toLowerCase().includes(query.toLowerCase()) ||
      vendor.Address1.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredVendors(filtered);
  };

  // Use filtered vendors if search query is present, otherwise use all vendors
  const displayedVendors = searchQuery ? filteredVendors : vendors;


  return (
    <>
      <Typography variant="h5" align="center" margin={1}>
        Vendor
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ padding: '0 10px' }}>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => navigate("/addVendor/")}
              >
                Add Vendor
              </Button>
            </Grid>
            <Grid item xs={15} sm={3}>
              <Controls.Input
                label="Search Vendor"
                variant="standard"
                value={searchQuery}
                style={{width: '100%', maxWidth: '285px' }}
                onChange={(e: any) => handleSearch(e.target.value)}
              />
            </Grid>
          </Grid>


          <div style={{ width: "100%", marginTop: 10, padding: '0 10px' }}>
            <Grid container spacing={2}>
              {displayedVendors.map((vendor) => (
                <Grid
                  key={vendor.VendorId}
                  sx={{
                    width: 296,
                    maxWidth: "100%",
                    boxShadow: 3,
                    position: "relative",
                    margin: 1,
                  }}
                  onDoubleClick={() => navigate("/editVendors/" + vendor.VendorId)}
                >
                  <CardContent sx={{ alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h6">{vendor.VendorName}</Typography>
                    <Typography variant="body2"><b>Code :</b> {vendor.VendorCode}</Typography>
                    <Typography variant="body2">{vendor.Address1}</Typography>
                    <Typography variant="body2">{vendor.City}</Typography>
                    <Typography variant="body2">
                      {vendor.State && vendor.State.State}
                    </Typography>
                    <Typography variant="body2">{vendor.Pincode}</Typography>
                    <Typography variant="body2">
                      {vendor.Email && (
                        <>
                          <MailOutlineOutlinedIcon fontSize="inherit" />
                          {vendor.Email}
                        </>
                      )}
                    </Typography>
                    <Typography variant="body2">{vendor.Spocname}</Typography>
                    <Typography variant="body2">
                      {vendor.Spocmobile && (
                        <>
                          <CallOutlinedIcon fontSize="inherit" />
                          {vendor.Spocmobile}
                        </>
                      )}
                    </Typography>
                  </CardContent>
                  </Grid>
              ))}
            </Grid>
          </div>
        </CardContent>
      </Card>
    </>
  );
};


export default MVendorList;