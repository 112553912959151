import React, { useEffect, useState } from "react";
import { Grid, TextField, CardActions, Card, CardContent, Button, Typography, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { mUomalternativeTextService } from "../../services/MUomalternativeTextsService";
import { MUomalternativeTextsModel } from "../../models/MUomalternativeTextsModel";

const MUomalternativeTextsForm = (...props: any) =>{

  let navigate = useNavigate();
  const { UomalternativeTextId, Uomid } : any = useParams();
  const mode = UomalternativeTextId ? "Edit" : "Create";

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    if ("UomalternativeTextName" in fieldValues)
      temp.UomalternativeTextName = fieldValues.UomalternativeTextName ? "" : "UOM Alternative Text Name is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mUomalternativeTextService.initialFieldValues, validate, props.setCurrentId);

  const newUser = () => {
    setValues(mUomalternativeTextService.initialFieldValues);
  };

  function setFormValue(model: MUomalternativeTextsModel) {
    let newModel = {
      UomalternativeTextId: model.UomalternativeTextId,
      Uomid: model.Uomid,
      UomalternativeTextName: model.UomalternativeTextName,
      Active: model.Active,
    }
    return newModel;
  }

  useEffect(() => {
    if (UomalternativeTextId) {
      getUomName();
      setErrors({});
    }
       else newUser();
  }, [UomalternativeTextId]);

  const getUomName = () => {
    mUomalternativeTextService.getById(UomalternativeTextId).then((response) => {
      
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    })
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
    values.Uomid = Uomid;
      if (UomalternativeTextId) {
        mUomalternativeTextService.put(values).then((response: any) => {
          
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/mUomalternativeTexts/" + Uomid);
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        mUomalternativeTextService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              resetForm();
              navigate("/mUomalternativeTexts/" + Uomid);
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  return (
    <>
      <Typography variant="h5"  align="center">
        {mode} UOM Alternative Text
      </Typography>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="UOM Alternative Text Name"
                    required
                    name="UomalternativeTextName"
                    value={values.UomalternativeTextName}
                    onChange={handleInputChange}
                    error={errors.UomalternativeTextName}
                  />
                </Grid>

                <Grid item xs={12} sm={4} className={mode !== "Edit" ? "hidden" : ""}>
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">Submit</Button>
              <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/mUomalternativeTexts/" + Uomid)}
            >
              Back To List
            </Button>
            </Stack>

          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default MUomalternativeTextsForm