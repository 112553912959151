import React, { useEffect, useState } from "react";
import {
  Grid,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps,
  PaperProps,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { boqUploadService } from "../../services/BoqUploadService";
import {
  BOQItemDtlsModel,
  BoqUploadUpdateDTO,
} from "../../models/BoqUploadModel";
import {
  MultiSelectListModel,
  SelectListModel,
} from "../../models/ApiResponse";
import { boqTitleService } from "../../services/BoqTitleService";
import { boqSubTitleService } from "../../services/BoqSubTitleService";
import { boqitemService } from "../../services/BoqItemService";
import ConfirmDialog from "../helper/ConfirmDialog";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { AddType } from "../../utility/Config";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { boqPackageMakesService } from "../../services/BoqPackageMakeService";
import Draggable from "react-draggable";
import { mBoqshortFormsService } from "../../services/MBoqshortFormsService";

const BoqUploadForm = (props: any) => {
  // spGetBOQItemDtlsResult
  const row: BOQItemDtlsModel = props.boqUpload;
  debugger;
  console.log(row)
  const boqPackageId = props.boqPackageId;
  const projectDirectoryId = props.projectDirectoryId;
  let navigate = useNavigate();
  const mode = row.BOQUploadId ? "Edit" : "Create";
  const [activityTypes, setActivityTypes] = useState<SelectListModel[]>([]);
  const [boqTitles, setBoqTitles] = useState<SelectListModel[]>([]);
  const [boqSubTitles, setBoqSubTitles] = useState<SelectListModel[]>([]);
  const [boqItems, setBoqItems] = useState<SelectListModel[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openMake, setOpenMake] = React.useState(false);
  const [openStandardMake, setOpenStandardMake] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  const [addType, setAddType] = React.useState("");
  const [makeLists, setMakeLists] = useState<any[]>([]);
  const [standardMakeLists, setstandardMakeLists] = useState<any[]>([]);
  const [boqMakeValue, setBoqMakeValue] = useState(null);
  const [boqShortForm, setBoqShortForm] = useState<SelectListModel[]>([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });
  // const [confirmDialogForMake, setConfirmDialogForMake] = useState({
  //   isOpen: false,
  //   onConfirm: () => {},
  // });
  // const [confirmDialogForStandardMake, setConfirmDialogForStandardMake] =
  //   useState({
  //     isOpen: false,
  //     onConfirm: () => {},
  //   });
  const [makeListPackageName, setMakeListPackageName] = useState("");
  const [makeListprojectDirectoryName, setMakeListProjectDirectoryName] =
    useState("");

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    if ("BoqMake" in fieldValues)
      temp.BoqMake = fieldValues.BoqMake ? "" : "BOQ Make is required.";
    // if ("BoqSize" in fieldValues)
    //     temp.BoqSize = fieldValues.BoqSize ? "" : "BOQ Size is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      boqUploadService.initialBoqUploadUpdateValues,
      validate,
      props.setCurrentId
    );

  const newUser = () => {
    setValues(boqUploadService.initialBoqUploadUpdateValues);
  };

  function setFormValue(model: BoqUploadUpdateDTO) {
    let newModel = {
      BOQUploadId: model.BOQUploadId,
      BOQMake: model.BOQMake,
      boqMakeValue: model.boqMakeValue,
      BOQSize: model.BOQSize,
      Location: model.Location,
      Qty: model.Qty,
      Unit: model.Unit,
      Rate: model.Rate,
      Amount: model.Amount,
      SupplyRate: model.SupplyRate,
      InstallationRate: model.InstallationRate,
      Remarks: model.Remarks,
      Specifications: model.Specifications,
      BOQActivity: model.BOQActivity ? model.BOQActivity : "",
      BOQTitleId: model.BOQTitleId ? model.BOQTitleId : "",
      BOQShortFormID: model.BOQShortFormID ? model.BOQShortFormID : "",
      BOQSubTitleId: model.BOQSubTitleId ? model.BOQSubTitleId : "",
      BOQItemId: model.BOQItemId ? model.BOQItemId : "",
      NewBoqTitle: "",
      NewBoqShortForm: "",
    };

    // alert("Row"+JSON.stringify(row));
    // alert("newModel"+JSON.stringify(row));
    //cascading edit
    if (model.BOQTitleId) {
      getBoqSubTitles(model.BOQTitleId);
      if (model.BOQSubTitleId) {
        getBoqItems(model.BOQSubTitleId);
      }
    }
    if (model.BOQShortFormID) getShortForms();
    return newModel;
  }

  useEffect(() => {
    if (boqShortForm.length === 0) getShortForms();
    getBoqUpload();
    setErrors({});
    if (boqTitles.length === 0) getBoqTitles();
  }, []);

  const getBoqUpload = () => {
    //since case-sensitive, we are setting it to BoqUploadUpdateDTO
    let model: BoqUploadUpdateDTO = {
      BOQUploadId: row.BOQUploadId,
      BOQMake: row.BOQMake,
      boqMakeValue: row.boqMakeValue,
      BOQSize: row.BOQSize,
      Location: row.Location,
      Unit: row.Unit,
      Qty: row.Qty,
      Rate: row.Rate,
      Amount: row.Amount,
      SupplyRate: row.SupplyRate,
      InstallationRate: row.InstallationRate,
      Remarks: row.Remarks,
      Specifications: row.Specifications,
      BOQActivity: row.BOQActivity,
      BOQTitleId: row.BOQTitleId,
      BOQSubTitleId: row.BOQSubTitleId,
      BOQItemId: row.BOQItemId,
      NewBoqTitle: "",
      NewBoqSubTitle: "",
      NewBoqItem: "",
      NewBoqShortForm: "",
      BOQShortFormID: row.BOQShortFormID,
      AddType: "",
      ImageFileName: "",
    };
    setValues(setFormValue(model));
  };

  const getBoqTitles = () => {
    boqTitleService
      .getTitleSelectListByPackageId(boqPackageId)
      .then((response) => {
        setBoqTitles(response.data);
        setBoqSubTitles([]);
        setBoqItems([]);
        //don't set '' in values property here
      });
  };

  const getBoqSubTitles = (titleId: any) => {
    boqSubTitleService
      .getSubTitleSelectListByTitleId(titleId)
      .then((response) => {
        setBoqSubTitles(response.data);
        setBoqItems([]);
      });
  };

  const getBoqItems = (subTitleId: any) => {
    boqitemService
      .getItemSelectListBySubTitleId(subTitleId)
      .then((response) => {
        setBoqItems(response.data);
      });
  };

  const getShortForms = () => {
    mBoqshortFormsService
      .getShortFormSelectList(boqPackageId)
      .then((response) => {
        setBoqShortForm(response.data);
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (row.BOQUploadId) {
        boqUploadService.EditBoqUpload(values).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            props.onCloseDialog();
            props.refreshConvertedBoqs();
          } else {
            globalService.error(result.message);
          }
        });
      }
    }
  };

  const openAddByTypeDialog = (addType: string) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setAddType(addType);
    values.AddType = addType;
    if (addType === AddType.Title) values.NewBoqTitle = row.Title;
    // if (addType === AddType.BOQShortForm) values.BoqshortFormName = row.BoqshortFormName;
    setOpen(true);
  };

  const openPackageMakeDialog = () => {
    getMakeLists();
    setOpenMake(true);
  };

  const openPackageStandardMakeDialog = () => {
    getStandardMakeList();
    setOpenStandardMake(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenMake(false);
    setOpenStandardMake(false);
  };

  const addByType = () => {
    values.BOQMake = boqMakeValue;
    values.boqPackageId = boqPackageId;
    if (row.BOQUploadId) {
      boqUploadService
        .AddTitleSubTitleOrItemByType(values)
        .then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            handleClose();
            if (addType === AddType.Title) {
              getBoqTitles();
              values.BOQTitleId = "";
              values.BOQSubTitleId = "";
              values.BOQItemId = "";
            }

            if (addType === AddType.SubTitle) {
              //cascading edit
              if (values.BOQTitleId) {
                getBoqSubTitles(values.BOQTitleId);
                values.BOQSubTitleId = "";
                values.BOQItemId = "";
              }
            }
            if (addType === AddType.Item) {
              if (values.BOQSubTitleId) {
                getBoqItems(values.BOQSubTitleId);
                values.BOQItemId = "";
              }
            }
            if (addType === AddType.BOQShortForm) {
              //cascading edit
              //if (values.BOQShortFormID) {
              getShortForms();
              //values.BOQShortFormID = "";
              // values.BOQShortFormID = "";
              // }
            }
          } else {
            globalService.error(result.message);
          }
        });
    }
  };

  const handleDoubleClick = (params: any) => {
    values.BOQMake = params.row.Makes;
    setBoqMakeValue(values.BOQMake);
    handleClose();
  };
  const BoqMakeListColumn: GridColDef[] = [
    {
      field: "Material",
      headerName: "Material ",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <div onDoubleClick={() => handleDoubleClick(params)}>
          {params.value}
        </div>
      ),
    },
    {
      field: "Makes",
      headerName: "Makes Name ",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <div onDoubleClick={() => handleDoubleClick(params)}>
          {params.value}
        </div>
      ),
    },
    {
      field: "ItemType",
      headerName: "ItemType ",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <div onDoubleClick={() => handleDoubleClick(params)}>
          {params.value}
        </div>
      ),
    },
  ];

  const BoqStandardMakeListColumn: GridColDef[] = [
    {
      field: "Material",
      headerName: "Material ",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <div onDoubleClick={() => handleDoubleClick(params)}>
          {params.value}
        </div>
      ),
    },
    {
      field: "Makes",
      headerName: "Makes Name ",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <div onDoubleClick={() => handleDoubleClick(params)}>
          {params.value}
        </div>
      ),
    },
    {
      field: "ItemType",
      headerName: "ItemType ",
      width: 130,
      flex: 1,
      renderCell: (params) => (
        <div onDoubleClick={() => handleDoubleClick(params)}>
          {params.value}
        </div>
      ),
    },
  ];

  const getMakeLists = () => {
    if (projectDirectoryId && boqPackageId) {
      boqUploadService
        .GetMakeListsByProjectDirectoryAndPackage(
          projectDirectoryId,
          boqPackageId
        )
        .then((response: any) => {
          setMakeLists(response.data.list);
          setMakeListPackageName(response.data.list[0].BoqPackageName);
          setMakeListProjectDirectoryName(
            response.data.list[0].ProjectDirectoryName
          );
        });
    }
  };

  const getStandardMakeList = () => {
    if (boqPackageId) {
      boqPackageMakesService
        .getAllBoqpackageMakes(boqPackageId)
        .then((response: any) => {
          setstandardMakeLists(response.data.list);
          setMakeListPackageName(
            response.data.list[0].Boqpackage.BoqpackageName
          );
        });
    }
  };

  //   const openMakeList = () => {
  //     setOpenMake(true);
  //   };

  return (
    <>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    sx={{ marginTop: 2 }}
                    name={"BOQActivity"}
                    label="BOQ Activity"
                    required
                    showEmptyItem={true}
                    options={activityTypes}
                    value={activityTypes.length > 0 ? values.BOQActivity : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.BOQActivity}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="BOQ Make"
                    name="BOQMake"
                    value={values.BOQMake}
                    onChange={handleInputChange}
                    error={errors.BOQMake}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button onClick={openPackageMakeDialog} variant="contained">
                    Show BOQ MakeList
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  {/* <span>{values.BoqMake}</span> */}
                  <Button
                    onClick={openPackageStandardMakeDialog}
                    variant="contained"
                  >
                    Standard Package MakeList
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="BOQ Size"
                    name="BOQSize"
                    value={values.BOQSize}
                    onChange={handleInputChange}
                    error={errors.BOQSize}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="BOQ Color"
                    name="BOQColor"
                    value={values.BOQColor}
                    onChange={handleInputChange}
                    error={errors.BOQColor}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Location"
                    name="Location"
                    value={values.Location}
                    onChange={handleInputChange}
                    error={errors.Location}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Unit"
                    name="Unit"
                    value={values.Unit}
                    onChange={handleInputChange}
                    error={errors.Unit}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Qty"
                    name="Qty"
                    value={values.Qty}
                    onChange={handleInputChange}
                    error={errors.Qty}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Rate"
                    name="Rate"
                    value={values.Rate}
                    onChange={handleInputChange}
                    error={errors.Rate}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Amount"
                    name="Amount"
                    value={values.Amount}
                    onChange={handleInputChange}
                    error={errors.Amount}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Supply Rate"
                    name="SupplyRate"
                    value={values.SupplyRate}
                    onChange={handleInputChange}
                    error={errors.SupplyRate}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Installation Rate"
                    name="InstallationRate"
                    value={values.InstallationRate}
                    onChange={handleInputChange}
                    error={errors.InstallationRate}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.Input
                    label="Remarks"
                    name="Remarks"
                    value={values.Remarks}
                    onChange={handleInputChange}
                    error={errors.Remarks}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    label="Specifications"
                    name="Specifications"
                    value={values.Specifications}
                    onChange={handleInputChange}
                    error={errors.Specifications}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    label="Title"
                    name="Title"
                    multiline
                    value={row.Title}
                    readOnly
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <Controls.Select
                    name="BOQTitleId"
                    label="BOQ Title"
                    options={boqTitles}
                    value={boqTitles.length > 0 ? values.BOQTitleId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                      getBoqSubTitles(e.target.value);
                    }}
                    error={errors.BOQTitleId}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  {boqPackageId && (
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to add new Title?",
                          subTitle: "",
                          onConfirm: () => {
                            openAddByTypeDialog(AddType.Title);
                          },
                        });
                      }}
                    >
                      New
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    label="Sub Title"
                    name="SubTitle"
                    value={row.SubTitle}
                    multiline
                    readonly
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <Controls.Select
                    name="BOQSubTitleId"
                    label="BOQ Sub Title"
                    options={boqSubTitles}
                    value={boqSubTitles.length > 0 ? values.BOQSubTitleId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                      getBoqItems(e.target.value);
                    }}
                    error={errors.BOQSubTitleId}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  {values.BOQTitleId && (
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to add new SubTitle?",
                          subTitle: "",
                          onConfirm: () => {
                            openAddByTypeDialog(AddType.SubTitle);
                          },
                        });
                      }}
                    >
                      New
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controls.Input
                    label="Item Name"
                    name="Item Name"
                    value={row.ItemName}
                    multiline
                    readonly
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <Controls.Select
                    name="BOQItemId"
                    label="BOQ Item"
                    options={boqItems}
                    value={boqItems.length > 0 ? values.BOQItemId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                    error={errors.BOQItemId}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  {values.BOQSubTitleId && (
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to add new Item?",
                          subTitle: "",
                          onConfirm: () => {
                            openAddByTypeDialog(AddType.Item);
                          },
                        });
                      }}
                    >
                      New
                    </Button>
                  )}
                </Grid>
                <Grid item xs={2} sm={5}>
                  <Controls.MuiAutocomplete
                    options={boqShortForm}
                    label="Boq Short Form Name"
                    error={errors.BOQShortFormID}
                    // value={boqShortForm.length > 0 ? values.BOQShortFormID : values.BOQShortFormID}
                    onChange={(event: MultiSelectListModel) => {
                      handleInputChange({
                        target: {
                          name: "BOQShortFormID",
                          value: event ? event.value : "",
                        },
                      });
                    }}
                    value={
                      boqShortForm.find(
                        (item: any) => item.value === values.BOQShortFormID
                      ) || null
                    }
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  {/* {values.BOQSubTitleId && ( */}
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to add new Item?",
                        subTitle: "",
                        onConfirm: () => {
                          openAddByTypeDialog(AddType.BOQShortForm);
                        },
                      });
                    }}
                  >
                    New
                  </Button>
                  {/* )} */}
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>

      {/* Title, SubTitle and Item Addition */}
      <>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          aria-describedby="draggable-dialog-description"
        >
          <DialogTitle id="draggable-dialog-title">
            Add BOQ {addType}{" "}
            {addType === AddType.Title && (
              <i style={{ fontSize: 15 }}>{row.Title}</i>
            )}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400 }}>
            <>
              {addType === AddType.Title && (
                <>
                  <Controls.Input
                    label="BOQ Title"
                    name="NewBoqTitle"
                    value={values.NewBoqTitle}
                    onChange={handleInputChange}
                    error={errors.NewBoqTitle}
                  />
                </>
              )}
              {addType === AddType.SubTitle && (
                <>
                  <Controls.Input
                    label="BOQ SubTitle"
                    name="NewBoqSubTitle"
                    value={values.NewBoqSubTitle}
                    onChange={handleInputChange}
                    error={errors.NewBoqSubTitle}
                  />
                </>
              )}
              {addType === AddType.Item && (
                <>
                  <Controls.Input
                    label="BOQ Item"
                    name="NewBoqItem"
                    value={values.NewBoqItem}
                    onChange={handleInputChange}
                    error={errors.NewBoqItem}
                  />
                </>
              )}
              {addType === AddType.BOQShortForm && (
                <>
                  <Controls.Input
                    label="BOQ ShortForm"
                    name="NewBoqShortForm"
                    value={values.NewBoqShortForm}
                    onChange={handleInputChange}
                    error={errors.NewBoqShortForm}
                  />
                </>
              )}
            </>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => addByType()} variant="contained">
              Submit
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <>
        <Draggable>
          <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={openMake}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle id="customized-dialog-title">
              BOQ MakeList <br />
              <i style={{ fontSize: 15 }}>
                {"Project Directory : " + makeListprojectDirectoryName}
              </i>
              <i style={{ fontSize: 15 }}>
                {", Package Name : " + makeListPackageName}
              </i>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent sx={{ minHeight: 400 }}>
              <Card>
                <CardContent>
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.BoqpackageMakeId}
                      rows={makeLists}
                      columns={BoqMakeListColumn}
                      columnHeaderHeight={30}
                      //rowHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                      //loading={loading}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            boqTitleId: false,
                          },
                        },
                        pagination: { paginationModel: { pageSize: 50 } },
                      }}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                      pageSizeOptions={[10, 25, 50, 100]}
                    />
                  </div>
                </CardContent>
              </Card>
            </DialogContent>
          </Dialog>
        </Draggable>
      </>
      <>
        <Draggable>
          <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={openStandardMake}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle id="customized-dialog-title">
              BOQ Standard MakeList <br />
              <i style={{ fontSize: 15 }}>
                {"Package Name : " + makeListPackageName}
              </i>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent sx={{ minHeight: 400 }}>
              <Card>
                <CardContent>
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.BoqpackageMakeId}
                      rows={standardMakeLists}
                      columns={BoqStandardMakeListColumn}
                      columnHeaderHeight={30}
                      //rowHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                      //loading={loading}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns Id, the other columns will remain visible
                            boqTitleId: false,
                          },
                        },
                        pagination: { paginationModel: { pageSize: 50 } },
                      }}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                      pageSizeOptions={[10, 25, 50, 100]}
                    />
                  </div>
                </CardContent>
              </Card>
            </DialogContent>
          </Dialog>
        </Draggable>
      </>
    </>
  );
};

export default BoqUploadForm;
