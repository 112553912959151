import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { boqPackageMakesService } from "../../services/BoqPackageMakeService";
import { BoqPackageMakesModel } from "../../models/BoqPackageMakeModel";
import { boqPackageService } from "../../services/BoqPackageService";

const BoqPackageMakeForm = (...props: any) => {

  const { BoqpackageId, BoqpackageMakeId }: any = useParams();
  const [packageName, setPackageName] = useState();
  let navigate = useNavigate();
  const mode = BoqpackageMakeId ? "Edit" : "Create";

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };

    if ("Makes" in fieldValues)
      temp.Makes = fieldValues.Makes ? "" : "Make is required";
    setErrors({
      ...temp
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqPackageMakesService.initialFieldValues, validate, BoqpackageMakeId);

  const newPackageMakes = () => {
    setValues(boqPackageMakesService.initialFieldValues);
  }

  function setFormValue(model: BoqPackageMakesModel) {
    let newModel = {
      BoqpackageMakeId: model.BoqpackageMakeId,
      BoqpackageId: model.BoqpackageId,
      Makes: model.Makes,
      ItemType: model.ItemType,
      Material: model.Material,
      Active: model.Active,
    };
    return newModel;
  }

  useEffect(() => {
    if(BoqpackageMakeId) {
      getPackageMakes(BoqpackageMakeId);
      setErrors({});
    } else newPackageMakes();
    if(BoqpackageId) getByPackageId();
  }, [BoqpackageMakeId])

  const getByPackageId = () => {
    boqPackageService.getById(BoqpackageId).then((response: { data: any }) => {
      if (response) {
        let result = response.data;
        setPackageName(result.list.BoqpackageName);
      }
    });
  };


  const getPackageMakes = (BoqpackageMakeId: any) => {
boqPackageMakesService.getById(BoqpackageMakeId).then((response) => {
  if(response) {
    let result = response.data;
    setValues(setFormValue(result.list));
  }
});
  };

  const handleSubmit = (e: React.FormEvent) => {
   // var BoqpackageId =  values.BoqpackageId;
    e.preventDefault();
    if(validate()) {
      values.BoqpackageId = BoqpackageId;
      if(BoqpackageMakeId) {
        
        boqPackageMakesService.put(values).then((response: any) => {
          let result = response.data;
          if(response) {
            globalService.success(result.message);
            navigate(-1)
          } else {
            globalService.error(result.message);
          }
        });
    }else {      
      
      boqPackageMakesService.post(values).then((response: any) => {
        if(response) {
          let result = response.data;
          if(result.isSuccess){
              globalService.success(result.message);
              navigate(-1)
          } else {
              globalService.error(result.message);
            }
          }
    });
    }
  }
}

  return (
    <>
      <Typography>
        Package Make Form
      </Typography>
      <Typography variant="body1" align="right">
        {" "}
        <b>BOQ Package :</b> {packageName}
      </Typography>
<>

      <form autoComplete="off" noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Makes"
                    name="Makes"
                    value={values.Makes}
                    onChange={handleInputChange}
                    error={errors.Makes}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Item Type"
                    name="ItemType"
                    value={values.ItemType}
                    onChange={handleInputChange}
                    error={errors.ItemType}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="Material"
                    name="Material"
                    value={values.Material}
                    onChange={handleInputChange}
                    error={errors.Material}
                  />
                </Grid>


                <Grid item xs={12} sm={4}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>

              </Grid>
            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/boqpackageMakes/" + BoqpackageId)}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>

      </form>
      </>
    </>

  )
}

export default BoqPackageMakeForm