import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Link,
  Typography,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  CardActions,
  DialogProps,
  Divider,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import dayjs from "dayjs";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { globalService } from "../../services/GlobalService";
import { ExpenseQueriesModel } from "../../models/ExpenseQueriesModel";
import { expenseQueriesService } from "../../services/ExpenseQueriesServices";
import { ExpenseHdrsService } from "../../services/ExpenseHdrsService";
import Draggable from "react-draggable";
import { ROLES } from "../../utility/Config";
import { AuthContext } from "../../utility/context/AuthContext";

const ExpenseQueriesList = (props: any) => {
  const { ExpenseHdrId }: any = props;
  const { auth } = React.useContext(AuthContext);
  const [expenseQueries, setExpenseQueries] = useState([]);
  const { ExpenseQueryId }: any = useParams();

  const [expenseHdr, setExpenseHdr] = useState<any>(
    ExpenseHdrsService.initialFieldValues
  );
  const navigate = useNavigate();

  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");

  const [open, setOpen] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });

  const handleOpen = (row: any) => {
    setOpen(true);
    resetForm();
  };
  const handleClose = () => {
    resetForm();
    setOpen(false);
  };

  useEffect(() => {
    getExpenseQueries();
    getExpenseHdrId(ExpenseHdrId);
    if (ExpenseQueryId) {
      getQuery(ExpenseQueryId);
      setErrors({});
    } else newQuery();
  }, [ExpenseHdrId]);

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    if ("Remark" in fieldValues)
      temp.Remark = fieldValues.Remark ? "" : "Remark is required.";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const newQuery = () => {
    setValues(expenseQueriesService.initialFieldValues);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      expenseQueriesService.initialFieldValues,
      validate,
      props.setCurrentId
    );

  function setFormValue(model: ExpenseQueriesModel) {
    let newModel = {
      ExpenseQueryId: model.ExpenseQueryId,
      ExpenseHdrId: model.ExpenseHdrId,
      Remark: model.Remark,
    };
    return newModel;
  }

  const getExpenseQueries = () => {
    expenseQueriesService.getAllByExpenseHdr(ExpenseHdrId).then((response) => {
      let result = response.data;
      setExpenseQueries(result.list);
    });
  };

  const getQuery = (ExpenseQueryId: any) => {
    expenseQueriesService.getById(ExpenseQueryId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    });
  };

  const ScrollableContent = ({ value }: any) => (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>{value}</div>
  );
  const expenseQueriesColumns: GridColDef[] = [
    {
      field: "CreatedOn",
      headerName: "Created On",
      width: 180,
      
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },
    {
      field: "UserName",
      headerName: "Created By",
      width: 200,
      //valueGetter: (params) => params.row.CreatedById?.UserName,
    },
    {
      field: "Remark",
      headerName: "Remark",
      flex: 1,
      
    },
  ];

  const handleQuerySubmit = (e: React.FormEvent) => {
    values.ExpenseHdrId = ExpenseHdrId;
    e.preventDefault();
    if (validate()) {
      //  if(values.ExpenseHdrStatusId != 4)
      expenseQueriesService.post(values).then((response: any) => {
        if (response) {
          let result = response.data;
          if (result.isSuccess) {
            globalService.success(result.message);
            //navigate("/expenseQueries/" + ExpenseHdrId);
            getExpenseQueries();
            handleClose();
          } else {
            globalService.error(result.message);
          }
        }
      });
    }
  };

  const getExpenseHdrId = (ExpenseHdrId: any) => {
    ExpenseHdrsService.getById(ExpenseHdrId).then((response) => {
      if (response) {
        
        let result = response.data;
        setExpenseHdr(result.row);
        values.ExpenseHdrStatusId = result.row.ExpenseHdrStatusId;
      }
    });
  };

  return (
    <>
      <Card>
        <CardContent>
          <fieldset style={{ marginBottom: 10, marginTop: 2 }}>
            <legend>Project Directory</legend>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Typography className="label">Voucher No</Typography>
                <Typography variant="body2">
                  {expenseHdr?.ExpenseVoucherNo}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography className="label">Voucher Dt</Typography>
                <Typography variant="body2">
                  {expenseHdr
                    ? dayjs(expenseHdr.ExpenseVoucherDate).format("DD-MMM-YYYY")
                    : ""}
                </Typography>
              </Grid>


              <Grid item xs={12} sm={2}>
                <Typography className="label">Status</Typography>
                <Typography variant="body2">
                  {expenseHdr?.ExpenseHdrStatus}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography className="label">Total Amount</Typography>
                <Typography variant="body2">
                  {expenseHdr?.TotInvoiceAmt}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography className="label">Created By</Typography>
                <Typography variant="body2">
                  {expenseHdr?.CreatedBy?.UserName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography className="label">Created On</Typography>
                <Typography variant="body2">
                  {globalService.formatDateTime(expenseHdr?.CreatedOn)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="label">Expense Description</Typography>
                <ScrollableContent value={expenseHdr?.ExpenseDescription} />
              </Grid>

            </Grid>
          </fieldset>
          {values.ExpenseHdrStatusId != 4 && (
            <Button
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleOpen}
            // className={`${globalService.roleMatch([ROLES.Admin], auth) ? "" : "hidden"}`}
            // onClick={() => navigate("/addExpenseQuery/" + ExpenseHdrId )}
            >
              Add Record
            </Button>
          )}

          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.ExpenseQueryId}
              rows={expenseQueries}
              columns={expenseQueriesColumns}
              columnHeaderHeight={30}
              autoHeight={true}
              // checkboxSelection
              // disableRowSelectionOnClick
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    ExpenseQueryId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
      </Card>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <>
        <div>
          <Draggable>
            <Dialog
              fullWidth={true}
              // maxWidth={maxWidth}
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle
                sx={{ m: 0, p: 2, textAlign: "center" }}
                id="customized-dialog-title"
              >
                <Grid>Query</Grid>
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme: any) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <form
                  autoComplete="off"
                  noValidate
                  onSubmit={handleQuerySubmit}
                >
                  <Card>
                    <CardContent>
                      <React.Fragment>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12}>
                            <Controls.Input
                              label="Remark"
                              multiline
                              rows={4}
                              name="Remark"
                              value={values.Remark}
                              onChange={handleInputChange}
                              error={errors.Remark}
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    </CardContent>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Stack spacing={2} direction="row">
                        <Button type="submit" variant="contained">
                          Submit
                        </Button>
                      </Stack>
                    </CardActions>
                  </Card>
                </form>
              </DialogContent>
            </Dialog>
          </Draggable>
        </div>
      </>
    </>
  );
};

export default ExpenseQueriesList;
