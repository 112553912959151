import { config } from "../utility/Config";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const url = "/BoqExcelUploadHistory";

function GetOriginalClientExcelFormat(ProjectDirectoryID: any, BOQPackageID: any) {
    let payload =
    {
        ProjectDirectoryID: ProjectDirectoryID,
        BOQPackageID: BOQPackageID
    }
    return customAxios.post(`${url}/GetOriginalClientExcelFormat`, payload, axiosRetryConfig);
}

export const boqExcelUploadHistoryService = {
    GetOriginalClientExcelFormat
};