import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import Title from "../helper/Title";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { BoqtitleAlternativeTextsModel } from "../../models/BoqtitleAlternativeTextsModel";
import { boqtitleAlternativeTextService } from "../../services/BoqtitleAlternativeTextsService";

const BoqtitleAlternativeTextForm = (...props: any) => {
  const { BoqtitleId, BoqtitleAlternativeTextId } = useParams();
  let navigate = useNavigate();
  const { Code }: any = useParams();
  const mode = Code ? 'Edit' : 'Create';
  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    if ("BoqtitleAlternativeTextName" in fieldValues)
      temp.BoqtitleAlternativeTextName = fieldValues.BoqtitleAlternativeTextName
        ? ""
        : "BOQ Title AlternativeText Name is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqtitleAlternativeTextService.initialFieldValues, validate, props.setCurrentId);

  const newUser = () => {
    setValues(boqtitleAlternativeTextService.initialFieldValues);
  };
  //This is used since in get the null property is not returned
  function setFormValue(model: BoqtitleAlternativeTextsModel) {
    let newModel = {
      BoqtitleAlternativeTextId: model.BoqtitleAlternativeTextId,
      BoqtitleId: model.BoqtitleId,
      BoqtitleAlternativeTextName: model.BoqtitleAlternativeTextName,
      Active: model.Active,
    };
    return newModel;
  }

  useEffect(() => {
    if (BoqtitleAlternativeTextId) {
      getBoqtitleAlternativeText(BoqtitleAlternativeTextId);
      setErrors({});
    } else newUser();
  }, [BoqtitleAlternativeTextId]);

  const getBoqtitleAlternativeText = (BoqtitleAlternativeTextId: any) => {
    boqtitleAlternativeTextService.getById(BoqtitleAlternativeTextId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      values.BoqtitleId = BoqtitleId;
      if (BoqtitleAlternativeTextId) {
        boqtitleAlternativeTextService.put(values).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/boqtitleAlternativeTexts/" + BoqtitleId);
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        
        boqtitleAlternativeTextService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;

            if (result.isSuccess) {
              globalService.success(result.message);
              resetForm();
              navigate("/boqtitleAlternativeTexts/" + BoqtitleId);
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  return (
    <>
      
        <Title>  BOQ Title Alternative Text</Title>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="BOQ Title Alternative Text Name"
                    name="BoqtitleAlternativeTextName"
                    value={values.BoqtitleAlternativeTextName}
                    onChange={handleInputChange}
                    error={errors.BoqtitleAlternativeTextName}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={4}
                 className={mode !== "Edit" ? "hidden" : ""}
                 >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/boqtitleAlternativeTexts/" + BoqtitleId)}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default BoqtitleAlternativeTextForm;
