import { customAxios } from "./AxiosHttpCommon";


const url = "/report";

function downloadBoqPOPdf (poB2bId : string) {
    
    return customAxios.get(`${url}/DownloadBoqPOPdf/${poB2bId}`, { responseType: 'blob' });
}

export const reportService = {
    downloadBoqPOPdf
}