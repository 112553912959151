import { Guid } from "guid-typescript";
import { EmployeeModel } from "../models/MEmployeeModel";
import { config } from "../utility/Config";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: EmployeeModel = {
    EmployeeId: Guid.EMPTY,
    EmpName: "",
    EmpCode: "",
    Dob: null,
    Email: "",
    Mobile: "",
    Doj: null,
    Dol: null,
    MonthlySalary: "0",
    DepartmentId: "",
    DesignationId: "",
    ZoneId: "",
    Qualification: "",
    Address1: "",
    Address2: "",
    currentEmployeeId: "",
    Address3: "",
    City: "",
    StateId: "",
    Active: true,
    ProfileImageName: "",
    IsSecondLevelApproverYn: false,
    UserId: "",
    ReportingToId: "",
    Pincode: "",
    SelectedRoleNames: '',
    BankName: '',
    Ifsccode: '',
    AccountType: '',
    AccountNo: '',
    EmployeeNameAsPerTally: '',
    CompanyId: '',
    FilePath: '',
    //Password: '',
    User: {
        UserId: '',
        UserName: '',
        FirstName: "",
        LastName: '.',
        SelectedRoleNames: '',
        PhoneNumber: '',
        Email: '',
        FullName: '',
        Password: '',
        ConfirmPassword: '',
        Active: true,
    }
}

const url = 'mEmployees';

function post(payload: EmployeeModel, file: File) {
    const formData = new FormData();
    if (file != null)
        formData.append("profileImage", file);

    formData.append("data", JSON.stringify(payload));
    return customAxios.post(
        `${url}/Add`,
        formData,
        config.formDataConfig
    );
}

function put(payload: EmployeeModel, profileImageFile: File, empFiles: File[]) {
    const formData = new FormData();
    if (empFiles != null && empFiles.length > 0) {
        for (var i = 0; i < empFiles.length; i++) {
            formData.append("EmployeeFile", empFiles[i]);
        }
    }

    if (profileImageFile != null) {
        formData.append("profileImage", profileImageFile);
    }

    formData.append("data", JSON.stringify(payload));
    return customAxios.put(`${url}/Edit`, formData, config.formDataConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(EmployeeId: number) {
    return customAxios.get(`${url}/GetById/${EmployeeId}`, axiosRetryConfig);
}

function getAll(active: boolean) {
    return customAxios.get(`${url}/GetAll?active=${active}`, axiosRetryConfig);
}

// function getAll() {
//     return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
// }

function getDepartmentSelectList() {
    return customAxios.get(`${url}/GetMDepartmentSelectList`, axiosRetryConfig);
}

function getDesinationSelectList() {
    return customAxios.get(`${url}/GetMDesignationSelectList`, axiosRetryConfig);
}
function getZoneSelectList() {
    return customAxios.get(`${url}/GetMZoneSelectList`, axiosRetryConfig);
}

function getReportingToSelectList(currentEmployeeId: string) {
    currentEmployeeId = currentEmployeeId ? currentEmployeeId : Guid.EMPTY;
    return customAxios.get(`${url}/GetReportingToSelectList?currentEmployeeId=${currentEmployeeId}`, axiosRetryConfig);
}


export const mEmployeesService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll,
    getDepartmentSelectList,
    getDesinationSelectList,
    getZoneSelectList,
    getReportingToSelectList,
}