import React from 'react';
import { TextField } from '@mui/material';

import { GridRenderEditCellParams } from '@mui/x-data-grid';

interface TextareaCellEditorProps extends GridRenderEditCellParams {
    id: number | string;
    value: string;
    field: string;
}

const TextareaCellEditor: React.FC<GridRenderEditCellParams> = ({ id, value, field, api }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        api.setEditCellValue({ id, field, value: event.target.value });
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            const newValue = (event.target as HTMLTextAreaElement).value + '\n';
            api.setEditCellValue({ id, field, value: newValue });
        }
    };

    return (
        <TextField
            multiline
            fullWidth
            rows={5}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus
        />
    );
};

export default TextareaCellEditor;