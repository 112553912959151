import { BoqPackageModel } from "../models/BoqPackageModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { Guid } from "guid-typescript";

const initialFieldValues: BoqPackageModel = {
  BoqpackageId: Guid.EMPTY,
  BoqpackageName: "",
  Active: false,
};

const url = "MBoqPackages";

function getAll() {
  return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

function post(payload: BoqPackageModel) {
  return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}
function put(payload: BoqPackageModel) {
  return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}
function remove(id: number) {
  return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(boqpackageId: number) {
  return customAxios.get(`${url}/GetById/${boqpackageId}`, axiosRetryConfig);
}

function getPackageSelectList() {
  return customAxios.get(`${url}/GetSelectList`, axiosRetryConfig);
}

export const boqPackageService = {
  initialFieldValues,
  getAll,
  post,
  put,
  remove,
  getPackageSelectList,
  getById,
};
