import React, { useState, useEffect } from "react";

import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmDialog from "../helper/ConfirmDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { globalService } from "../../services/GlobalService";
import { boqPackageService } from "../../services/BoqPackageService";

const BoqPackageList: React.FC = () => {
  const [packages, setPackage] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '', onConfirm: () => { } })
  const navigate = useNavigate();
  useEffect(() => {
    getPackage();
  }, []);

  const getPackage = () => {
    boqPackageService.getAll().then((response) => {
      let result = response.data;
      setPackage(result.list);
    });
  };

  const columns: GridColDef[] = [
    { field: "BoqpackageName", headerName: "BOQ Package Name", flex: 1 },
    {
      field: "Active",
      headerName: "Active",
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>

            {/* {params.row.Active && "Yes"}
            {!params.row.Active && "No"} */}
            {params.row.Active && <DoneIcon color="success" />}
            {!params.row.Active && <CloseIcon color="error" />}
          </Stack>
        );
      },
    },
    {
      field: "Makes",
      headerName: "Makes",
      width: 130,
      flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack>

            <Button
              variant="contained"
              className="btnGrid"
              color="primary"
              onClick={() =>
                navigate("/boqpackageMakes/" + params.row.BoqpackageId, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              BOQ Package Makes
            </Button>
          </Stack>
        );
      },

    },
    {
      field: "Forms",
      headerName: "Forms",
      width: 130,
      flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack>

            <Button
              variant="contained"
              className="btnGrid"
              color="primary"
              onClick={() =>
                navigate("/mboqshortForms/" + params.row.BoqpackageId, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
             BOQ Short Forms
            </Button>
          </Stack>
        );
      },

    },
    {
      field: "Actions",
      headerName: "Actions",

      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editBoqPackage/" + params.row.BoqpackageId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small" aria-label="delete" color="error"
              onClick={() => {

                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure to delete this package ?',
                  subTitle: "You can't undo this operation",
                  onConfirm: () => { removepackage(params.row.BoqpackageId) }
                })
              }}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>

          </Stack>
        );
      },
    },
  ];

  const removepackage = (BoqpackageId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
    boqPackageService.remove(BoqpackageId).then((response) => {
      let result = response.data;
      if (response) {
        // globalService.success(result.message);
        getPackage();
      }
    });
  }

  return (
    <>
      <Typography variant="h5" align="center">
        BOQ Packages
      </Typography>
      <Card>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addBoqPackage/")}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BoqpackageId}
              rows={packages}
              columns={columns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    BoqpackageId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}


export default BoqPackageList