import { Guid } from "guid-typescript";
import { AppSettingModel } from "../models/AppSettingModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: AppSettingModel = {
    AppSettingId:  Guid.EMPTY,
    LapseDays: null,
    CoGstinno:  "",
    CoPanno:  "",
    PocreditDays: null,
    PotermsConditions:  "",
    CoName:  "",
    Address1:  "",
    Address2:  "",
    Address3:  "",
    City:  "",
    CoPinCode:  "",
    CoStateId:  "",
    CoTel:  "",
    CoUrl:  "",
    UpdatedById:  "",
    UpdatedOn: null
}

const url = 'appSetting';

function put(payload: AppSettingModel) {
    
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}

function getById() {
    
    return customAxios.get(`${url}/GetById`, axiosRetryConfig);
}

export const appSettingService = {
    initialFieldValues,
    put,
    getById
}