import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { MBoqshortFormsModel } from "../models/MBoqshortFormsModel";

const initialFieldValues: MBoqshortFormsModel = {
    BoqshortFormId: Guid.EMPTY,
    BoqshortFormName: '',
    Active: true
}

const url = "MBoqshortForms";

function post(payload: MBoqshortFormsModel) {
    return customAxios.post(`${url}/Add`, payload,axiosRetryConfig);
}

function put(payload: MBoqshortFormsModel) {
    return customAxios.put(`${url}/Edit`,payload,axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(ZoneId: number) {
    return customAxios.get(`${url}/GetById/${ZoneId}`, axiosRetryConfig);
}

function getAll (BoqpackageId : number) {
    return customAxios.get(`${url}/GetAll/${BoqpackageId}`, axiosRetryConfig);
}

function getShortFormSelectList (boqPackageId:string) {
    return customAxios.get(`${url}/GetShortFormSelectList/${boqPackageId}`, axiosRetryConfig);
}

export const mBoqshortFormsService = {
    initialFieldValues,
    post,
    put,
    remove,
    getById,
    getAll,
    getShortFormSelectList,
}