import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  CardActions,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { mZonesService } from "../../services/MZoneService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RentAgreementsService } from "../../services/RentAgreementsService";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { globalService } from "../../services/GlobalService";
import dayjs from "dayjs";
import { AuthContext } from "../../utility/context/AuthContext";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { rentAgreementFileService } from "../../services/RentAgreementFileService";

const RentAgreementsList = (...props: any) => {
  const { RentAgreementId }: any = useParams();
  const navigate = useNavigate();
  const { auth } = React.useContext(AuthContext);
  const [rentAgreements, setRentAgreements] = useState([]);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [rentAgreementDocOpen, setRentAgreementDocOpen] = React.useState(false);
  const [rentAgreementFiles, setrentAgreementFiles] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });
  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    getRentAgreement();
  }, []);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      RentAgreementsService.initialFieldValuesforGetRent,
      validate,
      props.setCurrentId
    );

    const openRentAgreementDocDialog = (RentAgreementId: any) => {
      setRentAgreementDocOpen(true);
      getAllFileByRentAgreements(RentAgreementId);
    };
  
    const getAllFileByRentAgreements = (RentAgreementId:any) => {
      rentAgreementFileService.getAllFileByRentAgreements(RentAgreementId).then((response) => {
        
        setrentAgreementFiles(response.data.list);
      })
    };

    const closeRentAgreementDialog = () => {
      setRentAgreementDocOpen(false);
    };

    const handleFileClick = (event: any, filePath: any) => {
      event.preventDefault();
      window.open(`${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`, "_blank");
    };

    const rentAgreementFileColumns: GridColDef[] = [
      {
        field: "FileName",
        headerName: "File Name",
        flex: 1,
        renderCell: (params) => (
          <Link
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleFileClick(event, params.row)}
          >
            {params.row.FileName}
          </Link>
        ),
      },
      {
        field: "CreatedOn",
        headerName: "Created On",
        flex: 1,
        valueFormatter: (params) =>
          params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
      },
      // {
      //   field: "Actions",
      //   headerName: "Actions",
      //   type: "number",
      //   flex: 1,
      //   renderCell: (params) => {
      //     return (
      //       <Stack direction="row" spacing={0}>
      //         <IconButton
      //           size="small"
      //           aria-label="delete"
      //           color="error"
      //           onClick={() => {
      //             setConfirmDialog({
      //               isOpen: true,
      //               title: "Are you sure to delete this ExpenseFile ?",
      //               subTitle: "You can't undo this operation",
      //               onConfirm: () => {
      //                 removeRentAgreementFile(params.row.RentAgreementFileId);
      //               },
      //             });
      //           }}
      //         >
      //           <DeleteIcon fontSize="inherit" />
      //         </IconButton>
      //       </Stack>
      //     );
      //   },
      // },
    ];
  
  const RentAgreementsColumns: GridColDef[] = [
    {
      field: "Files",
      headerName: "Files",
      width: 20,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <IconButton
              size="small"
              color="primary"
              aria-label="edit expense"
              onClick={
                () => {
                  openRentAgreementDocDialog(params.row.RentAgreementID);
                }
              }
            >
              <AttachFileIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
    { field: "Project", headerName: "Project", flex:1 },
    {
      field: "RentAgreementNo",
      headerName: "Agreement No",
      width: 150,
    },
    {
      field: "RentAgreementStartOn",
      headerName: "Agreement Start",
      width: 120,   valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY ") : "",
    },
    { field: "RentAgreementEndOn", headerName: "Agreement End", width: 120  ,  valueFormatter: (params) =>
      params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",},
    { field: "RentPerMonth", headerName: "Rent Per Month", width: 100,
      cellClassName: "cell-right",valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
     },
    { field: "SecurityDeposit", headerName: "Security Deposit", width: 100 ,
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    // {
    //   field: "RefundAmount",
    //   headerName: "Refund Amount",
    //   width: 150,
    // },
    // {
    //   field: "SecurityDepositRefundedOn",
    //   headerName: "Security Deposit Refunded On",
    //   width: 150,
    // },
    { field: "ContactName", headerName: "Contact Name", width: 150 },
    //{ field: "ContactMail", headerName: "Contact Mail", width: 150 },
    { field: "ContactMobile", headerName: "Contact Mobile", width: 150 },
    {
      field: "RentAgreementStatus",
      headerName: " Status",
      width: 100,
    },
    // { field: "CreatedBy", headerName: "Created By", width: 150 },
    // {
    //   field: "CreatedOn",
    //   headerName: "Created On",
    //   width: 150,
    //   valueFormatter: (params) =>
    //     params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    // },

    {
      field: "Actions",
      headerName: "Actions",
      // type: "number",
      //flex: 1,
      width:75,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() =>
                navigate("/editrentAgreement/" + params.row.RentAgreementID)
              }
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure to delete this Department` ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeZoneApprover(params.row.RentAgreementID);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const removeZoneApprover = (RentAgreementsId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    RentAgreementsService.remove(RentAgreementsId).then((response) => {
      if (response) {
        getRentAgreements();
      }
    });
  };
  const getRentAgreements = () => {
    RentAgreementsService.GetRentAgreement(values).then((response) => {
      let result = response.data;
      setRentAgreements(result.list);
    });
  };
  function getDefaultDate() {
    const currentDate = new Date();
    const defaultDate = new Date(currentDate.getFullYear(), 4 - 1, 1); // April is 3rd month (0-indexed)

    return defaultDate;
  }
  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     values.RentAgreementStatusID = "0";
  //     values.UserID = auth.Id;
  const getRentAgreement = () => {  RentAgreementsService.GetRentAgreement(values).then((response: any) => {
        let result = response.data;
        setRentAgreements(result.list);
      });
  }
  

  return (
    <>
      <Typography variant="h5" align="center">
        Rent Agreements
      </Typography>
      {/* <form autoComplete="off" noValidate>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
            {/* <Grid item xs={5} sm={3}>
                    <Controls.ReactDatePicker
                      label="From Date"
                
                      
                      // min={values.FromDt}
                      max={new Date()}
                      value={
                        (values.FromDt = values.FromDt || getDefaultDate())
                      }
                      onChange={(date: any) => {
                        handleInputChange({
                          target: {
                            value: globalService.getISODateOnlyForDatePicker(date),      
                            name: "FromDt",
                          },
                        });
                      }}
                      error={errors.FromDt}
                    />
                  </Grid>

                  <Grid item xs={5} sm={3}>
                    <Controls.ReactDatePicker
                      label="To Date"
                      min={values.FromDt}
                      max={new Date()}
                      value={(values.ToDt = values.ToDt || new Date())}
                      onChange={(date: any) => {
                        handleInputChange({
                          target: {
                            value:globalService.getISODateOnlyForDatePicker(date),      
                            name: "ToDt",
                          },
                        });
                      }}
                      error={errors.ToDt}
                    />
                  </Grid> */}
              {/* <Grid item xs={12} sm={4}>
                <Button type="submit" variant="contained">
                  Submit
                </Button> */}
              {/* </Grid> */}
            {/* </Grid>
          </CardContent>
        </Card>
      </form> */}

      <Card>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addrentAgreements")}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.RentAgreementID}
              rows={rentAgreements}
              columns={RentAgreementsColumns}
              columnHeaderHeight={30}
              autoHeight={true}
              // checkboxSelection
              // disableRowSelectionOnClick
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    RentAgreementsId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/mZones")}
          >
            Back
          </Button>
        </CardContent>
      </Card>
      <Dialog
              fullWidth={true}
              maxWidth={maxWidth}
              open={rentAgreementDocOpen}
              onClose={closeRentAgreementDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Rent Agreement Detail Documents
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={closeRentAgreementDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
                <>

                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.RentAgreementFileId}
                      rows={rentAgreementFiles}
                      columns={rentAgreementFileColumns}
                      columnHeaderHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                    />
                  </div>

                </>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeRentAgreementDialog} variant="outlined">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default RentAgreementsList;
