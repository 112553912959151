import { Guid } from "guid-typescript";
import {  BoqTitleAttributeModel } from "../models/BoqTitleAttributeModel";
// import { BoqSubTitleModel } from "../models/BoqSubTitleModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqTitleAttributeModel = {
    BoqtitleId:  "",
    BoqtitleAttributeId: Guid.EMPTY,
    AttributeType : 0,
    AttributeTypeName: "",
    BoqtitleAttributeName: "",
    Active: true,
    // ParentBoqsubTitleAttributeId: ""
}

const url = "MBoqtitleAttributes";

// function GetAllMBoqsubTitleAttributes(boqtitleId: number){
//     return customAxios.get(`${url}/GetAll/${boqtitleId}`,axiosRetryConfig);
// }

function post(payload:BoqTitleAttributeModel ){
    
    return customAxios.post(`${url}/Add`,payload , axiosRetryConfig);
}
function put(payload: BoqTitleAttributeModel){
    return customAxios.put(`${url}/Edit`,payload , axiosRetryConfig);
}
function remove(id: number){
    return customAxios.delete(`${url}/${id}`,axiosRetryConfig);
}

function GetById(boqtitleAttributeId: number){
    return customAxios.get(`${url}/GetById/${boqtitleAttributeId}`,axiosRetryConfig);
}

function GetAllMBoqtitleAttributes(BoqtitleId: number, AttributeType: number){
    return customAxios.get(`${url}/GetAll/${BoqtitleId}/${AttributeType}`,axiosRetryConfig);
}

// function getAll(){
//     return customAxios.get(`${url}/GetAll`,axiosRetryConfig);
// }

export const boqTitleAttributeService = {
    initialFieldValues,
    // GetAllMBoqsubTitleAttributes,
    post,
    put,
    remove,
    GetById,
    GetAllMBoqtitleAttributes,
    // getAll
};