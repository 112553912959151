import {
    Stack,
    IconButton,
    Card,
    CardContent,
    Button,
    Typography,
    CardActions,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { mBoqshortFormsService } from "../../services/MBoqshortFormsService";
import { boqPackageService } from "../../services/BoqPackageService";

const MBoqshortFormsList: React.FC = () => {
    const [boqshortForms, setBoqshortForms] = useState([]);
    const [packageName, setPackageName] = useState();
   const {BoqpackageId}: any = useParams();
    const navigate = useNavigate();

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: () => { },
    });

    useEffect(() => {
        if(BoqpackageId) getByPackageId();
        getBoqshortForms();
    }, []);

    const getBoqshortForms = () => {
        mBoqshortFormsService.getAll(BoqpackageId).then((response) => {
            let result = response.data;
            setBoqshortForms(result.list);
        })
    }
    const getByPackageId = () => {
        boqPackageService.getById(BoqpackageId).then((response: { data: any }) => {
          if (response) {
            let result = response.data;
            setPackageName(result.list.BoqpackageName);
          }
        });
      };
    const BoqshortFormsColumns: GridColDef[] = [
        { field: "BoqshortFormName", headerName: "Short Forms Name", width: 130, flex: 1,  },
        {
            field: "Active",
            headerName: "Active",
            width: 130,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack>

                        {/* {params.row.Active && "Yes"}
                    {!params.row.Active && "No"} */}
                        {params.row.Active && <DoneIcon color="success" />}
                        {!params.row.Active && <CloseIcon color="error" />}
                    </Stack>
                );
            },
        },
      
        {
            field: "Actions",
            headerName: "Actions",
            type: "number",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={0}>
                        <IconButton size="small"
                            color="primary"
                            aria-label="add an alarm"
                            onClick={() => navigate("/editmboqshortForms/" +BoqpackageId + "/" + params.row.BoqshortFormId)}
                        >
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small"
                            aria-label="delete" color="error"
                            onClick={() => {
                                
                                setConfirmDialog({
                                    isOpen: true,
                                    title: 'Are you sure to delete this BoqshortForms ?',
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                        removeBoqshortForms(params.row.BoqshortFormId);
                                    },
                                });
                            }}
                        >
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>

                    </Stack>
                );
            },
        },
    ];

    const removeBoqshortForms = (BoqshortFormId: any) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });

        mBoqshortFormsService.remove(BoqshortFormId).then((response) => {
            if (response) {
                getBoqshortForms();
            }
        });
    };


    return (
        <>
            <Typography variant="h5" align="center">
                BOQ Short Forms
            </Typography>
            <Typography variant="body1" align="right">
        {" "}
        <b>BOQ Package :</b> {packageName}
      </Typography>
            <Card>
                <CardContent>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => navigate("/addmboqshortForms/" + BoqpackageId )}
                    >
                        Add Record
                    </Button>

                    <div style={{ width: "100%" }}>
                        <DataGrid
                            getRowId={(row) => row.BoqshortFormId}
                            rows={boqshortForms}
                            columns={BoqshortFormsColumns}
                            columnHeaderHeight={30}
                            autoHeight={true}
                            // checkboxSelection
                            // disableRowSelectionOnClick
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}

                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns Id, the other columns will remain visible
                                        BoqshortFormId: false,
                                    },
                                },
                                pagination: { paginationModel: { pageSize: 50 } },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                        />

                    </div>

                </CardContent>
                <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/boqPackages/"  )}
              > 
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
            </Card>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />

        </>
    )
}

export default MBoqshortFormsList