import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { MVendorFileModel } from "../models/MVendorFilesModel";

const initialFieldValues: MVendorFileModel = {
  VendorFileId: Guid.EMPTY,
  VendorId: "",
  FileName: "",
  CreatedOn: null
}

const url = 'MVendorFiles';

function remove(VendorFileId: number) {

  return customAxios.delete(`${url}/${VendorFileId}`, axiosRetryConfig);
}

function getById(VendorFileId: number) {
  return customAxios.get(`${url}/GetById/${VendorFileId}`, axiosRetryConfig);
}

function getAllFileByVendors(VendorId: any) {

  return customAxios.get(`${url}/GetAllFileByVendors/${VendorId}`, axiosRetryConfig);
}


export const vendorFileService = {
  initialFieldValues,
  remove,
  getById,
  getAllFileByVendors,
}
