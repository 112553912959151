import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Controls from '../../utility/Controls.tsx/Controls';
import ConfirmDialog from '../helper/ConfirmDialog';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { ExpenseHdrsService } from '../../services/ExpenseHdrsService';
import { globalService } from '../../services/GlobalService';
import useForm from '../../utility/hooks/UseForm';
import { GetExpensesModel } from '../../models/ExpenseHdrsModel';
import { useNavigate } from 'react-router-dom';

const ExpenseForAccountList = (...props: any) => {

  const [expenseHdrs, setExpenseHdrs] = useState([]);
  const [visible, SetVisible] = useState<boolean>(false);
  // const FromDt: string = localStorage.getItem("FromDt") || "";
  // const ToDt: string = localStorage.getItem("ToDt") || "";

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });

  const navigate = useNavigate();

  useEffect(() => {
    // getEmployees();
    // getExpenseHdrs();
  }, []);

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  function setFormValue(model: GetExpensesModel) {
    let newValue = {
      ProjectDirectoryId: model.ProjectDirectoryId,
      UserID: model.UserID,
      ExpenseHdrStatusID: model.ExpenseHdrStatusID || "",
      FromDt: model.FromDt
        ? new Date(model.FromDt)
        : globalService.convertLocalToUTCDate(new Date()),
      ToDt: model.ToDt
        ? new Date(model.ToDt)
        : globalService.convertLocalToUTCDate(new Date()),
    };
    return newValue;
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      ExpenseHdrsService.initialFieldValues,
      validate,
      props.setCurrentId
    );

  const handleGo = () => {
    if (validate()) {
      var model = setFormValue(values);
      values.FromDt = values.FromDt || getDefaultDate();
      values.ToDt = values.ToDt || new Date();
      ExpenseHdrsService.getAll(model).then((response) => {
        let result = response.data;
        if (result.isSuccess) {
          setExpenseHdrs(result.list);
          SetVisible(true);
        } else {
          globalService.error(result.message);
        }
      });
    }
  };

  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 1);
    return oneMonthsAgo;
  }

  const expenseHdrsColumns: GridColDef[] = [
    { field: "ExpenseVoucherNo", headerName: "Voucher No", flex: 1, },
    {
      field: "ExpenseVoucherDate",
      headerName: "Voucher Dt",
      flex: 1,
      valueFormatter: (params: { value: any; }) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "ExpenseDescription",
      headerName: "Expense Description",
      flex: 2,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    {
      field: "Details",
      headerName: "Details",
      width: 120,
      //flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/expenseDtls/" + params.row.ExpenseHdrId, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Details ({params.row.TotDetails})
            </Button>
          </Stack>
        );
      },
    },

    {
      field: "Queryinfo",
      headerName: "Query Info",
      width: 80,
      //flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/expenseQueries/" + params.row.ExpenseHdrId, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Query
            </Button>
          </Stack>
        );
      },
    },
  ]

  return (
    <>
      <Typography variant="h5" align="center">
        Expenses
      </Typography>

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Controls.ReactDatePicker
                label="From Date"
                // min={values.FromDt}
                max={new Date()}
                value={values.FromDt = values.FromDt || getDefaultDate()}
                onChange={(date: any) => {
                  handleInputChange({
                    target: {
                      value: globalService.getISODateOnlyForDatePicker(date),
                      name: "FromDt",
                    },
                  });
                }}
                error={errors.FromDt}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Controls.ReactDatePicker
                label="To Date"
                min={values.FromDt}
                max={new Date()}
                value={values.ToDt = values.ToDt || new Date()}
                onChange={(date: any) => {
                  handleInputChange({
                    target: {
                      value: globalService.getISODateOnlyForDatePicker(date),
                      name: "ToDt",
                    },
                  });
                }}
                error={errors.ToDt}
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>

            <Grid item xs={12} sm={2}>
              <Button type="button" variant="contained" onClick={handleGo}>
                Go
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <>
        {visible && (
          <Card>
            <CardContent>
              {/* <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => navigate("/addExpenseHdr/")}
              >
                Add Record
              </Button> */}

              <div style={{ width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.ExpenseHdrId}
                  rows={expenseHdrs}
                  columns={expenseHdrsColumns}
                  columnHeaderHeight={30}
                  //rowHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                  //loading={loading}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns Id, the other columns will remain visible
                        ExpenseHdrId: false,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 50 } },
                  }}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </div>
            </CardContent>
          </Card>
        )}
      </>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  )
}

export default ExpenseForAccountList
