import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ExpenseRegisterModel } from "../../models/ExpenseRegisterModel";
import { ExpenseRegisterService } from "../../services/ExpenseRegisterService";
import useForm from "../../utility/hooks/UseForm";
import dayjs from "dayjs";
import Controls from "../../utility/Controls.tsx/Controls";
import { globalService } from "../../services/GlobalService";
import { AuthContext } from "../../utility/context/AuthContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ExpenseHdrsService } from "../../services/ExpenseHdrsService";
import { SelectListModel } from "../../models/ApiResponse";
import { ROLES } from "../../utility/Config";

const ExpenseRegisterList = (...props: any) => {
  const [expenseRegister, setExpenseRegister] = useState([]);
  const [visible, SetVisible] = useState<boolean>(false);

  const [expeseHdrStatusList, setExpeseHdrStatusList] = useState<SelectListModel[]>([]);

  const { auth } = React.useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (expeseHdrStatusList.length === 0) getExpeseHdrStatusList();
  }, []);

  const validate = (fieldValues: any = values) => {
    let temp: any = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  function setFormValue(model: ExpenseRegisterModel) {
    let newValue = {
      UserId: model.UserId,
      ExpenseHdrStatusId: model.ExpenseHdrStatusId || "",
      FromDt: model.FromDt
        ? new Date(model.FromDt)
        : globalService.convertLocalToUTCDate(new Date()),
      ToDt: model.ToDt
        ? new Date(model.ToDt)
        : globalService.convertLocalToUTCDate(new Date()),
    };
    return newValue;
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(
      ExpenseRegisterService.initialExpenseRegisterValues,
      validate,
      props.setCurrentId
    );

 

  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 1);
    return oneMonthsAgo;
  }

  const getExpeseHdrStatusList = () => {
    ExpenseHdrsService.getExpenseHdrsStatusSelectList().then((response) => {
      setExpeseHdrStatusList(response.data);
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      //var userId = values.UserId;
      var model = setFormValue(values);
      ExpenseRegisterService.getExpensesRegister(model).then((response) => {
        let result = response.data;
        if (result.isSuccess) {
          setExpenseRegister(result.list);
          SetVisible(true);
        } else {
          globalService.error(result.message);
        }
      });
    }
  };

  const expenseRegisterColumns: GridColDef[] = [
    { field: "Project", headerName: "Project", width: 100 },
    { field: "ExpenseVoucherNo", headerName: "Voucher No", width: 125 },
    {
      field: "ExpenseVoucherDate",
      headerName: "Voucher Dt",
      width: 100,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YY") : "",
    },
    {
      field: "ExpenseDescription",
      headerName: "Expense Description",
      width: 250,
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    { field: "ExpenseHdrStatus", headerName: "Status", width: 120 },
    { field: "ExpenseHeadName", headerName: "Expense Head", width: 120 },
    {
      field: "BasicAmount",
      headerName: "Basic Amount",
      width: 100,
      align: "right",
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    { field: "Cgst", headerName: "CGST", width: 100, headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      }},
    { field: "Sgst", headerName: "SGST", width: 100, headerAlign: "right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
      cellClassName: "cell-right"},
    { field: "Igst", headerName: "IGST", width: 100, headerAlign: "right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
      cellClassName: "cell-right"},
     { field: "Gsttotal", headerName: "GST Total", width: 130, headerAlign: "right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
      cellClassName: "cell-right"},
    {
      field: "InvoiceAmt",
      headerName: "Invoice Amount",
      width: 130,
      align: "right", headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    { field: "Remark", headerName: "Remark", width: 150 },
    {
      field: "Level1RejectedRemark",
      headerName: "Level1 Rejected Remark ",
      width: 120,
      cellClassName:"cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    {
      field: "Level2RejectedRemark",
      headerName: "Level2 Rejected Remark",
      width: 120,
      cellClassName:"cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    //{ field: "TotDetails", headerName: "Total Details",width: 100, },
    { field: "CreatedBy", headerName: "Created By", width: 150 },
    {
      field: "CreatedOn",
      headerName: "Created On",
      width: 100,
      align: "left",
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
  ];

  return (
    <>
      <Typography variant="h5" align="center">
        Expense Register
      </Typography>
      <>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card>
            <React.Fragment>
              <CardContent>
                <Grid container spacing={2}>
                {!globalService.roleMatch([ROLES.Accounts], auth) && (
                  <Grid item xs={12} sm={3}>
                    <Controls.Select
                      required
                      name="ExpenseHdrStatusId"
                      label="Expese Status"
                      options={expeseHdrStatusList}
                      value={
                        expeseHdrStatusList.length > 0
                          ? values.ExpenseHdrStatusId
                          : ""
                      }
                      onChange={(e: any) => {
                        handleInputChange(e);
                      }}
                      error={errors.DepartmentId}
                    />
                  </Grid>
                )}
                  <Grid item xs={5} sm={3}>
                    <Controls.ReactDatePicker
                      label="From Date"
                
                      
                      // min={values.FromDt}
                      max={new Date()}
                      value={
                        (values.FromDt = values.FromDt || getDefaultDate())
                      }
                      onChange={(date: any) => {
                        handleInputChange({
                          target: {
                            value: globalService.getISODateOnlyForDatePicker(date),      
                            name: "FromDt",
                          },
                        });
                      }}
                      error={errors.FromDt}
                    />
                  </Grid>

                  <Grid item xs={5} sm={3}>
                    <Controls.ReactDatePicker
                      label="To Date"
                     
                    
                      min={values.FromDt}
                      max={new Date()}
                      value={(values.ToDt = values.ToDt || new Date())}
                      onChange={(date: any) => {
                        handleInputChange({
                          target: {
                            value:globalService.getISODateOnlyForDatePicker(date),      
                            name: "ToDt",
                          },
                        });
                      }}
                      error={errors.ToDt}
                    />
                  </Grid>

                  <Grid item xs={2} sm={3}>
                    <Button type="submit" variant="contained">
                      Go
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </React.Fragment>
          </Card>
        </form>
      </>

      <>
        {visible && (
          <Card>
            <div>
              <DataGrid
                getRowId={(row) => row.ID}
                rows={expenseRegister}
                columns={expenseRegisterColumns}
                columnHeaderHeight={30}
                //rowHeight={30}
                autoHeight={true}
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
                //loading={loading}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns Id, the other columns will remain visible
                      Cgst: globalService.roleMatch([ROLES.Accounts], auth) ? true:false,
                    Sgst: globalService.roleMatch([ROLES.Accounts], auth) ? true:false,
                    Igst: globalService.roleMatch([ROLES.Accounts], auth) ? true:false,
                    },
                  },
                  pagination: {
                    paginationModel: { pageSize: 10, page: 2 },
                  },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </div>
          </Card>
        )}
      </>
    </>
  );
};

export default ExpenseRegisterList;
