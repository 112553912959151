import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { config } from "../utility/Config";
import { RentAgreementModel } from "../models/RentAgreementModel";

const initialFieldValues: RentAgreementModel = {
  RentAgreementFileId: Guid.EMPTY,
  RentAgreementId: "",
  FileName: "",
  CreatedOn: null
}

const url = 'RentAgreementFile';

function remove(id: number) {
  return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(RentAgreementFileId: number) {
  return customAxios.get(`${url}/GetById/${RentAgreementFileId}`, axiosRetryConfig);
}

function getAllFileByRentAgreements(RentAgreementId: any) {
  return customAxios.get(`${url}/GetAllFileByRentAgreements/${RentAgreementId}`, axiosRetryConfig);
}

// function getAllFileByExpenseHeaderId(ExpenseHeaderId: any) {
//   return customAxios.get(`${url}/GetAllFilesByExpenseHeaderId/${ExpenseHeaderId}`, axiosRetryConfig);
// }

export const rentAgreementFileService = {
  initialFieldValues,
  remove,
  getById,
  getAllFileByRentAgreements,
  //getAllFileByExpenseHeaderId
}
