import { AddCircleOutline, Subtitles } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { boqSubTitleService } from "../../services/BoqSubTitleService";
import ConfirmDialog from "../helper/ConfirmDialog";
import useForm from "../../utility/hooks/UseForm";
import Controls from "../../utility/Controls.tsx/Controls";
import { SelectListModel } from "../../models/ApiResponse";
import { boqPackageService } from "../../services/BoqPackageService";
import { config, AttributeTypes } from "../../utility/Config";
import { globalService } from "../../services/GlobalService";

const BoqSubTitleList = (...props: any)=> {
  
  const [boqSubTitle, setBoqSubTitle] = useState([]);
  const [packagesList, setPackagesList] = useState<SelectListModel[]>([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });
  const {boqpackageId,BoqtitleId ,AttributeType}: any = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if(packagesList.length === 0) {
      getPackageSelectLists();
    }else{
      getBoqSubTitle();
    }
  }, []);


const validate = (fieldValues:any = values) => {
  let temp: any = { ...errors };
  if ("BoqpackageId" in fieldValues)
    temp.BoqpackageId = fieldValues.BoqpackageId 
      ? ""
      : "Please Select BOQ Package";
  setErrors({
    ...temp,
  });
  if (fieldValues === values)
    return Object.values(temp).every((x) => x === "");
};

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqSubTitleService.initialFieldValues, validate, props.setCurrentId );
    
    const getPackageSelectLists = () => {
      boqPackageService.getPackageSelectList().then((response) => {
        setPackagesList(response.data);
        setValues(response.data);
      });
    };
  

  const getBoqSubTitle = () => {
    boqSubTitleService.getAllMBoqsubTitlesByPackageID(values.BoqpackageId).then((response) => {
      let result = response.data;
      setBoqSubTitle(result.list);
      });
    }


  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate()) {
      if (values.BoqpackageId) {
        boqSubTitleService.getAllMBoqsubTitlesByPackageID(values.BoqpackageId).then((response) => {
          let result = response.data;
          if (response) {
            setBoqSubTitle(result.list);
          } else {
            globalService.error(result.message);
          }
        });
      }else{
        globalService.error("Please Select BOQ Package");
      }
    }
  };





  const subTitlecoloumns: GridColDef[] = [
    { field: "BoqtitleName", headerName: "Title Name", width: 130, flex: 1,
    valueGetter: (params) => params.row.Boqtitle
    ?.BoqtitleName,
     },
    {field:"BoqsubTitleName", headerName: "SubTitle Name",flex: 1},
    {
      field: "AlternativeText",
      headerName: "Alternative Text",
      
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqSubTitlesAlternativeTexts/" 
                + params.row.BoqsubTitleId, 
               {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Alternatives
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "Attributes",
      headerName: "Attributes",
      // width: 400,
      flex:1,
      // type: "number",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>

            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqSubTitlesAttributes/" + params.row.BoqsubTitleId  +
               "/" + AttributeTypes.Size ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Size
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqSubTitlesAttributes/" + params.row.BoqsubTitleId  +
               "/" + AttributeTypes.Make ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Make
            </Button>
            {/* <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqSubTitlesAttributes/" + params.row.BoqsubTitleId  +
               "/" + AttributeTypes.Color ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Color
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/boqSubTitlesAttributes/" + params.row.BoqsubTitleId  +
               "/" + AttributeTypes.Material ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Material
            </Button>
            <Button
              className="btnGrid"
              variant="contained"
              
              onClick={() =>
                navigate("/boqSubTitlesAttributes/" + params.row.BoqsubTitleId  +
               "/" + AttributeTypes.Activity ,{
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Activity
            </Button> */}
          </Stack>
        );
      },
    },
   
    {
      field: "Actions",
      headerName: "Actions",
      type: "number",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton
              size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editSubTitle/" + params.row.Boqtitle.BoqpackageId + "/" + params.row.BoqsubTitleId)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              color="error"
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: `Are you sure to delete "${params.row?.BoqsubTitleName}" SubTitle ?`,
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    removeBoqSubTitle(params.row.BoqsubTitleId);
                  },
                });
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const removeBoqSubTitle = (BoqsubTitleId: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    boqSubTitleService.remove(BoqsubTitleId).then((response) => {
      if (response) {
        let result = response.data;
        globalService.success(result.message);
        getBoqSubTitle();
        // setBoqSubTitle(prevState => prevState.filter(row => row.BoqsubTitleId !== BoqsubTitleId));
      }
    });
  };

  const packageValidate = () => {
    var boqPackId = values.BoqpackageId
    if(boqPackId){
      navigate("/addSubTitle/" + values.BoqpackageId)
    }else{
      globalService.error("Please Select Package Name.")
    //  alert("Please select package name.")
    }
  }

  return (
    <>
      <Typography variant="h5" align="center">
        BOQ Sub Titles
      </Typography>
      <Card>
        <CardContent>
        <form noValidate onSubmit={handleSubmit}>
            <React.Fragment>
              <Grid container spacing={3} marginBottom={1}>
                <Grid item xs={12} sm={4}>
                  <Controls.Select
                    name="BoqpackageId"
                    label="BOQ Packages"
                    required
                    // showEmptyItem={true}
                    options={packagesList}
                    value={packagesList.length > 0 ? values.BoqpackageId : ""}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                     error={errors.BoqpackageId}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button type="submit" variant="contained">
                    Go
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          </form>
          <Button
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => packageValidate()}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.BoqsubTitleId}
              columns={subTitlecoloumns}
              rows={boqSubTitle}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    boqTitleId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
export default BoqSubTitleList