import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { mZonesService } from "../../services/MZoneService";
import { ZoneModel } from "../../models/MZonesModel";

const MZoneForm = (...props: any) => {
    const {ZoneId} = useParams();
    let navigate = useNavigate();
    const mode = ZoneId ? "Edit" : "Create";

    const validate = (fieldValues = values) => {
        let temp: any = {...errors};
  
        if("ZoneName" in fieldValues)
            temp.ZoneName = fieldValues.ZoneName ? "" : "Zone Name is required";
        setErrors({
            ...temp,
        });
  
        if(fieldValues === values)
            return Object.values(temp).every((x) => x === "");
  
    }

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(mZonesService.initialFieldValues, validate, ZoneId);

    const newZone = () => {
      setValues(mZonesService.initialFieldValues);
    };

    function setFormValue(model: ZoneModel) {
        let newModel = {
            ZoneId : model.ZoneId,
            ZoneName: model.ZoneName || "",
            Active: model.Active,
        };
        return newModel;
      }

      useEffect(() => {
        if(ZoneId) {
          getZone(ZoneId);
            setErrors({});
        } else newZone();
      }, [ZoneId]);
    
      const getZone = (ZoneId: any) => {
        mZonesService.getById(ZoneId).then((response) => {
            if(response) {
                let result = response.data;
                setValues(setFormValue(result.list));
            }
        });
      };

      const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if(validate()) {
           if(ZoneId) {
            
            mZonesService.put(values).then((response: any) => {
                let result = response.data;
                if(response) {
                  
                    globalService.success(result.message);
                    navigate("/mZones")
                } else {
                    globalService.error(result.message);
                }
            });
           } else {
            mZonesService.post(values).then((response: any) => {
                if(response) {
                    let result = response.data;
                    if(result.isSuccess){
                        globalService.success(result.message);
                        navigate("/mZones")
                    } else {
                        globalService.error(result.message);
                      }
                } 
            });
           }
        }
      }
  

  return (
    <>
     <Typography>
       Zone
      </Typography>

      <form
        autoComplete="off" noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>

              <Grid container spacing={3}>

                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label=" Zone Name"
                    name="ZoneName"
                    value={values.ZoneName}
                    onChange={handleInputChange}
                    error={errors.ZoneName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}
                  className={mode !== "Edit" ? "hidden" : ""}
                >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>

              </Grid>

            </React.Fragment>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/mZones" )}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>

      </form>
    </>
  )
}

export default MZoneForm