import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Dialog,
  Avatar,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LanIcon from "@mui/icons-material/Lan";
import React, { useEffect, useRef, useState } from "react";
import {
  MultiSelectListModel,
  SelectListModel,
} from "../../models/ApiResponse";
import { ProjectDirectoryModel } from "../../models/ProjectDirectoryModel";
import { MProcurementTypesService } from "../../services/MProcurementTypesService";
import { mSiteService } from "../../services/MSiteService";
import { projectDirectoryService } from "../../services/ProjectDirectoryService";
import Controls from "../../utility/Controls.tsx/Controls";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import { boqUploadService } from "../../services/BoqUploadService";
import useForm from "../../utility/hooks/UseForm";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { MVendorService } from "../../services/MVendorService";
import { MVendorModel } from "../../models/MVendorModel";
import Draggable from "react-draggable";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { RFQService } from "../../services/RequestForQuoteService";
import { globalService } from "../../services/GlobalService";
import ConfirmDialog from "../helper/ConfirmDialog";
import { mPaymentTermsService } from "../../services/MPaymentTermsService";
import { BoqDocFileService } from "../../services/BoqdocFilesService";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { config } from "../../utility/Config";
import ArrowBackIcon from "@mui/icons-material/ArrowForward";
import { useParams } from "react-router-dom";
import { Refresh } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
const RequestForQuoteList = () => {
  const { procurementTypeId } = useParams();
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [rateComparison, setRateComparison] = useState([]);
  const [clients, setClients] = useState<MultiSelectListModel[]>([]);
  const [procurementTypes, setProcurementTypes] = useState<SelectListModel[]>(
    []
  );
  const [sites, setSites] = useState<SelectListModel[]>([]);
  const [packages, setPackages] = useState<any[]>([]);
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const docFileInputRef = useRef<HTMLInputElement>(null);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [boqDocFiles, setBoqDocFile] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [docFiles, setDocFiles] = useState([]);
  const boqFileInputRef = useRef<HTMLInputElement>(null);
  const [nestedTabValue, setNestedTabValue] = React.useState("3");
  const [openForVendor, setOpenForVendor] = React.useState(false);
  const [openRateComparison, setOpenRateComparison] = React.useState(false);
  const [vendorRows, setVendorRows] = useState<MVendorModel[]>([]);
  const [projectDirectory, setProjectDirectory] =
    useState<ProjectDirectoryModel>(null);
  const [rowDetail, setRowDetail] = useState<any>(null);
  const [showProjectDirectory, setShowProjectDirectory] =
    useState<boolean>(false);
  const [selectedVendors, setSelectedVendorUploadIds] = useState<any[]>([]);
  const [selectedPaymentTermIds, setSelectedPaymentTermIds] = useState<any[]>(
    []
  );
  const [selectedboqDocFileIds, setSelectedboqDocFileIds] = useState<any[]>([]);
  const [boqItems, setBoqItems] = useState<any[]>([]);
  const [selectedBoqUploadIds, setSelectedBoqUploadIds] = useState<string[]>(
    []
  );
  const draggableRef = useRef(null);
  const goToNextTab = () => {
    const nextValue = (parseInt(value) + 1).toString();
    setValue(nextValue);
  };

  const makeListFileInputRef = useRef<HTMLInputElement>(null);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    item: "",
    onConfirm: () => { },
  });
  const { boqCandI, boqMEP, labourProcumentsvalue, procumentsvalue, factorywork } = useParams();

  const handleOpenforRateComparison = (
    BOQUploadId: any,
    ShowQuotedRatesOnly?: boolean
  ) => {
    getRateComparison(BOQUploadId, ShowQuotedRatesOnly);
    setOpenRateComparison(true);
  };

  const handleCloseforrateComparison = () => {
    setOpenRateComparison(false);
  };

  const isDisabled = !(
    selectedVendors.length > 0 && selectedPaymentTermIds.length > 0
  );
  const handleVendorDetailOpen = (row: any) => {
    getAllVendor();
    getPaymentTerms();
    setOpen(true);
  };

  const handleVendorDetailClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
    getAllVendor();
    getPaymentTerms();
  };

  const handleOpenforVendor = (row: any) => {
    setOpenForVendor(true);
  };
  const handleCloseforVendor = () => {
    setOpenForVendor(false);
  };

  const handleNestedTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setNestedTabValue(newValue);
  };
  useEffect(() => {

    //const node = draggableRef.current;
    if (sites.length === 0) getSites();
    if (procurementTypes.length === 0) getProcurementType();
    // if (boqDocFiles.length === 0) getBoqDocFile();
  }, []);

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    //if ("ClientID" in fieldValues)
    //   temp.ClientID = fieldValues.ClientID ? "" : "Client is required.";

    if ("SiteID" in fieldValues)
      temp.SiteID = fieldValues.SiteID ? "" : "Site is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };



  const handleConvertedBoqSelectionChange = (selectedIds: any) => {
    setSelectedBoqUploadIds(selectedIds);
  };

  const handleConvertedVendorSelectionChange = (selectedVendorsIds: any) => {
    setSelectedVendorUploadIds(selectedVendorsIds);
  };

  const handleConvertedPaymentTermSelectionChange = (
    selectedPaymentTermIds: any
  ) => {
    setSelectedPaymentTermIds(selectedPaymentTermIds);
  };

  const handleConvertedboqDocFileSelectionChange = (
    selectedboqDocFileIds: any
  ) => {
    setSelectedboqDocFileIds(selectedboqDocFileIds);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqUploadService.initialFieldValues, validate, "");

  const getAllVendor = () => {
    MVendorService.getAll().then((response) => {
      let result = response.data;
      setVendorRows(result.list);
    });
  };

  const handleSubmitSendToVendorsForQuotation = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    //e.preventDefault();
    values.VendorIDs = selectedVendors.toString();
    values.PaymentTermIDs = selectedPaymentTermIds.toString();
    values.BOQUploadIDs = selectedBoqUploadIds.toString();
    values.BOQUploadIDs = selectedBoqUploadIds.toString();
    values.BOQDocFileIDs = selectedboqDocFileIds.toString();
    values.BOQPackageID= values.BOQPackageID;
    values.ProcurementTypeID= values.ProcurementTypeId;
    //values.CreatedByID = "2BC4C9FD-B990-4805-86B2-BE69689E552E";
    
    RFQService.post(values).then((response: any) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          globalService.success(result.message);
          handleCloseforVendor();
          handleVendorDetailClose(null, "");
          GetBOQItemDtls();
        }
      }
    });
  };

  const getRateComparison = (
    BOQUploadId: any,
    ShowQuotedRatesOnly?: boolean
  ) => {
    RFQService.getQuotesRcvdFromVendorForPOCreationNew(
      BOQUploadId,
      ShowQuotedRatesOnly
    ).then((response) => {
      let result = response.data;
      setRateComparison(result.list);
    });
  };
  const getPaymentTerms = () => {
    mPaymentTermsService.getAllRFQPaymentTerms().then((reponse) => {
      let result = reponse.data;
      setPaymentTerms(result.list);
    });
  };

  const getBoqDocFile = () => {
    
    BoqDocFileService.getAll(values.ProjectDirectoryID).then((reponse) => {
      let result = reponse.data;
      setBoqDocFile(result.list);
    });
  };

  // const getClients = () => {
  //   mClientService.GetAllMultiSelectList().then((response: any) => {
  //     setClients(response.data);
  //   });
  // };

  const getProcurementType = () => {
    MProcurementTypesService.getAllSelectList().then(
      (response: any) => {
        setProcurementTypes(response.data);
        setValues((prevValues: any) => ({
          ...prevValues,
          ProcurementTypeId: procurementTypeId,
        }));

      }
    );
  };

  const getSites = () => {
    mSiteService.GetMultiSelectListByUploadedBoqs().then((response: any) => {
      setSites(response.data);
    });
  };
  const getBoqPackages = (ProjectDirectoryID: any) => {
    projectDirectoryService
      .getSelectList(ProjectDirectoryID)
      .then((response: any) => {
        setPackages(response.data);
        if (response.data.length > 0) {
          
          let PackageId = response.data[0].Value;
          setValues((prevValues: any) => ({
            ...prevValues,
            BOQPackageID: PackageId,
          }));   
          getProcurementType()     
        }
      });
  };
  const handleBrowseButtonClick = () => {
    if (nestedTabValue == "3" && docFileInputRef.current) {
      docFileInputRef.current.click();
    }
  };
  const handleFileClick = (event: any, filePath: any) => {
    event.preventDefault();
    window.open(
      `${process.env.REACT_APP_BASE_URL}/${filePath?.FilePath}`,
      "_blank"
    );
  };
  const getProjectDirectory = (siteId: any) => {
    projectDirectoryService
      .GetBySiteId(siteId)
      .then((response) => {
        let result = response.data;
        setProjectDirectory(result.row);
        if (result.row)
          values.ProjectDirectoryID = result.row.ProjectDirectoryId;
        else values.ProjectDirectoryID = "";
        getBoqPackages(values.ProjectDirectoryID);
        getBoqDocFile();
      });
  };

  const GetBOQItemDtls = () => {
    //e.preventDefault();
    if (validate()) {
      
      values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;
      boqUploadService.GetBOQItemDtls(values).then((response) => {
        let result = response.data;
        setBoqItems(result.list);
       
      });
    }
  };

  const uploadBoqDocs = () => {
    if (!docFiles || docFiles.length == 0) {
      globalService.error("Please select File less than 5Mb to upload.");
      return;
    }
    values.ProjectDirectoryID = projectDirectory.ProjectDirectoryId;
    
    BoqDocFileService.docsImport(values, docFiles).then((response) => {
      let result = response.data;

      if (result.isSuccess) {
        globalService.success(result.message);
        // setDocsListByProjectDirectoryId(result.docList);
        // refreshUploadFileControls();
        setDocFiles([]);
        getBoqDocFile();
      } else {
        globalService.error(result.message);
        // setDocsListByProjectDirectoryId([]);
        //setDocFilesUpload([]);
        // setDocFilesUpload(result.list);
      }
    });
  };

  const onDocFileChange = (fileInput: any) => {
    console.log(docFileInputRef.current);
    const filesArray: File[] = Array.from(fileInput.target.files);

    // Check file sizes
    const filesLessThan5MB = filesArray.filter(
      (file) => file.size <= 5 * 1024 * 1024
    ); // 5MB in bytes

    // Update state with the array of files if all files are less than 5MB
    if (filesLessThan5MB.length === filesArray.length) {
      setDocFiles(filesLessThan5MB);
    } else {
      // Display an error message or handle the condition where file size exceeds 5MB
      console.error("One or more files exceed the size limit of 5MB");
    }
  };
  const removeBoqDocFile = (index: number) => {
    setDocFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const ScrollableContent = ({ value }: any) => (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>{value}</div>
  );

  const itemDtlsColumns: GridColDef[] = [

    {
      field: "ClientSrNo",
      headerName: "Client SrNo",
      width: 100,
      // flex: 1,
    },
    {
      field: "ImageFileName",
      headerName: "Image",
      width: 60,
      renderCell: (params) => (
        <div className="zoom">
          <Stack>
            {params.row.ImageURL ? (
              <Avatar
                alt=" "
                src={`${process.env.REACT_APP_BASE_URL}${params.row.ImageURL}`}
                sx={{
                  width: "40px",
                  height: "40px",
                  marginTop: 2,
                  border: "0.5px solid black",
                }}
              />
            ) : (
              <Avatar
                src=""
                alt=" "
                sx={{ width: 40, height: 40, marginTop: 2 }}
              >
                <PersonIcon />
              </Avatar>
            )}
            {/* {
            <img
              src={`${process.env.REACT_APP_BASE_URL}${params.row.ImageURL}`}
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
          } */}
          </Stack>
        </div>
      ),
    },
    // {
    //   field: "Title",
    //   headerName: "Title",
    //   width: 175,
    //   // flex: 1,

    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    // {
    //   field: "SubTitle",
    //   headerName: "Sub Title",
    //   width: 175,
    //   // flex: 1,

    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    // {
    //   field: "ItemName",
    //   headerName: "Item Name",
    //   width: 175,
    //   // flex: 1,

    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    // {
    //   field: "Specifications",
    //   headerName: "Specifications",
    //   width: 175,
    //   // flex: 1,

    //   renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    // },
    {
      field: "BOQDescription",
      headerName: "BOQ Description",
      // width: 300,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
      flex: 1,
    },
    { field: "Unit", headerName: "Unit", width: 100 },
    { field: "Qty", headerName: "Qty", width: 100 ,
      headerAlign: "right",
      cellClassName: "cell-right",},
    {
      field: "BOQMake ",
      headerName: "Make ",
      width: 100,
      // flex: 1,
    },
    {
      field: "Location",
      headerName: "Location",
      width: 150,
      // flex: 1,
    },

    {
      field: "RFQSendToVendors",
      headerName: "RFQ Send To Vendors",
      width: 100,
      // flex: 1,

      renderCell: (params) => (
        <>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setRowDetail(params.row);
              handleOpenforRateComparison(params.row.BOQUploadId, null);
            }}
          >
            {params.row.RFQSendToVendors}
          </Link>
        </>
      ),
    },

    {
      field: "BOQUploadStatus",
      headerName: "Status",
      width: 100,
      // flex: 1,
    },
    // {
    //   field: "BOQSize ",
    //   headerName: "Size",
    //   width: 100,
    //   // flex: 1,

    // },
    {
      field: "ANJSrNo",
      headerName: "ANJ SrNo",
      width: 100,
      // flex: 1,
    },
    // {
    //   field: "UploadedSeqNo",
    //   headerName: "Uploaded SeqNo",
    //   width: 100,
    //   // flex: 1,
    // },
  ];
  const paymentTermsColumns: GridColDef[] = [
    {
      field: "PaymentTerm",
      headerName: "Term's And Conditions",
      flex: 6,
      align: "left",
    },
  ];

  const vendorColumns: GridColDef[] = [
    { field: "VendorName", headerName: "Vendor Name", flex: 1 },
    { field: "VendorCode", headerName: "Vendor Code", width: 100 },
    { field: "Email", headerName: "Email", width: 200 },
    { field: "Address1", headerName: "Address1", flex: 1 },
    { field: "Spocname", headerName: "Spoc Name", flex: 1 },
    { field: "Spocmobile", headerName: "Spoc Mobile", width: 130 },
    // { field: 'Address', headerName: 'Address', flex: 1 },
  ];

  const boqDocFilesColumns: GridColDef[] = [
    {
      field: "FileName",
      headerName: "File Name",
      width: 500,
      renderCell: (params) => (
        <>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={(event) => handleFileClick(event, params.row)}
          >
            {params.row.FileName}
          </Link>
        </>
      ),
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY hh:mm A") : "",
    },

    // { field: 'Address', headerName: 'Address', flex: 1 },
  ];
  const rateComparisonColumns: GridColDef[] = [
    { field: "BOQRFQNo", headerName: " RFQ No", width: 140 },
    {
      field: "BOQRFQDate",
      headerName: "RFQ Date",
      width: 120,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    { field: "VendorName", headerName: " Vendor", flex: 1 },
    { field: "SPOCName", headerName: " Spoc Name", flex: 1 },
    { field: "SPOCMobile", headerName: " Spoc Mobile", width: 100 },
    {
      field: "SupplyRate",
      headerName: "Supply Rate",
      width: 100   ,headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
              return globalService.formatToIndianCurrency(params.value);
            },
    },
    {
      field: "InstallationRate",
      headerName: "Installation Rate",
      width: 150,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
              return globalService.formatToIndianCurrency(params.value);
            },
    },
    { field: "Rate", headerName: "Rate", flex: 1 ,
      headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
              return globalService.formatToIndianCurrency(params.value);
            },},
    { field: "VendorRemarks", headerName: "Vendor Remarks", flex: 1 },
    { field: "VendorMake", headerName: "Vendor Make", flex: 1 },
    { field: "ANJRemarks", headerName: "ANJ Remarks", flex: 1 },
  ];

  const getRowClassName = (params: any) => {
    if (typeof params.row.BOQUploadStatus === 'string' && params.row.BOQUploadStatus.toUpperCase() === 'PO') {
      return 'row-green'; // Apply green background for rows where BOQUploadStatus is "PO"
    }
    return ''; // No additional class for other rows
  };

  return (
    <div>
      <Typography variant="h5" align="center">
        Request For Quote{" "}
      </Typography>
      <Card>
        <CardContent>
          <React.Fragment>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={3}>
                <Controls.MuiAutocomplete
                  options={clients}
                  label="Client"
                  error={errors.ClientID}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "ClientID",
                        value: event ? event.value : "",
                      },
                    });
                    setSites([]);
                    if (event) {
                      getSites(event.value);
                      setValues((prevValues: any) => ({
                        ...prevValues,
                        SiteID: "",
                      }));
                      setProjectDirectory(
                        projectDirectoryService.initialFieldValues
                      );
                    }
                  }}
                  value={
                    clients.find(
                      (item: any) => item.value === values.ClientID
                    ) || null
                  }
                />
              </Grid> */}

              <Grid item xs={12} sm={3}>
                <Controls.MuiAutocomplete
                  options={sites}
                  label="Site"
                  error={errors.SiteID}
                  onChange={(event: MultiSelectListModel) => {
                    handleInputChange({
                      target: {
                        name: "SiteID",
                        value: event ? event.value : "",
                      },
                    });

                    if (event) {
                      getProjectDirectory(event.value);
                    }
                  }}
                  value={
                    sites.find((item: any) => item.value === values.SiteID) ||
                    null
                  }
                />
                {/* <Controls.Select
                  name="SiteID"
                  showEmptyItem={true}
                  label="Sites"
                  required
                  value={sites.length > 0 ? values.SiteID : ""}
                  onChange={(e: any) => {
                    handleInputChange(e);
                    getProjectDirectory(e.target.value);
                  }}
                  options={sites}
                  error={errors.SiteID}
                /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <form
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    GetBOQItemDtls();
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Controls.Select
                        name="BOQPackageID"
                        label="BOQ Package"
                        required
                        showEmptyItem={false}
                        options={packages}
                        value={packages.length > 0 ? values.BOQPackageID : ""}
                        onChange={(e: any) => {
                          handleInputChange(e);
                          // Submit form on change
                        }}
                        error={errors.BOQPackageID}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Controls.Select
                        name="ProcurementTypeId"
                        label="Procurement Type"
                        required
                        disabled
                        showEmptyItem={true}
                        options={procurementTypes}
                        value={
                          procurementTypes.length > 0
                            ? values.ProcurementTypeId
                            : ""
                        }
                        onChange={(e: any) => {
                          handleInputChange(e);
                        }}
                        error={errors.ProcurementTypeID}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button type="submit" variant="contained">
                        Go
                      </Button>
                      {projectDirectory && (
                        <IconButton
                          size="small"
                          color="error"
                          aria-label="add an alarm"
                          onClick={() =>
                            setShowProjectDirectory(
                              (showProjectDirectory) => !showProjectDirectory
                            )
                          }
                        >
                          <LanIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {showProjectDirectory &&
                projectDirectory &&
                projectDirectory.ProjectDirectoryId && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="h5"
                        align="center"
                        className="MuiCardHeader-title"
                      >
                        Project Directory
                      </Typography>
                      <Divider></Divider>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">
                            Site Address
                          </Typography>
                          <Typography variant="body2">
                            {projectDirectory.SiteAddress}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">
                            Project Name{" "}
                          </Typography>
                          <Typography variant="body2">
                            {projectDirectory.ProjectShortName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">
                            Project Detail{" "}
                          </Typography>
                          <Typography variant="body2">
                            {projectDirectory.ProjectDetails}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">Area </Typography>
                          <Typography variant="body2">
                            {projectDirectory.Area}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">Start Date </Typography>
                          <Typography variant="body2">
                            {dayjs(projectDirectory.ProjectStartOn).format(
                              "DD-MMM-YYYY"
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">End Date </Typography>
                          <Typography variant="body2">
                            {dayjs(projectDirectory.ProjectEndOn).format(
                              "DD-MMM-YYYY"
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
            </Grid>
          </React.Fragment>
          <div style={{ height: 650, width: "100%" }}>
            <Grid item xs={12} sm={3}>
              <Stack direction="row" spacing={1} marginY={1}>
                {selectedBoqUploadIds.length > 0 && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleVendorDetailOpen}
                    >
                      Select Vendor's to Send Quotation
                    </Button>
                  </Grid>
                )}
                <p> {selectedBoqUploadIds.length} BOQ's selected.</p>
              </Stack>
            </Grid>

            <DataGrid
              getRowId={(row) => row.BOQUploadId + "_" + row.ClientSrNo}
              rows={boqItems}
              columns={itemDtlsColumns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={false}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              checkboxSelection
              isRowSelectable={(params) => typeof params.row.BOQUploadStatus === 'string' && (params.row.BOQUploadStatus.toUpperCase() !== 'PO')}
              rowSelectionModel={selectedBoqUploadIds}
              disableRowSelectionOnClick
              onRowSelectionModelChange={handleConvertedBoqSelectionChange}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    BOQUploadId: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              getRowClassName={getRowClassName}
            />
          </div>
        </CardContent>

        <CardActions sx={{ display: "flex", justifyContent: "left" }}>
          <Stack spacing={2} direction="row">
            {selectedBoqUploadIds.length > 0 && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVendorDetailOpen}
                >
                  Select Vendor's to Send Quotation
                </Button>
              </Grid>
            )}
          </Stack>
        </CardActions>
      </Card>

      <Draggable nodeRef={draggableRef}>
        <Dialog
          ref={draggableRef}
          fullWidth={true}
          maxWidth={maxWidth}
          onClose={handleVendorDetailClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{ m: 0, p: 2, textAlign: "center" }}
            id="customized-dialog-title"
          >
            Vendor Details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleVendorDetailClose(null, "")}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme: any) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <>
              <Grid item xs={12} sm={3}>
                <Stack direction="row" spacing={1} marginY={1}>
                  {/* {selectedVendors.length > 0 &&  selectedPaymentTermIds.length > 0 && selectedboqDocFileIds.length > 0 && ( */}
                  <Tooltip
                    title={
                      isDisabled
                        ? "Please select Vendor, Payment, and Doc files"
                        : ""
                    }
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isDisabled}
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Are you sure to send mails to vendors for quotation?",
                            item: "You can't undo this operation",
                            onConfirm: () => {
                              handleSubmitSendToVendorsForQuotation();
                            },
                          });
                        }}
                      >
                        Send to Vendor for Quotation
                      </Button>
                    </span>
                  </Tooltip>

                  <p>
                    {" "}
                    {selectedVendors.length} Vendor's selected.{" "}
                    {selectedPaymentTermIds.length} Term's And Conditions
                    selected.{selectedboqDocFileIds.length} Doc File's selected.
                  </p>
                </Stack>
              </Grid>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Vendor's" value="1" />
                      <Tab label="Term's And Conditions" value="2" />
                      <Tab label="Doc File's" value="3" />
                    </TabList>
                  </Box>

                  <TabPanel value="1">
                    {" "}
                    <>
                      <div style={{ width: "100%" }}>
                        <DataGrid
                          getRowId={(row) => row.VendorId}
                          rows={vendorRows}
                          columns={vendorColumns}
                          columnHeaderHeight={30}
                          autoHeight={true}
                          checkboxSelection
                          disableRowSelectionOnClick
                          rowSelectionModel={selectedVendors}
                          onRowSelectionModelChange={
                            handleConvertedVendorSelectionChange
                          }
                          getRowHeight={() => "auto"}
                          getEstimatedRowHeight={() => 200}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                // Hide columns Id, the other columns will remain visible
                                Id: false,
                              },
                            },
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                        />
                        <CardActions
                          sx={{ display: "flex", justifyContent: "right" }}
                        >
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              startIcon={<ArrowBackIcon />}
                              onClick={() => goToNextTab()}
                            >
                              Next
                            </Button>
                          </Stack>
                        </CardActions>
                      </div>
                    </>
                  </TabPanel>
                  <TabPanel value="2">
                    {" "}
                    <>
                      <div style={{ width: "100%" }}>
                        <DataGrid
                          getRowId={(row) => row.PaymentTermId}
                          rows={paymentTerms}
                          columns={paymentTermsColumns}
                          columnHeaderHeight={30}
                          autoHeight={true}
                          checkboxSelection
                          disableRowSelectionOnClick
                          rowSelectionModel={selectedPaymentTermIds}
                          onRowSelectionModelChange={
                            handleConvertedPaymentTermSelectionChange
                          }
                          getRowHeight={() => "auto"}
                          getEstimatedRowHeight={() => 200}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                // Hide columns Id, the other columns will remain visible
                                Id: false,
                              },
                            },
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 500 },
                            },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                        />
                      </div>
                      <CardActions
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="contained"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => goToNextTab()}
                          >
                            Next
                          </Button>
                        </Stack>
                      </CardActions>
                    </>
                  </TabPanel>
                  <TabPanel value="3">
                    {" "}
                    <>
                      <Grid item xs={12} sm={3}>
                        <input
                          type="file"
                          //name="file"
                          id="docSelectedFile"
                          multiple
                          style={{ display: "none" }}
                          onChange={(event: any) => {
                            onDocFileChange(event);
                          }}
                          // onChange={onFileChange}
                          ref={docFileInputRef}
                          accept=".pdf, .doc, .docx, .xls, .xlsx, image/*"
                        />
                        <Button
                          startIcon={<PostAddIcon />}
                           variant="contained"
                        color="primary"
                          onClick={() => handleBrowseButtonClick()}
                        >
                          Browse Doc Files
                        </Button>

                        <Typography
                          style={{ color: "green", fontSize: "0.75rem" }}
                        >
                          {" "}
                          File Size Should be 5MB
                        </Typography>

                        {docFiles.length > 0 &&
                          docFiles.map((file, index) => (
                            <Stack key={index} direction="row" spacing={1}>
                              <Typography>{file.name}</Typography>
                              {/* Display other file details if needed */}
                              <IconButton
                                size="small"
                                aria-label="delete"
                                color="error"
                                onClick={() => removeBoqDocFile(index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Stack>
                          ))}

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Stack direction="row" spacing={0}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<UploadIcon />}
                              onClick={() => uploadBoqDocs()}
                            >
                              Upload Documents
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>

                      <div style={{ width: "100%" }}>
                        <DataGrid
                          getRowId={(row) => row.BoqdocFileId}
                          rows={boqDocFiles}
                          columns={boqDocFilesColumns}
                          columnHeaderHeight={30}
                          autoHeight={true}
                          checkboxSelection
                          disableRowSelectionOnClick
                          rowSelectionModel={selectedboqDocFileIds}
                          onRowSelectionModelChange={
                            handleConvertedboqDocFileSelectionChange
                          }
                          getRowHeight={() => "auto"}
                          getEstimatedRowHeight={() => 200}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                // Hide columns Id, the other columns will remain visible
                                Id: false,
                              },
                            },
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          slots={{ toolbar: GridToolbar }}
                          slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                              quickFilterProps: { debounceMs: 10 },
                            },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                        />
                      </div>
                    </>
                  </TabPanel>
                </TabContext>
              </Box>
            </>
          </DialogContent>
        </Dialog>
      </Draggable>

      <div>
        <Draggable nodeRef={draggableRef}>
          <Dialog
            ref={draggableRef}
            fullWidth={true}
            maxWidth={maxWidth}
            onClose={handleCloseforrateComparison}
            aria-labelledby="customized-dialog-title"
            open={openRateComparison}
          >
            <DialogTitle
              sx={{ m: 0, p: 2, textAlign: "center" }}
              id="customized-dialog-title"
            >
              <Grid>List Of RFQ's </Grid>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseforrateComparison}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme: any) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <>
                {rowDetail && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">Title </Typography>
                          <Typography variant="body2">
                            {rowDetail.Title}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">Sub Title </Typography>
                          <Typography variant="body2">
                            <ScrollableContent value={rowDetail.SubTitle} />
                            {/* {rowDetail.SubTitle} 
                           <ScrollableContent value={expenseHdr?.ExpenseDescription} /> */}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Typography className="label">Item Name</Typography>
                          <Typography variant="body2">
                            {rowDetail.ItemName}
                          </Typography>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                          <Typography className="label">Make </Typography>
                          <globalService.ScrollableCellRenderer value={rowDetail.BOQMake} />
                        </Grid>
                        <Grid item xs={6} sm={12}>
                          <Typography className="label">
                            Specifications{" "}
                          </Typography>
                          <globalService.ScrollableCellRenderer
                            value={rowDetail.Specifications}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <div style={{ width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.BoqrfqitemId}
                    rows={rateComparison}
                    columns={rateComparisonColumns}
                    columnHeaderHeight={30}
                    autoHeight={true}
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    // rowSelectionModel={selectedVendors}
                    // onRowSelectionModelChange={
                    //   handleConvertedVendorSelectionChange
                    // }
                    getRowHeight={() => "auto"}
                    getEstimatedRowHeight={() => 200}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          // Hide columns Id, the other columns will remain visible
                          BOQUploadId: false,
                        },
                      },
                      pagination: { paginationModel: { pageSize: 50 } },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </div>
              </>
            </DialogContent>
          </Dialog>
        </Draggable>
      </div>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default RequestForQuoteList;
