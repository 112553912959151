import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../helper/ConfirmDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { boqPackageMakesService } from "../../services/BoqPackageMakeService";

const BoqPackageMakeList: React.FC = () => {
  const [packageMakes,setPackageMakes] = useState([]);
  const {BoqpackageId}: any = useParams();
  const navigate = useNavigate();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });

  useEffect(() => {
    getPackageMakes();
}, []);

const getPackageMakes = () => {
    boqPackageMakesService.getAllBoqpackageMakes(BoqpackageId).then((response) => {
        
      let result =  response.data;
      setPackageMakes(result.list);
    })
}

const packageMakesColumns: GridColDef [] =[
  { field: "Makes", headerName: "Makes", flex: 1,  },
  { field: "ItemType", headerName: "Item Type", flex: 1,  },
  { field: "Material", headerName: "Material",  flex: 1,  },
  {
    field: "Active",
    headerName: "Active",
    width: 130,
    flex: 1,
    renderCell: (params) => {
      return (
        <Stack>

          {/* {params.row.Active && "Yes"}
              {!params.row.Active && "No"} */}
          {params.row.Active && <DoneIcon color="success" />}
          {!params.row.Active && <CloseIcon color="error" />}
        </Stack>
      );
    },
  },
  {
    field: "Actions",
    headerName: "Actions",
    type: "number",
    flex: 1,
    renderCell: (params) => {
      return (
        <Stack direction="row" spacing={0}>
          <IconButton size="small"
            color="primary"
            aria-label="add an alarm"
            onClick={() => navigate("/editboqpackageMake/"+BoqpackageId+ "/" + params.row.BoqpackageMakeId)}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
          <IconButton size="small"
            aria-label="delete" color="error"
            onClick={() => {
              
              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to delete this Make` ?',
                subTitle: "You can't undo this operation",
                onConfirm: () => {
                  removePackageMake(params.row.BoqpackageMakeId);
                },
              });
            }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>

        </Stack>
      );
    },
  },

]

const removePackageMake = (BoqpackageMakeId: any) => {
  setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
  });

  boqPackageMakesService.remove(BoqpackageMakeId).then((response) => {
  if(response) {
    getPackageMakes();
  }
  });
};



  return (

    <>
    <Typography variant="h5" align="center">
      Boq Package MakeList
    </Typography>

    <Card>
        <CardContent>
            <Button
             variant="contained"
             startIcon={<AddCircleOutlineIcon />}
             onClick={() => navigate("/addboqpackageMake/" + BoqpackageId)}
             >
                Add Record
            </Button>
            <div style={{ width: "100%" }}>
                <DataGrid
                getRowId={(row) => row.BoqpackageMakeId}
                rows={packageMakes}
                columns={packageMakesColumns}
                columnHeaderHeight={30}
                autoHeight={true}
                // checkboxSelection
                // disableRowSelectionOnClick
                getRowHeight={() => "auto"}
                getEstimatedRowHeight={() => 200}
  
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns Id, the other columns will remain visible
                      BoqpackageMakeId: false,
                    },
                  },
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                 />

            </div>

        </CardContent>
    </Card>

    <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

    </>
   
  )
}

export default BoqPackageMakeList