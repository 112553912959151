import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import useForm from "../../utility/hooks/UseForm";

import { EditPosmodel } from "../../models/BoqposModel";
import { BoqposService } from "../../services/BoqPosService";
import Controls from "../../utility/Controls.tsx/Controls";
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import fileDownload from "js-file-download";
import { SelectListModel } from "../../models/ApiResponse";
import { projectDirectoryService } from "../../services/ProjectDirectoryService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { globalService } from "../../services/GlobalService";
import { AuthContext } from "../../utility/context/AuthContext";
import { tallyImportBatchHeadersService } from "../../services/TallyBachesService";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { BatchHeadersmodel, } from "../../models/TallyBachesModel";
import ConfirmDialog from "../helper/ConfirmDialog";
import DownloadIcon from '@mui/icons-material/Download';
import ExpenseQueriesList from "./ExpenseQueriesList";

function TallyBatchesForm(...props: any) {
  let navigate = useNavigate();
  const [expenseHdrId, setExpenseHdrId] = useState<any>(null);
  const [expenseQueryOpen, setExpenseQueryOpen] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { },
  });
  const { auth } = React.useContext(AuthContext);
  const [tallyExpensesSummary, settallyExpensesSummary] = useState([]);
  const [tallyExpenses, setTallyExpenses] = useState([]);
  const [tallyImportBatchHeader, setTallyImportBatchHeader] = useState(null);

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => { }, []);
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(tallyImportBatchHeadersService.initialFieldValues, validate, props.setCurrentId);

  const newUser = () => {
    setValues(tallyImportBatchHeadersService.initialFieldValues);
  };

  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 3);
    return oneMonthsAgo;
  }
  const openExpenseQueryDialog = (expenseHdrId: any) => {
    setExpenseHdrId(expenseHdrId);
    setExpenseQueryOpen(true);
  }
  const closeExpenseQueryDialog = () => {
    setExpenseQueryOpen(false);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  const TallyTransfered = (TallyImportBatchHeaderID: any) => {

    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    values.TallyImportBatchHeaderID = TallyImportBatchHeaderID;
    if (TallyImportBatchHeaderID) {
      tallyImportBatchHeadersService.tallyImportStatusUpdate(values.TallyImportBatchHeaderID).then(
        (response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/tallybatches")
          } else {
            globalService.error(result.message);
          }
        });
    }
  };


  //   values.TallyImportBatchHeaderID = TallyImportBatchHeaderID;
  //   tallyImportBatchHeadersService.tallyImportStatusUpdate(values.TallyImportBatchHeaderID ).then(
  //     (response: any) => {
  //       let result = response.data;
  //       if (response) {
  //         globalService.success(result.message);
  //         //getExpenseHdrs();
  //         navigate("/expenses");
  //       } else {
  //         globalService.error(result.message);
  //       }
  //     }
  //   );
  // };

  const columns: GridColDef[] = [
    {
      field: "Action", headerName: "Excel", width: 130, flex: 1,
      renderCell: (params) => {
        return (
          <Button className="btnGrid"
            variant="contained" startIcon={<DownloadIcon />} size="small" color='primary'
            onClick={() => getDataForTallyBatchDownload(params.row.TallyImportBatchHeaderID, params.row.BatchNo)}
          >
            Download Excel for Tally Import
          </Button>
        )
      }
    },
    {
      field: "BatchNo", headerName: "Batch No", width: 130, flex: 1,

      renderCell: (params) => (
        <>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() =>
              getForTallyExpensesList(params.row.TallyImportBatchHeaderID)
            }
          >
            {params.row.BatchNo}
          </Link>
        </>)
    },
    { field: "TallyImportStatus", headerName: "Tally Import Status", width: 130, flex: 1, },
    {
      field: "CreatedOn", headerName: "Created On", width: 130, flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },

    {
      field: "MarkAfterTallyTransfer",
      headerName: "TallyTransfered",
      width: 200,
      renderCell: (params) =>
        params.row.TallyImportStatus == "Pending" ? (
          <Button
            className="btnGrid"
            variant="contained"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure, want to Transfer Tally",
                subTitle: "Once send, changes won't be allowed",
                onConfirm: () => {
                  TallyTransfered(params.row.TallyImportBatchHeaderID);
                },
              });
            }}
          >
            Mark After Tally Transfer
          </Button>
        ) : null,
    }
  ];

  const columnfortallyExpenses: GridColDef[] = [


    { field: "Project", headerName: "Project", width: 130, flex: 1, },
    { field: "ExpenseVoucherNo", headerName: " Voucher No", width: 130, flex: 1, },
    {
      field: "ExpenseVoucherDate", headerName: " Voucher Dt", width: 130, flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YY") : "",
    },
    {
      field: "TotInvoiceAmt", headerName: "Tot Amount", width: 130, flex: 1, headerAlign: "right",
      cellClassName: "cell-right",
      valueFormatter: (params) => {
        return globalService.formatToIndianCurrency(params.value);
      },
    },
    {
      field: "ExpenseDescription",
      headerName: "Expense Description",
      width: 200,
      cellClassName: "cell-left",
      renderCell: (params) => <globalService.ScrollableCellRenderer value={params.value} />,
    },
    { field: "ExpenseHdrStatus", headerName: "Status", width: 130, flex: 1, },
    {
      field: "CreatedOn", headerName: "CreatedOn", width: 130, flex: 1,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format("DD-MMM-YYYY") : "",
    },
    { field: "CreatedBy", headerName: "CreatedBy", width: 130, flex: 1, },
    {
      field: "ExpenseFor",
      headerName: "Expense For",
      width: 100,
    },
    {
      field: "VendorName",
      headerName: "Vendor Name",
      width: 100,
    },
    {
      field: "Details",
      headerName: "Details",
      width: 120,
      //  flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={2} direction="column">
            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                navigate("/expenseDtls/" + params.row.ExpenseHdrID, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              Details ({params.row.TotDetails})
            </Button>


            <Button
              className="btnGrid"
              variant="contained"
              onClick={() =>
                openExpenseQueryDialog(params.row.ExpenseHdrID)
                // navigate("/expenseQueries/" + params.row.ExpenseHdrId, {
                //   state: {
                //     row: params.row,
                //   },
                // })
              }
            >
              Query ({params.row.QueriesTotDetails})
            </Button>
          </Stack>
        );
      },
    },
    //  {field: "QueriesTotDetails",     headerName: "Queries To tDetails",    width: 130,     flex: 1,  },

  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      tallyImportBatchHeadersService
        .getForTallyExpensesSummary(values)
        .then((response) => {

          let result = response.data;
          settallyExpensesSummary(result.list.Result);
          //values.ExpenseHdrStatusId = result.list.ExpenseHdrStatusId;
          values.TallyImportBatchHeaderID = result.list.TallyImportBatchHeaderID;
          TallyTransfered(values.TallyImportBatchHeaderID);
        });
    }
  };

  const createBatchForTally = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    }); tallyImportBatchHeadersService.createBatchForTally(auth.Id)
      .then((response: any) => {
        if (response) {
          let result = response.data;
          
          setTallyImportBatchHeader(result.message);
          resetForm();

        }
      });
  };

  const getForTallyExpensesList = (TallyImportBatchHeaderID: any) => {
    tallyImportBatchHeadersService
      .getForTallyExpensesList(TallyImportBatchHeaderID)
      .then((Response) => {
        let result = Response.data;
        setTallyExpenses(result.list.Result);
        values.TallyExpensesid = result.list.Result[0]

      });
  };

  const getDataForTallyBatchDownload = (TallyImportBatchHeaderID: any, BatchNo: any) => {

    values.TallyImportBatchHeaderId = TallyImportBatchHeaderID;
    values.BatchNo = BatchNo;

    tallyImportBatchHeadersService
      .getDataForTallyBatchDownload(values)
      .then((Response) => {
        let result = Response.data;
        fileDownload(result, `DataForTallyBatch : ${values.BatchNo}.xlsx`);
        //  values.TallyExpensesid =  result.list.Result[0]

      });
  };
  // const createBatchForTally = () => {
  //   tallyImportBatchHeadersService
  //     .createBatchForTally(auth.Id)
  //     .then((response: any) => {
  //       if (response) {
  //         let result = response.data;
  //         
  //         setTallyImportBatchHeader(result.list.Result);
  //         // values.info = result.list.Result[0];

  //     }
  //     });
  // };
  return (
    <>
      <>
        <Typography variant="h5" align="center">
          Tally Batches
        </Typography>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="History" value="1" />
                <Tab label="Create Batch " value="2" />

              </TabList>
            </Box>
            <TabPanel value="1">
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Card>
                  <CardContent>
                    <React.Fragment>
                      <Grid container spacing={5}>
                        <Grid item xs={6} sm={2}>
                          <Controls.ReactDatePicker
                            label="FromDt"
                            name="FromDt"
                            max={new Date()}
                            value={values.FromDt = values.FromDt || getDefaultDate()}
                            onChange={(date: Date) =>
                              handleInputChange({
                                target: {
                                  value: globalService.convertLocalToUTCDate(date),
                                  name: "FromDt",
                                },
                              })
                            }
                            error={errors.FromDt}
                          />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <Controls.ReactDatePicker
                            label="ToDt"
                            name="ToDt"
                            max={new Date()}
                            value={(values.ToDt = values.ToDt || new Date())}
                            onChange={(date: Date) =>
                              handleInputChange({
                                target: {
                                  value: globalService.convertLocalToUTCDate(date),
                                  name: "ToDt",
                                },
                              })
                            }
                            error={errors.ToDt}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Button type="submit" variant="contained">
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </React.Fragment>


                    <div style={{ width: "100%" }}>
                      <DataGrid
                        getRowId={(row) => row.TallyImportBatchHeaderID}
                        rows={tallyExpensesSummary}
                        columns={columns}
                        columnHeaderHeight={30}
                        //rowHeight={30}
                        autoHeight={true}
                        getRowHeight={() => "auto"}
                        getEstimatedRowHeight={() => 200}
                        //loading={loading}
                        initialState={{
                          columns: {
                            columnVisibilityModel: {
                              // Hide columns Id, the other columns will remain visible
                              BOQPOB2BID: false,
                            },
                          },
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                          },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                      />
                    </div>
                  </CardContent>
                </Card>
              </form>
              <>
                <Card>
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.ExpenseHdrID}
                      rows={tallyExpenses}
                      columns={columnfortallyExpenses}
                      columnHeaderHeight={30}
                      //rowHeight={30}
                      autoHeight={true}
                      getRowHeight={() => "auto"}
                      getEstimatedRowHeight={() => 200}
                      //loading={loading}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns Id, the other columns will remain visible
                            ExpenseHdrID: false,
                          },
                        },
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                      pageSizeOptions={[10, 25, 50, 100]}
                    />
                  </div>
                </Card>
              </>




              {/* { tallyImportBatchHeaders.info}   */}
            </TabPanel>
            <TabPanel value="2">
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: `Are you sure you want to create Batch for tally`,
                          subTitle: "You can't undo this operation",
                          onConfirm: () => { createBatchForTally(); },
                        });
                      }} variant="contained">
                        Create Batch For Tally
                      </Button>
                    </Grid>

                    {/* Display the result */}
                    <Grid item xs={12}>
                      <div>
                        <ul>
                          <h3>{tallyImportBatchHeader}</h3>
                        </ul>
                      </div>
                    </Grid>
                    {/* {tallyImportBatchHeaders && (
                      <Grid item xs={12}>
                        <div>
                          <ul>
                            {tallyImportBatchHeaders.map((item, index) => (
                              <h3>{item.Info}</h3>
                            ))}
                          </ul>
                        </div>
                      </Grid>
                    )} */}
                  </Grid>

                </CardContent>
              </Card>
            </TabPanel>

          </TabContext>
        </Box>

      </>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={expenseQueryOpen}
          onClose={closeExpenseQueryDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Expense Queries
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeExpenseQueryDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ minHeight: 400, paddingTop: 0 }}>
            <>
              <ExpenseQueriesList
                ExpenseHdrId={expenseHdrId}
                onCloseExpenseQueryDialog={closeExpenseQueryDialog}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeExpenseQueryDialog} variant="outlined">Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>

  );
}

export default TallyBatchesForm;
