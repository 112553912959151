import React, { useState, useEffect } from "react";

import {
  Stack,
  IconButton,
  Card,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmDialog from "../helper/ConfirmDialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { mUomsService } from "../../services/MUomsService";

const MUomsList: React.FC = () => {
  const [mUoms, setmUoms] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '', onConfirm: () => { } })
  const navigate = useNavigate();
  useEffect(() => {
    getUoms();
  }, []);

  const getUoms = () => {
    mUomsService.getAll().then((response) => {
      let result = response.data;
      setmUoms(result.list);
    });
  };

  const columns: GridColDef[] = [
    { field: "Uomname", headerName: "UOM Name", flex: 1 },
    { field: "Uomcode", headerName: "UOM Code", flex: 1 },
    {
      field: "Active",
      headerName: "Active",
    //   width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            {params.row.Active && <DoneIcon color="success" />}
            {!params.row.Active && <CloseIcon color="error" />}
          </Stack>
        );
      },
    },
    {
      field: "MUomalternativeTexts",
      headerName: "UOM Alternative Texts",
    //   width: 130,
      flex: 1,
      headerClassName: "bold-font",
      renderCell: (params) => {
        return (
          <Stack>

            <Button
              variant="contained"
              className="btnGrid"
              color="primary"
              onClick={() =>
                navigate("/mUomalternativeTexts/" + params.row.Uomid, {
                  state: {
                    row: params.row,
                  },
                })
              }
            >
              UOM Alternative Texts
            </Button>
          </Stack>
        );
      },

    },
      // {
      //   field: "UOMConversions",
      //   headerName: "UOM Conversions",
      // //   width: 130,
      //   flex: 1,
      //   headerClassName: "bold-font",
      //   renderCell: (params) => {
      //     return (
      //       <Stack>

      //         <Button
      //           variant="contained"
      //           className="btnGrid"
      //           color="primary"
      //         //   onClick={() =>
      //         //     navigate("/boqpackageMakes/" + params.row.BoqpackageId, {
      //         //       state: {
      //         //         row: params.row,
      //         //       },
      //         //     })
      //         //   }
      //         >
      //           UOM Conversion
      //         </Button>
      //       </Stack>
      //     );
      //   },

      // },
    {
      field: "Actions",
      headerName: "Actions",

      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0}>
            <IconButton size="small"
              color="primary"
              aria-label="add an alarm"
              onClick={() => navigate("/editMUoms/" + params.row.Uomid)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small" aria-label="delete" color="error"
              onClick={() => {

                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure to delete this Uoms ?',
                  subTitle: "You can't undo this operation",
                  onConfirm: () => { removeUoms(params.row.Uomid) }
                })
              }}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>

          </Stack>
        );
      },
    },
  ];

  const removeUoms = (Uomid: any) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    })
    mUomsService.remove(Uomid).then((response) => {
      let result = response.data;
      if (response) {
        // globalService.success(result.message);
        getUoms();
      }
    });
  }

  return (
    <>
      <Typography variant="h5" align="center">
       UOMS
      </Typography>
      <Card>
        <CardContent>
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/addMUoms/")}
          >
            Add Record
          </Button>
          <div style={{ width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.Uomid}
              rows={mUoms}
              columns={columns}
              columnHeaderHeight={30}
              //rowHeight={30}
              autoHeight={true}
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 200}
              //loading={loading}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide columns Id, the other columns will remain visible
                    Uomid: false,
                  },
                },
                pagination: { paginationModel: { pageSize: 50 } },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        </CardContent>
      </Card>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}


export default MUomsList