import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  CardActions,
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import Title from "../helper/Title";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Controls from "../../utility/Controls.tsx/Controls";
import useForm from "../../utility/hooks/UseForm";
import { BoqsubTitleAlternativeTextService } from "../../services/BoqSubTitleAlternativeTextService";
import { BoqsubTitleAlternativeTextModel } from "../../models/BoqSubTitleAlternativeTextModel";


const BoqSubTitleAlternativeTextForm = (...props: any) => {

    const { BoqsubTitleId, BoqsubTitleAlternativeTextId } = useParams();
    let navigate = useNavigate();
    const { Code }: any = useParams();
    const mode = Code ? 'Edit' : 'Create';
    const validate = (fieldValues = values) => {
      let temp: any = { ...errors };
      if ("BoqsubTitleAlternativeTextName" in fieldValues)
        temp.BoqsubTitleAlternativeTextName = fieldValues.BoqsubTitleAlternativeTextName
          ? ""
          : "BOQ SubTitle AlternativeText Name is required.";
      setErrors({
        ...temp,
      });
  
      if (fieldValues === values)
        return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(BoqsubTitleAlternativeTextService.initialFieldValues, validate, BoqsubTitleAlternativeTextId);

  const newSubTitleAltText = () => {
    setValues(BoqsubTitleAlternativeTextService.initialFieldValues);
  };

  function setFormValue(model: BoqsubTitleAlternativeTextModel) {
    let newModel = {
        BoqsubTitleAlternativeTextId: model.BoqsubTitleAlternativeTextId,
      BoqtitleId: model.BoqtitleId || "",
      BoqsubTitleId:model.BoqsubTitleId || "",
      BoqsubTitleAlternativeTextName: model.BoqsubTitleAlternativeTextName || "",
      Active: model.Active,
    };
    return newModel;
  }
  useEffect(() => {
    if (BoqsubTitleAlternativeTextId) {
      getSubTitleAltText(BoqsubTitleAlternativeTextId);
      setErrors({});
    } else newSubTitleAltText();
  }, [BoqsubTitleAlternativeTextId]);

  const getSubTitleAltText = (BoqsubTitleAlternativeTextId: any) => {
    BoqsubTitleAlternativeTextService.GetById(BoqsubTitleAlternativeTextId).then((response) => {
      if (response) {
        let result = response.data;
        setValues(setFormValue(result.list));
      }
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      
       
      values.BoqsubTitleId = BoqsubTitleId;

      if (BoqsubTitleAlternativeTextId) {
        BoqsubTitleAlternativeTextService.put(values).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/boqSubTitlesAlternativeTexts/" + BoqsubTitleId);
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        
        BoqsubTitleAlternativeTextService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;

            if (result.isSuccess) {
              globalService.success(result.message);
             
              navigate("/boqSubTitlesAlternativeTexts/" + BoqsubTitleId);
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };




  return (
    <>

<Title>  BOQ SubTitle Alternative Text</Title>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controls.Input
                    label="BOQ SubTitle Alternative Text Name"
                    name="BoqsubTitleAlternativeTextName"
                    value={values.BoqsubTitleAlternativeTextName}
                    onChange={handleInputChange}
                    error={errors.BoqsubTitleAlternativeTextName}
                  />
                </Grid>

                <Grid item xs={12} sm={4}
                 className={mode !== "Edit" ? "hidden" : ""}
                 >
                  <Controls.Checkbox
                    name="Active"
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate("/boqSubTitlesAlternativeTexts/" + BoqsubTitleId)}
              >
                Back{" "}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </>
  )
}

export default BoqSubTitleAlternativeTextForm