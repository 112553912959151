
import { Guid } from "guid-typescript";
import { BoqrequisitionsModel, GetRequisitionBOQItemDtlsForVendors, InsertDataForRequisitionsModel } from "../models/BoqRequisitionModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";


const initialRequisitionBOQItemDtlsForVendorsValues: GetRequisitionBOQItemDtlsForVendors = {
    BOQRequisitionId: '',
    BOQUploadId: '',
    Title: '',
    SubTitle: '',
    ItemName: '',
    Specifications: '',
    Location: '',
    Unit: '',
    Qty: 0,
    SupplyRate: 0,
    InstallationRate: 0,
    Rate: 0,
    BOQSize: '',
    BOQMake: '',
    BOQRequisitionHdrStatus: '',
    RateSubmittedOn: null,
    Remarks: '',
    VendorRemarks: '',
    ANJRemarks: '',
};

const initialFieldValues: BoqrequisitionsModel = {
    VendorId: '',
    BoqrequisitionId: '',
    BoqrequisitionHdrId: '',
    BoquploadId: '',
    SupplyRate: 0,
    InstallationRate: 0,
    Rate: 0,
    VendorRemarks: '',
    VendorMake: '',
    Anjremarks: '',
    BoqrequisitionStatusId: '',
    BoqrequisitionStatus: '',
    RateSubmittedOn: null,
    OrgQuotedSupplyRate: 0,
    OrgQuotedInstallationRate: 0,
    OrgQuotedRate: 0,
}

const initialInsertDataForRequisitionsValues: InsertDataForRequisitionsModel = {
    VendorIDs: [],
    BOQUploadIDs: [],
    CreatedByID: "",
    ProjectDirectoryID:"",
}
const url = "/Boqrequisitions";

function spGetRequisitionBOQItemDtlsForVendors(vendorID: string, bOQRequisitionStatusID: string) {
    return customAxios.get(`${url}/GetRequisitionBOQItemDtlsForVendors/${vendorID}/${bOQRequisitionStatusID}`, axiosRetryConfig);
}


function put(payload: BoqrequisitionsModel) {
    return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}

function getByID(BoqrequisitionId: string) {
    return customAxios.get(`${url}/GetById/${BoqrequisitionId}`, axiosRetryConfig);
}
function getQuotesRcvdFromVendorForPOCreation(BOQUploadId: string, ShowQuotedRatesOnly?: boolean) {
    let payload = {
        BOQUploadID: BOQUploadId,
        ShowQuotedRatesOnly: ShowQuotedRatesOnly
    }
    return customAxios.post(`${url}/GetQuotesRcvdFromVendorForPOCreation`, payload, axiosRetryConfig);
}

function post(payload: InsertDataForRequisitionsModel) {
    return customAxios.post(`${url}/InsertDataForRequisitions`, payload, axiosRetryConfig);
}

function editBOQRequisitions(payloads: any[]) {
    //BOQRequisitionId, InstallationRate, SupplyRate, VendorRemarks, ANJRemarks 
    return customAxios.put(`${url}/EditBOQRequisitions`, payloads, axiosRetryConfig);
}

export const boqrequisitionsService = {
    initialFieldValues,
    getQuotesRcvdFromVendorForPOCreation,
    initialRequisitionBOQItemDtlsForVendorsValues,
    initialInsertDataForRequisitionsValues,
    spGetRequisitionBOQItemDtlsForVendors,
    put,
    post,
    getByID,
    editBOQRequisitions
};