import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Button, Toolbar, Link, Divider, ListItemText, ListItemIcon, Collapse, List, Box, IconButton, Typography } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../utility/context/AuthContext";
import { ROLES } from "../../utility/Config";
import { MProcurementTypesService } from "../../services/MProcurementTypesService";
import { IconMenuItem, NestedMenuItem } from "mui-nested-menu";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalculateIcon from '@mui/icons-material/Calculate';

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = React.useContext(AuthContext);

  const [masterAnchorEl, setMasterAnchorEl] = React.useState<null | HTMLElement>(null);
  const [transactionAnchorEl, setTransactionAnchorEl] = React.useState<null | HTMLElement>(null);
  const [procTypeAnchorEl, setProcTypeAnchorEl] = useState(null);
  const [expenseReportAnchorE1, setExpenseReportAnchorE1] = React.useState<null | HTMLElement>(null);
  const [dashboardAnchorE1, setDashboardAnchorE1] = React.useState<null | HTMLElement>(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState<null | HTMLElement>(null);

  //MEP and C&I
  const [b2bCandI_ProcTypeId, setB2bCandI_ProcTypeId] = useState('');
  const [b2bMEP_ProcTypeId, setB2bMEP_ProcTypeId] = useState('');
  const [labour_ProcTypeId, setLabour_ProcTypeId] = useState('');
  const [procurement__ProcTypeId, setProcurement__ProcTypeId] = useState('');
  const [factoryWork_ProcTypeId, setFactoryWork__ProcTypeId] = useState('');

  const { UserId } = useParams();

  useEffect(() => {
    getCIMEP();
  }, [])

  const getCIMEP = () => {
    MProcurementTypesService.getForMenu().then((response) => {

      let labour = getTypeRow(response.data, 'Labour');
      let b2b_CNI = getTypeRow(response.data, 'B2B C&I');
      let b2b_MEP = getTypeRow(response.data, 'B2B MEP');
      let FactoryWork = getTypeRow(response.data, 'Factory Work');
      let Procurement = getTypeRow(response.data, 'Procurement');

      setB2bMEP_ProcTypeId(b2b_MEP.length > 0 ? b2b_MEP[0].Value : null);
      setB2bCandI_ProcTypeId(b2b_CNI.length > 0 ? b2b_CNI[0].Value : null);
      setLabour_ProcTypeId(labour.length > 0 ? labour[0].Value : null);
      setProcurement__ProcTypeId(Procurement.length > 0 ? Procurement[0].Value : null);
      setFactoryWork__ProcTypeId(FactoryWork.length > 0 ? FactoryWork[0].Value : null);
    })
  }

  const getTypeRow = (data: any, type: string) => {
    let row = data.filter((x: any) => x.Text.toUpperCase() === type.toUpperCase());
    return row;
  }

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    menuType: string
  ) => {

    switch (menuType) {
      case 'master':
        setMasterAnchorEl(event.currentTarget);
        break;
      case 'transaction':
        setTransactionAnchorEl(event.currentTarget);
        break;

      case 'proctype':
        setProcTypeAnchorEl(event.currentTarget);
        break;
      case 'dashboard':
        setDashboardAnchorE1(event.currentTarget);
        break;
      case 'expensereport':
        setExpenseReportAnchorE1(event.currentTarget);
        break;
      case 'account':
        setAccountAnchorEl(event.currentTarget);
        break;
      default:
        break;

    }
  }

  const handleClose = () => {
    setMasterAnchorEl(null);
    setTransactionAnchorEl(null);
    setProcTypeAnchorEl(null);
    setExpenseReportAnchorE1(null);
    setDashboardAnchorE1(null);
    setAccountAnchorEl(null);
  };

  function logout() {
    localStorage.clear();
    setAuth(null);
    navigate("/");
  }
  const roleMatch = (allowedRoles: string[]) => {
    var isMatch = false;
    auth?.Roles?.find((role: any) => allowedRoles?.includes(role))
      ? (isMatch = true)
      : (isMatch = false);
    return isMatch;
  };
  return (
    <>
      <React.Fragment>
        <Toolbar
          component="nav"
          variant="dense"
          sx={{
            justifyContent: "flex-start",
            backgroundColor: "var(--primary-color)",
            color: "white",
            textDecoration: "none",
            overflowX: "auto",
          }}
        >
          <Box display="flex" alignItems="center">
            {/* Dashboard */}
            <Button
              id="dashboard-button"
              disableElevation
              onClick={(e) => handleClick(e, "dashboard")}
              endIcon={<KeyboardArrowDownIcon />}
              className={`nav-btn ${roleMatch([ROLES.Admin, ROLES.Expense_Manager, ROLES.Expense, ROLES.Accounts])
                ? ""
                : "hidden"
                }`}
            >
              Dashboard
            </Button>
            <Menu anchorEl={dashboardAnchorE1}
              open={Boolean(dashboardAnchorE1)}
              onClose={handleClose}>
              <Link color="inherit" href={"/expansedashboard"} className="nav-link">
                <MenuItem onClick={handleClose} disableRipple>
                  Expense Dashboard
                </MenuItem>
              </Link>
              <Link color="inherit" href={`/boqdashboard`} className={`nav-link ${roleMatch([ROLES.Admin])
                ? ""
                : "hidden"
                }`}>
                <MenuItem onClick={handleClose} disableRipple>
                  BOQ Dashboard
                </MenuItem>
              </Link>
              {/* <Link color="inherit" href={"/boqdashboard"} className={"nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])}}>
                <MenuItem onClick={handleClose} disableRipple>
                  BOQ Dashboard
                </MenuItem>
              </Link> */}
            </Menu>
            {/* Ends Dashboard Menu */}

            {/* Starts Master Menu */}
            <Button
              id="master-button"
              disableElevation
              onClick={(e) => handleClick(e, "master")}
              className={`nav-btn ${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Expense_Manager])
                ? ""
                : "hidden"
                }`}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Masters
            </Button>
            <Menu anchorEl={masterAnchorEl}
              open={Boolean(masterAnchorEl)}
              onClose={handleClose}>
              {/* BOQ Masters */}
              <NestedMenuItem
                leftIcon={<CalculateIcon />}
                rightIcon={<KeyboardArrowRightIcon />}
                label="BOQ"
                className={`${roleMatch([ROLES.Admin, ROLES.BOQ_Manager])
                  ? ""
                  : "hidden"
                  }`}
                parentMenuOpen={Boolean(masterAnchorEl)}
              >
                <Link color="inherit" href={"/boqPackages"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Package
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/boqTitles"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Title
                  </MenuItem>
                </Link>
                {/* <Divider sx={{ my: 0.5 }} /> */}
                <Link color="inherit" href={"/boqSubTitles"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    SubTitle
                  </MenuItem>
                </Link>

                <Link color="inherit" href={"/boqItems"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Item
                  </MenuItem>
                </Link>

                <Link color="inherit" href={"/vendors"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Vendor
                  </MenuItem>
                </Link>

                <Link color="inherit" href={"/appSetting"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    App Setting
                  </MenuItem>
                </Link>

                <Link color="inherit" href={"/mPaymentTerms"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Terms And Conditions
                  </MenuItem>
                </Link>


                <Link color="inherit" href={"/mUOMS"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    UOMs
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/mItems"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Items
                  </MenuItem>
                </Link>

                <Link color="inherit" href={"/boqUpload"} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}
                >
                  <MenuItem onClick={handleClose} disableRipple>
                    BOQ Upload
                  </MenuItem>
                </Link>
              </NestedMenuItem>

              {/* Expense Master*/}
              <NestedMenuItem
                leftIcon={<CurrencyRupeeIcon />}
                rightIcon={<KeyboardArrowRightIcon />}
                label="Expense"
                parentMenuOpen={Boolean(masterAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.Expense_Manager])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={"/vendors"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Vendor
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/mexpenseHeads"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Expense Head
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/mDepartments"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Department
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/mDesignations"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Designation
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/mEmployees"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Employee
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/mZones"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Zone
                  </MenuItem>
                </Link>
              </NestedMenuItem>
            </Menu>
            {/* Ends Masters */}

            {/* Starts Transaction Menu */}
            <Button
              id="transaction-button"
              disableElevation
              onClick={(e) => handleClick(e, "transaction")}
              className={`nav-btn ${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Accounts, ROLES.Expense_Manager, ROLES.Expense, ROLES.Vendor])
                ? ""
                : "hidden"
                }`}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Transaction
            </Button>
            <Menu anchorEl={transactionAnchorEl}
              open={Boolean(transactionAnchorEl)}
              onClose={handleClose}>
              <NestedMenuItem
                rightIcon={<KeyboardArrowRightIcon />}
                label="Expense"
                parentMenuOpen={Boolean(transactionAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.Expense, ROLES.Expense_Manager, ROLES.Accounts])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={"/expenses"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    My Expenses
                  </MenuItem>
                </Link>

                <Link color="inherit" href={"/expenseApproval/" + auth.Id}
                  className={`nav-link ${roleMatch([ROLES.Admin, ROLES.Expense_Manager, ROLES.Expense, ROLES.Accounts])
                    ? ""
                    : "hidden"
                    }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Expense Approval
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/rentAgreements"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Rent Agreements
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/rentDues"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Rent Dues
                  </MenuItem>
                </Link>
              </NestedMenuItem>

              <NestedMenuItem
                rightIcon={<KeyboardArrowRightIcon />}
                label="Vendor"
                parentMenuOpen={Boolean(transactionAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.Vendor])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={"/quoteInputForVendor"}
                  className={`nav-link ${roleMatch([ROLES.Admin, ROLES.Vendor])
                    ? ""
                    : "hidden"
                    }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Quote Input
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/editposForVendor"}
                  className={`nav-link ${roleMatch([ROLES.Admin, ROLES.Vendor])
                    ? ""
                    : "hidden"
                    }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Edit POs
                  </MenuItem>
                </Link>
              </NestedMenuItem>

              <NestedMenuItem
                rightIcon={<KeyboardArrowRightIcon />}
                label="Account"
                parentMenuOpen={Boolean(transactionAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.Accounts])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={"/expenses"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Mark Expenses For Tally Transfer
                  </MenuItem>
                </Link>
                <Link color="inherit" href={"/tallybatches"} className="nav-link">
                  <MenuItem onClick={handleClose} disableRipple>
                    Tally Batches
                  </MenuItem>
                </Link>
              </NestedMenuItem>
            </Menu>
            {/* Ends Transaction Menu */}

            {/* Starts Procurement Type Menu */}
            <Button
              id="proctype-button"
              disableElevation
              onClick={(e) => handleClick(e, "proctype")}
              className={`nav-btn ${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Procurement])
                ? ""
                : "hidden"
                }`}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Procurement Type
            </Button>
            <Menu anchorEl={procTypeAnchorEl}
              open={Boolean(procTypeAnchorEl)}
              onClose={handleClose}
              className={`${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Procurement])
                ? ""
                : "hidden"
                }`}>
              <NestedMenuItem
                rightIcon={<KeyboardArrowRightIcon />}
                label=" B2B C&I"
                parentMenuOpen={Boolean(procTypeAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Procurement])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={`/Boqrfqitems/${b2bCandI_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Request For Quote
                  </MenuItem>
                </Link>

                <Link color="inherit" href={`/quoteInput/${b2bCandI_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin, ROLES.Vendor])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Quote Input
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/ratecomparison/${b2bCandI_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Rate Comparison & Create PO
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/editpos/${b2bCandI_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Edit POs
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/poApproval/${b2bCandI_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    PO Approval / Rejection
                  </MenuItem>
                </Link>
              </NestedMenuItem>
              <NestedMenuItem
                rightIcon={<KeyboardArrowRightIcon />}
                label="B2B MEP"
                parentMenuOpen={Boolean(procTypeAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Procurement])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={`/Boqrfqitems/${b2bMEP_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Request For Quote
                  </MenuItem>
                </Link>

                <Link color="inherit" href={`/quoteInput/${b2bMEP_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Quote Input
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/ratecomparison/${b2bMEP_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Rate Comparison & Create PO
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/editpos/${b2bMEP_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Edit POs
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/poApproval/${b2bMEP_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    PO Approval / Rejection
                  </MenuItem>
                </Link>
              </NestedMenuItem>
              <NestedMenuItem
                rightIcon={<KeyboardArrowRightIcon />}
                label=" BOQ Labour"
                parentMenuOpen={Boolean(procTypeAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Procurement])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={`/Boqrfqitems/${labour_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Request For Quote
                  </MenuItem>
                </Link>

                <Link color="inherit" href={`/quoteInput/${labour_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Quote Input
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/ratecomparison/${labour_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Rate Comparison & Create PO
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/editpos/${labour_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Edit POs
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/poApproval/${labour_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    PO Approval / Rejection
                  </MenuItem>
                </Link>
              </NestedMenuItem>
              <NestedMenuItem
                rightIcon={<KeyboardArrowRightIcon />}
                label=" BOQ Procurement"
                parentMenuOpen={Boolean(procTypeAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Procurement])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={`/Boqrfqitems/${procurement__ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Request For Quote
                  </MenuItem>
                </Link>

                <Link color="inherit" href={`/quoteInput/${procurement__ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Quote Input
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/ratecomparison/${procurement__ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Rate Comparison & Create PO
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/editpos/${procurement__ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Edit POs
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/poApproval/${procurement__ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    PO Approval / Rejection
                  </MenuItem>
                </Link>
              </NestedMenuItem>
              <NestedMenuItem
                rightIcon={<KeyboardArrowRightIcon />}
                label="BOQ Factory Work"
                parentMenuOpen={Boolean(procTypeAnchorEl)}
                className={`${roleMatch([ROLES.Admin, ROLES.BOQ_Manager, ROLES.Procurement])
                  ? ""
                  : "hidden"
                  }`}
              >
                <Link color="inherit" href={`/Boqrfqitems/${factoryWork_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Request For Quote
                  </MenuItem>
                </Link>

                <Link color="inherit" href={`/quoteInput/${factoryWork_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Quote Input
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/ratecomparison/${factoryWork_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Rate Comparison & Create PO
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/editpos/${factoryWork_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    Edit POs
                  </MenuItem>
                </Link>
                <Link color="inherit" href={`/poApproval/${factoryWork_ProcTypeId}`} className={`nav-link ${roleMatch([ROLES.Procurement, ROLES.Admin])
                  ? ""
                  : "hidden"
                  }`}>
                  <MenuItem onClick={handleClose} disableRipple>
                    PO Approval / Rejection
                  </MenuItem>
                </Link>
              </NestedMenuItem>
            </Menu>

            {/* Ends Procurement Type Menu */}

            {/* Expense Report  */}
            <Button
              id="expensereport-button"
              className={`nav-btn ${roleMatch([ROLES.Admin, ROLES.Accounts, ROLES.Expense_Manager])
                ? ""
                : "hidden"
                }`}
              disableElevation
              onClick={(e) => handleClick(e, "expensereport")}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Expense Report
            </Button>
            <Menu anchorEl={expenseReportAnchorE1}
              open={Boolean(expenseReportAnchorE1)}
              onClose={handleClose}>
              <Link color="inherit" href={"/expenseRegister"} className="nav-link">
                <MenuItem onClick={handleClose} disableRipple>
                  Expense Register
                </MenuItem>
              </Link>
            </Menu>

          </Box>

          {/* Spacer to push the button to the right */}
          <Box sx={{ flexGrow: 1 }} />

          <Box alignItems="center">
            <Button
              variant="outlined"
              sx={{ color: "white" }}
              id="account-button"
              onClick={(e) => handleClick(e, "account")}
              startIcon={<ManageAccountsIcon />}
            >
              {auth?.UserName}
            </Button>

            <Menu anchorEl={accountAnchorEl}
              open={Boolean(accountAnchorEl)}
              onClose={handleClose}>
              <MenuItem disabled onClick={handleClose} disableRipple>
                {auth?.UserName} ({auth?.Roles?.join(", ")})
              </MenuItem>
              <Link color="inherit" href={"/ChangePassword/" + auth.Id} className="nav-link">
                <MenuItem onClick={handleClose} disableRipple>
                  Change Password
                </MenuItem>
              </Link>
              <Link color="inherit" onClick={() => logout()} className="nav-link">
                <MenuItem onClick={handleClose} disableRipple>
                  Logout
                </MenuItem>
              </Link>
            </Menu>

          </Box>
        </Toolbar>



      </React.Fragment >
    </>
  );
};

export default Navbar;
