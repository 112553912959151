import { Guid } from "guid-typescript";
import { BoqSubTitleModel } from "../models/BoqSubTitleModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const initialFieldValues: BoqSubTitleModel = {
    BoqsubTitleId: Guid.EMPTY,
    BoqtitleId: "",
    BoqsubTitleName: "",
    Active: false,
}

const url = "MBoqsubTitles";

function getAllByBoqTitle(boqtitleId: number) {
    return customAxios.get(`${url}/GetAll/${boqtitleId}`, axiosRetryConfig);
}

function post(payload: BoqSubTitleModel) {
    return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}
function put(payload: BoqSubTitleModel) {
    return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}
function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function GetById(BoqsubTitleId: number) {
    return customAxios.get(`${url}/GetById/${BoqsubTitleId}`, axiosRetryConfig);
}

function getAllMBoqsubTitlesByPackageID(boqpackageId: number) {
    return customAxios.get(`${url}/GetAllMBoqsubTitlesByPackageID/${boqpackageId}`, axiosRetryConfig);
}
function GetAllMBoqsubTitleAttributesByAttType(BoqsubTitleId: number, AttributeType: number) {
    return customAxios.get(`${url}/GetAllMBoqsubTitleAttributesByAttType/${BoqsubTitleId}/${AttributeType}`, axiosRetryConfig);
}

function getSubTitleSelectList() {
    return customAxios.get(`${url}/GetSelectList`, axiosRetryConfig);
}
function getSubTitleSelectListByTitleId(titleId: string) {
    return customAxios.get(`${url}/GetSelectListByTitleId/${titleId}`, axiosRetryConfig);
}
function getAll() {
    return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

export const boqSubTitleService = {
    initialFieldValues,
    getAllByBoqTitle,
    post,
    put,
    remove,
    GetById,
    getAllMBoqsubTitlesByPackageID,
    getAll,
    GetAllMBoqsubTitleAttributesByAttType,
    getSubTitleSelectList,
    getSubTitleSelectListByTitleId
};