export const config = {
  formDataConfig: {
    headers: {
      "content-type": "multipart/form-data",
    },
  },
};

export const AttributeTypes = {
  Size: 1,
  Make: 3,
  Color: 5,
  Material: 7,
  Activity: 9,
};

export const ROLES = {
  Admin: "Admin",
  Accounts: 'Accounts',
  Client: 'Client',
  Customer: 'Customer',
  Project_Incharge: 'Project_Incharge',
  ReadOnly: 'ReadOnly',
  Vendor: 'Vendor',
  Expense: 'Expense',
  Site_Incharge: 'Site_Incharge',
  Expense_Manager: 'Expense_Manager',
  BOQ_Manager: 'BOQ_Manager',
  Procurement: 'Procurement',
  Procurement_Manager: 'Procurement_Manager',
  Factory_Work: 'Factory_Work',
  Labour: 'Labour',
  Others: 'Others'
};

export const Folderpath = {
  'ExcelFile': '/ExcelFile',
  'Employee': '/Employee',
  'Expense': '/ExpenseUpload',
  'RentAgreementFile': '/RentAgreementFileUpload',
  'BOQUploadImage':'/BOQUploadImage'
}

export const AddType = {
  Title: 'Title',
  SubTitle: 'SubTitle',
  Item: 'Item',
  BOQShortForm: 'BOQShortForm',
}
export const ItemTypes = ["Finished", "Intermediate", "Raw Material"];