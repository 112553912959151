import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import { boqitemService } from "../../services/BoqItemService";
import { BoqItemModel } from "../../models/BoqItemModel";
import { useEffect } from "react";
import Controls from "../../utility/Controls.tsx/Controls";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useForm from "../../utility/hooks/UseForm";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { SelectListModel } from "../../models/ApiResponse";
import { boqTitleService } from "../../services/BoqTitleService";
import { boqSubTitleService } from "../../services/BoqSubTitleService";
import { boqPackageService } from "../../services/BoqPackageService";

const BoqItemForm = (...props: any) => {
  globalService.pageTitle = "BoqitemNames";
  const { BoqpackageId, BoqitemId }: any = useParams();
  let navigate = useNavigate();
  const mode = BoqitemId ? "Edit" : "Create";

  const [titleList, setTitleList] = useState<SelectListModel[]>([]);
  const [PackageName, setPackageName] = useState();
  const [subTitleList, setSubTitleList] = useState<SelectListModel[]>([]);
  const validate = (fieldValues: BoqItemModel = values) => {
    let temp: any = { ...errors };
    if ("BoqtitleId" in fieldValues)
      temp.BoqtitleId = fieldValues.BoqtitleId 
        ? ""
        : "Please Select BOQ  Title Name";
    if ("BoqsubTitleId" in fieldValues)
      temp.BoqsubTitleId = fieldValues.BoqsubTitleId 
        ? ""
        : "Please Select BOQ Sub Title Name";
    if ("BoqitemName" in fieldValues)
      temp.BoqitemName = fieldValues.BoqitemName
        ? ""
        : "BOQ Item Name is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(boqitemService.initialFieldValues, validate, BoqpackageId);


  const newItem = () => {
    setValues(boqitemService.initialFieldValues);
  };
  //This is used since in get the null property is not returned
  function setFormValue(model: BoqItemModel) {
    let newModel = {
      BoqitemId: model.BoqitemId,
      BoqsubTitleId: model.BoqsubTitleId,
      BoqitemName: model.BoqitemName,
      Active: model.Active,
      BoqtitleId: model.BoqtitleId,
      // Abrv:model.Abrv,
    };
    return newModel;
  }

  useEffect(() => {
    if (BoqitemId) {
      getBoqitemName(BoqitemId);
      setErrors({});
    } else newItem();
    if(BoqpackageId) getByPackageId();
    if (titleList.length === 0) getTitleList();
    if (subTitleList.length === 0) getSubTitleList();
  }, [BoqitemId]);

  const getBoqitemName = (BoqitemId: any) => {
    boqitemService.getByIdboqitemId(BoqitemId).then((response) => {
      if (response) {
        let result = response.data;
        result.data.BoqtitleId = result.data.BoqsubTitle?.Boqtitle?.BoqtitleId;
        setValues(setFormValue(result.data));
      }
    });
  };

  const getTitleList = () => {
    boqTitleService.getTitleSelectList().then((response) => {
      setTitleList(response.data);
    });
  };

  const getSubTitleList = () => {
    boqSubTitleService.getSubTitleSelectList().then((response) => {
      setSubTitleList(response.data);
    })
  };

  const getByPackageId = () => {
    boqPackageService.getById(BoqpackageId).then((response: { data: any; }) => {
        if(response){
            let result = response.data;
            setPackageName(result.list.BoqpackageName);
        }
    });
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      if (BoqitemId) {
        boqitemService.put(values).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/boqItems");
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        boqitemService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              resetForm();
              navigate("/boqItems");
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  return (
    <div>
      <Typography variant="h5" align="center">
        BOQ Item
      </Typography>
      <Typography variant="body1" align='right'> <b>BOQ Package :</b> {PackageName}</Typography>
      <>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Controls.Select
                      required
                      name="BoqtitleId"
                      label="Title Name"
                      options={titleList}
                      value={titleList.length > 0 ? values.BoqtitleId : ""}
                      onChange={(e: any) => {
                        handleInputChange(e);
                      }}
                      error={errors.BoqtitleId}
                      
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controls.Select
                      required
                      name="BoqsubTitleId"
                      label="SubTitle Name"
                      options={subTitleList}
                      value={subTitleList.length > 0 ? values.BoqsubTitleId : ""}
                      onChange={(e: any) => {
                        handleInputChange(e);
                      }}
                      error={errors.BoqsubTitleId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controls.Input
                      required
                      label="Item Name"
                      name="BoqitemName"
                      value={values.BoqitemName}
                      onChange={handleInputChange}
                      error={errors.BoqitemName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} className={mode !== "Edit" ? "hidden" : ""}>
                    <Controls.Checkbox
                      label="Active"
                      name="Active"
                      value={values.Active}
                      onChange={handleInputChange}
                      error={errors.Active}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Stack spacing={2} direction="row">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/boqItems")}
                >
                  Back To List
                </Button>
              </Stack>
            </CardActions>
          </Card>
        </form>
      </>
    </div>
  );
};

export default BoqItemForm;
