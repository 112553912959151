import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { MEmployeeFileModel } from "../models/MEmployeeFilesModel";

const initialFieldValues: MEmployeeFileModel = {
  EmployeeFileId: Guid.EMPTY,
  EmployeeId: "",
  FileName: "",
  CreatedOn: null
}

const url = 'MEmployeeFiles';

function remove(EmployeeFileId: number) {

  return customAxios.delete(`${url}/${EmployeeFileId}`, axiosRetryConfig);
}


function getAllFileByEmployees(EmployeeId: any) {

  return customAxios.get(`${url}/GetAllFileByEmployees/${EmployeeId}`, axiosRetryConfig);
}


export const employeeFileService = {
  initialFieldValues,
  remove,
  getAllFileByEmployees,
}
