import { Guid } from "guid-typescript";
import { BoqDocFileModel } from "../models/BoqdocFilesModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { config } from "../utility/Config";

const initialFieldValues: BoqDocFileModel = {
    BoqdocFileId: Guid.EMPTY,
    ProjectDirectoryId: "",
    CreatedById: "",
    FileName: "",
    CreatedOn: null,
    files: [],
}

const url = 'BoqDocs';

function docsImport(payload: BoqDocFileModel, files: File[], ) 
{
    const formData = new FormData();

    if (files != null && files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        formData.append("BoqDocs", files[i]);
      }
    }
    formData.append("data", JSON.stringify(payload));
    return customAxios.post(`${url}/Add`, formData, config.formDataConfig);
  }

  function getById(BoqdocFileId: number) {
    return customAxios.get(`${url}/GetById/${BoqdocFileId}`, axiosRetryConfig);
  }

  function GetBoqdocFilesForPoEdit (boqpob2bId : any) {
    return customAxios.get(`${url}/GetBoqdocFilesForPoEdit/${boqpob2bId}`, axiosRetryConfig);
  }
  
  function getByProjectDirectoryId(ProjectDirectoryId: any) {
  
    return customAxios.get(`${url}/GetByProjectDirectoryId/${ProjectDirectoryId}`, axiosRetryConfig);
  }

  function getByBoqrfqId(boqrfqId: any) {
  
    return customAxios.get(`${url}/GetByBoqrfqId/${boqrfqId}`, axiosRetryConfig);
  }
  
  function getByBoqpob2bId(boqpob2bId: any) {
  
    return customAxios.get(`${url}/GetByBoqpob2bId/${boqpob2bId}`, axiosRetryConfig);
  }

  function remove(BoqdocFileId: number) {

    return customAxios.delete(`${url}/${BoqdocFileId}`, axiosRetryConfig);
  }
  
  function getAll (ProjectDirectoryId: any) {
    return customAxios.get(`${url}/GetAll/${ProjectDirectoryId}`, axiosRetryConfig);
}

  export const BoqDocFileService = {
    initialFieldValues,
    docsImport,
    getById,
    getAll,
    GetBoqdocFilesForPoEdit,
    getByProjectDirectoryId,
    remove,
    getByBoqrfqId,
    getByBoqpob2bId,
  }
